import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import {
  KEY_CHALLENGE_TOUR_CONNECTED_MISSION_LIST,
  KEY_STAMPTOUR_REQUEST_DETAIL,
} from '../../../../common/key';
import { getStamptourRequestDetail } from '../../../../common/api/ApiStamptour';
import { SwConfirmDialog } from '../../../commonComponent/views/SwConfirmDialog';
import { getChallengeConnectedMissionList } from '../../../../common/api/ApiChallengeTour';
import { selectedConnectedMissionState } from '../../../../common/atom';
import { DefaultPhotoCertificate } from '../../../../models/challenge';

const columns = ['스탬프 지점', '연결된 미션', '삭제'];

interface Props {
  challengeId: string;
  mission: any;
  reload: boolean;
  setReload: Dispatch<React.SetStateAction<boolean>>;
}

export const ConnectMissionTable: React.FC<Props> = ({
  challengeId,
  mission,
  reload,
  setReload,
}) => {
  const { loginVal } = useSession();

  const [selectedConnectedMission, setSelectedConnectedMission] =
    useRecoilState(selectedConnectedMissionState);
  const [stamptourId, setStamptourId] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [contentList, setContentList] = useState<any>([]);

  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const { refetch: challengeConnectedMissionRefetch } = useQuery(
    KEY_CHALLENGE_TOUR_CONNECTED_MISSION_LIST,
    () => {
      if (mission && challengeId) {
        const newData: any = {
          challengeId: challengeId,
          missionId: mission.missionId,
        };
        return getChallengeConnectedMissionList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.mission) {
          const tmpData = res.mission.map((item: any) => {
            const temp = {
              id: item.connectedId,
              name: item.connectedName,
              targetId: item.targetId,
              targetType: item.targetType,
              goal: item.goal,
            };
            return temp;
          });
          setContentList(tmpData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const clickRowItem = (idx: number, newContentList: any[]) => {
    setSelectedRow(idx);
    setSelectedConnectedMission({
      challengeId: challengeId,
      missionId: mission.missionId,
      connectedId: newContentList[idx].id,
      connectedName: newContentList[idx].name,
      targetId: newContentList[idx].targetId,
      targetType: newContentList[idx].targetType,
      goal: newContentList[idx].goal,
    });
  };

  const clickRow = (event: any, idx: number) => {
    if (event.target.classList.contains('MuiButton-root')) {
      return;
    }
    clickRowItem(idx, contentList);
  };

  const getConnectedTypeName = (targetType: number) => {
    let result = '-';
    switch (targetType) {
      case 1:
        result = '오디오';
        break;
      case 2:
        result = '사진인증';
        break;
      case 3:
        result = '선택인증';
        break;
      case 4:
        result = '설문조사';
        break;
      case 5:
        result = '퀴즈';
        break;
      default:
        '-';
    }
    return result;
  };

  useEffect(() => {
    if (reload) {
      setReload(false);
      challengeConnectedMissionRefetch();
    }
  }, [reload]);

  useEffect(() => {
    if (mission && challengeId) {
      setSelectedRow(-1);
      challengeConnectedMissionRefetch();
    }
  }, [mission]);

  return (
    <>
      {contentList && contentList.length > 0 && (
        <Box>
          <Typography sx={{ m: '1rem 0 ' }}>미션연결 목록</Typography>
          <TableContainer
            sx={{
              p: '0 1rem',
              height: '33vh',
              overflowY: 'auto',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <TableCell
                      key={`audio_content_head_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {contentList?.map((row: any, idx: number) => (
                  <TableRow
                    key={`audio_content_body_${idx}`}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                    onClick={event => clickRow(event, idx)}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {getConnectedTypeName(row.targetType)}
                    </TableCell>
                    <TableCell
                      className='removeButton'
                      sx={{ p: '.1rem .6rem' }}
                    >
                      <Button
                        color='info'
                        variant='contained'
                        onClick={() => {
                          setOpenConfirm(true);
                        }}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <SwConfirmDialog
        contents='삭제하시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </>
  );
};

import { Dispatch } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Challenge Tour
 */

export const getChallengeTourMissionList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/challengetour/${data.challengeId}/mission`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeTourMissionTypeList = () =>
  xapi.get(`/admin/v2/apis/challengetour/mission-type`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerChallengeMission = (data: any) =>
  xapi
    .post(`/admin/v2/apis/challengetour/${data.challengeId}/mission`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteChallengeMission = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/challengetour/${data.challengeId}/mission/${data.missionId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

/*
 * Challenge Connect Mission
 */
export const getChallengeConnectedMissionList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challengetour/${data.challengeId}/mission/${data.missionId}/connected`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerChallengeConnectMission = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challengetour/${data.challengeId}/mission/connected`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeConnectedAudioContent = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challengetour/${data.challengeId}/mission/${data.missionId}/audio-content`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeConnectedPhotoCertificate = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challengetour/${data.challengeId}/mission/${data.missionId}/photo-certificate`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getChallengeConnectedQuestionAnswer = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challengetour/${data.challengeId}/mission/${data.missionId}/question-answer`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

/*
 * Reward Mission
 */
export const getChallengeTourRewardMissionList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challengetour/${data.challengeId}/reward/${data.rewardId}/mission`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

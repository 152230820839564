import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useResize } from '../../../hooks/resize';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import excelIcon from '../../../images/excel.png';

import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import {
  KEY_APP_REVIEW_LIST,
  KEY_NOTICE_LIST,
  KEY_STORE_BRAND_PRODUCT_LIST,
} from '../../../common/key';
import {
  changeAppReview,
  getAppReviewList,
} from '../../../common/api/ApiAppReview';
import { ProductAdd } from './ProductAdd';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import {
  getStoreBrandProductDownload,
  getStoreBrandProductList,
  putStoreBrandProduct,
} from '../../../common/api/ApiRewardMall';
import { productTypeList } from '../common/SelectProductType';
import { productStatusList } from '../common/SelectProductStatus';
import { downloadS3FileDirect } from '../../../common/api/ApiDownload';
import { useSession, xapi } from '../../../hooks/session';
import SwLoading from '../../commonComponent/spinner/SwLoading';

interface Props {}

const columns = [
  '번호',
  '상품명',
  '판매가',
  '상품 유형',
  '브랜드명',
  '등록일',
  '재고',
  '상태',
];

const searchOption = [
  {
    title: '상품명',
    value: 'product',
  },
  {
    title: '브랜드명',
    value: 'brand',
  },
];

const SAMPLE_FILE = '/v2/store/common/store_product_register_format.xlsx';
export const STORE_ID = '69739D4770C8429F9F89FD36B9816BF5';

export const ProductManagement: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { loginVal } = useSession();

  const productRegistRef = useRef<any>();
  const { isMobile } = useResize();
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();
  const requestTypeRef = useRef<any>();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [productList, setProductList] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [loading, setLoading] = useState<any>(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [openProductDialog, setOpenProductDialog] = useState<boolean>(false);

  const resetCheckButtons = () => {
    setCheckedAll(false);
    setCheckedButtons([]);
  };

  const {
    data,
    isLoading,
    refetch: storeBrandProductRefetch,
  } = useQuery(
    KEY_STORE_BRAND_PRODUCT_LIST,
    () => {
      setLoading(true);
      const newData: any = {
        storeId: STORE_ID,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getStoreBrandProductList(newData);
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res != null) {
          setProductList(res.product);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (
    newSearchKey: string,
    newSearchWord: string,
    newPage: number,
    newRowsPerPage: number
  ) => {
    queryClient.prefetchQuery(KEY_STORE_BRAND_PRODUCT_LIST, () => {
      const newData = {
        storeId: STORE_ID,
        page: newPage,
        rowsPerPage: newRowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
      };
      return getStoreBrandProductList(newData);
    });
  };

  const getDownloadSampleFile = async (path: string) => {
    try {
      const dataUrl = await downloadS3FileDirect(path);
    } catch (error) {
      console.error('Error downloading or displaying image:', error);
    }
  };

  const uploadProductFile = (evt: ChangeEvent<HTMLInputElement>) => {
    const excelFile = evt.target.files && evt.target.files[0];

    var formData = new FormData();
    if (excelFile !== null) formData.append('file', excelFile);

    xapi.post(`/admin/v2/apis/upload/store/product`, formData).then(res => {
      productRegistRef.current.value = null;
      if (res.status !== 200) throw new Error('http 에러');
      setOpenSnackbar(true);
      setDialogMessage('엑셀 업로드로 상품을 등록했습니다.');
      storeBrandProductRefetch();
      return res.data;
    });
  };

  const { mutate: putStoreBrandProductMutate, isError: isMutateOrderError } =
    useMutation(putStoreBrandProduct, {
      onSuccess: () => {
        const newType = requestTypeRef.current;
        setOpenSnackbar(true);
        setDialogMessage(
          newType === 1
            ? `선택한 상품을 ${
                openConfirm !== 0 ? '모두' : ''
              } 삭제 처리했습니다.`
            : newType === 2
            ? `선택한 상품을 모두 숨김 처리했습니다.`
            : newType === 3
            ? `선택한 상품을 모두 판매중 처리했습니다.`
            : newType === 4
            ? `선택한 상품을 모두 품절 처리했습니다.`
            : ''
        );
        resetCheckButtons();
        setOpenConfirm(0);
        storeBrandProductRefetch();
        requestTypeRef.current = -1;
      },
      onError: error => {
        setOpenConfirm(0);
        setOpenSnackbar(true);
        setDialogMessage(
          '상품 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const donwloadProductList = () => {
    const newData = {
      storeId: STORE_ID,
      page: 0,
      rowsPerPage: 0,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
    };
    getStoreBrandProductDownload(newData);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      productList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.productId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const searchProduct = () => {
    const newSearchKey = selectRef.current.value;
    const newSearchWord = searchRef.current.value;
    setPage(1);
    setSearchKey(newSearchKey);
    setSearchWord(newSearchWord);
    prefetch(newSearchKey, newSearchWord, 1, rowsPerPage);
  };

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
    prefetch(searchKey, searchWord, 1, newRowsPerPage);
  };

  const onChangeProduct = (type: number) => {
    const newIds = [...checkedButtons];
    const newData = {
      type: type,
      ids: newIds,
    };

    if (newIds === null || newIds.length === 0) {
      setOpenSnackbar(true);
      setDialogMessage('상품을 선택해주세요');
      return;
    }
    putStoreBrandProductMutate(newData);
  };

  const onChangeStatus = (id: string, value: number) => {
    requestTypeRef.current = value + 2;
    const newData = {
      type: value + 2,
      ids: [id],
    };
    putStoreBrandProductMutate(newData);
  };

  const chooseRequest = (i: number) => {
    setSelectedProduct(productList[i]);
    setSelectedRow(i);
    setOpenProductDialog(true);
  };

  useEffect(() => {
    storeBrandProductRefetch();
  }, [page]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>상품 관리</title>
      </Helmet>
      <Paper sx={{ m: '1rem 0', p: '1rem' }}>
        <Box className='flex_between'>
          <SelectSearchBox
            placeholderString='검색어를 입력해주세요'
            searchOption={searchOption}
            searchWord={searchRef}
            optionValue={selectRef}
            defaultOption='product'
            isSearching={() => searchProduct()}
          />
          <Box>
            <Button
              sx={{ mr: '.5rem' }}
              variant='outlined'
              color='info'
              onClick={() => {
                setSelectedRow(-1);
                setSelectedProduct(null);
                setOpenProductDialog(true);
              }}
            >
              상품 추가
            </Button>
            <Button
              sx={{ mr: '.5rem' }}
              variant='outlined'
              color='info'
              onClick={() => getDownloadSampleFile(SAMPLE_FILE)}
            >
              <img src={excelIcon} alt='id' width='18px' height='18px' />
              양식 다운로드
            </Button>
            <Button
              sx={{ mr: '.5rem' }}
              variant='outlined'
              color='info'
              onClick={() => {
                const productRegistBtn =
                  document.getElementById('productRegist');
                productRegistBtn && productRegistBtn.click();
              }}
            >
              <img src={excelIcon} alt='id' width='18px' height='18px' />
              양식 업로드
            </Button>
            <input
              ref={productRegistRef}
              type='file'
              id='productRegist'
              style={{ display: 'none' }}
              onChange={uploadProductFile}
            />
            <TextField
              select
              value={rowsPerPage}
              defaultValue={10}
              onChange={evt => {
                onChangeRowsPerPage(Number(evt.target.value));
              }}
              sx={{
                mb: 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  height: '2rem',
                },
              }}
            >
              <MenuItem value={5}>5개씩 보기</MenuItem>
              <MenuItem value={10}>10개씩 보기</MenuItem>
              <MenuItem value={15}>15개씩 보기</MenuItem>
              <MenuItem value={20}>20개씩 보기</MenuItem>
            </TextField>
          </Box>
        </Box>
        <Divider sx={{ p: '1rem 0 0 0', m: '0' }} />
        <Paper sx={{ m: '1rem 0', p: '1rem' }}>
          <Box className='flex_between'>
            <Box className='flex_start'>
              <Typography color='primary'>{totalRecords}</Typography>
              <Typography>개가 조회되었습니다.</Typography>
            </Box>
            <Box>
              <Button
                sx={{ mr: '.5rem' }}
                variant='outlined'
                color='warning'
                onClick={() => setOpenConfirm(1)}
              >
                상품 삭제
              </Button>
              <Button
                sx={{ mr: '.5rem' }}
                variant='outlined'
                color='info'
                onClick={() => setOpenConfirm(2)}
              >
                일괄 숨김 처리
              </Button>
              <Button
                sx={{ mr: '.5rem' }}
                variant='outlined'
                color='info'
                onClick={() => setOpenConfirm(3)}
              >
                일괄 판매중 처리
              </Button>
              <Button
                sx={{ mr: '.5rem' }}
                variant='outlined'
                color='info'
                onClick={() => setOpenConfirm(4)}
              >
                일괄 품절 처리
              </Button>
              <Button
                variant='outlined'
                color='info'
                onClick={donwloadProductList}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
            </Box>
          </Box>
          <Divider sx={{ p: 0, m: '1rem 0 0 0' }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={handleSelectAllClick}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productList?.map((row: any, idx: number) => (
                <TableRow
                  key={`product_list_${idx}`}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  <TableCell
                    padding='checkbox'
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                    onClick={e => e.stopPropagation()}
                  >
                    <Checkbox
                      id={row.productId}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, row.productId);
                      }}
                      checked={checkedButtons.includes(row.productId)}
                    />
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                  >
                    {(page - 1) * rowsPerPage + idx + 1}
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                  >
                    <Box className='flex_start'>
                      {row.image ? (
                        <img
                          src={
                            row.image !== ''
                              ? `${imageUrlPrefix}${row.image}`
                              : ''
                          }
                          alt='img'
                          style={{
                            width: '3rem',
                            height: '3rem',
                            margin: '0 .5rem 0 0',
                          }}
                        />
                      ) : (
                        ''
                      )}
                      <Typography>{row.name}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                  >
                    {row.salePrice}
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                  >
                    {productTypeList[row.type - 1]}
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                  >
                    {row.brandName}
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                  >
                    {timestamp2Localestring(row.createDate, 1000)}
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                  >
                    {row.quantity}
                  </TableCell>
                  <TableCell
                    className={
                      selectedRow === idx ? clsx(' selected_row') : clsx(' ')
                    }
                    onClick={e => e.stopPropagation()}
                  >
                    <TextField
                      select
                      value={row.status}
                      onChange={evt => {
                        const newStatus = Number(evt.target.value);
                        onChangeStatus(row.productId, newStatus);
                      }}
                      sx={{
                        mb: 0,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '4px',
                          height: '2rem',
                        },
                      }}
                    >
                      <MenuItem value={0}>숨김</MenuItem>
                      <MenuItem value={1}>판매중</MenuItem>
                      <MenuItem value={2}>품절</MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
              ))}
              {(productList === null || productList.length === 0) && (
                <TableRow>
                  <TableCell colSpan={8}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={productList && Math.ceil(totalRecords / rowsPerPage)}
          />
        </Paper>
        {loading && <SwLoading />}
      </Paper>
      <ProductAdd
        open={openProductDialog}
        onClose={() => {
          setOpenProductDialog(false);
          searchProduct();
        }}
        isEdit={selectedProduct !== null}
        productInfo={selectedProduct}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents={
          openConfirm === 1
            ? '선택한 상품을 모두 삭제 처리하시겠습니까?'
            : openConfirm === 2
            ? '선택한 상품을 모두 숨김 처리 하시겠습니까?'
            : openConfirm === 3
            ? '선택한 상품을 모두 판매중 처리 하시겠습니까?'
            : openConfirm === 4
            ? '선택한 상품을 모두 품절 처리 하시겠습니까?'
            : ''
        }
        open={!!openConfirm}
        onClose={() => setOpenConfirm(0)}
        onConfirm={() => {
          requestTypeRef.current = openConfirm;
          onChangeProduct(openConfirm);
        }}
        confirm={1}
      />
    </div>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSession, xapi } from '../../../hooks/session';
import { IUploadFile } from '../../../models/common';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';

const MAX_NAME_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;

interface Props {
  open: boolean;
  spot: any;
  spotIndex: number;
  onClose: () => void;
  onConfirm: (idx: number, spot: any) => void;
  onAdd: (idx: number, spot: any) => void;
}

export const WalkcourseSpotInfo: React.FC<Props> = ({
  open,
  spot,
  spotIndex,
  onClose,
  onConfirm,
  onAdd,
}) => {
  const IMAGE_COUNT = 5;
  const defaultValue = {
    lat: '',
    lng: '',
    radius: '',
    name: '',
    image: '',
    description: '',
  };
  const { loginVal } = useSession();
  const [value, setValue] = useState<any>(defaultValue);
  const tt = useRef<any>();
  const [address, setAddress] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [spotMainImage, setSpotMainImage] = useState<string>('');
  const [spotMainImageFile, setSpotMainImageFile] = useState<any>(null);
  const [descriptionBuffer, setDescriptionBuffer] = useState<string[]>([]);
  const [spotImage, setSpotImage] = useState<string>('');
  const [spotImageFile, setSpotImageFile] = useState<any>(null);
  const [spotImageBuffer, setSpotImageBuffer] = useState<string[]>([]);
  const [spotImageFileBuffer, setSpotImageFileBuffer] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });

  const saveCurrentPage = () => {
    const newDescriptionBuffer = [...descriptionBuffer];
    newDescriptionBuffer[page - 1] = description;
    setDescriptionBuffer(newDescriptionBuffer);

    const newImageBuffer = [...spotImageBuffer];
    newImageBuffer[page - 1] = spotImage;
    setSpotImageBuffer(newImageBuffer);

    const newImageFileBuffer = [...spotImageFileBuffer];
    newImageFileBuffer[page - 1] = spotImageFile;
    setSpotImageFileBuffer(newImageFileBuffer);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    saveCurrentPage();

    setDescription(descriptionBuffer[newPage - 1]);
    setSpotImage(spotImageBuffer[newPage - 1]);
    setSpotImageFile(spotImageFileBuffer[newPage - 1]);
    setPage(newPage);
  };

  const getBufferCount = () => {
    const newDescription = descriptionBuffer.filter(
      (desc: string) => desc !== null && desc !== ''
    );
    return newDescription === null ||
      newDescription === undefined ||
      newDescription.length === 0
      ? 1
      : newDescription.length;
  };

  const onChangeValue = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = evt.target.name;
    const targetValue =
      target !== 'name'
        ? evt.target.value
        : evt.target.value.substring(0, MAX_NAME_LENGTH);
    const newValue = { ...value, [target]: targetValue };
    setValue(newValue);
  };

  const addDescriptionImage = () => {
    const newDescriptionBuffer = [...descriptionBuffer];
    newDescriptionBuffer[page - 1] = description;
    newDescriptionBuffer[page] = '';
    setDescriptionBuffer(newDescriptionBuffer);

    const newImageBuffer = [...spotImageBuffer];
    newImageBuffer[page - 1] = spotImage;
    newImageBuffer[page] = '';
    setSpotImageBuffer(newImageBuffer);

    const newImageFileBuffer = [...spotImageFileBuffer];
    newImageFileBuffer[page - 1] = spotImageFile;
    newImageFileBuffer[page] = null;
    setSpotImageFileBuffer(newImageFileBuffer);

    setDescription('');
    setSpotImage('');
    setSpotImageFile(null);
    const newPage = newDescriptionBuffer.length;

    setPage(newPage);
    setTotalPage(newPage);
  };

  const deleteDescriptionImage = () => {
    if (totalPage === 1) {
      return;
    }

    const newDescriptionBuffer = descriptionBuffer.filter(
      (item: any, idx: number) => idx !== page - 1
    );
    setDescriptionBuffer(newDescriptionBuffer);

    const newImageBuffer = spotImageBuffer.filter(
      (item: any, idx: number) => idx !== page - 1
    );
    setSpotImageBuffer(newImageBuffer);

    const newImageFileBuffer = spotImageFileBuffer.filter(
      (item: any, idx: number) => idx !== page - 1
    );
    setSpotImageFileBuffer(newImageFileBuffer);

    const newPage = page > totalPage - 1 ? totalPage - 1 : page;
    if (page > totalPage - 1) {
      setPage(newPage);
    }
    setTotalPage(totalPage - 1);

    setDescription(newDescriptionBuffer[newPage - 1]);
    setSpotImage(newImageBuffer[newPage - 1]);
    setSpotImageFile(newImageFileBuffer[newPage - 1]);
  };

  async function saveSpotInfo() {
    var newMainUploadUrl = '';
    if (spotMainImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'walkcourse',
        spotMainImageFile
      );
      newMainUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newDescription = await Promise.all(
      descriptionBuffer
        .filter(item => item.length > 0)
        .map(async (item: any, idx: number) => {
          var imageUrl = null;
          const imageFile = spotImageFileBuffer[idx];
          var newUploadFile: any = null;
          if (imageFile !== null && imageFile !== undefined) {
            newUploadFile = await uploadFile('walkcourse', imageFile);
          }

          return {
            idx: idx + 1,
            description: item,
            image:
              spotImageBuffer[idx] === null ||
              spotImageBuffer[idx] === undefined
                ? ''
                : spotImageBuffer[idx],
            imageFile:
              imageFile !== null && imageFile !== undefined ? imageFile : null,
            uploadUrl:
              imageFile !== null && imageFile !== undefined
                ? newUploadFile.data.uploadUrl.file.path
                : null,
          };
        })
    );

    const newConvertDescription = newDescription.map((desc: any) => {
      const newData: any = {
        idx: desc.idx,
        description: desc.description,
        image: desc.uploadUrl !== null ? desc.uploadUrl : desc.image,
      };
      return newData;
    });

    const newValue = {
      ...value,
      image: newMainUploadUrl,
      address: address,
      description: JSON.stringify(newConvertDescription),
    };
    setValue(newValue);

    spot && spot?.lng !== undefined && spot?.lat !== undefined
      ? onConfirm(spotIndex, newValue)
      : onAdd(spotIndex, newValue);
  }

  const changeDescription = (desc: string) => {
    setDescription(desc);
    const newDescriptionBuffer = [...descriptionBuffer];
    newDescriptionBuffer[page - 1] = desc;
    setDescriptionBuffer(newDescriptionBuffer);
  };

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      setAddress(newAddress);
    }
  }, [targetData]);

  useEffect(() => {
    if (spotImage === '' && spotImageFile === null) {
      const newImageBuffer = [...spotImageBuffer];
      newImageBuffer[page - 1] = '';
      setSpotImageBuffer(newImageBuffer);

      const newImageFileBuffer = [...spotImageFileBuffer];
      newImageFileBuffer[page - 1] = null;
      setSpotImageFileBuffer(newImageFileBuffer);
    } else {
      const newImageFileBuffer = [...spotImageFileBuffer];
      newImageFileBuffer[page - 1] = spotImageFile;
      setSpotImageFileBuffer(newImageFileBuffer);
    }
  }, [spotImage, spotImageFile]);

  useEffect(() => {
    setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);

    if (spot && spot?.lat !== undefined && spot?.lat !== undefined && open) {
      const newValue = {
        id: spot?.id,
        lat: spot?.lat,
        lng: spot?.lng,
        radius: spot?.radius,
        name: spot.name ? spot.name : '',
        className: spot?.className ? spot.className : '',
        link: spot?.link ? spot.link : '',
        phoneNo: spot?.phoneNo ? spot.phoneNo : '',
      };
      setAddress(spot?.address ? spot.address : '');
      setDescription('');
      setValue(newValue);
      setPage(1);

      setSpotMainImage(spot?.image?.length > 0 ? spot?.image : '');
      setSpotMainImageFile(null);

      const newDescriptionBuffer: any[] = [];
      const newImageBuffer: any[] = [];
      const newFileBuffer: any[] = [];
      const newDescription =
        spot?.description !== undefined && spot?.description !== null
          ? JSON.parse(spot.description)
          : [];
      newDescription.map((item: any) => {
        newDescriptionBuffer.push(
          item.description !== undefined && item.description !== null
            ? item.description
            : ''
        );
        const newImage =
          item.imageFile !== '' &&
          item.imageFile !== null &&
          item.imageFile !== undefined
            ? item.uploadUrl
            : item.image !== '' &&
              item.image !== null &&
              item.image !== undefined
            ? item.image
            : '';
        newImageBuffer.push(newImage);
        newFileBuffer.push(null);
      });

      setDescriptionBuffer(newDescriptionBuffer);
      setSpotImageBuffer(newImageBuffer);
      setSpotImageFileBuffer(newFileBuffer);
      setDescription(
        newDescriptionBuffer.length > 0 ? newDescriptionBuffer[0] : ''
      );
      setSpotImage(newImageBuffer.length > 0 ? newImageBuffer[0] : '');
      setSpotImageFile(null);
      setTotalPage(
        newDescriptionBuffer.length > 0 ? newDescriptionBuffer.length : 1
      );
    }
    return () => {
      setValue(defaultValue);
    };
  }, [open]);

  return (
    <Dialog
      id='test'
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          marginTop: '18vh',
          marginLeft: '35vw',
          padding: '1rem .5rem 0',
          height: '66vh',
          width: '22vw',
          minWidth: '333px',
        },
      }}
      ref={tt}
    >
      <DialogTitle sx={{ padding: '1rem .5rem 0' }}>
        {spotIndex + 1}번 장소
      </DialogTitle>
      <DialogContent sx={{ padding: '1rem .5rem 0' }}>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>위도</Typography>
          <TextField
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.lat}
            name='lat'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>경도</Typography>
          <TextField
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.lng}
            name='lng'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>장소 이름</Typography>
          <TextField
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.name}
            name='name'
            onChange={evt => onChangeValue(evt)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>
                    {value.name.length} / {MAX_NAME_LENGTH}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>장소 분류</Typography>
          <TextField
            placeholder='가게, 유적지 등'
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.className}
            name='className'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>장소 이미지</Typography>
          <SwFileSetting
            setImage={setSpotMainImage}
            image={
              spotMainImageFile !== null
                ? spotMainImage
                : spotMainImage !== '' && spotMainImage !== undefined
                ? `${imageUrlPrefix}${spotMainImage}`
                : ''
            }
            setFile={setSpotMainImageFile}
            file={spotMainImageFile}
            disabled={false}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>(선택)링크 정보</Typography>
          <TextField
            placeholder='url'
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.link}
            name='link'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>(선택)전화번호</Typography>
          <TextField
            placeholder='02-0000-0000'
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.phoneNo}
            name='phoneNo'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>(선택)주소</Typography>
          <TextField
            placeholder='예) 서울 강남구 봉은사로 437'
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={address}
            name='address'
            onChange={evt => setAddress(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={() => setOpenPostCode(1)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {openPostCode && (
          <SwSearchPostCode
            open={!!openPostCode}
            onClose={() => setOpenPostCode(0)}
            setTarget={setTargetData}
          />
        )}
        <Box sx={{ p: '.5rem' }}>
          <Box className='flex_between'>
            <Typography sx={{ fontWeight: 500 }}>장소 설명</Typography>
            <Box>
              {totalPage > 1 && (
                <Button
                  onClick={deleteDescriptionImage}
                  size='small'
                  sx={{ color: 'red' }}
                >
                  삭제
                </Button>
              )}
              <Button onClick={addDescriptionImage} size='small'>
                추가
              </Button>
            </Box>
          </Box>
          <TextField
            placeholder='장소 설명을 입력해주세요.'
            fullWidth
            multiline
            rows={3}
            value={description}
            name='description'
            onChange={evt =>
              changeDescription(
                evt.target.value.substring(0, MAX_DESCRIPTION_LENGTH)
              )
            }
            sx={{
              '& .MuiOutlinedInput-root': { height: 'auto', padding: '.5rem' },
            }}
          />
          <Box className='flex_end' sx={{ fontSize: '0.7rem' }}>
            <span>
              {description.length} / {MAX_DESCRIPTION_LENGTH}
            </span>
          </Box>
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>(선택)이미지</Typography>
          <SwFileSetting
            setImage={setSpotImage}
            image={
              spotImageFile !== null
                ? spotImage
                : spotImage !== '' && spotImage !== undefined
                ? `${imageUrlPrefix}${spotImage}`
                : ''
            }
            setFile={setSpotImageFile}
            file={spotImageFile}
            disabled={false}
          />
        </Box>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalPage / rowsPerPage)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>취소</Button>
        <Button onClick={() => saveSpotInfo()}>저장</Button>
      </DialogActions>
    </Dialog>
  );
};

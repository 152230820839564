import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { GeofencingMap } from './GeofencingMap';
import {
  IFieldDetail,
  IFieldInfo,
  DefaultFieldInfo,
  DefaultFieldDetail,
} from '../../../models/field';
import {
  getFieldList,
  getFieldRequestDetail,
  getFieldCharacterList,
  changeFieldApplyStatus,
} from '../../../common/api/ApiField';
import {
  KEY_FIELD_CHALLENGE_COUNT,
  KEY_FIELD_LIST,
  KEY_FIELD_REQUEST_DETAIL,
  KEY_GEOFENCING_GEOMETRY_LIST,
  KEY_WALKCOURSE_LIST,
  KEY_WALKCOURSE_REQUEST_DETAIL,
  KEY_WALKCOURSE_WALKWAY_COUNT,
} from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { selectedFieldIdState } from '../../../common/atom';
import { getGeoFencingGeometryList } from '../../../common/api/ApiGeohash';
import { getCategoryName, getSubCategoryName } from './GeofencingInfoView';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';

const requestColumnlists = [
  '이름',
  '카테고리',
  '하위 카테고리',
  '행정기간 코드',
  '도',
  '시/군/구',
  '행정동',
  '생성일자',
];

const selectVal = [
  {
    title: '이름',
    value: 'name',
  },
];

interface Props {}

export const GeofencingInquire: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const selectRef = useRef<any>(0);
  const searchRef = useRef<any>();
  const [selectRegion, setSelectRegion] = useState<any[]>([]);
  const [commandType, setCommandType] = useState<number>(0); // 0: no-action, 1: save, 2:update, 3: delete
  const [mapReload, setMapReload] = useState(0);
  const [mapInfo, setMapInfo] = useState<any>();
  const [gpsData, setGpsData] = useState<any>([]);
  const [amdCode, setAmdCode] = useState<any>('');
  const [geometryId, setGeometryId] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchWord, setSearchWord] = useState<string>('');
  const [geometryList, setGeometryList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [fieldData, setFieldData] = useState<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fieldDetail, setFieldDetail] =
    useState<IFieldDetail>(DefaultFieldDetail);
  const [walkwayList, setWalkwayList] = useState<string[]>([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const clearSearchCondition = () => {
    setSearchKey('');
    setSearchWord('');
  };

  const {
    data,
    isLoading,
    refetch: geofenceGeometryRefetch,
  } = useQuery(
    KEY_GEOFENCING_GEOMETRY_LIST,
    () => {
      if (searchKey !== '') {
        const newData = {
          amdCode: amdCode,
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
        };
        return getGeoFencingGeometryList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        const newGeometry =
          res &&
          res.amdGeometry &&
          res.amdGeometry.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const newData = [
              item.name,
              getCategoryName(item.category),
              getSubCategoryName(item.category, item.subCategory),
              item.emdCode,
              item.emdDepth1,
              item.emdDepth2,
              item.emdName,
              newDate,
            ];
            return newData;
          });
        setRequestList(newGeometry);
        setGeometryList(res ? res.amdGeometry : []);
        setTotalRecords(res.pagination.totalRecordCount);
        setGeometryId('');
        if (res.amdGeometry && res.amdGeometry.length > 0) {
          setFieldData({
            cmdType: 'total',
            geometryId: '',
            amdCode: res.amdGeometry[0].emdCode,
          });
        } else {
          setFieldData({
            cmdType: 'reset',
            geometryId: '',
            amdCode: amdCode,
          });
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const geometryPrefetchPage = (newPage: number) => {
    queryClient.prefetchQuery(KEY_GEOFENCING_GEOMETRY_LIST, () => {
      const newData = {
        amdCode: amdCode,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getGeoFencingGeometryList(newData);
    });
  };

  const geometryPrefetch = (
    newAmdCode: string,
    newSearchKey: string,
    newSearchWord: string
  ) => {
    queryClient.prefetchQuery(KEY_GEOFENCING_GEOMETRY_LIST, () => {
      const newData = {
        amdCode: newAmdCode,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(newSearchKey, newSearchWord),
      };
      return getGeoFencingGeometryList(newData);
    });
  };

  const onReload = () => {
    geometryPrefetch(amdCode, searchKey, searchWord);
  };

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  const isSearching = () => {
    if (searchRef.current.value && selectRef.current.value) {
      setSearchKey(selectRef.current.value);
      setSearchWord(searchRef.current.value);
    } else {
      clearSearchCondition();
    }
  };

  const chooseRequest = (i: number) => {
    setGeometryId(geometryList[i].geometryId);
    setFieldData({
      cmdType: 'detail',
      geometryId: geometryList[i].geometryId,
      path: geometryList[i].path,
      amdCode: geometryList[i].emdCode,
      totalArea: geometryList[i].area,
    });
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    geometryPrefetchPage(newPage);
  };

  const getFocusIndex = () => {
    const newIndex = geometryList
      .map((item: any, idx: number) =>
        item.geometryId === geometryId ? idx : -1
      )
      .filter((item: any) => item !== -1);
    const index = newIndex !== null && newIndex.length > 0 ? newIndex[0] : -1;
    return index;
  };

  const reloadField = (id: string) => {
    if (id !== '') {
      setGeometryId(id);
      geofenceGeometryRefetch();
    }
  };

  const {
    mutate: changeFieldApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeFieldApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('수정 요청되었습니다');
      geofenceGeometryRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '필드 수정을 요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const onConfirm = () => {
    const newData = {
      geometryId: geometryId,
      status: 4, // 1-작성중 2-접수요청 3-반려 4-접수완료
      message: '',
    };
    changeFieldApplyStatusMutation(newData);
  };

  useEffect(() => {
    if (selectRegion && selectRegion.length > 0) {
      setPage(1);
      searchRef.current.value = '';
      setSearchWord('');
      setAmdCode(selectRegion[0].amd);
      geometryPrefetch(selectRegion[0].amd, searchKey, searchWord);
    }
  }, [selectRegion]);

  useEffect(() => {
    setPage(1);
    geometryPrefetch(amdCode, searchKey, searchWord);
  }, [searchKey, searchWord]);

  useEffect(() => {
    if (mapInfo) {
      setGpsData(mapInfo.points);
    }
  }, [mapInfo]);

  return (
    <Box className='root_box'>
      <Paper
        className='flex_start'
        sx={{ cursor: 'pointer', p: '1rem', mt: '.5rem', mb: '.5rem' }}
      >
        <Box>
          <Typography className='title'>시군구 선택</Typography>
          <Box>
            <SwSelectLocation
              limitKu={true}
              includeAmd={true}
              selectOne={true}
              showButton={false}
              showDepth1={false}
              location={selectRegion}
              setLocation={setSelectRegion}
              showContainer={false}
              mapId='geofencing_map'
            />
          </Box>
        </Box>
        <Box>
          <Typography className='title'>검색</Typography>
          <SelectSearchBox
            searchOption={selectVal}
            searchWord={searchRef}
            defaultOption='name'
            isSearching={isSearching}
            optionValue={selectRef}
          />
        </Box>
      </Paper>
      <Paper>
        <Box sx={{ mb: '1rem' }}>
          <RequestTable
            columns={requestColumnlists}
            rows={requestList}
            onclick={chooseRequest}
            getFocusIndex={getFocusIndex}
          />
        </Box>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <GeofencingMap
        fieldData={fieldData}
        mapInfo={mapInfo}
        setMapInfo={setMapInfo}
        reloadField={reloadField}
        elementId='geofencing_inquire'
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};

import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NONE_QUESTION,
} from '../../common/key';
import { IGuideTool } from '../../models/challenge';
import {
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  SwSwitch,
} from '../styles/Styles';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';
import { SwConfirmDialog } from './views/SwConfirmDialog';

const DataChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const formtool = {
  title: '소제목',
  content: '안내내용',
};

const infoType = [
  { id: 99, title: '선택' },
  { id: 0, title: '연락처' },
  { id: 1, title: '카드번호' },
  { id: 2, title: '생년월일' },
  { id: 3, title: '주소' },
  { id: 4, title: '객관식' },
  { id: 5, title: '주관식' },
  { id: 6, title: '이름' },
];

const applyFormTemplate = [
  {
    type: 0,
    title: '연락처',
    content: '연락처를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 1,
    title: '카드번호',
    content: '카드번호 16자리를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 2,
    title: '생년월일',
    content: '생년월일 6자리를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 3,
    title: '주소',
    content: '주소를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 4,
    title: '질문을 입력해주세요',
    content: '댭변을 입력해주세요',
    hasSubOption: 0,
    subOptions: [
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
      KEY_WORD_NEXT_QUESTION,
    ],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 5,
    title: '질문을 입력해주세요',
    content: '안내내용을 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 6,
    title: '이름',
    content: '이름을 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
];

const inforDataTemplate = [
  {
    type: 6,
    title: '이름',
    content: '이름을 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 3,
    title: '주소',
    content: '주소를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
  {
    type: 0,
    title: '연락처',
    content: '연락처를 입력해주세요',
    hasSubOption: 0,
    subOptions: [],
    parentTitle: '',
    parentOptionIndex: -1,
  },
];

const INIT_SUB_FORM = [
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
];

const MAX_FORM_PAGE = 100;

interface Props {
  draggableName?: string;
  formList: any[];
  setFormList: Dispatch<React.SetStateAction<any[]>>;
}

export const SwRewardApplyForm: React.FC<Props> = ({
  draggableName = 'draggable',
  formList,
  setFormList,
}) => {
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);

  const [type, setType] = useState<number>(99);
  const [formTitle, setFormTitle] = useState<any>('');
  const [formContent, setFormContent] = useState<any>('');
  const [formPage, setFormPage] = useState(1);
  // const [formList, setFormList] = useState<any[]>(inforDataTemplate);
  const [options, setOptions] = useState<any[]>([]);
  const [optionList, setOptionList] = useState<any[]>([]);
  const [optionsCount, setOptionsCount] = useState<number>(0);
  const [hasSubOption, setHasSubOption] = useState<boolean>(false);
  const [subFormKey, setSubFormKey] = useState<string[]>(INIT_SUB_FORM);
  const formItems = document.querySelectorAll(`.${draggableName}`);
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [formIndex, setFormIndex] = useState<number>(0);

  function addFormInfo() {
    if (formTitle.length > 0 && formContent.length > 0) {
      const newTitle = formTitle;
      const newContent = formContent;
      const newFormLists = formList;
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        title: newTitle,
        content: newContent,
      };
      return newFormLists;
    }
    return [];
  }
  const saveOptions = (opts: any[]) => {
    const newOptions = opts.map(opt => opt);
    const newFormList = formList.map((frm: any, idx: number) => {
      const newData =
        idx === formIndex
          ? {
              type: Number(frm.type),
              title: frm.title,
              content: frm.content,
              options: newOptions,
              hasSubOption: frm.hasSubOption,
              subOptions: frm.subOptions,
              parentTitle: frm.parentTitle,
              parentOptionIndex: frm.parentOptionIndex,
            }
          : frm;
      return newData;
    });
    setFormList(newFormList);
  };

  const selectFormList = (newFormIndex: number) => {
    const newFormData = formList[newFormIndex];
    console.log('newFormData:', newFormData);
    setFormTitle(newFormData.title);
    setFormContent(newFormData.content);
    if (Number(newFormData.type) === 4) {
      setHasSubOption(newFormData.hasSubOption === 1);
      if (newFormData.hasSubOption === 1) {
        console.log('newFormData.subOptions :', newFormData.subOptions);
        setSubFormKey(newFormData.subOptions);
      } else {
        setSubFormKey(INIT_SUB_FORM);
      }
      setOptions(
        newFormData.options === undefined
          ? [{ idx: 0, content: '' }]
          : newFormData.options
      );
    }
  };

  const addFormOptions = () => {
    const newOptions = [...options, { idx: 0, content: '' }];
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const changeOption = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const newOptions = options.map((opt: any, i: number) => {
      const newData =
        i === idx
          ? {
              idx: i,
              content: evt.target.value,
            }
          : opt;
      return newData;
    });
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const changeSubOption = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const value = String(evt.target.value);
    const newSubFormKey = [...subFormKey];
    const prevValue = newSubFormKey[idx];
    newSubFormKey[idx] = value;
    const hasValue = newSubFormKey.includes(prevValue);
    setSubFormKey(newSubFormKey);
    console.log('--> changeSubOption prevValue :', prevValue);
    console.log('--> changeSubOption hasValue :', hasValue);
    console.log('--> changeSubOption newSubFormKey :', newSubFormKey);

    const newFormList = formList.map((frm: any, i: number) => {
      const newData =
        i === formIndex
          ? {
              type: Number(frm.type),
              title: frm.title,
              content: frm.content,
              options: frm.options,
              hasSubOption: frm.hasSubOption,
              subOptions: [...newSubFormKey],
              parentTitle: frm.parentTitle,
              parentOptionIndex: frm.parentOptionIndex,
            }
          : frm.title === value
          ? {
              type: Number(frm.type),
              title: frm.title,
              content: frm.content,
              options: frm.options,
              hasSubOption: frm.hasSubOption,
              subOptions: frm.subOptions,
              parentTitle: formList[formIndex].title,
              parentOptionIndex: idx,
            }
          : hasValue === false && frm.title === prevValue
          ? {
              type: Number(frm.type),
              title: frm.title,
              content: frm.content,
              options: frm.options,
              hasSubOption: frm.hasSubOption,
              subOptions: frm.subOptions,
              parentTitle: '',
              parentOptionIndex: -1,
            }
          : frm;
      return newData;
    });
    console.log('--> changeSubOption newFormList :', newFormList);
    setFormList(newFormList);
  };

  const removeFormOption = (idx: number) => {
    const newOptions = options.filter((_form, i) => i !== idx);
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const changePageContents = () => {
    if (formList[formIndex]) {
      // setFormTitle(formList[formIndex].title);
      // setFormContent(formList[formIndex].content);
      selectFormList(formIndex);
    }
  };

  const addFormView = () => {
    if (formList.length === MAX_FORM_PAGE) {
      setDialogMessage(
        `리워드 폼은 최대 ${MAX_FORM_PAGE}까지 추가할 수 있습니다.`
      );
      setOpenSnackbar(true);
      return;
    }

    if (type !== 99) {
      const newFormData = applyFormTemplate[type];
      const isExist = formList.filter(
        (item: any) => Number(item.type) === type
      );
      console.log('formList:', formList);
      console.log('type:', type, ', isExist:', isExist);
      if (
        (type === 0 || type === 1 || type === 2 || type === 3 || type === 6) &&
        isExist !== undefined &&
        isExist.length > 0
      ) {
        setDialogMessage(`중복 추가 불가합니다.`);
        setOpenSnackbar(true);
        return;
      }
      const newForm = [...formList, newFormData];
      setFormList(newForm);
    }
  };

  const changeHasSubOption = () => {
    const newForm = formList[formIndex];

    const newFormLists = formList.map((frm: any) => {
      const newData = newForm.subOptions.filter(
        (option: string) => option === frm.title
      );

      return newData.length > 0
        ? {
            type: Number(frm.type),
            title: frm.title,
            content: frm.content,
            options: frm.options,
            hasSubOption: 0,
            subOptions: [],
            parentTitle: '',
            parentOptionIndex: -1,
          }
        : frm;
    });
    newFormLists[formIndex] = {
      ...newForm,
      hasSubOption: newForm.hasSubOption === 1 ? 0 : 1,
      subOptions: [],
    };
    setFormList(newFormLists);
    setHasSubOption(newForm.hasSubOption !== 1);
  };

  const updateFormView = () => {
    if (formTitle.length > 0 && formContent.length > 0) {
      const newTitle = formTitle;
      const newContent = formContent;
      const newFormLists = formList;
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        title: newTitle,
        content: newContent,
        subOptions: [...subFormKey],
      };
      setFormList(newFormLists);
    }
  };

  const updateFormViewTitle = (newTitle: string) => {
    const newFormLists = formList;
    const newForm = formList[formIndex];
    newFormLists[formIndex] = {
      ...newForm,
      title: newTitle,
    };
    setFormTitle(newTitle);
    setFormList(newFormLists);
  };

  const removeFormView = (idx: number, cmd: number) => {
    console.log(formPage);
    if (cmd === 2) {
      // 연결된 질문을 갖고 있는 질문 삭제
      const selectForm = formList[formIndex];
      const newFormLists = formList.map((frm: any) => {
        const newData = selectForm.subOptions.filter(
          (option: string) => option === frm.title
        );

        return newData.length > 0
          ? {
              type: Number(frm.type),
              title: frm.title,
              content: frm.content,
              options: frm.options,
              hasSubOption: 0,
              subOptions: [],
              parentTitle: '',
              parentOptionIndex: -1,
            }
          : frm;
      });

      const newForm = newFormLists.filter((_form: any, i: number) => i !== idx);
      setFormList(newForm);
    } else if (cmd === 3) {
      // 연결된 질문에 포함된 질문 삭제
      const selectForm = formList[formIndex];
      const newFormList = formList.map((frm: any, i: number) => {
        const newHasSubOption = frm.subOptions.filter(
          (item: string) => item === selectForm.title
        );
        const newSubOptions = frm.subOptions.filter(
          (item: string) => item !== selectForm.title
        );
        let newData: any;
        if (newHasSubOption !== null && newHasSubOption.length > 0) {
          newData = {
            ...frm,
            subOptions:
              newSubOptions !== null && newSubOptions.length > 0
                ? [...newSubOptions, ...INIT_SUB_FORM]
                : INIT_SUB_FORM,
          };
        } else {
          newData = frm;
        }
        return newData;
      });
      const newForm = newFormList.filter((_form: any, i: number) => i !== idx);
      setFormList(newForm);
    } else {
      const newForm = formList.filter((_form: any, i: number) => i !== idx);
      setFormList(newForm);
      changePageContents();
    }
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: formList,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setFormList(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    formItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setFormList(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    formItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    formItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newFormIndex = Number(evt.currentTarget.dataset.position);
    setFormIndex(newFormIndex);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newFormIndex : null,
    });
    console.log('formIndex:', newFormIndex);
    selectFormList(newFormIndex);
  };

  useEffect(() => {
    if (
      formTitle === undefined ||
      formTitle === null ||
      formContent === undefined ||
      formContent === null
    )
      return;
    if (formTitle.length > 0 && formContent.length > 0) updateFormView();
  }, [formTitle, formContent]);

  useEffect(() => {
    if (formList != null && formList.length > 0) changePageContents();
    else {
      setFormList(inforDataTemplate);
    }
  }, [formList]);

  return (
    <>
      <Box className='flex_start'>
        <Box className='flex_start'>
          <Typography className='title'>정보입력하기</Typography>
          <TextField
            sx={{ width: '10rem', ml: '3rem' }}
            select
            value={type}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setType(Number(event.target.value))}
          >
            {infoType.map((option: any, idx: number) => (
              <MenuItem key={idx} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <Box sx={{ ml: '3rem' }}>
            <Button variant='outlined' onClick={addFormView}>
              추가
            </Button>
            <Button
              sx={{ ml: '1rem' }}
              variant='outlined'
              onClick={updateFormView}
            >
              저장
            </Button>
          </Box>
        </Box>
        <Box>
          {formList != null && Number(formList[formIndex]?.type) === 4 && (
            <Box className='flex_end' sx={{ ml: '36rem' }}>
              <SwSwitch
                disabled={formList[formIndex]?.parentTitle !== ''}
                checked={hasSubOption}
                onChange={() => {
                  hasSubOption ? setOpenConfirm(1) : changeHasSubOption();
                }}
              />
              <Typography sx={{ ml: '.4rem' }}>질문 연결</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        divider={
          <Divider
            orientation='vertical'
            flexItem
            sx={{ p: 0, m: '1.5rem 3rem 0' }}
          />
        }
      >
        <Box>
          {formList?.map((item: any, idx: number) => (
            <Box className='flex_between' key={`form_${idx}`}>
              <DraggablePaper
                className={draggableName}
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                onDragOver={dragOver}
                onDrop={drop}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                draggable
                data-position={idx}
                onClick={selectList}
                sx={{ width: '25rem' }}
              >
                <div>
                  <span>
                    {Number(item.type) === 4
                      ? `객관식)${item.title}`
                      : Number(item.type) === 5
                      ? `주관식)${item.title}`
                      : item.title}
                  </span>
                </div>
                <DragHandleIcon />
              </DraggablePaper>
              {formList.length > 1 && (
                <ClearIcon
                  className='clear_icon_button'
                  onClick={() => {
                    setFormIndex(idx);
                    formList[idx].hasSubOption === 1
                      ? setOpenConfirm(2) // 연결된 질문을 갖고 있는 항목 삭제
                      : formList[idx].parentTitle !== ''
                      ? setOpenConfirm(3) // 연결 질문에 속한 항목을 삭제
                      : removeFormView(idx, 1);
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Box>
          <Box>
            <TextField
              sx={{ width: '30rem' }}
              value={formTitle}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => updateFormViewTitle(evt.target.value)}
            />
            {formList != null && Number(formList[formIndex]?.type) === 4 && (
              <Button
                sx={{ ml: '1rem' }}
                variant='outlined'
                onClick={addFormOptions}
              >
                선택지 추가
              </Button>
            )}
          </Box>
          <Box>
            <>
              {formList != null && Number(formList[formIndex]?.type) !== 4 ? (
                <TextField
                  value={formContent}
                  fullWidth
                  multiline
                  rows={5}
                  sx={{
                    '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' },
                  }}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setFormContent(evt.target.value)}
                />
              ) : (
                options.map((opt, idx: number) => (
                  <Box key={`option_${idx}`}>
                    <Typography
                      component='div'
                      sx={{ fontSize: '1rem', m: '.5rem', mb: '1rem' }}
                    >
                      선택지 {idx + 1}
                    </Typography>
                    <Box className='flex_start'>
                      <TextField
                        sx={{ width: hasSubOption ? '15rem' : '30rem' }}
                        value={opt.content}
                        placeholder='선택지를 입력해주세요'
                        onChange={evt => changeOption(evt, idx)}
                      />
                      {hasSubOption && (
                        <TextField
                          sx={{
                            width: '20rem',
                            ml: '.5rem',
                          }}
                          defaultValue='-'
                          select
                          value={subFormKey[idx]}
                          onChange={evt => changeSubOption(evt, idx)}
                        >
                          <MenuItem
                            key={`sub_form_idex+${99}`}
                            value={KEY_WORD_NEXT_QUESTION}
                          >
                            {KEY_WORD_NEXT_QUESTION}
                          </MenuItem>
                          {formList
                            ?.filter(
                              (item: any) =>
                                item.title !== formList[formIndex].title &&
                                item.hasSubOption !== 1 &&
                                (item.parentTitle === '' ||
                                  item.parentTitle ===
                                    formList[formIndex].title)
                            )
                            .map((item: any, i: number) => (
                              <MenuItem
                                key={`sub_form_idex+${i}`}
                                value={item.title}
                              >
                                {item.title}
                              </MenuItem>
                            ))}
                          <MenuItem
                            key={`sub_form_idex+${999}`}
                            value={KEY_WORD_NONE_QUESTION}
                          >
                            {KEY_WORD_NONE_QUESTION}
                          </MenuItem>
                        </TextField>
                      )}
                      {options.length > 1 && !hasSubOption && (
                        <ClearIcon
                          sx={{ ml: '1rem', mt: '.5rem' }}
                          className='clear_icon_button'
                          onClick={() => {
                            removeFormOption(idx);
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                ))
              )}
            </>
          </Box>
        </Box>
      </Stack>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents={
          openConfirm === 1
            ? '선택지에 연결된 질문이 있습니다. 이 질문에 해당 기능을 사용하지 않겠습니까?'
            : openConfirm === 2
            ? '연결된 질문이 있습니다. 이 질문을 삭제하시겠습니까?'
            : '연결된 질문에 포함된 질문이 있습니다. 이 질문을 삭제하시겠습니까?'
        }
        open={!!openConfirm}
        onClose={() => setOpenConfirm(0)}
        onConfirm={() => {
          openConfirm === 1
            ? changeHasSubOption()
            : removeFormView(formIndex, openConfirm);
          setOpenConfirm(0);
        }}
        confirm={1}
      />
    </>
  );
};

import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Store
 */

export const getStoreBrandList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/rewardmall/store/${data.storeId}/brand`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postStoreProduct = (data: any) =>
  xapi.post(`/admin/v2/apis/rewardmall/store/product`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

/*
 * Product
 */

export const getStoreBrandProductList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/rewardmall/store/${data.storeId}/brand/product?searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putStoreBrandProduct = (data: any) =>
  xapi
    .put(`/admin/v2/apis/rewardmall/store/product/${data.type}`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getStoreBrandProductDownload = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/rewardmall/store/${data.storeId}/brand/product/download?searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `product_list.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

/*
 * Order
 */
export const getRewardMallOrderList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/rewardmall/order?searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardMallOrderDownload = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/rewardmall/order/download?searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `order_list.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

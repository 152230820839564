import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import groupmember from '../../../dummy/groupmember.json';
import { useFind } from '../../../hooks/api';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { LargeDialog, SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwLoadUser } from '../../commonComponent/SwLoadUser';
import {
  KEY_COMMUNITY_MEMBER_LIST,
  KEY_COMMUNITY_TYPE_MEMBER_LIST,
} from '../../../common/key';
import {
  getCommunityMemberList,
  getCommunityMemberListByPhonenos,
  putCommunityChangeGroup,
} from '../../../common/api/ApiCommunity';
import { encodeSearchWord } from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { RESULT_OK } from '../../../common/resultCode';

interface Props {
  open: boolean;
  onClose: () => void;
  onChange: () => void;
  groups: any;
  communityId: any;
  communityMembers: any;
  setCommunityMembers: Dispatch<any>;
}

const DataChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const GroupSelectField = styled(TextField)({
  width: '18rem',
  padding: '.1rem',
  marginBottom: 0,
  ' & .MuiOutlinedInput-input': {
    p: '.5rem 1rem',
  },
  '& .MuiOutlinedInput-root': {
    height: '2rem',
  },
});

const HeadCell = styled(TableCell)({
  fontWeight: 400,
});

const columns = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '그룹',
  '연락처',
  '나이',
  '성별',
];

const searchItems = [
  {
    value: 'name',
    title: '닉네임',
  },
  {
    value: 'groupname',
    title: '그룹',
  },
  {
    value: 'phonenum',
    title: '연락처',
  },
  {
    value: 'age',
    title: '나이',
  },
  {
    value: 'gender',
    title: '성별',
  },
];

const MAX_LOAD_PHONE_LENGTH = 500;

export const CommunityGroupMemberChange: React.FC<Props> = ({
  open,
  groups,
  onClose,
  onChange,
  communityId,
  communityMembers,
  setCommunityMembers,
}) => {
  const queryClient = useQueryClient();
  const groupRef = useRef<any>();
  const selectGroupRef = useRef<any>();
  const searchRef = useRef<any>();
  const [loadUserList, setLoadUserList] = useState<any>([]);
  const [cgroup, setGroup] = useState<any>();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const rowsPerPageOptions = [5, 8, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [saveSelected, setSaveSelected] = useState<readonly string[]>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<null | HTMLElement>(null);
  const [openLoadUser, setOpenLoadUser] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: communityMemberRefetch,
  } = useQuery(
    KEY_COMMUNITY_MEMBER_LIST,
    () => {
      console.log('communityId :', communityId);
      const searchKey = selectGroupRef.current?.value;
      const searchWord = searchRef.current?.value;
      const newData = {
        communityId: communityId,
        searchKey: searchKey,
        searchWord:
          searchKey === 'groupname'
            ? searchWord === '기본그룹' || searchWord === '미분류'
              ? 'no-group'
              : encodeSearchWord(searchKey, searchWord)
            : encodeSearchWord(searchKey, searchWord),
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (open && communityId.length > 0)
        return getCommunityMemberList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('CommunityGroupMemberChange :', res);
        if (res !== null) {
          setCommunityMembers(res.member);
          setTotalRecords(res.pagination?.totalRecordCount);

          const newMember = res.member.filter(
            (member: any, idx: number) =>
              selected.findIndex(
                (select: any) => select.name === member.name
              ) !== -1
          );
          setCheckedAll(newMember.length === res.member.length);
          setCheckedButtons(newMember);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_MEMBER_LIST, () => {
      const searchKey = selectGroupRef.current?.value;
      const searchWord = searchRef.current?.value;
      const newData = {
        communityId: communityId,
        searchKey: searchKey,
        searchWord:
          searchKey === 'groupname'
            ? searchWord === '기본그룹' || searchWord === '미분류'
              ? 'no-group'
              : encodeSearchWord(searchKey, searchWord)
            : encodeSearchWord(searchKey, searchWord),
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      return getCommunityMemberList(newData);
    });
  };

  const { mutate: putCommunityChangeGroupMutate, isError: isMutateError } =
    useMutation(putCommunityChangeGroup, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenDialog(true);
          setDialogMessage('커뮤니티 그룹을 변경했습니다.');
          communityMemberRefetch();
        } else {
          setOpenDialog(true);
          setDialogMessage(
            `커뮤니티 그룹 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 그룹 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: communityMemberListByPhonenosMutate,
    isError: isMemberListMutateError,
  } = useMutation(getCommunityMemberListByPhonenos, {
    onSuccess: res => {
      console.log('communityMemberListByPhonenosMutate :', res);
      if (res !== null) {
        const newSelected = res.member.filter(
          (item: any) => selected.indexOf(item.name) === -1
        );
        setSelected([...selected, ...newSelected]);
      }
    },
    onError: error => {
      setOpenDialog(true);
      setDialogMessage(
        '연락처를 이용한 커뮤니티 멤버 목록 조회가 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const loadMemberList = () => {
    if (loadUserList.length === 0) {
      setOpenDialog(true);
      setDialogMessage('업로드할 데이터가 없습니다. 확인 후 다시 시도해주세요');
      return;
    }
    const newData = {
      communityId: communityId,
      phonenos: loadUserList,
    };

    communityMemberListByPhonenosMutate(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setCheckedAll(false);
    setCheckedButtons([]);
    setPage(newPage);
    prefetch(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      const newMember = communityMembers.filter(
        (member: any, idx: number) =>
          selected.findIndex((select: any) => select.name === member.name) ===
          -1
      );
      setSelected([...selected, ...newMember]);

      const newData = communityMembers.map((member: any) => member.name);
      setCheckedButtons(newData);
    } else {
      setCheckedAll(false);
      const changedMember = selected.filter(
        (member: any, idx: number) =>
          communityMembers.findIndex(
            (select: any) => select.name === member.name
          ) === -1
      );
      setSelected(changedMember);
    }
  };

  const handleClick = (event: any, idx: number) => {
    const newData = communityMembers[idx];
    if (event.target.checked) {
      const newButtons = [...checkedButtons, newData.name];
      setCheckedButtons(newButtons);
      setCheckedAll(communityMembers.length === newButtons.length);
      setSelected([...selected, newData]);
    } else {
      setCheckedAll(false);
      setCheckedButtons(checkedButtons.filter(item => item !== newData.name));
      const newSelected = selected.filter(
        (select: any) => select.name !== newData.name
      );
      setSelected(newSelected);
    }
  };

  const isSelected = (name: string) => {
    const newData = selected.filter((select: any) => select.name === name);
    return newData.length > 0;
  };

  const groupChangeEvent = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number,
    member: any
  ) => {
    // TODO viewchanging should be occur
    const newDatum = communityMembers;
    newDatum[idx] = {
      ...member,
      groupname: event.target.value,
    };
    setCommunityMembers(newDatum);
  };

  const multiChangeGroup = () => {
    if (groupRef.current.value) {
      const newGroupId = groupRef.current.value;
      const newGroup = groups.filter(
        (group: any, idx: number) => group.groupId === newGroupId
      );

      const changedMemberIds = selected.map((member: any) => member.userId);
      const newChangeGroup = {
        groupId: newGroup[0].groupId,
        name: newGroup[0].groupName,
      };
      const newData = {
        communityId: communityId,
        userIds: changedMemberIds,
        targetGroup: newChangeGroup,
      };
      putCommunityChangeGroupMutate(newData);
    }
  };

  const checkChangeGroup = () => {
    if (groupRef.current.value) {
      const newGroupId = groupRef.current.value;
      const newGroup = groups.filter(
        (group: any, idx: number) => group.groupId === newGroupId
      );
      let attendChallengeCount = 0;
      selected.map((member: any) => {
        groups.map((group: any, idx: number) => {
          if (group.groupId === member.groupId) {
            attendChallengeCount += group.attendCount;
          }
        });
      });
      if (attendChallengeCount > 0 && newGroup[0].attendCount === 0) {
        setOpenConfirm(true);
      } else {
        multiChangeGroup();
      }
    }
  };

  const isSearching = () => {
    setPage(1);
    prefetch(1);
  };

  const resetData = () => {
    setCommunityMembers([]);
    setSelected([]);
  };

  const isOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
    setOpenFilter(event.currentTarget);
  };

  // const testApi = () => {
  //   const params = {
  //     isDev: false,
  //     communityId: '8098B6FFE2AA4AC07FD503941A416C94',
  //   };
  //   axios
  //     .post(
  //       'http://admin.swallaby.net/admin/v2/apis/community/members',
  //       JSON.stringify(params)
  //     )
  //     .then(res => {
  //       setCommunityMembers(res.data.data.members);
  //       const tempDatum = res.data.data.members;
  //       const reducer = (q: any, val: any, idx: number) => {
  //         const check = q.findIndex((w: any) => w === val);
  //         check === -1 ? q.push(val) : q;
  //         return q;
  //       };
  //       const tempGroups = tempDatum.map((q: any) => q.groupname);
  //       const tempGroup = tempGroups.reduce(reducer, []);
  //       setGroup(tempGroup);
  //     });
  // };

  // useEffect(() => {
  //   testApi();
  // }, []);

  // useMemo(() => first, [second])

  useEffect(() => {
    if (loadUserList.length > 0) loadMemberList();
  }, [loadUserList]);

  useEffect(() => {
    console.log('groups :', groups);
  }, []);

  useEffect(() => {
    if (open) {
      communityMemberRefetch();
    } else {
      resetData();
    }
  }, [open]);

  return (
    <>
      <LargeDialog open={open} fullWidth>
        <DialogTitle className='flex_between'>
          <Typography sx={{ fontSize: '1rem', fontWeight: 500 }}>
            그룹 멤버 변경
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          <Box sx={{ m: '0 1.1rem 1rem' }} className='flex_between'>
            <Typography variant='h6' sx={{ fontWeight: 400 }}>
              변경할 멤버 선택
            </Typography>
            <Box className='flex_between'>
              <Button
                variant='contained'
                color='info'
                sx={{ mr: '1rem' }}
                onClick={() => {
                  setOpenLoadUser(1);
                }}
              >
                복사하여 추가
              </Button>
              <SelectSearchBox
                optionValue={selectGroupRef}
                searchWord={searchRef}
                searchOption={searchItems}
                defaultOption='name'
                isSearching={isSearching}
                selectLength='8rem'
                searchLength='18rem'
              />
            </Box>
          </Box>
          <Paper sx={{ m: '1rem', textAlign: 'right' }}>
            {/* <Button
            color='info'
            onClick={isOpenFilter}
            aria-controls={openFilter ? 'long-menu' : undefined}
            aria-expanded={openFilter ? 'true' : undefined}
            aria-haspopup='true'
            sx={{ m: '.4rem 1rem' }}
          >
            <FilterAltOutlinedIcon />
            <span>필터</span>
          </Button>
          <Menu
            open={!!openFilter}
            anchorEl={openFilter}
            onClose={() => setOpenFilter(null)}
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem
                key={option.toString()}
                onClick={() => {
                  setOpenFilter(null);
                  setRowsPerPage(option);
                  setPage(1);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu> */}
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
            <Table size='small'>
              <TableHead sx={{ bgcolor: '#F9FAFC' }}>
                <TableRow>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      // indeterminate={
                      //   selected.length > 0 &&
                      //   selected.length < communityMembers.length
                      // }
                      checked={checkedAll}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  {columns.map((item, idx) => (
                    <HeadCell key={idx.toString()}>{item}</HeadCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {communityMembers?.map((member: any, idx: number) => {
                  const isItemSelected = isSelected(member.name);
                  return (
                    <TableRow key={idx.toString()} selected={isItemSelected}>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={isItemSelected}
                          onClick={event => handleClick(event, idx)}
                        />
                      </TableCell>
                      <TableCell onClick={event => handleClick(event, idx)}>
                        {member.name}
                      </TableCell>
                      <TableCell>
                        {member.nickName === null
                          ? '-'
                          : member.useNickName === 1
                          ? 'O'
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {member.nickName !== null ? member.nickName : '-'}
                      </TableCell>
                      <TableCell sx={{ width: '15rem' }}>
                        {member.groupName}
                      </TableCell>
                      <TableCell>{member.phoneno}</TableCell>
                      <TableCell>{member.age}세</TableCell>
                      <TableCell>{member.gender}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <SwPagination
              page={page}
              handleChangePage={handleChangePage}
              count={communityMembers && Math.ceil(totalRecords / rowsPerPage)}
            />
            {/* <Box sx={{ p: '.6rem', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              color='primary'
              variant='outlined'
              siblingCount={4}
              page={page}
              onChange={handleChangePage}
              count={
                communityMembers &&
                Math.ceil(communityMembers.length / rowsPerPage)
              }
            />
          </Box> */}
          </Paper>
          <Paper sx={{ m: '1rem', p: '1.5rem', height: '20vh' }}>
            <Stack
              direction='row'
              divider={
                <Divider orientation='vertical' flexItem sx={{ m: 0 }} />
              }
              spacing={2}
            >
              <Box sx={{ width: '60%', height: '16vh', overflowY: 'scroll' }}>
                {selected.map((select, idx) => (
                  <DataChip
                    label={select.name}
                    variant='outlined'
                    deleteIcon={<CloseIcon />}
                    onDelete={() => {
                      const newSelected = selected.filter((s, i) => i !== idx);
                      setSelected(newSelected);
                    }}
                    key={idx.toString()}
                  />
                ))}
              </Box>
              <Box sx={{ height: '16vh', width: '38%' }}>
                <Collapse in={!!selected.length} sx={{ p: '0 1rem 1rem' }}>
                  <Typography
                    component='div'
                    sx={{ fontSize: '1rem', m: '.5rem', mb: '1rem' }}
                  >
                    변경할 그룹 선택
                  </Typography>
                  <Stack
                    direction='row'
                    divider={
                      <Divider orientation='vertical' flexItem sx={{ m: 0 }} />
                    }
                    spacing={2}
                  >
                    <TextField
                      placeholder='그룹을 선택해 주세요.'
                      select
                      inputRef={groupRef}
                      defaultValue={groups && groups[0]?.groupId}
                      sx={{ width: '20vw', mb: 0 }}
                    >
                      {groups?.map((group: any, i: number) => (
                        <MenuItem key={group.groupId} value={group.groupId}>
                          {group.groupName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      onClick={checkChangeGroup}
                      variant='outlined'
                      color='info'
                      sx={{ ml: '1rem' }}
                    >
                      변경
                    </Button>
                  </Stack>
                </Collapse>
              </Box>
            </Stack>
          </Paper>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions sx={{ m: '0 2rem' }}>
          <Button onClick={onClose} variant='outlined' color='info'>
            닫기
          </Button>
          <Button onClick={onClose} variant='contained' color='info'>
            저장
          </Button>
        </DialogActions>
      </LargeDialog>
      <SwSnackbar
        open={false}
        onClose={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='그룹 대항 챌린지 참여 멤버를 포함하고 있습니다. 옮기려는 그룹은 그룹 대항 챌린지 대상 그룹이 아니기 때문에 해당 멤버들이 챌린지에서 탈퇴될 수 있습니다. 진행하시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false);
          multiChangeGroup();
        }}
        confirm={1}
      />
      <SwLoadUser
        open={!!openLoadUser}
        onclose={() => {
          setOpenLoadUser(0);
        }}
        setLoadList={setLoadUserList}
        maxSize={MAX_LOAD_PHONE_LENGTH}
      />
    </>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { ChallengeTypeBox } from '../ChallengeStyles';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { SwStampTourView } from '../../../commonComponent/SwStampTourView';
import { SwFieldView } from '../../../commonComponent/SwFieldView';

interface Props {
  challengeId: string;
  mission: any;
  setMission: Dispatch<React.SetStateAction<any>>;
}

export const FieldMissionView: React.FC<Props> = ({
  challengeId,
  mission,
  setMission,
}) => {
  const { loginVal } = useSession();
  const findFieldRef = useRef<any>();
  const [challengeFieldId, setChallengeFieldId] = useState<string>('');
  const [challengeFieldName, setChallengeFieldName] = useState<string>('');
  useState<string>('');
  const [missionGoal, setMissionGoal] = useState<number>(0);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');

  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [openFieldView, setOpenFieldView] = useState<boolean>(false);

  const searchField = () => {
    setOpenType(2);
    setTargetTitle('필드');
    setSearchKey('field');
    setSearchWord(findFieldRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchField();
    }
  };

  useEffect(() => {
    const newSearchResult = [...searchResult];
    if (openType === 2) {
      setChallengeFieldId(newSearchResult[0]?.id);
      setChallengeFieldName(newSearchResult[0]?.name);
      setMission({ ...mission, fieldId: newSearchResult[0]?.id });
    }
    return () => {};
  }, [searchResult]);

  useEffect(() => {
    if (mission) {
      setChallengeFieldId(mission.fieldId);
      setMissionGoal(mission.goal);
    }
    return () => {};
  }, [mission]);

  return (
    <>
      <Box>
        <ChallengeTypeBox>
          <Box className='flex_start'>
            <Typography className='title'>필드 입력</Typography>
            <TextField
              placeholder='필드 이름, 아이디 검색'
              sx={{ marginRight: '1rem', width: '21rem' }}
              inputRef={findFieldRef}
              onKeyDown={evt => {
                handleKeyDown(evt, 'field');
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        searchField();
                      }}
                      sx={{ pr: 0 }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Typography className='title'>
            {challengeFieldId && challengeFieldId !== ''
              ? `${challengeFieldId}`
              : ''}
          </Typography>
          {challengeFieldId && challengeFieldId !== '' && (
            <Button
              variant='outlined'
              color='primary'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFieldView(true)}
            >
              코스 확인
            </Button>
          )}
        </ChallengeTypeBox>
        <ChallengeTypeBox sx={{ mt: '1rem' }}>
          <Typography className='title'>목표 마일리지</Typography>
          <TextField
            sx={{ marginRight: '1rem' }}
            value={missionGoal}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const newGoal = Number(evt.target.value);
              setMissionGoal(newGoal);
              setMission({ ...mission, goal: newGoal });
            }}
          />
        </ChallengeTypeBox>
      </Box>
      <SwSearchTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwFieldView
        title='설정된 코스 정보'
        open={openFieldView}
        onClose={() => setOpenFieldView(false)}
        fieldId={challengeFieldId}
        challengeId={challengeId}
      />
    </>
  );
};

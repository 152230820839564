import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getTarget } from '../../common/api/ApiCommon';
import { KEY_TARGET_LIST } from '../../common/key';
import SwLoading from './spinner/SwLoading';
import { encodeSearchWord } from '../../common/helper';

interface Props {
  title: string;
  searchKey: string;
  searchWord: string;
  targetData?: any[];
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = ['이름', '아이디'];

export const SwSearchTarget: React.FC<Props> = ({
  title,
  open,
  onClose,
  searchKey,
  searchWord,
  targetData,
  setTarget,
}) => {
  const queryClient = useQueryClient();
  const [targetList, setTargetList] = useState<any>([]);
  const [loadReq, setLoadReq] = useState<boolean>(true);

  const clickItem = (row: any) => {
    const target = [
      {
        id: row.id,
        name: row.name,
        groups: row.groups === undefined ? null : row.groups,
        phoneno:
          row.phoneno === undefined || row.phoneno === null
            ? null
            : row.phoneno.replaceAll('+82', 0),
        count: row.count === undefined ? null : row.count,
        distance: row.distance === undefined ? null : row.distance,
        isAuto: 0,
      },
    ];
    setTarget(target);
    onClose();
  };

  const {
    data,
    isLoading,
    refetch: refetchTarget,
  } = useQuery(
    KEY_TARGET_LIST,
    () => {
      if (searchKey !== '')
        return getTarget(searchKey, encodeSearchWord(searchKey, searchWord));
      return null;
    },
    {
      onSuccess: res => {
        if (res != null) {
          setTargetList(res.target);
          if (res.target.length === 1) {
            clickItem(res.target[0]);
          }
        }
        setLoadReq(false);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  useEffect(() => {
    if (open) {
      if (targetData !== undefined && targetData.length > 0) {
        const newData = targetData.filter(
          (item: any) =>
            item.id.includes(searchWord) || item.name.includes(searchWord)
        );
        setTargetList(newData);
      } else {
        setLoadReq(true);
        setTargetList([]);
        refetchTarget();
      }
    } else {
      setLoadReq(false);
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '30rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {searchWord && searchWord.length > 0 && `'${searchWord}'`} {title}{' '}
            검색결과
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
                {(searchKey === 'user' || searchKey === 'storeowner') && (
                  <TableCell key={columns.length} sx={{ p: '.6rem' }}>
                    연락처
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {targetList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()} onClick={() => clickItem(row)}>
                  <TableCell key={row.na} sx={{ p: '.3rem .6rem' }}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.id}</TableCell>
                  {(searchKey === 'user' || searchKey === 'storeowner') && (
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.phoneno}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loadReq === false &&
          (targetList === null || targetList.length === 0) && (
            <Typography sx={{ color: 'red', mt: '2rem' }}>
              데이터가 없습니다.
            </Typography>
          )}
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontSize: '.87rem' }}
          onClick={onClose}
        >
          닫기
        </Button>
      </DialogActions> */}
      {loadReq && <SwLoading />}
    </Dialog>
  );
};

import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IPush, IPushCancel, IPushUpdate } from '../../models/push';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Report
 */

export const requestCommunityYearReport = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/year-report/make-excel/${data.year}/${data.portalId}/${data.communityId}?mainImage=${data.mainImage}&detailImage=${data.detailImage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getCommunityYearReport = (data: any) =>
  xapi
    .get(`/admin/v2/apis/year-report/${data.year}/${data.communityId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadCommunityYearReport = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/year-report/make-excel/${data.year}/${data.portalId}/${data.communityId}?mainImage=${data.mainImage}&detailImage=${data.detailImage}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `community_year_report_${data.portalId}_${data.communityId}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

/*
 * Region Report
 */
export const getRegionReportList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/region-report/make-excel/${data.rateYear}/amd/${data.amdCode}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

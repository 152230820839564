import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { ChallengeFieldMap } from './ChallengeFieldMap';
import { ChallengeFieldForm } from './ChallengeFieldForm';
import {
  IFieldDetail,
  IFieldInfo,
  DefaultFieldInfo,
  DefaultFieldDetail,
} from '../../../models/field';
import {
  getFieldList,
  getFieldRequestDetail,
  getFieldCharacterList,
  changeFieldApplyStatus,
} from '../../../common/api/ApiField';
import {
  KEY_FIELD_CHALLENGE_COUNT,
  KEY_FIELD_LIST,
  KEY_FIELD_REQUEST_DETAIL,
  KEY_WALKCOURSE_LIST,
  KEY_WALKCOURSE_REQUEST_DETAIL,
  KEY_WALKCOURSE_WALKWAY_COUNT,
} from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { selectedFieldIdState } from '../../../common/atom';

const requestColumnlists = [
  '개설 기관명',
  '길코스 이름',
  '추천코스 노출',
  '생성일시',
];

const selectVal = [
  {
    title: '필드 이름',
    value: 'name',
  },
  { title: '필드 아이디', value: 'id' },
  { title: '개설 기관명', value: 'company' },
];

interface Props {}

export const ChallengeFieldInquire: React.FC<Props> = () => {
  const [selectedFieldId, setSelectedFieldId] =
    useRecoilState(selectedFieldIdState);

  const queryClient = useQueryClient();
  const selectRef = useRef<any>(0);
  const searchRef = useRef<any>();
  const [commandType, setCommandType] = useState<number>(0); // 0: no-action, 1: save, 2:update, 3: delete
  const [mapReload, setMapReload] = useState(0);
  const [mapInfo, setMapInfo] = useState<any>();
  const [gpsData, setGpsData] = useState<any>([]);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [fieldId, setFieldId] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchWord, setSearchWord] = useState<string>('');
  const [fieldList, setFieldList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [fieldData, setFieldData] = useState<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fieldDetail, setFieldDetail] =
    useState<IFieldDetail>(DefaultFieldDetail);
  const [walkwayList, setWalkwayList] = useState<string[]>([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const clearSearchCondition = () => {
    setSearchKey('');
    setSearchWord('');
  };

  const {
    data,
    isLoading,
    refetch: fieldRefetch,
  } = useQuery(
    KEY_FIELD_LIST,
    () => {
      if (searchKey !== '') {
        const newData = {
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
        };
        return getFieldList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('FieldInquire  res:', res);
        const newField =
          res &&
          res.field &&
          res.field.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const newCreatorName = item.dataPortalName
              ? item.dataPortalName
              : item.dataPortalId;
            const show = item.hidden === 0 ? '노출' : '-';
            const newData = [newCreatorName, item.name, show, newDate];
            return newData;
          });
        setRequestList(newField);
        setFieldList(res ? res.field : []);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const fieldPrefetchPage = (newPage: number) => {
    queryClient.prefetchQuery(KEY_FIELD_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getFieldList(newData);
    });
  };

  const fieldPrefetch = (newSearchKey: string, newSearchWord: string) => {
    queryClient.prefetchQuery(KEY_FIELD_LIST, () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(newSearchKey, newSearchWord),
      };
      return getFieldList(newData);
    });
  };

  const { refetch: fieldRequestDetailRefetch } = useQuery(
    KEY_FIELD_REQUEST_DETAIL,
    () => {
      console.log('fieldId:', fieldId);
      if (fieldId.length > 0) return getFieldRequestDetail(fieldId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          // const tmpRoute = res.creationRequestDetail.point.map((point: any) => {
          //   const temp = {
          //     lat: point.pointPtLat,
          //     lng: point.pointPtLng,
          //     radius: point.pointRadius,
          //   };
          //   return temp;
          // });
          const tmpRoute: any = [];
          setGpsData(tmpRoute);

          const newSpot = res.creationRequestDetail.spot.map((spot: any) => {
            const temp = {
              id: spot.spotId,
              name: spot.spotName,
              image: spot.spotImage,
              lat: spot.spotPointLat,
              lng: spot.spotPointLng,
              radius: spot.spotRadius,
              description: spot.description,
              className: spot.className,
              link: spot.link,
              phoneNo: spot.phoneNo,
              address: spot.address,
            };
            return temp;
          });

          setFieldDetail(res.creationRequestDetail);

          const newFieldData = {
            fieldId: fieldId,
            gpsData: tmpRoute,
            spotData: newSpot,
            totalArea: res.creationRequestDetail.field.totalArea,
          };
          setFieldData(newFieldData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newFieldId: string) => {
    queryClient.prefetchQuery(KEY_FIELD_REQUEST_DETAIL, () =>
      getFieldRequestDetail(newFieldId)
    );
  };

  const onReload = () => {
    fieldPrefetch(searchKey, searchWord);
  };

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  const isSearching = () => {
    if (searchRef.current.value && selectRef.current.value) {
      setSearchKey(selectRef.current.value);
      setSearchWord(searchRef.current.value);
    } else {
      clearSearchCondition();
    }
  };

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[0], Chosen[1], Chosen[2], Chosen[4]];
    // setIsSend(false);
    setChosenRequest([newChosen]);
    setFieldId(fieldList[i].fieldId);
    setSelectedFieldId(fieldList[i].fieldId);
    prefetchDetail(fieldList[i].fieldId);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    fieldPrefetchPage(newPage);
  };

  const getFocusIndex = () => {
    const newIndex = fieldList
      .map((item: any, idx: number) => (item.fieldId === fieldId ? idx : -1))
      .filter((item: any) => item !== -1);
    const index = newIndex !== null && newIndex.length > 0 ? newIndex[0] : -1;
    return index;
  };

  const reloadField = (id: string) => {
    if (id !== '') {
      setFieldId(id);
      fieldRefetch();
    }
  };

  const {
    mutate: changeFieldApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeFieldApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('수정 요청되었습니다');
      fieldRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '필드 수정을 요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const onConfirm = () => {
    const newData = {
      fieldId: fieldId,
      status: 4, // 1-작성중 2-접수요청 3-반려 4-접수완료
      message: '',
    };
    changeFieldApplyStatusMutation(newData);
  };

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    fieldPrefetch(searchKey, searchWord);
  }, [searchKey, searchWord]);

  useEffect(() => {
    if (mapInfo) {
      setGpsData(mapInfo.points);
    }
  }, [mapInfo]);

  return (
    <Box className='root_box'>
      <Box className='flex_between'>
        <SelectSearchBox
          searchOption={selectVal}
          searchWord={searchRef}
          defaultOption='name'
          isSearching={isSearching}
          optionValue={selectRef}
        />
      </Box>
      <Paper>
        <Box sx={{ mb: '1rem' }}>
          <RequestTable
            columns={requestColumnlists}
            rows={requestList}
            onclick={chooseRequest}
            getFocusIndex={getFocusIndex}
          />
        </Box>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <ChallengeFieldMap
        fieldData={fieldData}
        mapInfo={mapInfo}
        setMapInfo={setMapInfo}
        reloadField={reloadField}
      />
      <ChallengeFieldForm
        commandType={commandType}
        setCommandType={setCommandType}
        mapInfo={mapInfo}
        fieldDetail={fieldDetail}
        setFieldDetail={setFieldDetail}
        onReload={onReload}
      />
      <Box className='flex_end' sx={{ m: '2rem 0' }}>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={() => {
              setOpenDelete(true);
            }}
          >
            삭제
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              onConfirm();
            }}
          >
            승인
          </Button>
        </Box>
      </Box>
      <SwConfirmDialog
        contents='필드를 삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setCommandType(3);
          setOpenDelete(false);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { ChallengeTypeBox } from '../ChallengeStyles';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { SwStampTourView } from '../../../commonComponent/SwStampTourView';
import { ChallengeQRCode } from '../ChallengeQRCode';
import { MissionQRCodeView } from './MissionQRCodeView';
import { MissionNFCView } from './MissionNFCView';

interface Props {
  challengeId: string;
  mission: any;
  setMission: Dispatch<React.SetStateAction<any>>;
}

export const StampMissionView: React.FC<Props> = ({
  challengeId,
  mission,
  setMission,
}) => {
  const { loginVal } = useSession();
  const findStampcourseRef = useRef<any>();
  const [challengeStampTourId, setChallengeStampTourId] = useState<string>('');
  const [challengeStampTourName, setChallengeStampTourName] =
    useState<string>('');
  const [gpsGetType, setGpsGetType] = useState<number>(1);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');

  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [openStampTourView, setOpenStampTourView] = useState<boolean>(false);

  const searchStampcourse = () => {
    setOpenType(1);
    setTargetTitle('스탬프 코스');
    setSearchKey('stampcourse');
    setSearchWord(findStampcourseRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchStampcourse();
    }
  };

  const onChangeGpsGetType = (evt: any) => {
    const value =
      evt.currentTarget.value === 'bymanual'
        ? 1
        : evt.currentTarget.value === 'auto'
        ? 2
        : evt.currentTarget.value === 'qrcode'
        ? 3
        : 4;
    setGpsGetType(value);
    setMission({ ...mission, gpsGetType: value });
  };

  useEffect(() => {
    const newSearchResult = [...searchResult];
    if (openType === 1) {
      setChallengeStampTourId(newSearchResult[0]?.id);
      setChallengeStampTourName(newSearchResult[0]?.name);
      setMission({
        ...mission,
        stampTourId: newSearchResult[0]?.id,
        gpsGetType: gpsGetType,
        goal: newSearchResult[0]?.count,
      });
    }
    return () => {};
  }, [searchResult]);

  useEffect(() => {
    if (mission) {
      setChallengeStampTourId(mission.stampTourId);
      setGpsGetType(mission.gpsGetType || 1);
    }
    return () => {};
  }, [mission]);

  return (
    <>
      <Box>
        <ChallengeTypeBox>
          <div className='flex_center'>
            <Typography className='title'>스탬프 코스</Typography>
            <TextField
              placeholder='스탬프 코스 이름, 아이디 검색'
              sx={{ marginRight: '1rem', width: '21rem' }}
              inputRef={findStampcourseRef}
              onKeyDown={evt => {
                handleKeyDown(evt, 'stampcourse');
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        searchStampcourse();
                      }}
                      sx={{ pr: 0 }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Typography className='title'>
            {challengeStampTourId && challengeStampTourId !== ''
              ? `${challengeStampTourId}`
              : ''}
          </Typography>
          {challengeStampTourId && challengeStampTourId !== '' && (
            <Button
              variant='outlined'
              color='primary'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenStampTourView(true)}
            >
              코스 보기
            </Button>
          )}
        </ChallengeTypeBox>
        <ChallengeTypeBox sx={{ mt: '1rem' }}>
          <Typography className='title' sx={{ mr: '1rem' }}>
            스탬프 획득 방식
          </Typography>
          <RadioGroup
            row
            aria-labelledby='is-auto-row-radio-buttons-group-label'
            name='is-auto-row-radio-buttons-group'
            value={
              gpsGetType === 1
                ? 'bymanual'
                : gpsGetType === 2
                ? 'auto'
                : gpsGetType === 3
                ? 'qrcode'
                : 'nfc'
            }
            onChange={evt => {
              onChangeGpsGetType(evt);
            }}
          >
            <FormControlLabel
              value='bymanual'
              control={<Radio />}
              label='GPS 수동'
            />
            <FormControlLabel
              value='auto'
              control={<Radio />}
              label='GPS 자동'
            />
            <FormControlLabel
              value='qrcode'
              control={<Radio />}
              label='QR코드'
            />
            <FormControlLabel value='nfc' control={<Radio />} label='NFC' />
          </RadioGroup>
        </ChallengeTypeBox>{' '}
      </Box>
      {gpsGetType === 3 && (
        <Box>
          <MissionQRCodeView challengeId={challengeId} mission={mission} />
        </Box>
      )}
      {challengeId && gpsGetType === 4 && (
        <Box>
          <MissionNFCView challengeId={challengeId} mission={mission} />
        </Box>
      )}
      <SwSearchTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwStampTourView
        title='코스 보기'
        open={openStampTourView}
        onClose={() => setOpenStampTourView(false)}
        stamptourId={challengeStampTourId}
        challengeId={challengeId}
        readOnly={true}
      />
    </>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import {
  KEY_CHALLENGE_NFC_LIST,
  KEY_CHALLENGE_TOUR_MISSION_LIST,
  KEY_CHALLENGE_TOUR_REWARD_MISSION_LIST,
} from '../../../../common/key';
import { encodeSearchWord } from '../../../../common/helper';
import {
  downloadNfcList,
  getChallengeNfcList,
} from '../../../../common/api/ApiChallenge';
import { TableTitle } from '../../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../../commonComponent/tableElement/SwPagination';
import SwLoading from '../../../commonComponent/spinner/SwLoading';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import {
  getChallengeTourMissionList,
  getChallengeTourRewardMissionList,
} from '../../../../common/api/ApiChallengeTour';

const columns = ['투어 미션', '활동 미션', '인증 미션', '질문 미션'];

interface Props {
  challengeId: string;
  rewardId: string;
  checkedMission: any;
  setCheckedMission: Dispatch<React.SetStateAction<any>>;
}

export const MissionPickerView: React.FC<Props> = ({
  challengeId,
  rewardId,
  checkedMission,
  setCheckedMission,
}) => {
  const open = true;
  const [category1Mission, setCategory1Mission] = useState<any[]>([]);
  const [category2Mission, setCategory2Mission] = useState<any[]>([]);
  const [category3Mission, setCategory3Mission] = useState<any[]>([]);
  const [category4Mission, setCategory4Mission] = useState<any[]>([]);
  const [categoryMissionMaxCount, setCategoryMissionMaxCount] =
    useState<number>(0);

  const {
    data,
    isLoading,
    refetch: challengeTourRewardMissionRefetch,
  } = useQuery(
    KEY_CHALLENGE_TOUR_REWARD_MISSION_LIST,
    () => {
      const newData: any = {
        challengeId: challengeId,
        rewardId: rewardId,
      };
      if (
        challengeId &&
        rewardId &&
        challengeId.length > 0 &&
        rewardId.length > 0
      )
        return getChallengeTourRewardMissionList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.mission !== undefined && res.mission !== null) {
          const tmpCategory1Mission: any[] = [];
          const tmpCategory2Mission: any[] = [];
          const tmpCategory3Mission: any[] = [];
          const tmpCategory4Mission: any[] = [];
          const newChecked: any[] = [];

          res.mission.map((item: any, idx: number) => {
            item.category === 1
              ? tmpCategory1Mission.push(item)
              : item.category === 2
              ? tmpCategory2Mission.push(item)
              : item.category === 3
              ? tmpCategory3Mission.push(item)
              : item.category === 4
              ? tmpCategory4Mission.push(item)
              : '';
            item.checked === 1 ? newChecked.push(item.missionId) : '';
          });
          const maxValue = Math.max(
            tmpCategory1Mission.length,
            tmpCategory2Mission.length,
            tmpCategory3Mission.length,
            tmpCategory4Mission.length
          );
          setCategory1Mission(tmpCategory1Mission);
          setCategory2Mission(tmpCategory2Mission);
          setCategory3Mission(tmpCategory3Mission);
          setCategory4Mission(tmpCategory4Mission);
          setCategoryMissionMaxCount(maxValue);
          setCheckedMission(newChecked);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedMission([...checkedMission, id]);
    } else {
      setCheckedMission(checkedMission.filter((button: any) => button !== id));
    }
  };

  const getMissionCheckBox = (category: number, idx: number) => {
    const item =
      category === 1 && idx < category1Mission.length
        ? {
            title: category1Mission[idx].title,
            id: category1Mission[idx].missionId,
          }
        : category === 2 && idx < category2Mission.length
        ? {
            title: category2Mission[idx].title,
            id: category2Mission[idx].missionId,
          }
        : category === 3 && idx < category3Mission.length
        ? {
            title: category3Mission[idx].title,
            id: category3Mission[idx].missionId,
          }
        : category === 4 && idx < category4Mission.length
        ? {
            title: category4Mission[idx].title,
            id: category4Mission[idx].missionId,
          }
        : { title: '', id: '' };
    return item.id !== '' ? (
      <Box className='flex_start'>
        <Checkbox
          id={item.id}
          onChange={e => {
            changeHandler(e.currentTarget.checked, item.id);
          }}
          checked={checkedMission.includes(item.id)}
        />
        <Typography className='title'>{item.title}</Typography>
      </Box>
    ) : (
      ''
    );
  };

  useEffect(() => {
    if (challengeId) challengeTourRewardMissionRefetch();
  }, [challengeId, rewardId]);

  return (
    <Box>
      <Typography className='title'>달성 미션</Typography>
      <TableContainer
        sx={{
          p: '0 1rem',
          height: '33vh',
          overflowY: 'auto',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={`audio_content_head_${idx}`}
                  sx={{ p: '.4rem .6rem' }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: categoryMissionMaxCount }, (_, i) => ``).map(
              (row: any, idx: number) => (
                <TableRow key={`mission_type_${idx}`}>
                  <TableCell>{getMissionCheckBox(1, idx)}</TableCell>
                  <TableCell>{getMissionCheckBox(2, idx)}</TableCell>
                  <TableCell>{getMissionCheckBox(3, idx)}</TableCell>
                  <TableCell>{getMissionCheckBox(4, idx)}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSession, xapi } from '../../../hooks/session';
import { IUploadFile } from '../../../models/common';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';

const MAX_NAME_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;

export const categoryList = [
  { name: '선택', value: 0 },
  { name: 'WALKZONE', value: 1 },
  { name: 'HOTPLACE', value: 2 },
  { name: 'PARK', value: 3 },
  { name: 'LANDMARK', value: 4 },
  { name: 'MOUNTAIN', value: 5 },
  { name: 'UNIV', value: 6 },
  { name: 'GOLF', value: 7 },
  { name: 'MARKET(E-MART)', value: 8 },
  { name: 'APARTMENT', value: 11 },
];

interface Props {
  open: boolean;
  spot: any;
  onClose: () => void;
  onSave: (spot: any) => void;
}

export const getCategoryName = (category: number) => {
  let categoryName = '-';

  switch (category) {
    case 1:
      categoryName = 'WALKZONE';
      break;
    case 2:
      categoryName = 'HOTPLACE';
      break;
    case 3:
      categoryName = 'PARK';
      break;
    case 4:
      categoryName = 'LANDMARK';
      break;
    case 5:
      categoryName = 'MOUNTAIN';
      break;
    case 6:
      categoryName = 'UNIV';
      break;
    case 7:
      categoryName = 'GOLF';
      break;
    case 8:
      categoryName = 'MARKET(E-MART)';
      break;
    case 11:
      categoryName = 'APARTMENT';
      break;
    case 99:
      categoryName = 'ETC';
      break;
    default:
      categoryName = '-';
  }
  return categoryName;
};
export const getSubCategoryName = (category: number, subCategory: number) => {
  let subCategoryName = '-';

  if (category === 3) {
    switch (subCategory) {
      case 1:
        subCategoryName = '가로공원';
        break;
      case 2:
        subCategoryName = '근린공원';
        break;
      case 3:
        subCategoryName = '도시농업공원';
        break;
      case 4:
        subCategoryName = '묘지공원';
        break;
      case 5:
        subCategoryName = '문화공원';
        break;
      case 6:
        subCategoryName = '소공원';
        break;
      case 7:
        subCategoryName = '수변공원';
        break;
      case 8:
        subCategoryName = '어린이공원';
        break;
      case 9:
        subCategoryName = '역사공원';
        break;
      case 10:
        subCategoryName = '주제공원';
        break;
      case 11:
        subCategoryName = '체육공원';
        break;
      case 12:
        subCategoryName = '기타공원';
        break;
      case 20:
        subCategoryName = '기타';
        break;
      case 21:
        subCategoryName = '기타-공공공지';
        break;
      case 22:
        subCategoryName = '기타-광장';
        break;
      case 23:
        subCategoryName = '기타-쌈지공원';
        break;
      case 24:
        subCategoryName = '기타-저류지';
        break;
      default:
        subCategoryName = '-';
        break;
    }
  }
  return subCategoryName;
};

export const GeofencingInfoView: React.FC<Props> = ({
  open,
  spot,
  onClose,
  onSave,
}) => {
  const { loginVal } = useSession();
  const [name, setName] = useState<string>('');
  const [category, setCategory] = useState<number>(0);

  const saveInfo = () => {
    const newSpot = {
      name: name,
      category: category,
    };
    onSave(newSpot);
  };

  useEffect(() => {
    if (spot) {
      setName(spot.name);
      setCategory(spot.category);
    }
  }, [spot]);

  useEffect(() => {
    if (!open) {
      setName('');
      setCategory(0);
    }
  }, [open]);

  return (
    <Dialog
      id='test'
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          height: '36vh',
          width: '22vw',
          minWidth: '333px',
        },
      }}
    >
      <DialogTitle sx={{ padding: '1rem .5rem 0' }}>공간 정보 작성</DialogTitle>
      <DialogContent sx={{ padding: '1rem .5rem 0' }}>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>장소 이름</Typography>
          <TextField
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={name}
            name='name'
            onChange={evt => setName(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>
                    {name.length} / {MAX_NAME_LENGTH}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>장소 분류</Typography>
          <TextField
            sx={{ p: '.4rem 0 0', width: '50%' }}
            select
            value={category}
            name='className'
            onChange={evt => setCategory(Number(evt.target.value))}
          >
            {categoryList.map(item => (
              <MenuItem key={`category_idx_${item.value}`} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <Divider sx={{ p: '0', m: '0' }} />
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          취소
        </Button>
        <Button variant='contained' onClick={() => saveInfo()}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

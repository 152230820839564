import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { StringIterator } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { DefaultLoginUser, ILoginUser } from '../models/emp';
import useLocalStorage from './storage';

type Action =
  | { type: 'login'; payload: { loginid: string; passwd: string } }
  | {
      type: 'join';
      payload: { name: string; id: string; password: string; role: number };
    }
  | { type: 'logout' }
  | { type: 'setUser'; payload: ILoginUser };

interface LoginProps {
  id: string;
  password: string;
}

const xapi = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  // withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain',
    // 'Access-Control-Allow-Origin': 'http://localhost:3000',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS,PATCH',
    'Access-Control-Allow-Headers':
      'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
  },
});

const initialState = DefaultLoginUser;

const reducer = (session: ILoginUser, action: Action) => {
  switch (action.type) {
    case 'login':
      return session;
    case 'logout':
      return initialState;
    case 'setUser': {
      return { ...action.payload, didLogin: !!action.payload?.user?.name };
    }
    default:
      return session;
  }
};

interface ContextProps {
  session: ILoginUser;
  signAction: Dispatch<Action>;
  login: (params: LoginProps, errfn?: (err: AxiosError) => void) => void;
  logout: () => void;
  loginVal: any;
  setLoginVal: any;
}

const SessionContext = createContext<ContextProps>({
  session: initialState,
  signAction: () => {},
  login: () => {},
  logout: () => {},
  loginVal: initialState,
  setLoginVal: () => {},
});

function SessionProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const [session, signAction] = useReducer(reducer, initialState);

  // 1일 동안만  로그인 유지? ㅎ...
  const [loginVal, setLoginVal] = useLocalStorage(
    'loginVal',
    session,
    86400000
  );

  useEffect(() => {
    // signAction({
    //   type: 'setUser',
    //   payload: {
    //     user: {
    //       id: '',
    //       name: '',
    //       role: '',
    //     },
    //     didLogin: false,
    //     attemptedPage: '',
    //     authorization: '',
    //   },
    // });
    // setLoginVal({
    //   user: {
    //     id: '',
    //     name: '',
    //     role: '',
    //   },
    //   didLogin: false,
    //   attemptedPage: '',
    //   authorization: '',
    // });
  }, []);

  // // 요청 인터셉터 설정 -> 백스페이스 제거 루틴 추가할 경우 이미지 업로드 실패처리됨, comment 처리함
  // xapi.interceptors.request.use(
  //   config => {
  //     if (config.data) {
  //       const bodyString = JSON.stringify(config.data);
  //       const cleanedBodyString = bodyString.replaceAll('\\b', ''); // 백스페이스 제거
  //       config.data = JSON.parse(cleanedBodyString); // 다시 객체로 변환
  //     }
  //     return config;
  //   },
  //   error => Promise.reject(error)
  // );

  // 응답 인터셉터 추가하기
  xapi.interceptors.response.use(
    response => response,
    error => {
      // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 오류가 있는 작업 수행
      const { code } = error;
      const status = error.response?.status;

      if (status === 408 || status === 401) {
        // alert('요청이 만료되었습니다.');
        signAction({ type: 'setUser', payload: initialState });
        setLoginVal(initialState);
        navigate('/login');
      }
      return Promise.reject(error);
    }
  );

  const login = (params: LoginProps, errfn?: (err: AxiosError) => void) => {
    xapi
      .post<ILoginUser>('/admin/v2/apis/account/signin', params)
      .then(res => {
        const newUser = { ...res.data };
        xapi.defaults.headers.common.Authorization = res.headers.authorization;
        signAction({
          type: 'setUser',
          payload: newUser,
        });
        const newStorage = {
          user: { ...newUser.user },
          didLogin: newUser.user ? !!newUser.user.name : false,
          attemptedPage: 'home',
          authorization: res.headers.authorization,
        };
        setLoginVal(newStorage);

        if (newUser.user) {
          navigate('/');
        } else {
          const responseData = newUser.resultCodeMsg;
          const responseCode = Number(newUser.resultCode);
          const response: AxiosResponse = {
            data: responseData,
            status: responseCode,
          } as AxiosResponse;
          const axiosError = {
            config: {},
            request: {},
            response: response,
          } as AxiosError<any>;
          if (errfn) errfn(axiosError);
        }
        // if (!newUser.role) {
        //   const { attemptedPage } = session;
        //   if (attemptedPage && attemptedPage !== '/login') {
        //      session.attemptedPage = '';
        //      navigate(attemptedPage);
        //   } else
        //     navigate('/dashboard');
        // } else {
        //   navigate('/test');
        // }
      })
      .catch((error: AxiosError) => {
        console.log('error >>>>> ', error);
        if (errfn) errfn(error);
      });
  };

  const logout = () => {
    // var config = {
    //   headers: { authorization: session.authorization },
    // };
    xapi.post<ILoginUser>('/admin/v2/apis/account/signout').then(res => {
      signAction({ type: 'setUser', payload: initialState });
      setLoginVal(initialState);
      navigate('/login');
    });
    // setLoginVal(initialState);
    // console.log('logout complete>>>>>');
    // navigate('/login');
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        signAction,
        login,
        logout,
        loginVal,
        setLoginVal,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

const setAuthorization = (authoriztion: string) => {
  xapi.defaults.headers.common.Authorization = authoriztion;
};

const useSession = () => useContext(SessionContext);

export { useSession, SessionProvider, xapi, setAuthorization };

import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useResize } from '../../../hooks/resize';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import {
  KEY_APP_REVIEW_LIST,
  KEY_NOTICE_LIST,
  KEY_REWARD_MALL_ORDER_LIST,
} from '../../../common/key';
import {
  changeAppReview,
  getAppReviewList,
} from '../../../common/api/ApiAppReview';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { STORE_ID } from '../productManagement/Index';
import {
  getRewardMallOrderDownload,
  getRewardMallOrderList,
  getStoreBrandProductDownload,
} from '../../../common/api/ApiRewardMall';
import { productTypeList } from '../common/SelectProductType';
import { productStatusList } from '../common/SelectProductStatus';
import { useSession } from '../../../hooks/session';
import { OrderInfoView } from './OrderInfoView';

interface Props {}

const columns = [
  '고객명',
  '데이터 포털 아이디',
  '상품 정보',
  '주문 번호',
  '상품 유형',
  '브랜드',
  '발송 일시',
  '총 결제 금액',
  '발송 수량',
  '상태',
];

const searchOption = [
  {
    title: '고객명',
    value: 'user',
  },
  {
    title: '포털 아이디',
    value: 'id',
  },
  {
    title: '상품명',
    value: 'product',
  },
  {
    title: '주문번호',
    value: 'order',
  },
];

export const orderStatusList = ['주문 요청', '주문 완료', '주문 취소'];

export const PaymentHistory: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { loginVal } = useSession();

  const { isMobile } = useResize();
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();
  const [orderList, setOrderList] = useState<any>([]);
  const [selectedOrderIndx, setSelectedOrderIndx] = useState<number>(-1);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openOrderDialog, setOpenOrderDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  const {
    data,
    isLoading,
    refetch: rewardmallOrderRefetch,
  } = useQuery(
    KEY_REWARD_MALL_ORDER_LIST,
    () => {
      setLoading(true);
      const newData: any = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getRewardMallOrderList(newData);
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res != null) {
          setOrderList(res.order);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (
    newSearchKey: string,
    newSearchWord: string,
    newPage: number,
    newRowsPerPage: number
  ) => {
    queryClient.prefetchQuery(KEY_REWARD_MALL_ORDER_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: newRowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(newSearchKey, newSearchWord),
      };
      return getRewardMallOrderList(newData);
    });
  };

  const donwloadOrderList = () => {
    const newData = {
      page: 0,
      rowsPerPage: 0,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
    };
    getRewardMallOrderDownload(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const searchOrder = () => {
    const newSearchKey = selectRef.current.value;
    const newSearchWord = searchRef.current.value;
    setPage(1);
    setSearchKey(newSearchKey);
    setSearchWord(newSearchWord);
    prefetch(newSearchKey, newSearchWord, 1, rowsPerPage);
  };

  const chooseRequest = (i: number) => {
    setSelectedOrderIndx(i);
    setOpenOrderDialog(true);
  };

  useEffect(() => {
    rewardmallOrderRefetch();
  }, [page]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>결제 내역</title>
      </Helmet>
      <Paper sx={{ m: '1rem 0', p: '1rem' }}>
        <Box className='flex_between'>
          <SelectSearchBox
            placeholderString='검색어를 입력해주세요'
            searchOption={searchOption}
            searchWord={searchRef}
            optionValue={selectRef}
            defaultOption='user'
            isSearching={() => searchOrder()}
          />
          <Box>
            <Button variant='outlined' color='info' onClick={donwloadOrderList}>
              데이터 다운로드
              <FileDownloadRoundedIcon color='success' />
            </Button>
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList?.map((row: any, idx: number) => (
              <TableRow
                key={`order_list_idx_${idx}`}
                onClick={() => chooseRequest(idx)}
              >
                <TableCell>{row.customName}</TableCell>
                <TableCell>{row.dataPortalId}</TableCell>
                <TableCell>
                  <Box className='flex_start'>
                    {row.productImage ? (
                      <img
                        src={
                          row.image !== ''
                            ? `${imageUrlPrefix}${row.productImage}`
                            : ''
                        }
                        alt='img'
                        style={{
                          width: '3rem',
                          height: '3rem',
                          margin: '0 .5rem 0 0',
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <Typography>{row.productName}</Typography>
                  </Box>
                </TableCell>
                <TableCell>{row.orderId}</TableCell>
                <TableCell>{productTypeList[row.productType - 1]}</TableCell>
                <TableCell>{row.brandName}</TableCell>
                <TableCell>
                  {row.deliveryDate
                    ? timestamp2Localestring(row.deliveryDate, 1000)
                    : '-'}
                </TableCell>
                <TableCell>{row.quantity * row.salePrice}</TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>{orderStatusList[row.status - 1]}</TableCell>
              </TableRow>
            ))}
            {(orderList === null || orderList.length === 0) && (
              <TableRow>
                <TableCell colSpan={10}>검색결과 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={orderList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <OrderInfoView
        open={openOrderDialog}
        onClose={() => {
          setOpenOrderDialog(false);
        }}
        orderInfo={orderList[selectedOrderIndx]}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </div>
  );
};

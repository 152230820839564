import { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper, Slide, styled, Typography } from '@mui/material';
import { SwTable2 } from '../../commonComponent/table/SwTable2';
import { SwNaverMap } from '../../commonComponent/map/SwNaverMap';
import {
  timestamp2string,
  getYearList,
  getMonthList,
  commaFormat,
  timestamp2Localestring,
  getCurrentDate,
  MAX_STEP,
  formatDuration,
} from '../../../common/helper';

interface Props {
  type: any;
  userInfo: any;
  missionList: any;
  rewardInfo: any;
}

const TitleTypography = styled(Typography)({
  padding: '.5rem .5rem .5rem .15rem',
  marginBottom: '.33rem',
  fontWeight: 500,
  fontSize: '1rem',
});

const GridRow = styled(Grid)({
  border: '1px solid #E7EDF6',
  backgroundColor: '#F9FAFC',
  borderLeft: 'none',
  padding: '.7em',
});
const GridCell = styled(Grid)({
  border: '1px solid #E7EDF6',
  borderLeft: 'none',
  padding: '.7em',
});

export const UserChallengeDetailTourType: React.FC<Props> = ({
  type,
  userInfo,
  missionList,
  rewardInfo,
}) => {
  const a = 1;

  return (
    <>
      <Box>
        <TitleTypography>사용자 정보</TitleTypography>
        <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
          <GridRow item xs={3}>
            닉네임
          </GridRow>
          <GridCell item xs={9}>
            {userInfo.name}
          </GridCell>
          <GridRow item xs={3}>
            휴대폰 번호
          </GridRow>
          <GridCell item xs={9}>
            {userInfo.phoneno}
          </GridCell>
          <GridRow item xs={3}>
            달성 미션
          </GridRow>
          <GridCell item xs={9}>
            {commaFormat(String(userInfo.progress))}
          </GridCell>
        </Grid>
      </Box>
      <Box sx={{ mt: '2em' }}>
        <TitleTypography>챌린지 진행 정보</TitleTypography>
        <Grid
          container
          sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
        >
          <GridRow item xs={4}>
            미션 이름
          </GridRow>
          <GridRow item xs={4}>
            달성 여부
          </GridRow>
          <GridRow item xs={4}>
            달성 날짜
          </GridRow>
          {missionList?.map((mission: any, idx: number) => (
            <Fragment key={idx.toString()}>
              <GridCell item xs={4}>
                {mission?.missionName}
              </GridCell>
              <GridCell item xs={4}>
                {mission.isAchieved === 0 ? '미달성' : '달성'}
              </GridCell>
              <GridCell item xs={4}>
                {mission.isAchieved === 1
                  ? timestamp2Localestring(mission.achieveData * 1000)
                  : '-'}
              </GridCell>
            </Fragment>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mt: '2em' }}>
        <TitleTypography>
          리워드 응모 정보({rewardInfo.draw ? rewardInfo.draw?.length : 0}
          건)
        </TitleTypography>
        {rewardInfo.draw &&
          rewardInfo.draw.map((item: any, idx: number) => (
            <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
              <GridRow item xs={4}>
                쿠폰명
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardTitle}
              </GridCell>
              <GridRow item xs={4}>
                쿠폰 지급 형태
              </GridRow>
              <GridCell item xs={8}>
                {
                  rewardInfo.rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                }
              </GridCell>
              <GridRow item xs={4}>
                응모 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardDrawTime !== null
                  ? timestamp2Localestring(item.rewardDrawTime, 1000)
                  : '-'}
              </GridCell>
            </Grid>
          ))}
      </Box>
      <Box sx={{ mt: '2em' }}>
        <TitleTypography>
          리워드 발급 정보({rewardInfo.reward ? rewardInfo.reward?.length : 0}
          건)
        </TitleTypography>
        {rewardInfo.reward &&
          rewardInfo.reward.map((item: any, idx: number) => (
            <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
              <GridRow item xs={4}>
                쿠폰명
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardTitle}
              </GridCell>
              <GridRow item xs={4}>
                쿠폰 지급 형태
              </GridRow>
              <GridCell item xs={8}>
                {
                  rewardInfo.rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                }
              </GridCell>
              <GridRow item xs={4}>
                응모 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardDrawTime !== null
                  ? timestamp2Localestring(item.rewardDrawTime, 1000)
                  : '-'}
              </GridCell>
              <GridRow item xs={4}>
                발급 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardCreateTime !== null
                  ? timestamp2Localestring(item.rewardCreateTime, 1000)
                  : '-'}
              </GridCell>
              <GridRow item xs={4}>
                사용 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardUsedTime !== null
                  ? timestamp2Localestring(item.rewardUsedTime, 1000)
                  : ''}
              </GridCell>
              <GridRow item xs={4}>
                양식 입력 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.양식입력일시}
              </GridCell>
            </Grid>
          ))}
      </Box>
    </>
  );
};

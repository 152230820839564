import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { useResize } from '../../../hooks/resize';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  KEY_APP_REVIEW_LIST,
  KEY_APP_REVIEW_TARGET_LIST,
  KEY_NOTICE_LIST,
  KEY_WORD_PREFIX_PRODUCT,
} from '../../../common/key';
import {
  changeAppReview,
  getAppReviewList,
  getAppReviewTargetList,
  postAppReview,
} from '../../../common/api/ApiAppReview';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { useSession } from '../../../hooks/session';
import { newUuidId } from '../../commonComponent/SwUuidCreate';
import { SelectStoreBrand } from '../common/SelectStoreBrand';
import { SelectProductType } from '../common/SelectProductType';
import { SelectProductDuration } from '../common/SelectProductDuration';
import { SelectProductStatus } from '../common/SelectProductStatus';
import { postStoreProduct } from '../../../common/api/ApiRewardMall';
import { uploadFile } from '../../../common/api/ApiUpload';

interface Props {
  open: boolean;
  onClose: () => void;
  isEdit: boolean;
  productInfo: any;
}

const columns = ['유형', '고객명', '이름', '종류', '진행기간', '참여 인원수'];

export const ProductAdd: React.FC<Props> = ({
  open,
  onClose,
  isEdit,
  productInfo,
}) => {
  const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();

  const queryClient = useQueryClient();
  const { loginVal } = useSession();

  const [productImage, setProductImage] = useState<string>('');
  const [productImageFile, setProductImageFile] = useState<any>(null);
  const [productDescImage, setProductDescImage] = useState<string>('');
  const [productDescImageFile, setProductDescImageFile] = useState<any>(null);
  const [productName, setProductName] = useState<string>('');
  const [productId, setProductId] = useState<string>('');
  const [productTypeId, setProductTypeId] = useState<string>('');
  const [brand, setBrand] = useState<any>();
  const [productDescription, setProductDescription] = useState<string>('');
  const [productType, setProductType] = useState<any>();
  const [productDuration, setProductDuration] = useState<any>();
  const [productStatus, setProductStatus] = useState<any>();
  const [salePrice, setSalePrice] = useState<number>(0);
  const [regularPrice, setRegularPrice] = useState<number>(0);
  const [exchangePlace, setExchangePlace] = useState<string>('');

  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const { mutate: addStoreProductMutate, isError: isMutateError } = useMutation(
    postStoreProduct,
    {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage(isEdit ? '저장되었습니다.' : '추가되었습니다.');
        setTimeout((_: any) => {
          onClose();
        }, 300);
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage('오류가 발생했습니다. 다시 시도해주시기 바랍니다.');
      },
    }
  );

  const registerProductStore = (
    newUploadUrl: string,
    newDescUploadUrl: string
  ) => {
    const newImage =
      productImageFile !== null
        ? newUploadUrl
        : productImage.length > 0
        ? productImage
        : '';

    const newDescImage =
      productDescImageFile !== null
        ? newDescUploadUrl
        : productDescImage.length > 0
        ? productDescImage
        : '';

    const newItem: any = {
      productId: productId,
      productTypeId: productTypeId,
      brandId: brand.value,
      name: productName,
      image: newImage,
      description: productDescription,
      descriptionImage: newDescImage,
      regularPrice: regularPrice,
      salePrice: salePrice,
      type: productType.value,
      expirationPeriod: productDuration.value,
      exchangePlace: exchangePlace,
      status: productStatus.value,
      quantity: 1,
    };

    const newData = {
      product: newItem,
    };
    addStoreProductMutate(newData);
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (productImageFile !== null) {
      const newUploadFile: any = await uploadFile('store', productImageFile);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newDescUploadUrl = '';
    if (productDescImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'store',
        productDescImageFile
      );
      newDescUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    registerProductStore(newUploadUrl, newDescUploadUrl);
  }

  const checkValid = () => {
    var val = 0;

    if (val === 0 && productId.length === 0) val = 1;
    if (val === 0 && productName === '') val = 2;
    if (
      val === 0 &&
      (brand === undefined || brand === null || brand.value === 0)
    )
      val = 3;
    if (val === 0 && salePrice === 0) val = 4;
    if (val === 0 && regularPrice === 0) val = 5;

    let message = '';
    if (val !== 0) {
      switch (val) {
        case 1:
          message = '상품 아이디를 입력해주세요.';
          break;
        case 2:
          message = '상품 이름을 입력해주세요';
          break;
        case 3:
          message = '브랜드를 선택해주세요.';
          break;
        case 4:
          message = '판매가를 입력해주세요';
          break;
        case 5:
          message = '정가를 입력해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
    }

    if (val === 0) {
      uploadMultipleFiles();
    } else {
      setDialogMessage(message);
      setOpenAlert(true);
    }
  };

  const resetData = () => {
    setProductId('');
    setProductTypeId('');
    setProductName('');
    setSalePrice(0);
    setRegularPrice(0);
    setProductImage('');
    setProductImageFile(null);
    setProductDescImage('');
    setProductDescImageFile(null);
    setProductDescription('');
    setExchangePlace('');
    setBrand({ value: 0, name: '' });
    setProductStatus({ value: 0, name: '' });
    setProductDuration({ value: 0, name: '' });
    setProductType({ value: 0, name: '' });
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (open && isEdit) {
      setProductId(productInfo.productId);
      setProductTypeId(productInfo.productTypeId);
      setProductName(productInfo.name);
      setSalePrice(productInfo.salePrice);
      setRegularPrice(productInfo.regularPrice);
      setProductImage(productInfo.image || '');
      setProductImageFile(null);
      setProductDescImage(productInfo.descriptionImage || '');
      setProductDescImageFile(null);
      setProductDescription(productInfo.description);
      setExchangePlace(productInfo.exchangePlace);
    } else if (open && !isEdit) {
      setProductId(newId());
      setOpenSnackbar(false);
      setSearchWord('');
    } else {
      resetData();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '86vh',
          margin: '20rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <Typography className='title'>상품 추가</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          divider={<Divider orientation='vertical' flexItem />}
          spacing={2}
          sx={{ minHeight: '55vh' }}
        >
          <Box sx={{ width: '50%' }}>
            <Box>
              <Typography className='title'>상품 이미지</Typography>
              <SwFileSetting
                image={
                  productImageFile !== null
                    ? productImage
                    : productImage !== ''
                    ? `${imageUrlPrefix}${productImage}`
                    : ''
                }
                setImage={setProductImage}
                setFile={setProductImageFile}
                file={productImageFile}
              />
            </Box>
            <Box>
              <Typography className='title'>상품 이름</Typography>
              <TextField
                sx={{ width: '88%' }}
                value={productName}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setProductName(evt.target.value)}
              />
            </Box>
            <Box>
              <Box sx={{ p: '.5rem .15rem 0' }} className='flex_center'>
                <Typography className='title'>상품 아이디</Typography>
              </Box>
              <Box className='flex_center'>
                <TextField
                  sx={{ width: '88%', mb: 0 }}
                  value={`${KEY_WORD_PREFIX_PRODUCT}${productId}`}
                  InputProps={{ readOnly: true }}
                >
                  {`${KEY_WORD_PREFIX_PRODUCT}${productId}`}
                </TextField>
              </Box>
            </Box>
            <Box>
              <SelectStoreBrand
                brandId={productInfo?.brandId}
                setTarget={setBrand}
              />
            </Box>
            <Box>
              <Typography className='title'>상품 상세정보</Typography>
              <Typography className='' sx={{ mb: '.5rem' }}>
                텍스트 혹은 이미지에 상품 카테고리에 맞는 상품 정보 제공 고시를
                포함해 주세요.
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={6}
                onChange={evt =>
                  setProductDescription(evt.target.value.substring(0, 5000))
                }
                value={productDescription}
                placeholder='상세 정보를 입력해주세요. (최대 5,000자)'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>{productDescription?.length} / 5000</span>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                    padding: '.5rem',
                  },
                }}
              />
            </Box>
            <Box>
              <Typography className='title'>이미지</Typography>
              <SwFileSetting
                image={
                  productDescImageFile !== null
                    ? productDescImage
                    : productDescImage !== ''
                    ? `${imageUrlPrefix}${productDescImage}`
                    : ''
                }
                setImage={setProductDescImage}
                setFile={setProductDescImageFile}
                file={productDescImageFile}
              />
            </Box>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Box>
              <Typography className='title'>판매가</Typography>
              <TextField
                sx={{ width: '88%' }}
                value={salePrice}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setSalePrice(Number(evt.target.value))}
              />
            </Box>
            <Box>
              <Typography className='title'>정가</Typography>
              <TextField
                sx={{ width: '88%' }}
                value={regularPrice}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setRegularPrice(Number(evt.target.value))}
              />
            </Box>
            <SelectProductType
              type={productInfo?.type}
              setTarget={setProductType}
            />
            <SelectProductDuration
              duration={productInfo?.expirationPeriod}
              setTarget={setProductDuration}
            />
            <Box>
              <Typography className='title'>교환처(선택)</Typography>
              <TextField
                sx={{ width: '88%' }}
                placeholder='교환처를 입력해주세요'
                value={exchangePlace}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setExchangePlace(evt.target.value)}
              />
            </Box>
            <SelectProductStatus
              status={productInfo?.status}
              setTarget={setProductStatus}
            />
          </Box>
        </Stack>
      </DialogContent>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogActions>
        <Button color='info' variant='outlined' size='small' onClick={onClose}>
          취소
        </Button>
        <Button
          color='info'
          variant='contained'
          size='small'
          onClick={() => {
            checkValid();
          }}
        >
          {isEdit ? '저장' : '등록'}
        </Button>
      </DialogActions>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Dialog>
  );
};

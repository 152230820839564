import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { KEY_CHALLENGE_NFC_LIST } from '../../../../common/key';
import { encodeSearchWord } from '../../../../common/helper';
import {
  downloadNfcList,
  getChallengeNfcList,
} from '../../../../common/api/ApiChallenge';
import { TableTitle } from '../../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../../commonComponent/tableElement/SwPagination';
import SwLoading from '../../../commonComponent/spinner/SwLoading';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';

interface Props {
  challengeId: string;
  mission: any;
}

const columns = [
  '스탬프 번호',
  '스탬프 이름',
  '위도',
  '경도',
  '주소',
  '태그 ID',
  '태그 보기',
];

export const MissionNFCView: React.FC<Props> = ({ challengeId, mission }) => {
  const { loginVal } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [nfcList, setNfcList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [title, setTitle] = useState('');
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>('qrcode');
  const [searchWord, setSearchWord] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  const {
    data,
    isLoading,
    refetch: nfcRefetch,
  } = useQuery(
    KEY_CHALLENGE_NFC_LIST,
    () => {
      const newData: any = {
        challengeId: challengeId,
        missionId: mission.missionId,
        page: 1,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        startDate: rangeDate[0] !== null ? rangeDate[0] / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1] / 1000 : 0,
      };
      setLoading(true);
      if (mission) return getChallengeNfcList(newData);
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          setNfcList(res.nfc);
          setTotalRecords(res.pagination.totalRecordCount);
        } else {
          setNfcList([]);
          setTotalRecords(0);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_CHALLENGE_NFC_LIST, () => {
      const newData: any = {
        challengeId: challengeId,
        missionId: mission.missionId,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        startDate: rangeDate[0] !== null ? rangeDate[0] / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1] / 1000 : 0,
      };
      return getChallengeNfcList(newData);
    });
  };

  const downloaData = () => {
    const newData: any = {
      challengeId: mission.missionId,
    };
    downloadNfcList(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  useEffect(() => {
    console.log('--> challengeId:', challengeId);
    if (mission && challengeId) {
      nfcRefetch();
    }
  }, [mission]);

  return (
    <Box sx={{ m: '2rem 1rem' }}>
      <Box className='flex_between' sx={{ pb: '1rem' }}>
        <TableTitle title='NFC 태그' count={nfcList ? nfcList.length : 0} />
        <Box>
          <Button
            sx={{ mr: '1rem' }}
            color='info'
            variant='outlined'
            onClick={() => {
              downloaData();
            }}
          >
            다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
        </Box>
      </Box>
      <Paper>
        <Table size='small'>
          <TableHead sx={{ bgcolor: '#F9FAFC' }}>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {nfcList !== null && nfcList.length > 0 ? (
              nfcList.map((item: any, idx: number) => (
                <>
                  <TableRow key={`nfc_${idx}`}>
                    <TableCell>{item.seq}</TableCell>
                    <TableCell>{item.targetName}</TableCell>
                    <TableCell>{item.latitude}</TableCell>
                    <TableCell>{item.longitude}</TableCell>
                    <TableCell>{item.address}</TableCell>
                    <TableCell>{item.tagId}</TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          selectedRow === item.seq
                            ? setSelectedRow(-1)
                            : setSelectedRow(item.seq);
                        }}
                      >
                        태그 보기
                      </Button>
                    </TableCell>
                  </TableRow>
                  {item.seq === selectedRow && (
                    <TableRow key={`nfc_${idx}_2`}>
                      <TableCell>태그 내용</TableCell>
                      <TableCell
                        colSpan={7}
                        sx={{
                          wordWrap: 'break-word',
                          whiteSpace: 'normal',
                          maxWidth: 400,
                        }}
                      >
                        {item.tagContent}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>검색결과 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {nfcList && nfcList.length > 0 && (
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(nfcList.length / rowsPerPage)}
          />
        )}
        {loading && <SwLoading />}
      </Paper>
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};

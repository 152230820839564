import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';
import { ProgramForm } from './ProgramForm';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { ButtonBox, ContentTitle, MarginButton } from './ProgramStyles';
import {
  cloneProgramMaster,
  confirmProgramRegister,
  getProgramList,
} from '../../../common/api/ApiProgram';
import { KEY_PROGRAM_LIST } from '../../../common/key';
import {
  WhiteTextField,
  SwSwitch,
  CreateTabs,
  CreateTab,
} from '../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import { ProgramCurriculum } from './ProgramCurriculum';
import { ChallengeResult } from '../../challenge/challengeManagement/ChallengeResult';
import { DrawResult } from '../../challenge/challengeManagement/DrawResult';
import { ProgramPost } from './ProgramPost';
import { RewardPutDel } from '../../challenge/challengeManagement/RewardPutDel';
import { ProgramReward } from './ProgramReward';
import {
  getProgramRegisterStatus,
  programSearchOption,
} from '../../../common/helperProgram';
import { ProgramOpenRegion } from './ProgramOpenRegion';
import { ProgramMembers } from './ProgramMembers';
import { ProgramRewardPutDel } from './ProgramRewardPutDel';
import { ProgramRewardDraw } from './ProgramRewardDraw';
import { brandPointAdvertiserState } from '../../../common/atom';

interface Props {}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const requestColumnlists = [
  '고객명',
  '프로그램 이름',
  '프로그램 종류',
  '진행기간',
  '참여 인원수',
  '승인대기 인원수',
  '담당자',
];

const rewardColumns = [
  '순서',
  '리워드 이름',
  '수량',
  '지급 방법',
  '사용 방법',
  '상태',
];

export const ProgramList: React.FC<Props> = () => {
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const location = useLocation();
  const [curriculumId, setCurriculumId] = useState<string>('');
  const [programId, setProgramId] = useState<string>('');
  const [programList, setProgramList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [tabValue, setTabValue] = useState(0);
  const [rewardDrawUserList, setRewardDrawUserList] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSwitch, setOpenSwitch] = useState<boolean>(true);
  const [closeSwitch, setCloseSwitch] = useState<boolean>(true);
  const [programFilterInfo, setProgramFilterInfo] = useState<any>();
  const [filter, setFilter] = useState<any>(null);
  const [programInfo, setProgramInfo] = useState<any>();
  const [isReload, setIsReload] = useState<boolean>(true);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== tabValue) setTabValue(newValue);
  };

  const prefetchWithSearchWord = (
    newSearchWord: string,
    newAttendStartTime: number,
    newAttendEndTime: number
  ) => {
    queryClient.prefetchQuery(KEY_PROGRAM_LIST, () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newData = {
        status: status,
        startDate: newAttendStartTime,
        endDate: newAttendEndTime,
        page: 1,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
      };
      return getProgramList(newData);
    });
  };

  const reloadFromNewWindow = () => {
    const newSearch = location.search;
    if (isReload && newSearch !== null && newSearch.length > 0) {
      const newUrl = newSearch.split('?');
      const newParams = newUrl[1].split('&');
      const newProgramId = newParams[0].split('=')[1];
      const newAttendStartTime = newParams[1].split('=')[1];
      const newAttendEndTime = newParams[2].split('=')[1];
      setSearchWord(newProgramId);
      setProgramId(newProgramId);
      prefetchWithSearchWord(
        newProgramId,
        Number(newAttendStartTime),
        Number(newAttendEndTime)
      );
      setIsReload(false);
    }
  };

  const {
    data,
    isLoading,
    refetch: programRefetch,
  } = useQuery(
    KEY_PROGRAM_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      const newDate = new Date().getTime() / 1000;
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newRequest = {
        status: status,
        startDate: rangeDate[0] !== null ? rangeDate[0].getTime() / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1].getTime() / 1000 : 0,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (checkRequest) return getProgramList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newProgram =
          res &&
          res.program &&
          res.program.program.map((item: any, idx: number) => {
            const currentDate = new Date();
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const status = getProgramRegisterStatus(item.registerStatus);
            const newAttendStime = timestamp2Localestring(
              item.attendStartDate,
              1000
            ).split(' ')[0];
            const newAttendEtime = timestamp2Localestring(
              item.attendEndDate,
              1000
            ).split(' ')[0];
            const newData = [
              item.customerName,
              item.title,
              item.type,
              `${newAttendStime}~${newAttendEtime}`,
              item.attendUserCount,
              item.waitingUserCount,
              item.operatorName,
            ];
            if (programId === item.programId) {
              setSelectedRow(idx);
              setProgramInfo(item);
            }
            return newData;
          });
        setRequestList(newProgram);
        setProgramList(res ? res.program.program : []);
        setRewardGivetype(res ? res.program.rewardGivetype : null);
        setRewardUsetype(res ? res.program.rewardUsetype : null);
        setBrandPointAdvertiserList(res ? res.program.advertiser : null);
        setTotalRecords(res.pagination.totalRecordCount);

        const newFilterInfo = {
          type: res ? res.program.type : null,
          giveType: res ? res.program.rewardGivetype : null,
          useType: res ? res.program.rewardUsetype : null,
        };
        setProgramFilterInfo(newFilterInfo);

        reloadFromNewWindow();
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_PROGRAM_LIST, () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newData = {
        status: status,
        startDate: rangeDate[0] !== null ? rangeDate[0].getTime() / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1].getTime() / 1000 : 0,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getProgramList(newData);
    });
  };

  const { mutate: confirmProgramRegisterMutation, isError: isMutateError } =
    useMutation(confirmProgramRegister, {
      onSuccess: res => {
        console.log('confirmProgramRegister res:', res);
        if (res.resultCode === RESULT_OK || res.templateCode === 5) {
          setOpenSnackbar(true);
          setAlertMessage('저장되었습니다.');
          programRefetch();
        } else {
          setAlertOpen(true);
          setAlertMessage('저장되었습니다.'); // 리워드를 등록해 주세요.
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '프로그램을 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const { mutate: cloneProgramMasterMutation, isError: isMasterMutateError } =
    useMutation(cloneProgramMaster, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('복제 완료, 작성중 내역에서 확인하세요.');
          programRefetch();
        } else {
          setOpenSnackbar(true);
          setAlertMessage(
            `프로그램을 복제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
        setDisabledButton(false);
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          `프로그램을 복제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
        setDisabledButton(false);
      },
    });

  const searchProgram = () => {
    setFilter(null);
    setPage(1);
    prefetch(1);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);
    prefetch(newPage);
  };

  const confirmProgram = (testType: number) => {
    const newData: any = {
      programId: programId,
      testType: testType,
    };
    // if (
    //   (programInfo.rewardCount === 0 || programInfo.rewardCount === null) &&
    //   rewardCount === 0 &&
    //   programInfo.programType !== 5
    // ) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('리워드 정보를 입력해주세요');
    //   return;
    // }
    confirmProgramRegisterMutation(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchProgram();
    }
  };

  const onDownloadProgramList = () => {
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newRequest = {
      status: status,
      startDate: filter !== null ? filter.rangeDate[0] : 0,
      endDate: filter !== null ? filter.rangeDate[1] : 0,
      page: page,
      rowsPerPage: rowsPerPage,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
    };
    // downloadProgramList(newRequest);
  };

  useEffect(() => {
    console.log('openSwitch :', openSwitch, ', closeSwitch :', closeSwitch);
    prefetch(1);
  }, [openSwitch, closeSwitch]);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    setSelectedRow(i);
    const newChosen = [Chosen[2], Chosen[1], Chosen[3]];
    setChosenRequest([newChosen]);
    setProgramId(programList[i].programId);
    setProgramInfo(programList[i]);
    setCurriculumId(programList[i].curriculumId);
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== programInfo.rewardCount) {
      programRefetch();
    }
  };

  const reloadProgram = (id: string) => {
    if (id !== '') {
      setProgramId(id);
      programRefetch();
    }
  };

  const searching = () => {
    setPage(1);
    if (searchRef.current.value && selectRef.current.value) {
      if (
        searchRef.current.value === searchWord &&
        selectRef.current.value === searchKey
      ) {
        programRefetch();
      } else {
        setSearchKey(selectRef.current.value);
        setSearchWord(searchRef.current.value);
      }
    } else if (searchWord === '' && searchKey === '') {
      programRefetch();
    } else {
      setSearchKey(selectRef.current.value);
      setSearchWord('');
    }
  };

  const cloneProgram = () => {
    const newData: any = {
      programId: programId,
    };
    setDisabledButton(true);
    cloneProgramMasterMutation(newData);
  };

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    programRefetch();
  }, [searchKey, searchWord]);

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      programRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (filter !== null && filter !== undefined) {
      console.log('filter :', filter);
      setPage(1);
      prefetch(1);
    }
  }, [filter]);

  useEffect(() => {
    programRefetch();
  }, [rowsPerPage]);

  useEffect(() => {
    programRefetch();
  }, [rangeDate]);

  return (
    <>
      <Box className='flex_between' sx={{ m: '1.5rem 0' }}>
        <Box>
          <SelectSearchBox
            searchOption={programSearchOption}
            optionValue={selectRef}
            searchWord={searchRef}
            defaultOption='all'
            isSearching={searching}
            placeholderString='프로그램 이름, 고객명 검색'
            selectLength='13rem'
          />
        </Box>
        <Box sx={{ mr: '2rem' }} className='flex_between'>
          <Box className='flex'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
          <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onDownloadProgramList()}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
        </Box>
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>
              프로그램 <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
          <Box className='flex_between'>
            <Typography sx={{ mr: '.4rem' }}>Open</Typography>
            <SwSwitch
              defaultChecked={true}
              value={openSwitch}
              onChange={() => setOpenSwitch(!openSwitch)}
            />
            <Typography sx={{ ml: '1rem', mr: '.4rem' }}>Close</Typography>
            <SwSwitch
              defaultChecked={true}
              value={closeSwitch}
              onChange={() => setCloseSwitch(!closeSwitch)}
            />
            <TextField
              select
              value={rowsPerPage}
              defaultValue={10}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
              }}
              sx={{
                ml: '1rem',
                mb: 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  height: '2rem',
                },
              }}
            >
              <MenuItem value={5}>5개씩 보기</MenuItem>
              <MenuItem value={10}>10개씩 보기</MenuItem>
              <MenuItem value={15}>15개씩 보기</MenuItem>
              <MenuItem value={20}>20개씩 보기</MenuItem>
            </TextField>
          </Box>
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: '33vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {requestColumnlists.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) => (
                    <TableCell
                      key={rowIdx.toString()}
                      className={
                        programList[idx].status === 0 ? 'not_important_row' : ''
                      }
                      sx={{ maxWidth: '25rem' }}
                    >
                      {rowIdx !== 1
                        ? row[rowIdx]
                        : programList[idx].status === 0
                        ? `${row[rowIdx]} (close)`
                        : row[rowIdx]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Paper sx={{ p: '0rem 0' }}>
        <Box sx={{ background: '#F5F7FA' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <CreateTab label='기본 정보' />
              <CreateTab label='공개범위(필터)' />
              <CreateTab label='커리큘럼' />
              <CreateTab label='멤버' />
              <CreateTab label='리워드 정보' />
              <CreateTab label='리워드 지급/취소' />
              <CreateTab label='리워드 추첨' />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <ProgramForm
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ProgramOpenRegion
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <ProgramCurriculum
              curriculumId={curriculumId}
              programInfo={programInfo}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <ProgramMembers programInfo={programInfo} />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <ProgramReward
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
              saveRewardCount={saveRewardCount}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <ProgramRewardPutDel
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <ProgramRewardDraw
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
            />
          </TabPanel>
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box>
            <MarginButton
              variant='contained'
              color='info'
              disabled={disabledButton}
              onClick={() => cloneProgram()}
            >
              프로그램 복제
            </MarginButton>
            {/* <MarginButton
              variant='contained'
              color='info'
              onClick={() => {
                setOpenConfirm(1);
              }}
            >
              일시 중단
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => {
                setOpenConfirm(2);
              }}
            >
              강제 종료
            </MarginButton> */}
          </Box>
          <Box>
            <Button
              variant='contained'
              color='info'
              onClick={() => confirmProgram(0)}
            >
              승인
            </Button>
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents={
          openConfirm === 1
            ? '선택한 프로그램을 일시 중단 하시겠습니까?'
            : '선택한 프로그램을 강제 종료 하시겠습니까?'
        }
        open={!!openConfirm}
        onClose={() => setOpenConfirm(0)}
        onConfirm={() => {
          setOpenConfirm(0);
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </>
  );
};

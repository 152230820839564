import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { useResize } from '../../../hooks/resize';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  KEY_APP_REVIEW_LIST,
  KEY_APP_REVIEW_TARGET_LIST,
  KEY_NOTICE_LIST,
  KEY_WORD_PREFIX_PRODUCT,
} from '../../../common/key';
import {
  changeAppReview,
  getAppReviewList,
  getAppReviewTargetList,
  postAppReview,
} from '../../../common/api/ApiAppReview';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { useSession } from '../../../hooks/session';
import { newUuidId } from '../../commonComponent/SwUuidCreate';
import { SelectStoreBrand } from '../common/SelectStoreBrand';
import { SelectProductType } from '../common/SelectProductType';
import { SelectProductDuration } from '../common/SelectProductDuration';
import { SelectProductStatus } from '../common/SelectProductStatus';
import { postStoreProduct } from '../../../common/api/ApiRewardMall';
import { uploadFile } from '../../../common/api/ApiUpload';

interface Props {
  open: boolean;
  onClose: () => void;
  orderInfo: any;
}

export const OrderInfoView: React.FC<Props> = ({
  open,
  onClose,
  orderInfo,
}) => {
  const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();

  const queryClient = useQueryClient();
  const { loginVal } = useSession();

  const [salePrice, setSalePrice] = useState<number>(0);
  const [regularPrice, setRegularPrice] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);

  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const resetData = () => {
    setSalePrice(0);
    setRegularPrice(0);
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (open) {
      setSalePrice(orderInfo.salePrice);
      setRegularPrice(orderInfo.regularPrice);
      setQuantity(orderInfo.quantity);
    } else {
      resetData();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '30vh',
          width: '40vh',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <Typography className='title'>결제 상세</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Box sx={{ backgroundColor: '#F7F8F9', padding: '0 .5rem' }}>
          <Box className='flex_between'>
            <Typography className='title'>총 상품 금액</Typography>
            <Typography className='title'>
              {`${regularPrice * quantity}원`}
            </Typography>
          </Box>
          <Box className='flex_between'>
            <Typography className='title'>할인 금액</Typography>
            <Typography className='title'>{`-${
              regularPrice * quantity - salePrice * quantity
            }원`}</Typography>
          </Box>
          <Box className='flex_between'>
            <Typography className='title'>충전금 금액</Typography>
            <Typography className='title'>{`-${0}원`}</Typography>
          </Box>
        </Box>
        <Divider sx={{ m: 0, p: 0 }} />
        <Box className='flex_between'>
          <Typography className='title'>총 결제 금액</Typography>
          <Typography className='title'>
            {`${salePrice * quantity}원`}
          </Typography>
        </Box>
      </DialogContent>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Dialog>
  );
};

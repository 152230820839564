import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NONE_QUESTION,
} from '../../common/key';
import { IExerciseForm, IGuideTool } from '../../models/challenge';
import {
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  SwSwitch,
} from '../styles/Styles';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';
import { SwConfirmDialog } from './views/SwConfirmDialog';
import { SwFileSetting } from './SwFileSetting';
import { useSession } from '../../hooks/session';

const DataChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const guidetool: IExerciseForm = {
  sequence: 0,
  title: '제목',
  content: '내용',
  image: '',
  imageFile: null,
  uploadUrl: '',
};

const MAX_FORM_PAGE = 10;

interface Props {
  draggableName?: string;
  exerciseId: string;
  formList: any[];
  setFormList: Dispatch<React.SetStateAction<any[]>>;
}

export const SwExerciseMethodForm: React.FC<Props> = ({
  draggableName = 'draggable',
  exerciseId,
  formList,
  setFormList,
}) => {
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);

  const { loginVal } = useSession();
  const [formTitle, setFormTitle] = useState<any>('');
  const [formContent, setFormContent] = useState<any>('');
  const [formImage, setFormImage] = useState<any>('');
  const [formImageFile, setFormImageFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );

  const formItems = document.querySelectorAll(`.${draggableName}`);
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [formIndex, setFormIndex] = useState<number>(0);
  const inforDataTemplate = [
    {
      title: '준비 자세',
      content:
        exerciseId === 'squat'
          ? '1. 다리는 어깨너비, 발끝은 약간 바깥쪽을 향합니다.\n2.가슴을 펴고 허리는 곧게 세워 주세요.\n3. 팔은 앞으로 나란히 하여 균형을 잡습니다.'
          : exerciseId === 'plank'
          ? '1. 엎드려 누운 자세에서 시작합니다.\n2. 팔꿈치를 어깨 아래에 두고, 팔꿈치와 팔을 바닥에 대세요.\n3. 발끝을 세워 몸을 들어 올릴 준비를 합니다.'
          : exerciseId === 'pushup'
          ? '1. 양손을 어깨너비보다 약간 넓게 벌리고 바닥에 대세요.\n2. 발끝을 바닥에 고정하고, 몸을 머리부터 발끝까지 곧게 펴세요.\n3. 시선은 바닥을 바라보고, 목과 허리가 일직선을 이루도록 합니다.'
          : '',
      image: '',
      imageFile: null,
      uploadUrl: '',
    },
    {
      title: '동작',
      content:
        exerciseId === 'squat'
          ? '1. 엉덩이를 뒤로 빼며, 의자에 앉는 느낌으로 무릎을 굽힙니다.\n2. 허벅지가 바닥과 평행해질 때까지 내려갑니다.\n3. 발뒤꿈치로 바닥을 밀며 처음 자세로 돌아옵니다.\n 4. 허리는 항상 곧게 유지하고, 무릎이 발끝을 넘지 않도록 주의합니다.'
          : exerciseId === 'plank'
          ? '1. 몸을 들어 올려 머리부터 발끝까지 일직선을 만듭니다.\n2. 복부에 힘을 주고, 허리가 휘지 않도록 신경 씁니다.\n3. 목은 자연스럽게 두고, 시선은 바닥을 바라봅니다.\n4. 엉덩이가 위로 들리거나 내려가지 않도록 주의하세요.\n5. 정해진 시간 동안 자세를 유지하세요.'
          : exerciseId === 'pushup'
          ? '1. 팔꿈치를 구부리며 몸을 천천히 바닥 쪽으로 내립니다.\n2. 가슴이 바닥에 거의 닿을 정도로 내려간 후, 팔을 펴며 원래 자세로 돌아옵니다.\n3. 동작을 반복하세요.'
          : '',
      image: '',
      imageFile: null,
      uploadUrl: '',
    },
    {
      title: '호흡',
      content:
        exerciseId === 'squat'
          ? '1. 내려갈 때 코로 깊게 숨을 들이마십니다.\n2. 올라올 때 입으로 천천히 숨을 내쉬며 힘을 줍니다.'
          : exerciseId === 'plank'
          ? '복부에 힘을 주면서 규칙적으로 호흡하여 숨을 멈추지 않도록 주의하세요'
          : exerciseId === 'pushup'
          ? '1. 내려갈 때 코로 깊게 숨을 들이마십니다.\n2. 올라올 때 입으로 천천히 숨을 내쉬며 힘을 줍니다.'
          : '',
      image: '',
      imageFile: null,
      uploadUrl: '',
    },
  ];

  const selectFormList = (newFormIndex: number) => {
    const newFormData = formList[newFormIndex];
    console.log('--> newFormData:', newFormData);
    setFormTitle(newFormData.title);
    setFormContent(newFormData.content);
    setFormImage(newFormData.image);
    setFormImageFile(
      newFormData.imageFile !== undefined ? newFormData.imageFile : null
    );
  };

  const changePageContents = () => {
    if (formList[formIndex]) {
      selectFormList(formIndex);
    }
  };

  const addFormView = () => {
    if (formList.length === MAX_FORM_PAGE) {
      setDialogMessage(
        `리워드 폼은 최대 ${MAX_FORM_PAGE}까지 추가할 수 있습니다.`
      );
      setOpenSnackbar(true);
    }

    const newForm = [...formList, guidetool];
    setFormList(newForm);
  };

  const updateFormView = () => {
    if (formTitle.length > 0 && formContent.length > 0) {
      const newTitle = formTitle;
      const newContent = formContent;
      const newFormLists = formList.map((item: any) => item);
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        title: newTitle,
        content: newContent,
        uploadUrl: '',
      };
      setFormList(newFormLists);
    }
  };

  const updateFormImage = () => {
    if (formImageFile !== null) {
      const newFormLists = formList.map((item: any) => item);
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        image: formImage,
        imageFile: formImageFile,
        uploadUrl: '',
      };
      setFormList(newFormLists);
    }
  };

  const removeFormView = (idx: number, cmd: number) => {
    const newForm = formList.filter((_form: any, i: number) => i !== idx);
    setFormList(newForm);
    changePageContents();
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: formList,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setFormList(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    formItems.forEach(item => item.classList.remove('drag_over'));
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setFormList(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    formItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    formItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newFormIndex = Number(evt.currentTarget.dataset.position);
    setFormIndex(newFormIndex);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newFormIndex : null,
    });
    selectFormList(newFormIndex);
  };

  useEffect(() => {
    if (formImageFile !== null) updateFormImage();
  }, [formImageFile]);

  useEffect(() => {
    if (
      formTitle === undefined ||
      formTitle === null ||
      formTitle.trim().length === 0 ||
      formContent === undefined ||
      formContent === null ||
      formContent.trim().length === 0
    )
      return;
    if (formTitle.length > 0 && formContent.length > 0) updateFormView();
  }, [formTitle, formContent]);

  useEffect(() => {
    if (formList != null && formList.length > 0) {
      changePageContents();
    } else {
      setFormList(inforDataTemplate);
    }
  }, [formList]);

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        divider={
          <Divider
            orientation='vertical'
            flexItem
            sx={{ p: 0, m: '1.5rem 3rem 0' }}
          />
        }
      >
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>운동 방법 목록</Typography>

            <Box>
              <Button variant='outlined' onClick={addFormView}>
                추가
              </Button>
              <Button
                sx={{ ml: '1rem' }}
                variant='outlined'
                onClick={updateFormView}
              >
                저장
              </Button>
            </Box>
          </Box>
          <Box>
            {formList?.map((item: any, idx: number) => (
              <Box className='flex_between' key={`form_${idx}`}>
                <DraggablePaper
                  className={draggableName}
                  id={idx.toString()}
                  variant='outlined'
                  onDragStart={dragStart}
                  onDragEnd={dragEnd}
                  onDragOver={dragOver}
                  onDrop={drop}
                  onDragLeave={dragLeave}
                  onDragEnter={dragEnter}
                  draggable
                  data-position={idx}
                  onClick={selectList}
                  sx={{ width: '25rem' }}
                >
                  <div>
                    <span>{item.title}</span>
                  </div>
                  <DragHandleIcon />
                </DraggablePaper>
                {formList.length > 1 && (
                  <ClearIcon
                    className='clear_icon_button'
                    onClick={() => {
                      setFormIndex(idx);
                      removeFormView(idx, 1);
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <Typography className='title'>운동 방법</Typography>
          <Box>
            <TextField
              sx={{ width: '30rem' }}
              value={formTitle}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setFormTitle(evt.target.value)}
            />
          </Box>
          <Box>
            <TextField
              value={formContent}
              fullWidth
              multiline
              rows={5}
              sx={{
                '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' },
              }}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setFormContent(evt.target.value)}
            />
          </Box>
          <Box>
            <SwFileSetting
              image={
                formImageFile !== null
                  ? formImage
                  : formImage !== null && formImage !== ''
                  ? `${imageUrlPrefix}${formImage}`
                  : ''
              }
              setImage={setFormImage}
              setFile={setFormImageFile}
              file={formImageFile}
            />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

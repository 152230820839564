import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SelectTextField, TableTopButton, SwSwitch } from '../../styles/Styles';
import {
  deleteCommunityBanner,
  getCommunityBannerList,
  putCommunityOpenStep,
} from '../../../common/api/ApiCommunity';
import {
  KEY_CHALLENGE_LABEL_LIST,
  KEY_COMMUNITY_BANNER_LIST,
} from '../../../common/key';
import { timestamp2Localestring } from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { ChallengeBannerSetting } from './ChallengeBannerSetting';
import {
  deleteChallengeBanner,
  getChallengeBannerList,
  getChallengeLabelList,
} from '../../../common/api/ApiChallenge';
import { useSession } from '../../../hooks/session';
import { ChallengeLabelingSetting } from './ChallengeLabelingSetting';

interface Props {}

const columns = ['이미지', '인식된 키워드(라벨)', '생성일'];

export const ChallengeLabeling: React.FC<Props> = () => {
  const { loginVal } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const [labels, setLabels] = useState<any>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<DateRange<Date>>([null, null]);
  const [openSettingChange, setOpenSettingChange] = useState(false);
  const [labelId, setLabelId] = useState<number>(0);
  const [title, setTitle] = useState('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [page, setPage] = useState(1);
  const [openSwitchPrivateStep, setOpenSwitchPrivateStep] =
    useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: labelRefetch,
  } = useQuery(KEY_CHALLENGE_LABEL_LIST, () => getChallengeLabelList(), {
    onSuccess: res => {
      if (res !== null) {
        const label = res.label.map((row: any, idx: number) => {
          const newLabel = {
            labelId: row.labelId,
            image: row.image,
            label: row.label,
            createDate: timestamp2Localestring(row.createDate * 1000),
          };
          return newLabel;
        });
        setLabels(label);
      }
    },
    onError: e => {
      console.log(e);
    },
  });

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      labels?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.labelId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const viewBanner = (id: number) => {
    setLabelId(id);
    setOpenSettingChange(true);
    setTitle('이미지 추가하기');
  };

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box className='flex_between' sx={{ mt: '2rem' }}>
        <Typography variant='h4'>이미지 라벨링</Typography>
      </Box>
      <Paper sx={{ m: '.5rem 0', p: '2rem 1rem' }}>
        <Box className='flex_between' sx={{ pb: '1rem' }}>
          <TableTitle title='이미지' count={labels.length} />
        </Box>
        <Paper>
          <Table size='small'>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell
                    key={idx.toString()}
                    align={idx === 2 || idx === 3 ? 'right' : 'inherit'}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {labels.length > 0 ? (
                labels
                  ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  ?.map((item: any, idx: number) => (
                    <TableRow key={idx.toString()}>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          id={item.labelId.toString()}
                          onChange={e => {
                            changeHandler(
                              e.currentTarget.checked,
                              item.labelId
                            );
                          }}
                          checked={checkedButtons.includes(item.labelId)}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '20vw' }}>
                        <img
                          src={`${imageUrlPrefix}${item.image}`}
                          alt=''
                          style={{
                            width: '15vh',
                            height: '15vh',
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {item.label
                          .split(',')
                          .map((line: string, index: number) => (
                            <div key={index}>{line}</div>
                          ))}
                      </TableCell>
                      <TableCell align='right'>{item.createDate}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {labels.length > 0 && (
            <SwPagination
              page={page}
              handleChangePage={handleChangePage}
              count={labels && Math.ceil(labels.length / rowsPerPage)}
            />
          )}
        </Paper>
      </Paper>
      <Box sx={{ textAlign: 'right', mt: '1rem' }}>
        <Button
          variant='contained'
          sx={{ ml: '2rem' }}
          onClick={() => {
            setLabelId(0);
            setOpenSettingChange(true);
            setTitle('이미지 추가');
          }}
        >
          + 이미지 추가하기
        </Button>
      </Box>
      <ChallengeLabelingSetting
        open={openSettingChange}
        title={title}
        onClose={() => setOpenSettingChange(!openSettingChange)}
        onConfirm={() => {
          setOpenSettingChange(!openSettingChange);
          labelRefetch();
        }}
        labels={labels}
        setLabels={setLabels}
        labelId={labelId}
      />
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};

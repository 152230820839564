import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { v4 as uuidv4 } from 'uuid';
import { MarginButton } from '../challenge/challengeManagement/ChallengeStyles';
import { SwSnackbar } from './views/SwSnackbar';
import { SwExerciseMethodForm } from './SwExerciseMethodForm';
import { IExercise, IExerciseForm } from '../../models/challenge';
import { hometrainingType } from '../../common/helper';
import { exerciseDataState } from '../../common/atom';

const goalType1ItemType1 = [10, 15, 20, 30];
const goalType1ItemType2 = [30, 40, 60, 90];
const goalType2ItemType = [1, 2, 3, 4];
const breakTimeItemType = [15, 30, 60, 90, 120, 180];

interface Props {
  sequence: number;
  exercise: any;
}

export const SwChallengeHomeTrainingItem: React.FC<Props> = ({
  sequence,
  exercise,
}) => {
  const [exerciseData, setExerciseData] = useRecoilState(exerciseDataState);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [goalType1, setGoalType1] = useState<number>(0);
  const [goalType2, setGoalType2] = useState<number>(0);
  const [breakTime, setBreakTime] = useState<number>(0);
  const [afterBreakTime, setAfterBreakTime] = useState<number>(0);
  const [exerciseArea, setExerciseArea] = useState<string>('');
  const [videoCode, setVideoCode] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<string>('');
  const [formList, setFormList] = useState<any>([]);

  const addVideoCode = () => {
    const imageUrl = `https://img.youtube.com/vi/${videoCode.trim()}/mqdefault.jpg`;
    setThumbnail(imageUrl);
  };

  const changeExerciseData = (type: number, value: any) => {
    const newExerciseData: any[] = [];

    exerciseData.map((item, idx) => {
      if (idx === exercise.idx) {
        const newItem: IExercise = {
          homeTrainingId: item.homeTrainingId,
          sequence: sequence,
          exerciseType: exercise.idx + 1,
          goalType1: type === 1 ? value : goalType1,
          goalType2: type === 2 ? value : goalType2,
          breakTime: type === 3 ? value : breakTime,
          exerciseArea: type === 4 ? value : exerciseArea,
          videoCode: type === 5 ? value : videoCode,
          afterBreakTime: type === 6 ? value : afterBreakTime,
          methodList: formList,
        };

        newExerciseData.push({ ...newItem });
        return;
      }
      newExerciseData.push({ ...item });
    });
    setExerciseData([...newExerciseData]);
  };

  useEffect(() => {
    if (formList && formList.length > 0) {
      changeExerciseData(99, formList);
    }
  }, [formList]);

  useEffect(() => {
    if (exerciseData) {
      const idx = hometrainingType.findIndex(item => item.id === exercise.id);
      const newExerciseData = exerciseData[idx];
      const newExerciseArea =
        newExerciseData.exerciseArea !== ''
          ? newExerciseData.exerciseArea
          : exercise.id === 'squat'
          ? '허벅지와 코어 근력 강화'
          : exercise.id === 'plank'
          ? '코어 근력 강화'
          : exercise.id === 'pushup'
          ? '상체 근력 강화'
          : '복부 근력 강화';
      const newVideoCode =
        newExerciseData.videoCode !== ''
          ? newExerciseData.videoCode
          : exercise.id === 'squat'
          ? 'u-z5izwjh8o'
          : exercise.id === 'plank'
          ? 'Un-dIlT1GaM'
          : exercise.id === 'pushup'
          ? 'Tr348lcOfRQ'
          : '-';

      setGoalType1(newExerciseData.goalType1);
      setGoalType2(newExerciseData.goalType2);
      setBreakTime(newExerciseData.breakTime);
      setAfterBreakTime(newExerciseData.afterBreakTime);
      setExerciseArea(newExerciseArea);
      setVideoCode(newVideoCode);
      addVideoCode();

      setFormList(newExerciseData.methodList);
    }
  }, [exerciseData]);

  return (
    <Box>
      <Box>
        <Typography className='title bluecontent'>
          {exercise.name} 목표 설정
        </Typography>
        {sequence > 0 && (
          <Box>
            <Typography className='title'>이전 운동 후 휴식 시간</Typography>
            <TextField
              select
              sx={{ width: '10rem', mr: '1rem' }}
              value={afterBreakTime}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setAfterBreakTime(Number(event.target.value));
                changeExerciseData(6, Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={0}>
                선택
              </MenuItem>
              {breakTimeItemType.map((item: any, idx: number) => (
                <MenuItem key={`breaktime_idx_${idx}`} value={item}>
                  {item}초
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </Box>
        )}
        <Typography className='title'>목표 세트</Typography>
        <Box>
          <TextField
            select
            sx={{ width: '10rem', mr: '1rem' }}
            value={goalType1}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setGoalType1(Number(event.target.value));
              changeExerciseData(1, Number(event.target.value));
            }}
          >
            <MenuItem key={0} value={0}>
              선택
            </MenuItem>
            {exercise.id !== 'plank'
              ? goalType1ItemType1.map((item: any, idx: number) => (
                  <MenuItem key={`type1_idx1_${idx}`} value={item}>
                    {item}회씩
                  </MenuItem>
                ))
              : goalType1ItemType2.map((item: any, idx: number) => (
                  <MenuItem key={`type1_idx2_${idx}`} value={item}>
                    {item}초씩
                  </MenuItem>
                ))}
          </TextField>
          <TextField
            select
            sx={{ width: '10rem', mr: '1rem' }}
            value={goalType2}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setGoalType2(Number(event.target.value));
              changeExerciseData(2, Number(event.target.value));
            }}
          >
            <MenuItem key={0} value={0}>
              선택
            </MenuItem>
            {goalType2ItemType.map((item: any, idx: number) => (
              <MenuItem key={`type2_idx_${idx}`} value={item}>
                {item}세트
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: '10rem' }}
            value={
              goalType1 === 0 || goalType2 === 0
                ? ''
                : exercise.id !== 'plank'
                ? `총 ${goalType1 * goalType2}번`
                : `총 ${goalType1 * goalType2}초`
            }
          />
        </Box>
        <Divider />
        <Box>
          <Typography className='title'>한 세트 후 휴식 시간</Typography>
          <TextField
            select
            sx={{ width: '10rem', mr: '1rem' }}
            value={breakTime}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setBreakTime(Number(event.target.value));
              changeExerciseData(3, Number(event.target.value));
            }}
          >
            <MenuItem key={0} value={0}>
              선택
            </MenuItem>
            {breakTimeItemType.map((item: any, idx: number) => (
              <MenuItem key={`breaktime_idx_${idx}`} value={item}>
                {item}초
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>운동 부위</Typography>
          <TextField
            sx={{ width: '30rem' }}
            value={exerciseArea}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setExerciseArea(evt.target.value.substring(0, 20));
              changeExerciseData(4, evt.target.value.substring(0, 20));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{exerciseArea ? exerciseArea.length : 0} / 20</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>참고 영상</Typography>
          <Box className='flex_start'>
            <TextField
              sx={{ width: '30rem' }}
              value={videoCode}
              onChange={(
                evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setVideoCode(evt.target.value.substring(0, 200));
                changeExerciseData(5, evt.target.value.substring(0, 200));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{exerciseArea.length} / 200</span>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{ ml: '1.5rem' }}
              variant='outlined'
              color='info'
              onClick={() => {
                addVideoCode();
              }}
            >
              등록
            </Button>
          </Box>
          {thumbnail !== '' && (
            <Box>
              <img
                src={thumbnail}
                alt='videoPreview'
                style={{
                  width: '440px',
                  height: '247.5px',
                  borderRadius: '10px',
                }}
              />
            </Box>
          )}
          <Divider />
        </Box>
      </Box>
      <Box>
        <SwExerciseMethodForm
          exerciseId={exercise.id}
          formList={formList}
          setFormList={setFormList}
        />
      </Box>
      <Divider />
    </Box>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { EmptyPage } from '../../commonComponent/table/EmptyPage';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import {
  chosenCommunityState,
  communitySearchState,
} from '../../../common/atom';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import {
  getCommunityList,
  postCommunityDeletePreCondition,
  putCommunityChangeOfficial,
} from '../../../common/api/ApiCommunity';
import { KEY_COMMUNITY_LIST } from '../../../common/key';
import { ICommunity } from '../../../models/community';
import { CommunityGroup } from './CommunityGroup';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';

interface Props {}

const selectTabs = [
  {
    value: 'all',
    title: '전체',
  },
  {
    value: 'official',
    title: '공식 커뮤니티',
  },
  {
    value: 'personal',
    title: '개인 커뮤니티',
  },
];

const columns = [
  '커뮤니티 이름',
  '아이디',
  '고객명',
  '커뮤니티 형태',
  '운영 담당자',
  '그룹 개수',
  '공식/개인',
  '커뮤니티 종류',
  '개설 일시',
  '운영 종료 일시',
];

export const CommunityList: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();
  const checkBoxAllRef = useRef<any>();
  const [chosenCommunity, setChosenCommunity] =
    useRecoilState(chosenCommunityState);
  const [communitySearch, setCommunitySearch] =
    useRecoilState(communitySearchState);

  const [openFilter, setOpenFilter] = useState(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [isReload, setIsReload] = useState<boolean>(true);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(communitySearch.page);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentRow, setCurrentRow] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('official');
  const [searchWord, setSearchWord] = useState<string>('');
  const [communityList, setCommunityList] = useState<any>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  var changeType = '';

  const prefetchWithSearchWord = (
    newSearchKey: string,
    newSearchWord: string
  ) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_LIST, () => {
      const newData = {
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
      };
      return getCommunityList(newData);
    });
  };

  const reloadFromNewWindow = () => {
    const newSearch = location.search;
    if (isReload && newSearch !== null && newSearch.length > 0) {
      const newUrl = newSearch.split('?');
      const newParams = newUrl[1].split('&');
      const newCommunityId = newParams[0].split('=')[1];
      setSearchWord(newCommunityId);
      prefetchWithSearchWord('all', newCommunityId);
      setIsReload(false);
    }
  };

  const {
    data,
    isLoading,
    refetch: communityRefetch,
  } = useQuery(
    KEY_COMMUNITY_LIST,
    () => {
      const newData = {
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getCommunityList(newData);
    },
    {
      onSuccess: res => {
        console.log('community List :', res.community);
        setCommunityList(res.community);
        setTotalRecords(res.pagination.totalRecordCount);

        reloadFromNewWindow();
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_LIST, () => {
      const newData = {
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getCommunityList(newData);
    });
  };

  const prefetchSearch = (newSearchKey: string, newSearchWord: string) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_LIST, () => {
      const newData = {
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
      };
      return getCommunityList(newData);
    });
  };

  const { mutate: putCommunityChangeOfficialMutate, isError: isMutateError } =
    useMutation(putCommunityChangeOfficial, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage(
          changeType === 'official'
            ? '커뮤니티 개인변환이 되었습니다.'
            : changeType === 'close'
            ? '선택한 커뮤니티 운영이 종료되었습니다.'
            : changeType === 'delete'
            ? '선택한 커뮤니티를 삭제했습니다.'
            : ''
        );
        communityRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          changeType === 'official'
            ? '커뮤니티 개인변환이 실패했습니다. 다시 시도해주시기 바랍니다.'
            : changeType === 'close'
            ? '커뮤니티 운영종료 설정이 실패했습니다. 다시 시도해주시기 바랍니다.'
            : changeType === 'delete'
            ? '커뮤니티 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
            : ''
        );
      },
    });

  const {
    mutate: postCommunityDeletePreConditionMutate,
    isError: isCondtionMutateError,
  } = useMutation(postCommunityDeletePreCondition, {
    onSuccess: res => {
      if (res !== undefined && res.community !== null) {
        let count = 0;
        res.community.map((item: any) => {
          count += item.amount;
          return item;
        });
        setDialogMessage(
          count > 0
            ? `진행중인 챌린지가 ${count}개 있습니다. 삭제하시겠니까?`
            : '진행중인 챌린지가 없습니다. 삭제하시겠니까?'
        );
        setOpenConfirm(true);
      } else {
        setOpenDialog(true);
        setDialogMessage('커뮤니티를 필터로 지정한 챌린지 조회 실패 오류');
      }
    },
    onError: error => {},
  });

  const isSearching = () => {
    if (searchRef.current.value && selectRef.current.value) {
      setSearchKey(selectRef.current.value);
      setSearchWord(searchRef.current.value);
    } else {
      setSearchKey('');
      setSearchWord('');
    }
    setCommunitySearch({
      select: selectRef.current.value,
      searchWord: searchRef.current.value,
      chosenId: '워크온 운영팀',
      page: 1,
    });
    prefetchSearch(selectRef.current.value, searchRef.current.value);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      communityList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.communityId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const clickRow = (idx: number) => {
    setCommunitySearch({
      select: selectRef.current.value,
      searchWord: searchRef.current.value,
      chosenId: '워크온 운영팀',
      page: page,
    });
    setChosenCommunity(communityList[idx]);
    navigate('/communitymanage/listdetail/basicinfomation', {
      replace: true,
      state: communityList[idx],
    });
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const changeCommunityOfficial = (type: any) => {
    changeType = 'official';
    const checkIds = communityList
      .filter((item: any) => checkedButtons.includes(item.communityId))
      .filter((item: any) => item.official === 0);
    const newData = {
      type: changeType,
      value: type,
      communityIds: checkedButtons,
    };

    if (checkIds.length === 0) {
      console.log('changeCommunityOfficial :', checkIds);
      putCommunityChangeOfficialMutate(newData);
    } else {
      setOpenDialog(true);
      setDialogMessage('개인 커뮤니티를 개인형으로 변환할 수 없습니다.');
    }
  };

  const closeCommunity = (newChangeType: string) => {
    changeType = newChangeType;
    const newData = {
      type: changeType,
      value: 1,
      communityIds: checkedButtons,
    };
    putCommunityChangeOfficialMutate(newData);
  };

  const checkCondition = () => {
    const newData = {
      communityIds: checkedButtons,
    };
    postCommunityDeletePreConditionMutate(newData);
  };

  useEffect(() => {
    if (location.state) {
      const newState: any = location.state;
      if (newState?.comeBack === 1) {
        const newPage = communitySearch.page;
        setPage(newPage);
        prefetch(newPage);
      }
    }
  }, [location.state]);

  useEffect(() => {
    communityRefetch();
  }, [rowsPerPage]);

  useEffect(() => {
    communityRefetch();
  }, [rangeDate]);

  return (
    <Box sx={{ m: '2rem 0' }}>
      <SelectSearchBox
        optionValue={selectRef}
        searchOption={selectTabs}
        searchWord={searchRef}
        isSearching={isSearching}
        defaultOption='all'
        placeholderString='커뮤니티 이름, 아이디, 고객명 검색'
      />
      <Box sx={{ m: '1.5rem 0' }} className='flex_end'>
        <Collapse orientation='horizontal' in={openFilter}>
          <SwDateRangePicker
            rangeDate={rangeDate}
            setRangeDate={setRangeDate}
          />
        </Collapse>
        <Button
          variant='outlined'
          onClick={() => setOpenFilter(!openFilter)}
          sx={{ ml: '1rem', height: '2rem' }}
        >
          <FilterAltOutlinedIcon />
          필터
        </Button>
        <TextField
          select
          value={rowsPerPage}
          defaultValue={10}
          onChange={evt => {
            setRowsPerPage(Number(evt.target.value));
          }}
          sx={{
            ml: '1rem',
            mb: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px',
              height: '2rem',
            },
          }}
        >
          <MenuItem value={5}>5개씩 보기</MenuItem>
          <MenuItem value={10}>10개씩 보기</MenuItem>
          <MenuItem value={15}>15개씩 보기</MenuItem>
          <MenuItem value={20}>20개씩 보기</MenuItem>
        </TextField>
      </Box>
      <Paper sx={{ p: '1.5rem' }}>
        <Box className='flex_center' sx={{ p: '0 0 .8rem .12rem' }}>
          <Typography>커뮤니티</Typography>
          <Typography color='primary' sx={{ ml: '.3rem' }}>
            {` ${totalRecords}`}
          </Typography>
          <Typography>개</Typography>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {communityList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      id={row.communityId}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, row.communityId);
                      }}
                      checked={checkedButtons.includes(row.communityId)}
                    />
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.communityId}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.ownerName}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.contractTypeMaster === -1
                      ? '미지정'
                      : row.contractTypeMaster === 1
                      ? 'B2G'
                      : row.contractTypeMaster === 2
                      ? 'B2B'
                      : row.contractTypeMaster === 3
                      ? '학교'
                      : '기타'}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.official === 0
                      ? '-'
                      : row.operatorName === null ||
                        row.operatorName.length === 0
                      ? '-'
                      : row.operatorName}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.groups.length}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.official === 1 ? '공식' : '개인'}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {row.type === 0
                      ? '공개'
                      : row.type === 1
                      ? '승인가입형'
                      : row.type === 2
                      ? '코드입력형'
                      : '-'}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {timestamp2Localestring(row.createDate)}
                  </TableCell>
                  <TableCell
                    onClick={() => clickRow(idx)}
                    sx={{ p: '.3rem .6rem' }}
                  >
                    {timestamp2Localestring(row.endTimestamp)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Box className='flex_end' sx={{ mt: '1rem' }}>
        <Button
          color='error'
          variant='outlined'
          sx={{ mr: '1rem' }}
          onClick={() => changeCommunityOfficial(0)}
        >
          개인형 변환
        </Button>
        {/* <Button
          color='error'
          variant='contained'
          sx={{ mr: '1rem' }}
          onClick={() => closeCommunity('close')}
        >
          운영 종료
        </Button> */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => checkCondition()}
        >
          삭제
        </Button>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents={dialogMessage}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          closeCommunity('delete');
          setOpenConfirm(false);
        }}
        confirm={1}
      />
      {/* <Button
        onClick={() => {
          setCommunitySearch({
            select: selectRef.current.value,
            searchWord: searchRef.current.value,
            chosenId: '워크온 운영팀',
          });
          navigate('/communitymanage/listdetail/basicinfomation', {
            // replace: true,
            // state: { id: 1 },
          });
        }}
      >
        test
      </Button> */}
    </Box>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SelectTextField, TableTopButton, SwSwitch } from '../../styles/Styles';
import {
  deleteCommunityBanner,
  getCommunityBannerList,
  putCommunityOpenStep,
} from '../../../common/api/ApiCommunity';
import {
  KEY_CHALLENGE_BANNER_MAIN_LIST,
  KEY_CHALLENGE_BANNER_SEARCH_LIST,
  KEY_COMMUNITY_BANNER_LIST,
} from '../../../common/key';
import { timestamp2Localestring } from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { ChallengeBannerSetting } from './ChallengeBannerSetting';
import {
  deleteChallengeBanner,
  getChallengeBannerList,
} from '../../../common/api/ApiChallenge';
import { useSession } from '../../../hooks/session';

interface Props {
  type: string;
  viewTitle: string;
}

const columns = [
  '이미지',
  '액션',
  '공개 시작일시',
  '공개 종료일시',
  '공개 범위',
];

export const ChallengeBannerView: React.FC<Props> = ({ type, viewTitle }) => {
  const { loginVal } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const [banners, setBanners] = useState<any>([]);
  const [title, setTitle] = useState<string>('');
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<DateRange<Date>>([null, null]);
  const [openSettingChange, setOpenSettingChange] = useState(false);
  const [bannerId, setBannerId] = useState<number>(0);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [page, setPage] = useState(1);
  const [openSwitchPrivateStep, setOpenSwitchPrivateStep] =
    useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: bannerRefetch,
  } = useQuery(
    type === 'main'
      ? KEY_CHALLENGE_BANNER_MAIN_LIST
      : KEY_CHALLENGE_BANNER_SEARCH_LIST,
    () => getChallengeBannerList(type),
    {
      onSuccess: res => {
        if (res !== null) {
          const banner = res.banner.map((row: any, idx: number) => {
            const parsedData = JSON.parse(row.data);

            const newBanner = {
              bannerId: row.bannerId,
              image: parsedData.imageUrl,
              action: parsedData.extra.url,
              startDate: timestamp2Localestring(row.startTime * 1000),
              endDate: timestamp2Localestring(row.endTime * 1000),
              locationLimit: row.locationLimit,
              locationName: row.locationName !== '' ? row.locationName : '-',
            };
            return newBanner;
          });
          setBanners(banner);
          console.log('banners:', banner);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: deleteChallengeBannerMutate, isError: isMutateError } =
    useMutation(deleteChallengeBanner, {
      onSuccess: () => {
        setOpenSnacker(true);
        setDialogMessage('배너를 삭제했습니다.');
        bannerRefetch();
      },
      onError: error => {
        setOpenSnacker(true);
        setDialogMessage(
          '배너 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      banners?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.bannerId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const deleteBanner = () => {
    const newData = {
      bannerIds: checkedButtons,
    };

    deleteChallengeBannerMutate(newData);
  };

  const viewBanner = (id: number) => {
    setBannerId(id);
    setOpenSettingChange(true);
    setTitle('배너 추가하기');
  };

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box className='flex_between' sx={{ mt: '2rem' }}>
        <Typography variant='h4'>{viewTitle}</Typography>
        <Button
          variant='contained'
          sx={{ ml: '2rem' }}
          onClick={() => {
            setBannerId(0);
            setOpenSettingChange(true);
            setTitle('배너 추가하기');
          }}
        >
          + 배너 추가하기
        </Button>
      </Box>
      <Paper sx={{ m: '.5rem 0', p: '2rem 1rem' }}>
        <Box className='flex_between' sx={{ pb: '1rem' }}>
          <TableTitle title='배너' count={banners.length} />
        </Box>
        <Paper>
          <Table size='small'>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell
                    key={idx.toString()}
                    align={
                      idx === 2 || idx === 3
                        ? 'right'
                        : idx === 4
                        ? 'center'
                        : 'inherit'
                    }
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {banners.length > 0 ? (
                banners
                  ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  ?.map((banner: any, idx: number) => (
                    <TableRow key={idx.toString()}>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          id={banner.bannerId.toString()}
                          onChange={e => {
                            changeHandler(
                              e.currentTarget.checked,
                              banner.bannerId
                            );
                          }}
                          checked={checkedButtons.includes(banner.bannerId)}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ width: '20vw' }}
                        onClick={() => viewBanner(banner.bannerId)}
                      >
                        <img
                          src={`${imageUrlPrefix}${banner.image}`}
                          alt=''
                          style={{ width: '15vw', height: '15vh' }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: '20vw',
                          maxHeight: '10vh',
                          overflow: 'auto',
                        }}
                        onClick={() => viewBanner(banner.bannerId)}
                      >
                        {banner.action}
                      </TableCell>
                      <TableCell
                        align='right'
                        onClick={() => viewBanner(banner.bannerId)}
                      >
                        {banner.startDate}
                      </TableCell>
                      <TableCell
                        align='right'
                        onClick={() => viewBanner(banner.bannerId)}
                      >
                        {banner.endDate}
                      </TableCell>
                      <TableCell
                        align='center'
                        onClick={() => viewBanner(banner.bannerId)}
                      >
                        {banner.locationName}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {banners.length > 0 && (
            <SwPagination
              page={page}
              handleChangePage={handleChangePage}
              count={banners && Math.ceil(banners.length / rowsPerPage)}
            />
          )}
        </Paper>
      </Paper>
      <Box sx={{ textAlign: 'right', mt: '1rem' }}>
        <Button variant='outlined' color='info' onClick={deleteBanner}>
          삭제
        </Button>
        <Button
          variant='contained'
          color='info'
          sx={{ ml: '1rem' }}
          onClick={() => {
            setOpenSettingChange(true);
            setTitle('순서 변경하기');
          }}
        >
          순서 변경하기
        </Button>
      </Box>
      <ChallengeBannerSetting
        type={type}
        open={openSettingChange}
        title={title}
        onClose={() => setOpenSettingChange(!openSettingChange)}
        onConfirm={() => {
          setOpenSettingChange(!openSettingChange);
          bannerRefetch();
        }}
        banners={banners}
        setBanners={setBanners}
        bannerId={bannerId}
      />
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { KEY_CHALLENGE_QR_CODE_LIST } from '../../../../common/key';
import { encodeSearchWord } from '../../../../common/helper';
import {
  downloadQrCodeList,
  getChallengeQrCodeList,
} from '../../../../common/api/ApiChallenge';
import { TableTitle } from '../../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import SwLoading from '../../../commonComponent/spinner/SwLoading';

interface Props {
  challengeId: string;
  mission: any;
}

const columns = ['QR코드', '스탬프 번호', '스탬프 이름', '위도', '경도'];

export const MissionQRCodeView: React.FC<Props> = ({
  challengeId,
  mission,
}) => {
  const { loginVal } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const [qrCodeList, setQrCodeList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [title, setTitle] = useState('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [searchKey, setSearchKey] = useState<string>('qrcode');
  const [searchWord, setSearchWord] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(null);

  const {
    data,
    isLoading,
    refetch: qrCodeRefetch,
  } = useQuery(
    KEY_CHALLENGE_QR_CODE_LIST,
    () => {
      const newData: any = {
        challengeId: challengeId,
        missionId: mission.missionId,
        page: 1,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        startDate: rangeDate[0] !== null ? rangeDate[0] / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1] / 1000 : 0,
      };
      setLoading(true);
      if (mission) return getChallengeQrCodeList(newData);
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          setQrCodeList(res.qrCode);
          setTotalRecords(res.pagination.totalRecordCount);
        } else {
          setQrCodeList([]);
          setTotalRecords(0);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      qrCodeList.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.targetId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const downloaData = () => {
    const newData: any = {
      challengeId: mission.challengeId,
      ids: checkedButtons.length > 0 ? checkedButtons : [],
    };
    downloadQrCodeList(newData);
  };

  useEffect(() => {
    if (mission) {
      qrCodeRefetch();
    }
  }, [mission]);

  return (
    <Box sx={{ m: '2rem 1rem' }}>
      <Box className='flex_between' sx={{ pb: '1rem' }}>
        <TableTitle title='QR코드' count={qrCodeList ? qrCodeList.length : 0} />
        <Box>
          <Button
            sx={{ mr: '1rem' }}
            color='info'
            variant='outlined'
            onClick={() => {
              downloaData();
            }}
          >
            {`${checkedButtons.length > 0 ? '선택' : '전체'} 다운로드`}
            <FileDownloadRoundedIcon color='success' />
          </Button>
        </Box>
      </Box>
      <Paper>
        <Table size='small'>
          <TableHead sx={{ bgcolor: '#F9FAFC' }}>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={e => {
                    handleSelectAllClick(e);
                  }}
                  checked={checkedAll}
                />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {qrCodeList !== null && qrCodeList.length > 0 ? (
              qrCodeList.map((item: any, idx: number) => (
                <TableRow key={`qrcode_${idx}`}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      id={item.targetId}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, item.targetId);
                      }}
                      checked={checkedButtons.includes(item.targetId)}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '20vw' }}>
                    <img
                      src={`${imageUrlPrefix}${item.qrCodePath}`}
                      alt=''
                      style={{
                        width: '15vh',
                        height: '15vh',
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.seq}</TableCell>
                  <TableCell>{item.targetName}</TableCell>
                  <TableCell>{item.latitude}</TableCell>
                  <TableCell>{item.longitude}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>검색결과 없습니다.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {qrCodeList && qrCodeList.length > 0 && (
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        )}
        {loading && <SwLoading />}
      </Paper>
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};

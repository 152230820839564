import { Box, Divider, IconButton, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { v4 as uuidv4 } from 'uuid';
import { MarginButton } from '../challenge/challengeManagement/ChallengeStyles';
import { SwSnackbar } from './views/SwSnackbar';
import {
  getChallengeMemo,
  registerChallengeMemo,
} from '../../common/api/ApiChallenge';
import { KEY_CHALLENGE_MEMO } from '../../common/key';

interface Props {
  openMemo: boolean;
  challengeId: string;
  memo: string;
  reloadChallenge: (id: string) => void;
}

export const SwChallengeMemoView: React.FC<Props> = ({
  openMemo,
  challengeId,
  memo,
  reloadChallenge,
}) => {
  const [challengeMemo, setChallengeMemo] = useState<string>(memo);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();

  const {
    mutate: registerChallengeMemoMutation,
    isError: isChangeHoldingMutateError,
  } = useMutation(registerChallengeMemo, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage('저장되었습니다.');
      reloadChallenge(challengeId);
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(`오류가 발생하였습니다.`);
    },
  });

  const doWriting = () => {
    const newData: any = {
      challengeId: challengeId,
      challengeMemo: challengeMemo,
    };
    registerChallengeMemoMutation(newData);
  };

  useEffect(() => {
    if (openMemo) {
      setChallengeMemo(memo);
    }
  }, [memo]);

  return (
    <Box className='flex_start'>
      <Typography className='title'>메모</Typography>
      <TextField
        sx={{ ml: '1rem', mr: '1rem', width: '80%' }}
        value={challengeMemo}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setChallengeMemo(event.target.value);
        }}
      />
      <MarginButton
        variant='outlined'
        color='primary'
        onClick={() => doWriting()}
      >
        저장
      </MarginButton>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
    </Box>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { KEY_STORE_BRAND_LIST } from '../../../common/key';
import { getStoreBrandList } from '../../../common/api/ApiRewardMall';

interface Props {
  setTarget: Dispatch<React.SetStateAction<any>>;
  brandId: any;
}

export const SelectStoreBrand: React.FC<Props> = ({ setTarget, brandId }) => {
  const [brandList, setBrandList] = useState<any>([]);
  const [brandIdx, setBrandIdx] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: refetchStoerBrandList,
  } = useQuery(
    KEY_STORE_BRAND_LIST,
    () => {
      const newData = {
        storeId: '69739D4770C8429F9F89FD36B9816BF5',
      };
      return getStoreBrandList(newData);
    },
    {
      onSuccess: res => {
        if (res != null) {
          setBrandList(res.brand);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const changeTarget = (idx: number) => {
    setBrandIdx(idx);
    if (idx === 0) {
      setTarget({
        value: 0,
        name: '',
      });
    } else {
      setTarget({
        value: brandList[idx - 1].brandId,
        name: brandList[idx - 1].name,
      });
    }
  };

  useEffect(() => {
    const newIdx = brandList
      .map((item: any, idx: number) =>
        item.brandId === brandId ? idx + 1 : -1
      )
      .filter((item: any) => item !== -1);

    newIdx !== null && newIdx.length > 0
      ? changeTarget(newIdx[0])
      : changeTarget(0);
  }, [brandList, brandId]);

  return (
    <Box>
      <Typography className='title'>브랜드</Typography>
      <TextField
        select
        sx={{ width: '50%' }}
        defaultValue='0'
        value={brandIdx}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          const newValue = Number(event.target.value);
          changeTarget(newValue);
        }}
      >
        <MenuItem key={0} value='0'>
          브랜드 선택
        </MenuItem>
        {brandList &&
          brandList.map((item: any, idx: number) => (
            <MenuItem key={`store_brand_${idx + 1}`} value={`${idx + 1}`}>
              {item.name}
            </MenuItem>
          ))}
      </TextField>
    </Box>
  );
};

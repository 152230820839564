import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { DataportalConnect } from '../../commonComponent/DataportalConnect';
import { newUuidId } from '../../commonComponent/SwUuidCreate';
import {
  addHyphenToPhoneno,
  commaFormat,
  getPartnerType,
  getPartnerTypeItems,
  INIT_LOCATION,
  phoneNumberHyphen,
  timestamp2Localestring,
  validCheck,
} from '../../../common/helper';
import {
  IDataPortalRequest,
  IDataPortal,
  IDataPortalQnA,
} from '../../../models/dataportal';
import {
  getDataPortalAccountAmdList,
  postDataPortalAccountRegister,
  deleteDataPortalAccount,
  getDataPortalAccountInfo,
  getDataPortalAccountExist,
  postDataPortalTesterRegister,
} from '../../../common/api/ApiDataPortal';
import {
  KEY_ACCOUNT_LIST,
  KEY_DATAPORTAL_ACCOUNT_EXIST,
  KEY_DATAPORTAL_ACCOUNT_INFO,
  KEY_SERVICE_COUPON_INFO,
  KEY_WORD_EMAIL,
  KEY_WORD_PHONE,
  KEY_WORD_PREFIX_COMPANY,
} from '../../../common/key';
import { getAccountList } from '../../../common/api/ApiAccount';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSelectPhoneno } from '../../commonComponent/SwSelectPhoneno';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSearchUsers } from '../../commonComponent/SwSearchUsers';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { SwSelectPhonenoEvent } from '../../commonComponent/SwSelectPhonenoEvent';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import { SwSupplyPlanButton } from '../../commonComponent/SwSupplyPlanButton';
import { getServiceCouponInfo } from '../../../common/api/ApiContract';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwSelectLocationSimple } from '../../commonComponent/SwSelectLocationSimple';

interface Props {
  title: string;
  contractInfo: any;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
}

const TitleTypography = styled(Typography)({
  fontWeight: 500,
  margin: '0 .5rem .7rem 0',
});

const RootPaper = styled(Paper)({
  margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

const authorityIds = ['challenge', 'community', 'program', 'qna'];

export const CreatePartner: React.FC<Props> = ({
  title,
  contractInfo,
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
}) => {
  const [partnerId, setPartnerId] = useState<string>('');
  const [view, setView] = useState<boolean>(false);
  const [portalId, setPortalId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [manager1, setManager1] = useState<string>('');
  const [phoneno1, setPhoneno1] = useState<any>(['', '', '']);
  const [email1, setEmail1] = useState<string>('');
  const [manager2, setManager2] = useState<string>('');
  const [phoneno2, setPhoneno2] = useState<any>(['', '', '']);
  const [email2, setEmail2] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [role, setRole] = useState<number>(7);
  const [operator, setOperator] = useState<string>('0');
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>();
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [location, setLocation] = useState<string>('');
  const [locationAddress, setLocationAddress] = useState<any>('');
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [partnerType, setPartnerType] = useState(0);
  const [address, setAddress] = useState<any>('');
  const [targetInfo, setTargetInfo] = useState<any>();
  const [testerInfo, setTesterInfo] = useState<any[]>([]);
  const [region, setRegion] = useState<any[]>([]);
  const [empList, setEmpList] = useState<any>([]);
  const [contactPoints, setContactPoints] = useState<any>([]);
  const [businessNumber, setBusinessNumber] = useState<string>('');
  const [useDates, setUseDates] = useState<any[]>([null, null]);
  const [amdCode, setAmdCode] = useState<string>('');
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });
  const [isDuplicated, setIsDuplicated] = useState<number>(-1);
  const [inhibitDuplicated, setInhibitDuplicated] = useState<boolean>(true);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [dataPortal, setDataPortal] = useState<any>([]);
  const [findDataPortal, setFindDataPortal] = useState<string>('');
  const [connectDataPortal, setConnectDataPortal] = useState<any>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [communityValue, setCommunityValue] = useState<number>(0);
  const [challengeNormalValue, setChallengeNormalValue] = useState<number>(0);
  const [challengeFreeValue, setChallengeFreeValue] = useState<number>(0);
  const [programValue, setProgramValue] = useState<number>(0);
  const [communityUseStatus, setCommunityUseStatus] = useState<any>();
  const [challengeNormalUseStatus, setChallengeNormalUseStatus] =
    useState<any>();
  const [challengeFreeUseStatus, setChallengeFreeUseStatus] = useState<any>();
  const [programUseStatus, setProgramUseStatus] = useState<any>();
  const [managerIndex, setManagerIndex] = useState<number>(-1);
  const [messagingUseStatus, setMessagingUseStatus] = useState<any>();
  const [totalRecords, setTotalRecords] = useState(0);
  const [inputError1, setInputErro1] = useState<string>('');
  const [inputError2, setInputError2] = useState<string>('');
  const [useChallengeRegion, setUseChallengeRegion] = useState<number>(0);
  const [locationLimit, setLocationLimit] = useState<any[]>([INIT_LOCATION]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [requestCoupon, setRequestCoupon] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  const {
    data,
    isLoading,
    refetch: accountRefetch,
  } = useQuery(
    KEY_ACCOUNT_LIST,
    () => {
      if (open) return getAccountList(0, 0, '');
      return null;
    },
    {
      onSuccess: (res: any) => {
        console.log('getAccountList:');
        if (res !== null) {
          setEmpList(res.account);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: accountExistRefetch } = useQuery(
    KEY_DATAPORTAL_ACCOUNT_EXIST,
    () => {
      if (open && portalId !== '') return getDataPortalAccountExist(portalId);
      return null;
    },
    {
      onSuccess: (res: any) => {
        if (res !== null) {
          console.log('accountExistRefetch res :', res);
          setIsDuplicated(res.resultCode === RESULT_OK ? 0 : 1);
          setOpenSnakerDialog(true);
          setDialogMessage(
            res.resultCode === RESULT_OK
              ? '등록 가능한 아이디입니다.'
              : '중복 아이디입니다.'
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: serviceCouponDataRefetch } = useQuery(
    KEY_SERVICE_COUPON_INFO,
    () => {
      const newData: any = {
        portalId: portalId,
      };
      if (open && portalId !== '') return getServiceCouponInfo(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.serviceCoupon !== null) {
          const newCommunityData = res.serviceCoupon
            .map((row: any) => {
              const newData =
                row.type === 'community' && row.couponType === '0' ? row : null;
              return newData;
            })
            .filter((row: any) => row !== null);
          const newChallengeNormalData = res.serviceCoupon
            .map((row: any) => {
              const newData =
                row.type === 'challenge' && row.couponType === '0' ? row : null;
              return newData;
            })
            .filter((row: any) => row !== null);
          const newChallengeFreeData = res.serviceCoupon
            .map((row: any) => {
              const newData =
                row.type === 'challenge' && row.couponType === '4' ? row : null;
              return newData;
            })
            .filter((row: any) => row !== null);
          const newProgramData = res.serviceCoupon
            .map((row: any) => {
              const newData = row.type === 'program' ? row : null;
              return newData;
            })
            .filter((row: any) => row !== null);
          const newMessagingData = res.serviceCoupon
            .map((row: any) => {
              const newData = row.type === 'messaging' ? row : null;
              return newData;
            })
            .filter((row: any) => row !== null);
          setCommunityUseStatus(
            newCommunityData !== null && newCommunityData.length > 0
              ? newCommunityData[0]
              : {
                  couponCount: 0,
                  couponUseCount: 0,
                }
          );
          setChallengeNormalUseStatus(
            newChallengeNormalData !== null && newChallengeNormalData.length > 0
              ? newChallengeNormalData[0]
              : {
                  couponCount: 0,
                  couponUseCount: 0,
                }
          );
          setChallengeFreeUseStatus(
            newChallengeFreeData !== null && newChallengeFreeData.length > 0
              ? newChallengeFreeData[0]
              : {
                  couponCount: 0,
                  couponUseCount: 0,
                }
          );
          setProgramUseStatus(
            newProgramData !== null && newProgramData.length > 0
              ? newProgramData[0]
              : {
                  couponCount: 0,
                  couponUseCount: 0,
                }
          );
          setMessagingUseStatus(
            newMessagingData !== null && newMessagingData.length > 0
              ? newMessagingData[0]
              : {
                  couponCount: 0,
                  couponUseCount: 0,
                }
          );

          setCommunityValue(
            newCommunityData !== null && newCommunityData.length > 0
              ? newCommunityData[0].couponCount -
                  newCommunityData[0].couponUseCount
              : 0
          );

          setChallengeNormalValue(
            newChallengeNormalData !== null && newChallengeNormalData.length > 0
              ? newChallengeNormalData[0].couponCount -
                  newChallengeNormalData[0].couponUseCount
              : 0
          );
          setChallengeFreeValue(
            newChallengeFreeData !== null && newChallengeFreeData.length > 0
              ? newChallengeFreeData[0].couponCount -
                  newChallengeFreeData[0].couponUseCount
              : 0
          );
          setProgramValue(
            newProgramData !== null && newProgramData.length > 0
              ? newProgramData[0].couponCount - newProgramData[0].couponUseCount
              : 0
          );
          setRequestCoupon(false);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const copyId = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log('er>', error);
    }
  };
  const getCustomerUuid = () => {
    const newUuid = newUuidId();
    setPartnerId(newUuid);
  };

  const { mutate: registerDataPortalAccountMutation, isError: isMutateError } =
    useMutation(postDataPortalAccountRegister, {
      onSuccess: res => {
        setLoading(false);
        setOpenSnakerDialog(true);
        setDialogMessage(
          edit ? '고객정보를 수정했습니다.' : '고객이 추가되었습니다.'
        );
        onConfirm();
      },
      onError: error => {
        setLoading(false);
        setOpenSnakerDialog(true);
        setDialogMessage(
          edit
            ? '고객정보를 수정하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
            : '고객을 추가하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: registerDataPortalTesterMutation,
    isError: isTesterMutateError,
  } = useMutation(postDataPortalTesterRegister, {
    onSuccess: res => {
      setLoading(false);
      setOpenSnakerDialog(true);
      setDialogMessage('데이터 포탈 관리자를 저장했습니다.');
    },
    onError: error => {
      setLoading(false);
      setOpenSnakerDialog(true);
      setDialogMessage(
        '데이터 포탈 관리자를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteDataPortalAccountMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteDataPortalAccount, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('포털계정이 삭제되었습니다.');
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '포털계정을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const registerDataPortalAccount = () => {
    const newKeyList = JSON.stringify([{ name: name, key: name }]);
    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;
    const newPhoneno2 =
      phoneno2[1] !== undefined &&
      phoneno2[1] !== '' &&
      phoneno2[2] !== undefined &&
      phoneno2[2] !== ''
        ? `${phoneno2[0]}${phoneno2[1]}${phoneno2[2]}`
        : '';

    const newContactPoints = contactPoints.map((item: any, idx: number) => {
      const newData: any = {
        name: item.name,
        phoneno: `${item.phoneno[0]}${item.phoneno[1]}${item.phoneno[2]}`,
        phoneno2:
          item.phoneno2 !== undefined && item.phoneno2.length === 3
            ? `${item.phoneno2[0]}${item.phoneno2[1]}${item.phoneno2[2]}`
            : '',
        email: item.email,
      };
      return newData;
    });

    const newConnectedIds =
      dataPortal.length > 0
        ? dataPortal.map((item: any) => item.portalId)
        : connectDataPortal !== undefined && connectDataPortal.length > 0
        ? connectDataPortal.map((item: any) => item.portalId)
        : [];

    var authValue = 0;
    const newAuthority = checkedButtons.map((item: any) => {
      // eslint-disable-next-line no-bitwise
      if (item === 'challenge') authValue |= 0x01;
      // eslint-disable-next-line no-bitwise
      else if (item === 'community') authValue |= 0x02;
      // eslint-disable-next-line no-bitwise
      else if (item === 'program') authValue |= 0x04;
      // eslint-disable-next-line no-bitwise
      else if (item === 'qna') authValue |= 0x08;
    });

    const newServicePlan: any = {
      communityPlan:
        communityUseStatus !== null && communityUseStatus !== undefined
          ? communityValue -
            (communityUseStatus.couponCount - communityUseStatus.couponUseCount)
          : 0,
      challengeNormalPlan:
        challengeNormalUseStatus !== null &&
        challengeNormalUseStatus !== undefined
          ? challengeNormalValue -
            (challengeNormalUseStatus.couponCount -
              challengeNormalUseStatus.couponUseCount)
          : 0,
      challengeFreePlan:
        challengeFreeUseStatus !== null && challengeFreeUseStatus !== undefined
          ? challengeFreeValue -
            (challengeFreeUseStatus.couponCount -
              challengeFreeUseStatus.couponUseCount)
          : 0,
      programPlan:
        programUseStatus !== null && programUseStatus !== undefined
          ? programValue -
            (programUseStatus.couponCount - programUseStatus.couponUseCount)
          : 0,
    };

    const newData: any = {
      regType: 'company',
      portalId: portalId,
      password: password,
      conType: getPartnerType(partnerType) !== '기타' ? partnerType : 99,
      walkWayLocalId: '',
      amdCode: amdCode,
      constime: useDates[0] === null ? 0 : useDates[0].getTime() / 1000,
      activate: useDates[1] === null ? 0 : useDates[1].getTime() / 1000,
      key: name,
      keyList: newKeyList,
      x: newLa,
      y: newLo,
      name1: manager1,
      phoneno1: `${phoneno1[0]}${phoneno1[1]}${phoneno1[2]}`,
      email1: email1,
      name2: manager2,
      phoneno2: newPhoneno2,
      email2: email2,
      operator: operator,
      address: location.length > 0 ? location : '',
      locationAddress: locationAddress.length > 0 ? locationAddress : '',
      portalCode: partnerId,
      businessNumber: businessNumber,
      memo: memo,
      role: authValue,
      contactPoints: newContactPoints,
      detailAddress: detailAddress,
      inhibitDuplicated: inhibitDuplicated ? 1 : 0,
      isManager: isManager ? 1 : 0,
      connectedIds: newConnectedIds,
      servicePlan: newServicePlan,
      challengeRegion: useChallengeRegion === 1 ? locationLimit[0].amd : 0,
    };
    setLoading(true);
    registerDataPortalAccountMutation(newData);
  };

  const checkValid = () => {
    var val = 0;
    const newAddress =
      locationAddress !== null && locationAddress.length > 0
        ? locationAddress
        : '';

    let validPhoneno = 0;
    let validName = 0;
    let validEmail = 0;
    const newContactPoints = contactPoints.map((item: any, idx: number) => {
      if (item.name.length === 0) validName += 1;
      if (
        item.phoneno === undefined ||
        (item.phoneno !== undefined &&
          (item.phoneno[0] === '' ||
            item.phoneno[1] === '' ||
            item.phoneno[1] === undefined ||
            item.phoneno[2] === '' ||
            item.phoneno[2] === undefined))
      )
        validPhoneno += 1;
      if (
        item.email !== null &&
        item.email.length > 0 &&
        validCheck(KEY_WORD_EMAIL, item.email) === false
      ) {
        validEmail += 1;
      }
      const newData: any = {
        name: item.name,
        phoneno: `${item.phoneno[0]}${item.phoneno[1]}${item.phoneno[2]}`,
        phoneno2:
          item.phoneno2 !== undefined && item.phoneno2.length === 3
            ? `${item.phoneno2[0]}${item.phoneno2[1]}${item.phoneno2[2]}`
            : '',
        email:
          item.email === null || item.email.length === 0 ? '0' : item.email,
      };
      return newData;
    });

    if (validPhoneno > 0) {
      setDialogMessage('담당자 연락처를 입력해주세요.');
      setOpenDialog(true);
      return 9;
    }
    if (validName > 0) {
      setDialogMessage('담당자 이름을 입력해주세요.');
      setOpenDialog(true);
      return 4;
    }
    if (validEmail > 0) {
      setDialogMessage('이메일 입력이 없거나 이메일 형식 오류입니다.');
      setOpenDialog(true);
      return 7;
    }

    if (val === 0 && !edit && isDuplicated === -1) val = 10;
    if (val === 0 && !edit && isDuplicated === 1) val = 11;
    if (val === 0 && (portalId === null || portalId.length === 0)) val = 1;
    if (val === 0 && (password === null || password.length === 0)) val = 2;
    if (val === 0 && (name === null || name.length === 0)) val = 3;
    if (val === 0 && (newAddress === undefined || newAddress.length === 0))
      val = 5;
    if (
      val === 0 &&
      (operator === null || operator.length === 0 || operator === '0')
    )
      val = 6;
    if (val === 0 && (useDates[0] === null || useDates[1] === null)) val = 8;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '데이터 포탈 아이디를 입력해 주세요.';
          break;
        case 2:
          message = '데이터 포탈 비밀번호를 입력해 주세요.';
          break;
        case 3:
          message = '고객명을 입력해 주세요.';
          break;
        case 4:
          message = '담당자 이름을 입력해주세요.';
          break;
        case 5:
          message = '주소를 입력해주세요.';
          break;
        case 6:
          message = '워크온 담당자를 선택해 주세요.';
          break;
        case 7:
          message = '이메일 입력이 없거나 이메일 형식 오류입니다.';
          break;
        case 8:
          message = '포탈 이용시간을 입력해주세요.';
          break;
        case 9:
          message = '담당자1 연락처를 입력해주세요.';
          break;
        case 10:
          message = '사용할 포탈 아이디를 중복체크 해주세요.';
          break;
        case 11:
          message =
            '중복된 포털 아이디입니다. 포털 아이디 입력 후 중복 중복체크를 해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
    }

    return val;
  };

  // const deleteAccount = () => {
  //   if (portalId.length === 0) {
  //     setDialogMessage('삭제할 포탈 계정을 선택해 주세요');
  //     setOpenDialog(true);
  //     return;
  //   }
  //   deleteDataPortalAccountMutation(portalId);
  // };

  const checkDataPortalAccount = (type: number) => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }
    registerDataPortalAccount();

    if (type !== 0) isEdit();
  };

  const resetData = () => {
    setPortalId('');
    setPassword('');
    setName('');
    setManager1('');
    setPhoneno1('');
    setEmail1('');
    setManager2('');
    setPhoneno2('');
    setEmail2('');
    setMemo('');
    setOperator('0');
    setAddress('');
    setLocation('');
    setResultLatLng('');
    setAmdCode('');
    setPartnerType(0);
    setBusinessNumber('');
    setUseDates([null, null]);
    getCustomerUuid();
    setFindDataportal('');
    setConnectDataportal('');
    setIsDuplicated(-1);
    setRole(7);
    setLocationAddress('');
    setDetailAddress('');
    setCommunityValue(0);
    setChallengeNormalValue(0);
    setChallengeFreeValue(0);
    setProgramValue(0);
    setCommunityUseStatus(null);
    setChallengeNormalUseStatus(null);
    setChallengeFreeUseStatus(null);
    setProgramUseStatus(null);
    setMessagingUseStatus(null);
    setManagerIndex(-1);
    const newData: any = [
      { name: '', phoneno: ['', '', ''], phoneno2: ['', '', ''], email: '' },
    ];
    setContactPoints(newData);
    setRequestCoupon(false);
    setUseChallengeRegion(0);
    setLocationLimit([INIT_LOCATION]);
  };

  const setData = () => {
    setIsDuplicated(0);
    setPortalId(contractInfo.id);
    setPassword(contractInfo.password);
    setBusinessNumber(contractInfo.businessNumber);
    setName(contractInfo.name);
    setManager1(contractInfo.userName1);
    setPhoneno1(phoneNumberHyphen(contractInfo.userPhoneno1).split('-'));
    setEmail1(contractInfo.userEmail1);
    setManager2(contractInfo.userName2);
    setPhoneno2(phoneNumberHyphen(contractInfo.userPhoneno2).split('-'));
    setEmail2(contractInfo.userEmail2);
    setMemo(contractInfo.memo);
    setOperator(contractInfo.operator);
    setAddress(contractInfo.address !== null ? contractInfo.address : '');
    setLocation(contractInfo.address !== null ? contractInfo.address : '');
    const newType = getPartnerTypeItems()
      .map((item: any, idx: number) => {
        if (item === getPartnerType(contractInfo.contype)) return idx + 1;
        return 0;
      })
      .filter((item: any) => item !== 0);
    setPartnerType(newType.length > 0 ? newType[0] : 0);
    setPartnerId(contractInfo.portalCode);
    setAmdCode(contractInfo.amdCode);
    setLocationAddress(contractInfo.locationAddress);
    setDetailAddress(contractInfo.detailAddress);

    const newUseDate = [
      new Date(timestamp2Localestring(contractInfo.constime, 1000)),
      new Date(timestamp2Localestring(contractInfo.activatetime, 1000)),
    ];
    setUseDates(newUseDate);
    setRegion(contractInfo.address === null ? [] : [contractInfo.address]);

    setInhibitDuplicated(contractInfo.inhibitDuplicated !== 0);

    setCheckedButtons([]);
    if (contractInfo.role === 0) {
      setCheckedAll(false);
    }
    // eslint-disable-next-line no-bitwise
    if (contractInfo.role & 0x01)
      setCheckedButtons(current => [...current, authorityIds[0]]);
    // eslint-disable-next-line no-bitwise
    if (contractInfo.role & 0x02)
      setCheckedButtons(current => [...current, authorityIds[1]]);
    // eslint-disable-next-line no-bitwise
    if (contractInfo.role & 0x04)
      setCheckedButtons(current => [...current, authorityIds[2]]);
    // eslint-disable-next-line no-bitwise
    if (contractInfo.role & 0x08)
      setCheckedButtons(current => [...current, authorityIds[3]]);
    // eslint-disable-next-line no-bitwise
    if ((contractInfo.role & 0x0f) === 0x0f) setCheckedAll(true);
    else setCheckedAll(false);

    setIsManager(contractInfo.isManager === 1);
    if (contractInfo.isManager === 1) {
      const newConnectedIds = JSON.parse(contractInfo.connectedIds);
      const newPortal = newConnectedIds.map((item: any) => {
        const newData = {
          portalId: item,
          name: '',
        };
        return newData;
      });
      setConnectDataPortal(newPortal !== null ? newPortal : []);
    } else {
      console.log('##> newPortal');
      setConnectDataPortal([]);
    }

    const newUserInfo =
      contractInfo.tester !== undefined && contractInfo.tester !== null
        ? JSON.parse(contractInfo.tester)
        : [];
    setTesterInfo(newUserInfo);
    const newContactPoint =
      contractInfo.contactPoints !== undefined &&
      contractInfo.contactPoints !== null &&
      contractInfo.contactPoints.map((item: any, idx: number) => {
        const newData: any = {
          name: item.name,
          phoneno:
            item.phoneno === null || item.phoneno === ''
              ? ['', '', '']
              : addHyphenToPhoneno(item.phoneno).split('-'),
          phoneno2:
            item.phoneno2 === null || item.phoneno2 === ''
              ? ['', '', '']
              : addHyphenToPhoneno(item.phoneno2).split('-'),
          email: item.email,
        };
        return newData;
      });
    setContactPoints(newContactPoint);
    setRequestCoupon(true);
    setUseChallengeRegion(
      contractInfo.challengeRegion === undefined ||
        contractInfo.challengeRegion === 0
        ? 0
        : 1
    );
    setLocationLimit([
      contractInfo.challengeRegion === undefined ||
      contractInfo.challengeRegion === 0
        ? INIT_LOCATION
        : {
            location: contractInfo.challengeRegionName,
            amd: contractInfo.challengeRegion,
          },
    ]);
  };

  const validCheckInput = (
    type: string,
    value: string,
    setInputError: Dispatch<React.SetStateAction<any>>
  ) => {
    if (type === KEY_WORD_PHONE && validCheck(KEY_WORD_PHONE, value)) {
      setInputError('');
    } else if (type === KEY_WORD_PHONE) {
      value === '' ? setInputError('') : setInputError(KEY_WORD_PHONE);
    }
    return value;
  };

  const checkDuplicated = () => {
    if (portalId.length > 0) {
      accountExistRefetch();
    } else {
      setOpenSnakerDialog(true);
      setDialogMessage('생성할 포탈 아이디를 입력해 주세요');
    }
  };

  const deleteUserInfo = (idx: number) => {
    const newUserList = testerInfo.filter((item: any, i: number) => i !== idx);
    setTesterInfo(newUserList);
  };

  const addUesrContactPoint = () => {
    const newContactPoint: any = {
      name: '',
      phoneno: '',
      email: '',
    };
    setContactPoints([...contactPoints, newContactPoint]);
  };

  const deleteUesrContactPoint = () => {
    const newContactPoint = contactPoints.filter(
      (item: any, idx: number) => idx !== managerIndex
    );
    setContactPoints(newContactPoint);
    setManagerIndex(-1);
  };

  const changeName = (index: number, newName: string) => {
    const newContactPoints = contactPoints.map((item: any, idx: number) => {
      if (index === idx) {
        return {
          name: newName,
          phoneno: item.phoneno,
          phoneno2: item.phoneno2,
          email: item.email,
        };
      }
      return item;
    });
    setContactPoints(newContactPoints);
  };

  const changeEmail = (index: number, newEmail: string) => {
    const newContactPoints = contactPoints.map((item: any, idx: number) => {
      if (index === idx) {
        return {
          name: item.name,
          phoneno: item.phoneno,
          phoneno2: item.phoneno2,
          email: newEmail,
        };
      }
      return item;
    });
    setContactPoints(newContactPoints);
  };

  const changePhoneno = (newData: any) => {
    const index = newData.idx >= 100 ? newData.idx - 100 : newData.idx;
    const newContactPoints = contactPoints.map((item: any, idx: number) => {
      if (index === idx) {
        return {
          name: item.name,
          phoneno: newData.idx >= 100 ? item.phoneno : newData.phoneno,
          phoneno2: newData.idx >= 100 ? newData.phoneno : item.phoneno2,
          email: item.email,
        };
      }
      return item;
    });
    setContactPoints(newContactPoints);
  };

  const handleIsManager = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsManager(true);
    } else {
      setIsManager(false);
    }
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      authorityIds?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeRoleHandler = (checked: boolean, id: string) => {
    if (checked) {
      const newData = [...checkedButtons, id];
      setCheckedButtons(newData);
      if (
        newData.includes(authorityIds[0]) &&
        newData.includes(authorityIds[1]) &&
        newData.includes(authorityIds[2]) &&
        newData.includes(authorityIds[3])
      ) {
        setCheckedAll(true);
      }
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
      setCheckedAll(false);
    }
  };

  const registerTesterInfo = () => {
    const newData: any = {
      portalId: portalId,
      tester: testerInfo,
    };
    setLoading(true);
    registerDataPortalTesterMutation(newData);
  };

  const changeUseChallengeRegion = (value: number) => {
    if (value === 0) {
      setLocationLimit([INIT_LOCATION]);
    }
    setUseChallengeRegion(value);
  };

  useEffect(() => {
    if (open) {
      if (connectDataportal) {
        setPortalId(connectDataportal.id);
      } else setPortalId(contractInfo?.id);
    }
  }, [connectDataportal]);

  useEffect(() => {
    if (region.length > 0) {
      const selectRegion = region;
      console.log('region: ', selectRegion);
    }
  }, [region]);

  useEffect(() => {
    console.log('location : ', location, ', locationAddress:', locationAddress);
  }, [location, locationAddress]);

  useEffect(() => {
    if (targetInfo !== undefined && targetInfo !== null) {
      setPortalId(targetInfo.portalId);
      setPassword(targetInfo.password === null ? '' : targetInfo.password);
      const newUseDate = [
        new Date(timestamp2Localestring(targetInfo.constime, 1000)),
        new Date(timestamp2Localestring(targetInfo.activate, 1000)),
      ];
      setUseDates(newUseDate);
      setManager1(targetInfo.userName1);
      setPhoneno1(phoneNumberHyphen(targetInfo.userPhoneno1).split('-'));
      setEmail1(targetInfo.userEmail1);
      setIsDuplicated(0);
      setPartnerId(targetInfo.portalCode);
    }
  }, [targetInfo]);

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      setAmdCode(targetData.bcode); // 법정동코드
      setLocationAddress(newAddress);
    }
  }, [targetData]);

  useEffect(() => {
    if (open && portalId !== '' && requestCoupon) {
      serviceCouponDataRefetch();
    }
  }, [portalId]);

  useEffect(() => {
    if (open) {
      if (!edit) resetData();
      else if (contractInfo) setData();
      accountRefetch();
      serviceCouponDataRefetch();
    } else {
      resetData();
    }
  }, [open]);

  useEffect(() => {
    if (contractInfo && edit) {
      setData();
    } else {
      resetData();
    }
  }, [contractInfo]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '66vh',
            minWidth: '70vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Typography className='title'>{title}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent sx={{ p: '1.5rem 2.6em' }}>
          {/* <div className='flex_center' style={{ height: '' }}>
            <Box sx={{ width: '49%' }}>1</Box>
            <Divider flexItem orientation='vertical' sx={{ height: '' }} />
            <Box sx={{ width: '49%', ml: '2%' }}>2</Box>
          </div> */}
          {loading && <SwLoading />}
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            divider={<Divider orientation='vertical' flexItem />}
            spacing={2}
            sx={{ minHeight: '55vh' }}
          >
            <Box sx={{ width: '49%' }}>
              <Box>
                <Box sx={{ p: '.5rem .15rem 0' }} className='flex_center'>
                  <Typography className='title'>고객 아이디</Typography>
                  <IconButton onClick={getCustomerUuid} sx={{ p: 0 }}>
                    <CachedIcon
                      sx={{ fontSize: '1.2rem', m: '-.2rem 0 0 .4rem' }}
                    />
                  </IconButton>
                </Box>
                <Box className='flex_center'>
                  <TextField
                    sx={{ width: '88%', mb: 0 }}
                    value={`${KEY_WORD_PREFIX_COMPANY}${partnerId}`}
                    InputProps={{ readOnly: true }}
                  >
                    {`${KEY_WORD_PREFIX_COMPANY}${partnerId}`}
                  </TextField>
                  <IconButton
                    onClick={() =>
                      copyId(`${KEY_WORD_PREFIX_COMPANY}${partnerId}`)
                    }
                    sx={{ p: 0, m: '.5rem' }}
                  >
                    <ContentCopyOutlinedIcon sx={{ width: '1rem' }} />
                  </IconButton>
                </Box>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>고객 분류</Typography>
                <TextField
                  select
                  sx={{ width: '60%' }}
                  value={partnerType}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setPartnerType(Number(event.target.value));
                  }}
                >
                  <MenuItem key={0} value={0}>
                    선택
                  </MenuItem>
                  {getPartnerTypeItems().map((item: any, idx: number) => (
                    <MenuItem key={idx + 1} value={idx + 1}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>
                  챌린지 지역별 모아보기 노출 설정
                </Typography>
                <TextField
                  select
                  sx={{ width: '10rem' }}
                  value={useChallengeRegion}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    changeUseChallengeRegion(Number(event.target.value));
                  }}
                >
                  <MenuItem key={0} value={0}>
                    설정 안함
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    설정
                  </MenuItem>
                </TextField>
                {useChallengeRegion === 1 && (
                  <SwSelectLocationSimple
                    limitKu={true}
                    includeAmd={true}
                    selectOne={true}
                    showDepth1={true}
                    location={locationLimit}
                    setLocation={setLocationLimit}
                  />
                )}
                <Divider />
              </Box>
              <Box>
                <Box className='flex_start'>
                  <Typography sx={{ fontWeight: 500, m: '1rem 0' }}>
                    고객주소
                  </Typography>
                  <Button
                    sx={{ ml: '1.5rem' }}
                    variant='contained'
                    color='info'
                    onClick={() => {
                      setOpenPostCode(1);
                    }}
                  >
                    주소찾기
                  </Button>
                  <Button
                    sx={{ ml: '1.5rem' }}
                    variant='contained'
                    color='inherit'
                    onClick={() => {
                      setResultLatLng('');
                      setLocationAddress('');
                    }}
                  >
                    위치 정보 초기화
                  </Button>
                </Box>
                <SwFindLatLngbyKakaomap
                  elementId='portalPointMap'
                  setLatLng={setResultLatLng}
                  value={resultLatLng}
                  location={locationAddress}
                  setLocation={setLocationAddress}
                  showButton={false}
                  disabled={true}
                />
                {/* <Box className='flex_start'>
                  <Typography>상세 주소</Typography>
                  <TextField
                    sx={{ ml: '1rem', mt: '.5rem', width: '70%' }}
                    value={detailAddress}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => setDetailAddress(evt.target.value)}
                  />
                </Box> */}
                {openPostCode && (
                  <SwSearchPostCode
                    open={!!openPostCode}
                    onClose={() => setOpenPostCode(0)}
                    setTarget={setTargetData}
                  />
                )}
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>고객명</Typography>
                <TextField
                  sx={{ width: '88%' }}
                  value={name}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setName(evt.target.value)}
                />
                <Divider />
              </Box>
              {/* <Box>
                <Typography className='title'>사업자 등록증</Typography>
                <TextField
                  sx={{ width: '88%' }}
                  value={businessNumber}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setBusinessNumber(evt.target.value)}
                />
                <Divider />
              </Box> */}
              <Box>
                <Typography className='title'>워크온 담당자</Typography>
                <TextField
                  sx={{ width: '18rem' }}
                  select
                  value={operator}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setOperator(evt.target.value)}
                >
                  <MenuItem key={0} value='0'>
                    선택
                  </MenuItem>
                  {empList?.length > 0 &&
                    empList.map((emp: any, idx: number) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.name}
                      </MenuItem>
                    ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Box>
                  <Typography className='title'>커뮤니티 이용권</Typography>
                  <SwSupplyPlanButton
                    planValue={communityValue}
                    setPlanValue={setCommunityValue}
                  />
                </Box>
                <Box className='flex_start'>
                  <Box>
                    <Typography className='title'>
                      챌린지 이용권(일반)
                    </Typography>
                    <SwSupplyPlanButton
                      planValue={challengeNormalValue}
                      setPlanValue={setChallengeNormalValue}
                    />
                  </Box>
                  <Box sx={{ ml: '1rem' }}>
                    <Typography className='title'>
                      챌린지 이용권(자유)
                    </Typography>
                    <SwSupplyPlanButton
                      planValue={challengeFreeValue}
                      setPlanValue={setChallengeFreeValue}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography className='title'>프로그램 이용권</Typography>
                    <SwSupplyPlanButton
                      planValue={programValue}
                      setPlanValue={setProgramValue}
                    />
                  </Box>
                </Box>
                <Divider />
              </Box>
              <Box>
                <Box className='flex_start'>
                  <Checkbox
                    checked={isManager}
                    onChange={evt => handleIsManager(evt)}
                  />
                  <Typography>통합 계정 여부</Typography>
                </Box>
                {isManager && (
                  <DataportalMultiConnect
                    findDataportal={findDataPortal}
                    setFindDataportal={setFindDataPortal}
                    connectDataportal={connectDataPortal}
                    setConnectDataportal={setConnectDataPortal}
                    setTargetInfo={setDataPortal}
                    isDetail={true}
                    title='하위 포탈 연결'
                  />
                )}
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>권한</Typography>
                <FormGroup sx={{ flexDirection: 'row', ml: '1rem' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value='all'
                        checked={checkedAll}
                        onChange={evt => handleSelectAllClick(evt)}
                      />
                    }
                    label='전체'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={authorityIds[0]}
                        checked={checkedButtons.includes(authorityIds[0])}
                        onChange={evt =>
                          changeRoleHandler(
                            evt.currentTarget.checked,
                            authorityIds[0]
                          )
                        }
                      />
                    }
                    label='챌린지'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={authorityIds[1]}
                        checked={checkedButtons.includes(authorityIds[1])}
                        onChange={evt =>
                          changeRoleHandler(
                            evt.currentTarget.checked,
                            authorityIds[1]
                          )
                        }
                      />
                    }
                    label='커뮤니티'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={authorityIds[2]}
                        checked={checkedButtons.includes(authorityIds[2])}
                        onChange={evt =>
                          changeRoleHandler(
                            evt.currentTarget.checked,
                            authorityIds[2]
                          )
                        }
                      />
                    }
                    label='프로그램'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={authorityIds[3]}
                        checked={checkedButtons.includes(authorityIds[3])}
                        onChange={evt =>
                          changeRoleHandler(
                            evt.currentTarget.checked,
                            authorityIds[3]
                          )
                        }
                      />
                    }
                    label='질의응답'
                  />
                </FormGroup>
                <Divider />
              </Box>
              <Box>
                <Box className='flex_start'>
                  <Typography className='title'>동시 접속 차단</Typography>
                  <Checkbox
                    checked={inhibitDuplicated}
                    onChange={() => setInhibitDuplicated(!inhibitDuplicated)}
                    sx={{ p: 0, ml: '1rem', mr: '.5rem' }}
                  />
                  <Typography>
                    {inhibitDuplicated ? '설정' : '미설정'}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </Box>
            <Box sx={{ width: '49%', ml: '2%' }}>
              <Box>
                <Typography className='title'>데이터 포탈 아이디</Typography>
                <TextField
                  sx={{ width: '66%' }}
                  placeholder='생성할 포탈의 아이디를 입력해 주세요.'
                  value={portalId}
                  disabled={edit}
                  autoComplete='off'
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setIsDuplicated(-1);
                    setPortalId(evt.target.value.trim());
                  }}
                />
                <Button
                  sx={{ ml: '1rem' }}
                  variant='contained'
                  color='info'
                  disabled={edit}
                  onClick={() => checkDuplicated()}
                >
                  중복체크
                </Button>
                <Typography className='title'>데이터 포탈 비밀번호</Typography>
                <TextField
                  sx={{ width: '66%' }}
                  type={view ? 'text' : 'password'}
                  placeholder='생성할 포탈의 비밀번호를 입력해 주세요.'
                  value={password}
                  autoComplete='off'
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setPassword(evt.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => setView(!view)}>
                          {view ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <Typography className='title'>포털이용 일시</Typography>
                  <SwDateRangePicker
                    rangeDate={useDates}
                    setRangeDate={setUseDates}
                  />
                </Box>
                <Typography sx={{ mt: '.5rem' }} className='title'>
                  데이터 포탈 연결(새롭게 포탈을 생성하지 않는 경우 기존 포탈을
                  연결해 주세요.)
                </Typography>
                <DataportalConnect
                  findDataportal={findDataportal}
                  setFindDataportal={setFindDataportal}
                  connectDataportal={connectDataportal}
                  setConnectDataportal={setConnectDataportal}
                  isDetail={true}
                  filterName={!(portalId === null || portalId === '')}
                  setTargetInfo={setTargetInfo}
                />
                <Divider />
              </Box>
              <Box>
                <Box className='flex_between'>
                  <Box className='flex_start'>
                    <Typography className='title'>
                      데이터 포탈 관리자
                    </Typography>
                    <Typography className='title' sx={{ color: '#4259ec' }}>
                      (커뮤니티 생성 후 등록해 주세요)
                    </Typography>
                  </Box>
                  <Button
                    color='info'
                    variant='contained'
                    onClick={() => registerTesterInfo()}
                  >
                    저장
                  </Button>
                </Box>
                <SwSearchUsers
                  title=''
                  targetInfo={testerInfo}
                  setTargetInfo={setTesterInfo}
                />
                <Paper
                  sx={{
                    backgroundColor: '#FCFCFC',
                    p: '2rem 2rem 1rem',
                  }}
                >
                  <Box sx={{ height: '8rem', overflowY: 'scroll' }}>
                    {testerInfo &&
                      testerInfo.map((item: any, idx: number) => (
                        <Box className='flex_start' key={`user_info_${idx}`}>
                          <Typography>{item.phoneno}</Typography>
                          <ClearIcon
                            className='clear_icon_button'
                            onClick={() => deleteUserInfo(idx)}
                          />
                        </Box>
                      ))}
                  </Box>
                </Paper>
                <Divider />
              </Box>
              <Box>
                <Button
                  color='primary'
                  variant='outlined'
                  sx={{ width: '100%' }}
                  onClick={() => addUesrContactPoint()}
                >
                  <AddOutlinedIcon sx={{ fontSize: '1.5rem' }} />
                  담당자 추가
                </Button>
              </Box>
              {contactPoints &&
                contactPoints.map((item: any, idx: number) => (
                  <Box key={`contact_point_${idx}`}>
                    <Box sx={{ mt: '1rem' }}>
                      <Box className='flex_start'>
                        <Typography className='title'>
                          담당자 {idx + 1}
                        </Typography>
                        {idx !== 0 && (
                          <Button
                            sx={{ p: '0' }}
                            color='info'
                            variant='outlined'
                            onClick={() => {
                              setManagerIndex(idx);
                              setOpenConfirm(true);
                            }}
                          >
                            삭제
                          </Button>
                        )}
                      </Box>
                      <TextField
                        sx={{ width: '88%' }}
                        value={item.name}
                        onChange={(
                          evt: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => changeName(idx, evt.target.value)}
                      />
                    </Box>
                    <Box>
                      <Typography className='title'>
                        담당자 {idx + 1} 연락처(1)
                      </Typography>
                      <SwSelectPhonenoEvent
                        index={idx}
                        phoneno={item.phoneno}
                        changePhoneno={changePhoneno}
                      />
                      {inputError1 === KEY_WORD_PHONE && (
                        <Typography sx={{ color: 'red' }}>
                          핸드폰 형식이 아닙니다.
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography className='title'>
                        담당자 {idx + 1} 연락처(2)
                      </Typography>
                      <SwSelectPhonenoEvent
                        index={idx + 100}
                        phoneno={item.phoneno2}
                        changePhoneno={changePhoneno}
                      />
                      {inputError1 === KEY_WORD_PHONE && (
                        <Typography sx={{ color: 'red' }}>
                          핸드폰 형식이 아닙니다.
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography className='title'>
                        담당자 {idx + 1} 이메일
                      </Typography>
                      <TextField
                        sx={{ width: '66%' }}
                        value={item.email}
                        onChange={(
                          evt: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => {
                          changeEmail(idx, evt.target.value);
                        }}
                      />
                      {inputError1 === KEY_WORD_EMAIL && (
                        <Typography sx={{ color: 'red' }}>
                          이메일 형식이 아닙니다.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ))}
              <Divider />
              <Box>
                <Typography className='title'>메모</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: 'auto',
                    },
                  }}
                  value={memo}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setMemo(evt.target.value)}
                />
                <Divider />
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          {!edit ? (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                color='info'
                variant='contained'
                onClick={() => checkDataPortalAccount(1)}
              >
                저장
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                color='info'
                variant='contained'
                onClick={() => checkDataPortalAccount(0)}
              >
                수정
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='담당자 정보를 삭제하시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          deleteUesrContactPoint();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { KEY_STORE_BRAND_LIST } from '../../../common/key';
import { getStoreBrandList } from '../../../common/api/ApiRewardMall';

const productDurationList = [
  { name: '6개월', value: 180 },
  { name: '1년', value: 365 },
  { name: '2년', value: 730 },
  { name: '3년', value: 1095 },
  { name: '4년', value: 1460 },
  { name: '5년', value: 1825 },
];

interface Props {
  duration: any;
  setTarget: Dispatch<React.SetStateAction<any>>;
}

export const SelectProductDuration: React.FC<Props> = ({
  duration,
  setTarget,
}) => {
  const [productDuration, setProductDuration] = useState<number>(5);

  const changeTarget = (idx: number) => {
    setTarget({
      value: productDurationList[idx].value,
      name: productDurationList[idx].name,
    });
  };

  useEffect(() => {
    switch (duration) {
      case 180:
        setProductDuration(0);
        changeTarget(0);
        break;
      case 365:
        setProductDuration(1);
        changeTarget(1);
        break;
      case 730:
        setProductDuration(2);
        changeTarget(2);
        break;
      case 1095:
        setProductDuration(3);
        changeTarget(3);
        break;
      case 1460:
        setProductDuration(4);
        changeTarget(4);
        break;
      default:
        setProductDuration(5);
        changeTarget(5);
    }
  }, [duration]);

  return (
    <Box>
      <Typography className='title'>유효 기간</Typography>
      <TextField
        select
        sx={{ width: '50%' }}
        defaultValue={5}
        value={productDuration}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          const newType = Number(event.target.value);
          setProductDuration(newType);
          changeTarget(newType);
        }}
      >
        {productDurationList.map((item: any, idx: number) => (
          <MenuItem key={`product_duration_${idx}`} value={idx}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

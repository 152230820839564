import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { KEY_STORE_BRAND_LIST } from '../../../common/key';
import { getStoreBrandList } from '../../../common/api/ApiRewardMall';

export const productTypeList = [
  '디지털 상품권',
  '배송형 상품권(나중 개발)',
  '실물 상품(나중 개발)',
];

interface Props {
  setTarget: Dispatch<React.SetStateAction<any>>;
  type: any;
}

export const SelectProductType: React.FC<Props> = ({ setTarget, type }) => {
  const [productType, setProductType] = useState<number>(0);

  const changeTarget = (idx: number) => {
    setTarget({
      value: idx + 1,
      name: productTypeList[idx],
    });
  };

  useEffect(() => {
    switch (type) {
      case 1:
        setProductType(0);
        changeTarget(0);
        break;
      case 2:
        setProductType(1);
        changeTarget(1);
        break;
      case 3:
        setProductType(2);
        changeTarget(2);
        break;
      default:
        setProductType(0);
        changeTarget(0);
    }
  }, [type]);

  return (
    <Box>
      <Typography className='title'>상품 유형</Typography>
      <TextField
        select
        sx={{ width: '50%' }}
        defaultValue={0}
        value={productType}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          const newType = Number(event.target.value);
          setProductType(newType);
          changeTarget(newType);
        }}
      >
        {productTypeList.map((item: any, idx: number) => (
          <MenuItem key={`product_type_${idx}`} value={idx}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

import { Box, styled, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import clsx from 'clsx';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';

interface Props {}

export const Push: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(2);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('regist');
        break;
      case 2:
        setValue(2);
        navigate('inquire/dataportalpush');
        break;
      default:
        setValue(0);
        navigate('/push');
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/push/regist') setValue(1);
    else if (
      path === '/push/inquire/adminpush' ||
      path === '/push/inquire/dataportalpush'
    )
      setValue(2);
  }, [window.location.pathname]);
  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>푸시 알림</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='푸시 알림 작성' value={1} />
          <Tab label='푸시 알림 조회' value={2} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};

/* eslint-disable global-require */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AddIcon from '@mui/icons-material/Add';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation } from 'react-router';
import { SwSwitch } from '../../styles/Styles';
import { SwLoadGPX } from '../../commonComponent/SwLoadGPX';
import { KEY_AMD_GEOMETRY_LIST } from '../../../common/key';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { selectedFieldIdState } from '../../../common/atom';
import {
  addDistanceToCoordinates,
  FIXED_CHARACTER,
  generateUuidId,
  getGeometryObjectCenterLocation,
  isPointInPolygon,
  squareMetersToSquareKilometers,
} from '../../../common/helper';
import { useSession } from '../../../hooks/session';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import {
  deleteAmdGeometry,
  getAmdGeometryList,
  registerAmdGeometry,
  updateAmdGeometryInfo,
} from '../../../common/api/ApiGeohash';
import { GeofencingInfoView } from './GeofencingInfoView';

const POLYGON_TYPE = 'polygon';
const CIRCLE_TYPE = 'circle';
const FILL_COLOR = '#00a0e9';
const LINE_COLOR = '#00a0e9';
const MAX_GEOMETRY_COUNT = 1000;
const MAX_CHARACTER_COUNT = 1000;

const TextBox = styled(Box)({
  width: '6vw',
  padding: '.2rem .4rem',
  maxWidth: '6rem',
  minWidth: '5.9rem',
  marginLeft: '.3rem',
});

const OverlayView = styled(Box)({
  position: 'absolute',
  zIndex: 100,
  padding: '.5rem',
  margin: '1rem',
  height: '8%',
  display: 'flex',
  justifyContent: 'space-between',
  width: '98%',
});

const OverlayViewDivider = styled(Divider)({
  margin: 0,
  padding: 0,
});

const RadiusInput = styled(TextField)({
  minWidth: '6.5rem',
  maxWidth: '7.0rem',
  backgroundColor: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    height: '2.4rem',
    borderRadius: '4px',
    paddingRight: 10,
    paddingLeft: 10,
  },
  '& .MuiOutlinedInput-input': {
    padding: 0.1,
  },
});

const SwitchPaper = styled(Paper)({
  // display: 'flex',
  zIndex: 10,
  padding: '.5rem',
  margin: '1rem .5rem',
  minWidth: '6rem',
});

const ContentsPaper = styled(Paper)({
  width: '29%',
  height: '70vh',
  borderRadius: '12px',
  marginLeft: '1%',
});

const ButtonPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '10rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.1rem',
  cursor: 'grab',
  boxShadow: 'none',
});

interface Props {
  fieldData?: any;
  mapInfo?: any;
  setMapInfo?: any;
  elementId?: string;
  disabled?: boolean;
  reloadField: (id: string) => void;
}

export const GeofencingMap: React.FC<Props> = ({
  fieldData = null,
  mapInfo = null,
  setMapInfo = null,
  elementId = 'map',
  disabled = false,
  reloadField,
}) => {
  const { loginVal } = useSession();

  const [selectedAmdCode, setSelectedAmdCode] = useState('');

  const map = useRef<any>();
  const maps = document.getElementById(elementId);
  const location = useLocation();
  const drawRef = useRef<any>();
  const drawTypeRef = useRef<any>();
  const geometryIndexRef = useRef<number>(-1);
  const characterIndexRef = useRef<number>(-1);
  const drawingPointsRef = useRef<any>(null);
  const drawingCharacterRef = useRef<any>(null);
  const drawingCharacterTypeRef = useRef<any>(null);
  const tempCircleOpacityRef = useRef<any>(0.6);
  const tempPolylineRef = useRef<any>();
  const tempCircleInfoRef = useRef<any>();
  const tempCourseRef = useRef<any>();
  const tempMarkerRef = useRef<any>();
  const centerRef = useRef<any>();
  const radiusRef = useRef<any>();
  const reloadRef = useRef<any>(null);
  const addPointRef = useRef<any>(null);
  const changePointRef = useRef<any>(null);
  const tempPointsRef = useRef<any>([]);
  const stampCircleRef = useRef<any>(150);
  const rightClickRef = useRef<any>(false);
  const characterImageRef = useRef<any>(false);
  const characterImageWidthRef = useRef<any>('50');
  const characterImageHeightRef = useRef<any>('50');
  const tempCharacterListRef = useRef<any>([]);
  const tempMarkerCharacterRef = useRef<any>([]);
  const tempRef = useRef<any>([]);
  const totalAreaRef = useRef<any>(0);
  const selectedCharacterUrlRef = useRef<any>('');
  const itemRefs = useRef<any>([]);

  const [selectedPath, setSelectedPath] = useState<any>();
  const [selectedGeometryId, setSelectedGeometryId] = useState<string>('');
  const [selectInsertCharacter, setSelectInsertCharacter] =
    useState<boolean>(false);
  const [selectInsertContent, setSelectInsertContent] =
    useState<boolean>(false);
  const [drawType, setDrawType] = useState<number>(2);
  const [circle, setCircle] = useState<any>();
  const [pointChange, setPointChange] = useState<boolean>(false);
  const [changeCharacter, setChangeCharacter] = useState<number>(0);
  const [circleStart, setCircleStart] = useState(false);
  const [drawingOpacity, setDrawingOpacity] = useState(false);
  const [mapCenter, setMapCenter] = useState<any>([37.5, 127.012]);
  const [stampCircle, setStampCircle] = useState<number>(150);
  const [courseWeight, setCourseWeight] = useState<number>(40);
  const [courseDistance, setCourseDistance] = useState<number>(0);
  const [currentDistance, setCurrentDistance] = useState<any>([]);
  const [courseTime, setCourseTime] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<any>([]);
  const [markerStart, setMarkerStart] = useState(false);
  const [markerCharacterStart, setMarkerCharacterStart] = useState(false);
  const [markerContentStart, setMarkerContentStart] = useState(false);
  const [points, setPoints] = useState<any>([]);
  const [pointsCharacter, setPointsCharacter] = useState<any>([]);
  const [pointsContent, setPointsContent] = useState<any>([]);
  const [course, setCourse] = useState<any>(null);
  const [marker, setMarker] = useState<any>([]);
  const [markerCharacter, setMarkerCharacter] = useState<any>([]);
  const [markerContent, setMarkerContent] = useState<any>([]);
  const [polyline, setPolyline] = useState<any>(null);
  const [uploadFile, setUploadFile] = useState<any>('');
  const [tempPoint, setTempPoints] = useState<any>([]);
  const [tempPointsContent, setTempPointsContent] = useState<any>([]);
  const [markerShow, setMarkerShow] = useState<boolean>(true);
  const [contentMarkerShow, setContentMarkerShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<any>(null);
  const [circleData, setCircleData] = useState<any>();
  const [openGpx, setOpenGpx] = useState<boolean>(false);
  const [gpxData, setGpxData] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [characterInfoIndex, setCharacterInfoIndex] = useState<number>(0);
  const [characterInfo, setCharacterInfo] = useState<any>();
  const [contentInfoIndex, setContentInfoIndex] = useState<number>(0);
  const [contentInfo, setContentInfo] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [openCreateCharacter, setOpenCreateCharacter] =
    useState<boolean>(false);
  const [openSpot, setOpenSpot] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [showContent, setShowContent] = useState<boolean>(true);
  const [stamps, setStamps] = useState<any>([]);
  const [stampsCircle, setStampsCircle] = useState<any>([]);
  const [editIdx, setEditIdx] = useState<number>(-1);
  const [targetName, setTargetName] = useState<string>('');
  const [geometryIndex, setGeometryIndex] = useState<number>(-1);
  const [geometryObjectList, setGeometryObjectList] = useState<any[]>([
    {
      name: 'no-name',
      category: 0,
      type: '', // polygon, circle
      points: [],
      cirlce: null,
      courseMap: null,
      circleMap: null,
      area: 0,
      location: [],
      marker: null,
      hiddenRandomCharacter: false,
    },
  ]);

  let movingPoint = false;
  let ncourse: any;
  let drawingStart = false;
  let drawingCharacterStart = false;
  let drawingContentStart = false;
  let circleDrawStart = false;
  let drawingCircle: any;
  let drawingCircleLine: any;
  let length: any;
  let drawingCircleInfo: any;
  let drawingLine: any;
  let tempCircle: any;
  let tempPosition: any;
  let tempPolyline: any;
  let currentMarker: any;
  let currentCircle: any;
  let centerPosition: any;
  let mousePosition: any;
  const pointMouseOverInfo = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >지도 위에 원하는 지점을\n 연속적으로 클릭하여\n다각형 영역을 만들어보세요.</div>`;
  const characterMouseOverInfo = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >지도의 캐릭터 출현 영역 안에서\n 케릭터가 출현할 위치를\n클릭하여 지정해주세요.</div>`;
  const spotMouseOverInfo = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >영역 근처 원하는 지점을 클릭하여\n 소개하고 싶은 장소 콘텐츠를\n추가해보세요.</div>`;
  const changePointContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >이동하실 위치를 선택해주세요! :)</div>`;
  const mouseMoveCustomOverlay = new naver.maps.InfoWindow({
    content: '',
    borderWidth: 0,
    disableAnchor: true,
    backgroundColor: 'transparent',
    pixelOffset: new naver.maps.Point(0, -28),
  });

  const getBoundingBox = (lat: number, lng: number, radius: number) => {
    const earthRadius = 111000; // 1도당 약 111km

    // 위도 변화량 계산
    const deltaLat = radius / earthRadius;

    // 경도 변화량 계산 (위도에 따라 조정)
    const deltaLng = radius / (earthRadius * Math.cos((lat * Math.PI) / 180));

    return {
      left: lng - deltaLng, // minLongitude
      right: lng + deltaLng, // maxLongitude
      top: lat + deltaLat, // maxLatitude
      bottom: lat - deltaLat, // minLatitude
    };
  };

  const changeMapCenterByObjects = (newObject: any[]) => {
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    newObject.map((item: any) => {
      if (item.type === CIRCLE_TYPE) {
        const boundingBox = getBoundingBox(
          item.circle.center.y,
          item.circle.center.x,
          item.circle.radius
        );
        if (boundingBox.top < minLat) minLat = boundingBox.top;
        if (boundingBox.top > maxLat) maxLat = boundingBox.top;
        if (boundingBox.left < minLng) minLng = boundingBox.left;
        if (boundingBox.left > maxLng) maxLng = boundingBox.left;

        if (boundingBox.bottom < minLat) minLat = boundingBox.bottom;
        if (boundingBox.bottom > maxLat) maxLat = boundingBox.bottom;
        if (boundingBox.right < minLng) minLng = boundingBox.right;
        if (boundingBox.right > maxLng) maxLng = boundingBox.right;
      } else {
        item.points.map((pnt: any) => {
          if (pnt.lat < minLat) minLat = pnt.lat;
          if (pnt.lat > maxLat) maxLat = pnt.lat;
          if (pnt.lng < minLng) minLng = pnt.lng;
          if (pnt.lng > maxLng) maxLng = pnt.lng;
        });
      }
      const naverBounds = new naver.maps.LatLngBounds(
        new naver.maps.LatLng(minLat, minLng),
        new naver.maps.LatLng(maxLat, maxLng)
      );
      map.current.fitBounds(naverBounds);
    });
  };

  const changeMapCenter = (newObject: any) => {
    const naverBounds = new naver.maps.LatLngBounds(
      new naver.maps.LatLng(newObject.minLat, newObject.minLng),
      new naver.maps.LatLng(newObject.maxLat, newObject.maxLng)
    );
    map.current.fitBounds(naverBounds);
  };

  const changeFitBoundByPoints = (newPoints: any) => {
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;
    newPoints.map((pnt: any) => {
      if (pnt.lat < minLat) minLat = pnt.lat;
      if (pnt.lat > maxLat) maxLat = pnt.lat;
      if (pnt.lng < minLng) minLng = pnt.lng;
      if (pnt.lng > maxLng) maxLng = pnt.lng;
    });

    const naverBounds = new naver.maps.LatLngBounds(
      new naver.maps.LatLng(minLat, minLng),
      new naver.maps.LatLng(maxLat, maxLng)
    );
    map.current.fitBounds(naverBounds);
  };

  const changeFitBoundByPath = (newPath: any) => {
    const tmpPoint = newPath && newPath.length > 0 ? JSON.parse(newPath) : [];
    const newPoints = tmpPoint.map((item: any) => {
      const tmp: any = { lat: item[0], lng: item[1] };
      return tmp;
    });

    changeFitBoundByPoints(newPoints);
  };

  const changeFitBoundByObject = (newObject: any) => {
    const tmpObject = [newObject];
    changeMapCenterByObjects(tmpObject);
  };

  const scrollingToItem = (newGeometryList: any[]) => {
    const tmpIdx = newGeometryList
      .map((geometry: any, idx: number) =>
        geometry.geometryId === fieldData.geometryId ? idx : -1
      )
      .filter((item: any) => item !== -1);
    const selectedIdx = tmpIdx !== null && tmpIdx.length > 0 ? tmpIdx[0] : null;
    if (
      selectedIdx !== undefined &&
      selectedIdx !== null &&
      itemRefs.current[selectedIdx]
    ) {
      itemRefs.current[selectedIdx]?.scrollIntoView({
        behavior: 'smooth', // 부드럽게 스크롤
        block: 'center', // 중앙 정렬
      });
    }
  };

  const setupGeometryList = (newGeometryList: any[]) => {
    const newObjectList = geometryObjectList;
    newGeometryList.map((geometry: any, idx: number) => {
      if (geometry.type === 1) {
        const tmpPoint =
          geometry.path !== null && geometry.path.length > 0
            ? JSON.parse(geometry.path)
            : [];
        const newPoints = tmpPoint.map((item: any) => {
          const tmp: any = { lat: item[0], lng: item[1] };
          return tmp;
        });

        const tempPolygon = new naver.maps.Polygon({
          paths: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
          fillColor: FILL_COLOR,
          fillOpacity: 0.1,
          strokeWeight: 5,
          strokeColor: LINE_COLOR,
          strokeOpacity: 1,
          strokeStyle: 'solid',
          strokeLineCap: 'round',
          strokeLineJoin: 'round',
        });
        tempPolygon.setMap(map.current);

        const centerLoc: any = getGeometryObjectCenterLocation(newPoints);
        const tempMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(centerLoc.lat, centerLoc.lng),
          map: map.current,
          icon: {
            content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
            anchor: new naver.maps.Point(18, 38),
          },
          clickable: false,
          draggable: false,
        });
        tempMarker.setMap(map.current);

        newObjectList[idx].geometryId = geometry.geometryId;
        newObjectList[idx].name = geometry.name;
        newObjectList[idx].category = geometry.category;
        newObjectList[idx].points = newPoints;
        newObjectList[idx].courseMap = tempPolygon;
        newObjectList[idx].type = POLYGON_TYPE;
        newObjectList[idx].area = tempPolygon.getAreaSize();
        newObjectList[idx].marker = tempMarker;
        newObjectList[idx].hiddenRandomCharacter =
          geometry.hiddenRandomCharacter === 1;
      } else if (geometry.type === 2) {
        const newCircle = new naver.maps.Circle({
          map: map.current,
          center: new naver.maps.LatLng(geometry.lat || 0, geometry.lng || 0),
          radius: geometry.radius || 0,
          fillColor: FILL_COLOR,
          fillOpacity: 0.1,
          strokeWeight: 5,
          strokeColor: LINE_COLOR,
          strokeStyle: 'solid',
          strokeOpacity: 1,
        });

        const newCircleData = {
          center: new naver.maps.LatLng(geometry.lat || 0, geometry.lng || 0),
          radius: geometry.radius || 0,
        };
        newCircle.setMap(map.current);

        const tempMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(geometry.lat || 0, geometry.lng || 0),
          map: map.current,
          icon: {
            content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
            anchor: new naver.maps.Point(18, 38),
          },
          clickable: false,
          draggable: false,
        });
        tempMarker.setMap(map.current);

        newObjectList[idx].geometryId = geometry.geometryId;
        newObjectList[idx].name = geometry.name;
        newObjectList[idx].category = geometry.category;
        newObjectList[idx].circle = newCircleData;
        newObjectList[idx].circleMap = newCircle;
        newObjectList[idx].type = CIRCLE_TYPE;
        newObjectList[idx].area = newCircle.getAreaSize();
        newObjectList[idx].marker = tempMarker;
        newObjectList[idx].hiddenRandomCharacter =
          geometry.hiddenRandomCharacter === 1;
      }
    });
    const newIndex =
      newGeometryList.length > 0 ? newGeometryList.length - 1 : -1;
    geometryIndexRef.current = newIndex;
    setGeometryIndex(newIndex);
    setGeometryObjectList(newObjectList);

    if (fieldData.cmdType !== 'detail' && newObjectList.length > 0) {
      changeMapCenterByObjects(newObjectList);
    }
  };

  const resetContentData = () => {
    markerContent?.map((m: any) => m?.setMap(null));
  };

  const resetGeometryData = () => {
    setPoints([]);
    marker?.map((m: any) => m?.setMap(null));
    course?.setMap(null);
    circle?.setMap(null);
    setMarker([]);
    stampsCircle?.map((stamp: any) => stamp?.setMap(null));

    setGeometryIndex(-1);
    geometryIndexRef.current = -1;
    geometryObjectList.map((object: any) => {
      object.courseMap?.setMap !== undefined &&
        object.courseMap?.setMap !== null &&
        object.courseMap?.setMap(null);
      object.circleMap?.setMap !== undefined &&
        object.circleMap?.setMap !== null &&
        object.circleMap?.setMap(null);
      object.marker?.setMap !== undefined &&
        object.marker?.setMap !== null &&
        object.marker?.setMap(null);
      object.type = '';
      object.points = [];
      object.courseMap = null;
      object.circleMap = null;
      object.marker = null;
      object.hiddenRandomCharacter = false;
      object.geometryId = generateUuidId();
      object.name = 'no-name';
    });
  };

  const { refetch: amdGeometryRefetch } = useQuery(
    KEY_AMD_GEOMETRY_LIST,
    () => {
      if (selectedAmdCode && selectedAmdCode.length > 0) {
        const newData = {
          amdCode: selectedAmdCode,
        };
        return getAmdGeometryList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.amd) {
          console.log('--> res.amd:', res.amd);
          if (res.amd.amdGeometry.length > 0) {
            setupGeometryList(res.amd.amdGeometry);
            res.amd.amdInfo && changeMapCenter(res.amd.amdInfo);
            changeFitBoundByPath(selectedPath);
            setTimeout((_: any) => {
              scrollingToItem(res.amd.amdGeometry);
            }, 1000);
          } else {
            resetGeometryData();
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const refreshGeometryArea = () => {
    let newArea = 0;

    geometryObjectList.filter((item: any, idx: number) => {
      if (item.type !== '') {
        newArea += squareMetersToSquareKilometers(item.area);
      }
    });

    totalAreaRef.current = newArea;
  };

  const {
    mutate: registerAmdGeometryMutation,
    isError: isGeometryMutateError,
  } = useMutation(registerAmdGeometry, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('그리기 데이터가 등록되었습니다.');
      refreshGeometryArea();
      reloadField(selectedAmdCode);
      // fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '그리기 데이터 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteAmdGeometryMutation,
    isError: isGeometryDeleteMutateError,
  } = useMutation(deleteAmdGeometry, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('그리기 데이터가 삭제되었습니다.');
      refreshGeometryArea();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '그리기 데이터 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: changeAmdGeometryInfoMutation,
    isError: isChangeMutateError,
  } = useMutation(updateAmdGeometryInfo, {
    onSuccess: () => {
      setEditIdx(-1);
      setOpenSnackbar(true);
      setDialogMessage('이름이 수정되었습니다.');
      amdGeometryRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '이름을 변경하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const saveCurrentGeometry = () => {
    let newCenterPointLat = 0;
    let newCenterPointLng = 0;
    let newRadius = 0;
    let newPath = [];
    const newIndex = geometryIndexRef.current;

    if (geometryObjectList[newIndex].type === POLYGON_TYPE) {
      newPath = geometryObjectList[newIndex].points.map(
        (item: any, idx: number) => [item.lat, item.lng]
      );
    } else if (geometryObjectList[newIndex].type === CIRCLE_TYPE) {
      newCenterPointLat = geometryObjectList[newIndex].circle.center.y;
      newCenterPointLng = geometryObjectList[newIndex].circle.center.x;
      newRadius = geometryObjectList[newIndex].circle.radius;
    }
    const newGeometry = {
      name: geometryObjectList[newIndex].name,
      geometryId: geometryObjectList[newIndex].geometryId,
      type: geometryObjectList[newIndex].type === POLYGON_TYPE ? 1 : 2,
      path: JSON.stringify(newPath),
      lat: newCenterPointLat,
      lng: newCenterPointLng,
      radius: newRadius,
      area: geometryObjectList[newIndex].area,
      category: 0,
      subCategory: 0,
      hiddenRandomCharacter: geometryObjectList[newIndex].hiddenRandomCharacter
        ? 1
        : 0,
    };
    const newData: any = {
      amdCode: selectedAmdCode,
      geometry: newGeometry,
    };
    registerAmdGeometryMutation(newData);
  };

  const setDistance = (distance: number) => {
    const newDistance = Math.round(distance).toString();
    const contentLength = newDistance.length;
    let result: any;
    if (contentLength > 4) {
      const settingKm = Math.floor(distance / 1000);
      const settingMeter = (distance % 1000).toString().split('.')[0];
      result = `${settingKm}.${settingMeter}km`;
    } else if (contentLength === 4) {
      result = `${newDistance[0]},${newDistance.slice(1, 4)}m`;
    } else {
      result = `${newDistance}m`;
    }
    return result;
  };

  const setTime = (time: number) => {
    const newTime = Math.round(time);
    if (time / 60 >= 1) {
      const hour = Math.floor(newTime / 60);
      const minute = newTime % 60;
      return `${hour}시간${minute}분`;
    }
    if (newTime === 0) {
      const seconds = Math.round(time * 60);
      return `${seconds}초`;
    }
    return `${newTime}분`;
  };

  const initGeometryObjectList = () => {
    if (geometryObjectList !== undefined && geometryIndex !== undefined) {
      setGeometryIndex(-1);
      geometryIndexRef.current = -1;
      for (var j = 0; j < MAX_GEOMETRY_COUNT; j += 1) {
        geometryObjectList.pop();
      }
      for (var i = 0; i < MAX_GEOMETRY_COUNT; i += 1) {
        geometryObjectList.push({
          geometryId: generateUuidId(),
          type: '',
          points: [],
          circle: null,
          courseMap: null,
          circleMap: null,
          location: [],
          marker: null,
          hiddenRandomCharacter: false,
        });
      }
    }
  };

  const initMap = () => {
    if (maps) {
      map.current = new naver.maps.Map(elementId, {
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        zoom: 15,
      });
      const checkRadius = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
      });
      naver.maps.Event.addListener(map.current, 'zoom_changed', () => {
        checkRadius.setRadius(stampCircleRef.current);
        const newMaxBounds = checkRadius.getDrawingRect().getMax();
        const newMinBounds = checkRadius.getDrawingRect().getMin();
        const newWeight = newMaxBounds.y - newMinBounds.y;
        setCourseWeight(newWeight);
      });
    }
  };

  const addMarker = (newCenterPosition: any) => {
    const tempMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(newCenterPosition.y, newCenterPosition.x),
      map: map.current,
      icon: {
        content: `<div class='walkcourse_marker'>${
          geometryIndexRef.current + 1
        }</div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    tempMarker.setMap(map.current);

    const tmpIndex = geometryIndexRef.current;
    const newObjectList = geometryObjectList;
    newObjectList[tmpIndex].marker = tempMarker;
    setGeometryObjectList(newObjectList);
  };

  const changeMarker = (newCenterPosition: any) => {
    const tmpIndex = geometryIndexRef.current;
    const newObjectList: any = geometryObjectList;
    newObjectList[tmpIndex].marker?.setMap(null);

    const tempMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        newCenterPosition.lat,
        newCenterPosition.lng
      ),
      map: map.current,
      icon: {
        content: `<div class='walkcourse_marker'>${
          geometryIndexRef.current + 1
        }</div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    tempMarker.setMap(map.current);

    newObjectList[tmpIndex].marker = tempMarker;
    setGeometryObjectList(newObjectList);
  };

  const isPointInsideCircle = (point: any, center: any, radius: number) => {
    const tmpDrawingLine = new naver.maps.Polyline({
      strokeWeight: 3,
      strokeColor: LINE_COLOR,
      path: [center, point],
      map: map.current,
    });

    const distance = tmpDrawingLine?.getDistance();
    tmpDrawingLine.setMap(null);
    return distance <= radius;
  };

  const checkPointInPolygon = (coord: any) => {
    const newCoord: any = {
      lat: coord.y,
      lng: coord.x,
    };
    let isInside = false;
    geometryObjectList
      .filter((item: any, idx: number) => item.type !== '')
      .map((item: any, idx: number) => {
        if (
          item.type === POLYGON_TYPE &&
          isPointInPolygon(newCoord, item.points)
        ) {
          isInside = true;
        } else if (
          item.type === CIRCLE_TYPE &&
          isPointInsideCircle(coord, item.circle.center, item.circle.radius)
        ) {
          isInside = true;
        }
      });
    console.log('--> isInside:', isInside);
    return isInside;
  };

  // 지도 이벤트 설정
  const clickEvent = (evt: any) => {
    if (disabled) return;
    if (!drawingPointsRef.current && changePointRef.current === null) {
      if (!drawingStart) {
        setGeometryIndex &&
          setGeometryIndex((prevState: number) => prevState + 1);
        geometryIndexRef.current += 1;
        drawingStart = true;
        rightClickRef.current = false;
        centerPosition = evt.coord;
        if (drawTypeRef?.current?.value === 2) {
          centerPosition = evt.coord;
          drawingLine?.setMap(null);
          centerRef.current = evt.coord;
          drawingLine = new naver.maps.Polygon({
            fillColor: FILL_COLOR,
            fillOpacity: 0.1,
            strokeWeight: 3,
            strokeOpacity: 0.3,
            strokeColor: LINE_COLOR,
            paths: [centerPosition],
            map: map.current,
          });
          drawingLine?.setMap(map.current);
          setMarkerStart(true);

          addMarker(evt.coord);
        } else if (drawTypeRef?.current?.value === 3) {
          centerPosition = evt.coord;
          mouseMoveCustomOverlay.close();
          drawingStart = true;
          setMarkerStart(true);
          drawingLine = new naver.maps.Polyline({
            strokeWeight: 3,
            strokeColor: LINE_COLOR,
            path: [centerPosition],
            map: map.current,
          });
          tempCircle = new naver.maps.Circle({
            map: map.current,
            center: mousePosition,
            radius: stampCircleRef.current,
            fillColor: FILL_COLOR,
            fillOpacity: 0.1,
            strokeWeight: 5,
            strokeColor: LINE_COLOR,
            strokeOpacity: 1,
            strokeStyle: 'solid',
          });
          drawingLine?.setMap(map.current);
          tempCircle?.setMap(map.current);

          addMarker(evt.coord);
        }
      } else if (drawTypeRef?.current?.value === 2) {
        centerPosition = evt.coord;
        centerRef.current = evt.coord;
        setMarkerStart(false);
      }
    } else if (
      drawingPointsRef.current === 1 &&
      drawingCharacterRef.current !== null
    ) {
      if (drawingCharacterTypeRef.current === 1) {
        if (!drawingCharacterStart) {
          if (checkPointInPolygon(evt.coord)) {
            reloadRef.current?.lat
              ? (centerPosition = reloadRef.current)
              : null;
            mouseMoveCustomOverlay.close();
            drawingCharacterStart = true;
            setMarkerCharacterStart(true);
          }
        } else if (checkPointInPolygon(evt.coord)) {
          centerPosition = evt.coord;
          tempPosition = evt.coord;
          drawingLine?.setMap(null);
          centerRef.current = evt.coord;
          reloadRef.current = null;
          setMarkerCharacterStart(false);
        }
      }
    } else if (
      drawingPointsRef.current === 1 &&
      drawingCharacterRef.current === null
    ) {
      console.log(
        '--> drawingPointsRef.current === 1 & drawingCharacterRef.current === null '
      );
    } else if (
      drawingPointsRef.current === 2 &&
      changePointRef.current === null
    ) {
      if (!drawingContentStart) {
        reloadRef.current?.lat ? (centerPosition = reloadRef.current) : null;
        mouseMoveCustomOverlay.close();
        drawingContentStart = true;
        setMarkerContentStart(true);
      } else {
        centerPosition = evt.coord;
        tempPosition = evt.coord;
        drawingLine?.setMap(null);
        centerRef.current = evt.coord;
        reloadRef.current = null;
        setMarkerContentStart(false);
      }
    } else if (movingPoint) {
      // tempMarkerRef.current?.map((m: any) => m?.setMap(null));
      mouseMoveCustomOverlay.close();
      const newPoint = {
        lat: evt.coord.y,
        lng: evt.coord.x,
        radius: stampCircleRef.current,
      };
      const newPoints = [
        ...tempPointsRef.current.slice(0, changePointRef.current),
        newPoint,
        ...tempPointsRef.current.slice(
          changePointRef.current + 1,
          tempPointsRef.current.length
        ),
      ];
      tempPointsRef.current = newPoints;
      tempMarkerRef.current[changePointRef.current].setPosition(evt.coord);
      tempMarkerRef.current[changePointRef.current].setMap(map.current);
      tempCourseRef.current.setPath(tempPointsRef.current);
      tempPolylineRef.current.setPath(tempPointsRef.current);
      setPointChange(true);
      movingPoint = false;
    } else {
      if (changePointRef.current !== null)
        tempMarkerRef.current[changePointRef.current].setMap(null);
      movingPoint = true;
    }
  };
  const moveEvent = (evt: any) => {
    mousePosition = evt.coord;
    tempCircle?.setMap(null);
    if (!drawingPointsRef.current && changePointRef.current === null) {
      if (drawingStart) {
        if (drawTypeRef?.current?.value === 2) {
          setMarkerStart(true);
          const linePath = [centerPosition, mousePosition];
          drawingLine?.setPath(linePath);
          drawingLine?.setMap(map.current);
          mouseMoveCustomOverlay.setPosition(mousePosition);
          mouseMoveCustomOverlay.setMap(map.current);
        } else if (drawTypeRef?.current?.value === 3) {
          drawingLine.setPath([centerPosition, mousePosition]);
          tempCircle.setRadius(drawingLine?.getDistance());
          length = setDistance(drawingLine?.getDistance());
          drawingLine?.setMap(map.current);
          tempCircle?.setMap(map.current);

          mouseMoveCustomOverlay.setPosition(mousePosition);
          mouseMoveCustomOverlay.setMap(map.current);
          if (reloadRef.current !== 1) {
            const linePath = [centerPosition, mousePosition];
            drawingLine?.setPath(linePath);
            drawingLine?.setMap(map.current);
          }
        }
      } else {
        // mouseMoveCustomOverlay.setContent(pointMouseOverInfo);
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    } else if (circleDrawStart) {
      tempCircle?.setMap(null);
      drawingLine?.setMap(null);
      circle?.setMap(null);
      if (centerPosition) {
        const linePath = [centerPosition, mousePosition];
        drawingCircleLine?.setPath(linePath);
        length = drawingCircleLine?.getDistance();
        const info = `<div class ='drawing_circleinfo'><span>${setDistance(
          length
        )}\n우측클릭으로 완성</span></div>`;
        if (length >= 0) {
          const circleOptions = {
            center: centerPosition,
            radius: length,
          };
          drawingCircle.setOptions(circleOptions);
          drawingCircleLine.setMap(map.current);
          drawingCircle.setMap(map.current);
          mouseMoveCustomOverlay.setContent(info);
          mouseMoveCustomOverlay.setPosition(mousePosition);
          mouseMoveCustomOverlay.setMap(map.current);
        }
      } else {
        drawingCircleInfo.setPosition(mousePosition);
        drawingCircleInfo.setMap(map.current);
      }
    } else if (
      drawingPointsRef.current === 1 &&
      changePointRef.current === null
    ) {
      if (drawingCharacterStart) {
        setMarkerCharacterStart(true);
        tempCircle = new naver.maps.Circle({
          map: map.current,
          center: mousePosition,
          radius: stampCircleRef.current,
          fillColor: '#F0F4C3',
          fillOpacity: tempCircleOpacityRef.current,
          strokeColor: '#F0F4C3',
          strokeOpacity: 0,
        });
        tempCircle.setMap(map.current);
      } else {
        mouseMoveCustomOverlay.setContent(characterMouseOverInfo);
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    } else if (
      drawingPointsRef.current === 2 &&
      changePointRef.current === null
    ) {
      if (drawingContentStart) {
        setMarkerContentStart(true);
        tempCircle = new naver.maps.Circle({
          map: map.current,
          center: mousePosition,
          radius: stampCircleRef.current,
          fillColor: '#F0F4C3',
          fillOpacity: tempCircleOpacityRef.current,
          strokeColor: '#F0F4C3',
          strokeOpacity: 0,
        });
        tempCircle.setMap(map.current);
      } else {
        mouseMoveCustomOverlay.setContent(spotMouseOverInfo);
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    } else {
      const mouseLatLng = { lat: mousePosition.y, lng: mousePosition.x };
      // if (
      //   Math.abs(
      //     tempPointsRef.current[changePointRef.current].lat - mouseLatLng.lat
      //   ) < 0.0025 &&
      //   Math.abs(
      //     tempPointsRef.current[changePointRef.current].lng - mouseLatLng.lng
      //   ) < 0.0025
      // ) {
      //   movingPoint = true;
      // }
      if (movingPoint) {
        drawingLine?.setMap(null);
        tempMarkerRef.current[changePointRef.current].setMap(null);
        mouseMoveCustomOverlay.setOptions({
          content: `${changePointContent}<div class='walkcourse_marker_selected' style='margin-left: 6.3rem; display: inline-block;'>${
            changePointRef.current + 1
          }</div>`,
          pixelOffset: new naver.maps.Point(0, -10),
        });
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
        tempCircle = new naver.maps.Circle({
          map: map.current,
          center: mousePosition,
          radius: stampCircleRef.current,
          fillColor: '#F0F4C3',
          fillOpacity: tempCircleOpacityRef.current,
          strokeColor: '#F0F4C3',
          strokeOpacity: 0,
        });
        tempCircle.setMap(map.current);
        const newPoints = [
          ...tempPointsRef.current.slice(0, changePointRef.current),
          mouseLatLng,
          ...tempPointsRef.current.slice(
            changePointRef.current + 1,
            tempPointsRef.current.length
          ),
        ];
        const newMaxBounds = tempCircle.getDrawingRect().getMax();
        const newMinBounds = tempCircle.getDrawingRect().getMin();
        const newWeight = newMaxBounds.y - newMinBounds.y;
        tempCourseRef.current?.setOptions({
          path: newPoints,
          strokeWeight: newWeight,
        });
        tempPolylineRef.current?.setOptions({
          path: newPoints,
          strokeWeight: newWeight / 15,
        });
        tempCourseRef.current?.setMap(map.current);
        tempPolylineRef.current?.setMap(map.current);
      } else {
        mouseMoveCustomOverlay.setOptions({
          content: `<div class='walkcourse_marker_selected' style='margin-left: 6.3rem; display: inline-block;'>
변경을 원하시면 클릭 후 진행해주세요!
          </div>`,
          pixelOffset: new naver.maps.Point(0, -10),
        });
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    }
  };
  const rightClickEvent = (evt: any) => {
    if (drawingStart) {
      rightClickRef.current = true;
      if (drawTypeRef?.current?.value === 2) {
        tempPosition = evt.coord;
        centerRef.current = evt.coord;
        centerPosition = evt.coord;
        drawingLine?.setMap(null);
        setMarkerStart(false);
        drawingStart = false;
        mouseMoveCustomOverlay.setMap(null);
        drawRef.current = 1;
      } else if (drawTypeRef?.current?.value === 3) {
        length = 0;
        centerRef.current = evt.coord;
        mouseMoveCustomOverlay.setMap(null);
        drawingLine.setPath([centerPosition, evt.coord]);
        tempCircle.setRadius(drawingLine?.getDistance());
        tempCircleInfoRef.current = {
          point: [centerPosition.y, centerPosition.x],
          radius: drawingLine?.getDistance(),
        };
        drawingLine?.setMap(null);
        tempCircle?.setMap(null);
        setMarkerStart(false);
        drawingStart = false;
      }
    } else if (circleDrawStart) {
      radiusRef.current = [centerPosition, evt.coord];
      circleDrawStart = false;
      setCircleStart(false);
      centerPosition = null;
      drawingCircleLine?.setMap(null);
      drawingCircle?.setMap(null);
    }
  };
  const mouseoutEvent = (evt: any) => {
    mouseMoveCustomOverlay.close();
    tempCircle?.setMap(null);
    drawingStart = false;
    drawingLine?.setMap(null);
    centerPosition = tempPosition;
    circleDrawStart = false;
    drawingCircleLine?.setMap(null);
    drawingCircle?.setMap(null);
  };

  const keydownEvent = (evt: any) => {
    if (evt.keyboardEvent.key === 'Escape') {
      drawingStart = false;
      drawingLine?.setMap(null);
      circleDrawStart = false;
      drawingLine?.setMap(null);
      drawingCircle?.setMap(null);
    }
  };
  const drawingMap = () => {
    if (maps) {
      naver.maps.Event.addListener(map.current, 'click', clickEvent);
      naver.maps.Event.addListener(map.current, 'mousemove', moveEvent);
      naver.maps.Event.addListener(map.current, 'rightclick', rightClickEvent);
      naver.maps.Event.addListener(map.current, 'mouseout', mouseoutEvent);
      naver.maps.Event.addListener(map.current, 'keydown', keydownEvent);
    }
  };

  const parseGPX = (xml: any) => {
    const parser = new DOMParser();
    const result: any = [];
    const newXML = parser.parseFromString(uploadFile, 'text/xml');
    const toJson = newXML.querySelectorAll('trkpt');
    toJson.forEach((element: any, idx: number) => {
      idx % 2 &&
        idx % 4 === 1 &&
        idx % 8 === 1 &&
        idx % 16 === 1 &&
        // idx % 32 === 1 &&
        //   idx % 64 === 1 &&
        result.push({
          lat: element.attributes[0].value,
          lng: element.attributes[1].value,
          radius: stampCircleRef.current,
        });
    });
    console.log('parseGPX :', result);
    setTempPoints(result);
    setUploadFile('');
    setMapCenter([result[0]?.lat, result[0]?.lng]);
  };

  const getCurrentPosition = () => {
    const handleGeoSucces = (position: any) => {
      setMapCenter([position.coords.latitude, position.coords.longitude]);
    };
    const handleGeoError = () => {
      console.log('access geo location error>>>>>>>>>>>>>');
    };
    return navigator.geolocation.getCurrentPosition(
      handleGeoSucces,
      handleGeoError
    );
  };

  const deletePoint = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    marker?.map((m: any) => m?.setMap(null));
    const newPoints = points.filter(
      (_point: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    setPoints(newPoints);
    const newCourse = course;
    const newPolyline = polyline;
    const newMarker = marker.filter(
      (_m: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    marker[Number(evt.currentTarget.id)]?.setMap(null);
    const newCurrentDistance = currentDistance.filter(
      (_distance: any, i: number) => Number(evt.currentTarget.id) !== i
    );
    const newCurrentTime = currentTime.filter(
      (_time: any, i: number) => Number(evt.currentTarget.id) !== i
    );
    if (newPoints.length > 0) {
      newPolyline.setOptions({
        path: newPoints.map((p: any) => new naver.maps.LatLng(p.lat, p.lng)),
      });
      newCourse.setOptions({
        path: newPoints.map((p: any) => new naver.maps.LatLng(p.lat, p.lng)),
      });
      reloadRef.current = newPoints[newPoints.length - 1];
      setMarker(newMarker);
      setCourse(newCourse);
      setPolyline(newPolyline);
      setCourseDistance(newCourse.getDistance());
      setCourseTime(newCourse.getDistance() / 67);
      setCurrentDistance(newCurrentDistance);
      setCurrentTime(newCurrentTime);
    } else {
      newCourse?.setMap(null);
      newPolyline?.setMap(null);
      setMarkerStart(false);
      setMarker(newMarker);
      setCourse(null);
      setPolyline(null);
      setCourseDistance(0);
      setCourseTime(0);
      setCurrentDistance([]);
      setCurrentTime([]);
      reloadRef.current = 1;
    }
  };

  const deleteGeometryInfo = (idx: number) => {
    const tmpObject = geometryObjectList[idx];
    const newObjectList = geometryObjectList
      .map((object: any, i: number) => {
        if (i === idx) {
          object.courseMap?.setMap !== undefined &&
            object.courseMap?.setMap !== null &&
            object.courseMap?.setMap(null);
          object.circleMap?.setMap !== undefined &&
            object.circleMap?.setMap !== null &&
            object.circleMap?.setMap(null);
          object.marker?.setMap !== undefined &&
            object.marker?.setMap !== null &&
            object.marker?.setMap(null);
          object.type = '';
          object.points = [];
          object.courseMap = null;
          object.circleMap = null;
          object.location = [];
          object.marker = null;
          object.name = 'no-name';
          object.hiddenRandomCharacter = false;
        }
        return object;
      })
      .filter((object: any, i: number) => i !== idx);
    newObjectList.push({
      geometryId: generateUuidId(),
      type: '',
      points: [],
      circle: null,
      courseMap: null,
      circleMap: null,
      location: [],
    });
    setGeometryObjectList(newObjectList);

    let count = 0;
    geometryObjectList.map((object: any, i: number) => {
      if (object.type !== '') count += 1;
    });

    setGeometryIndex(count - 1);
    geometryIndexRef.current = count - 1;

    const newMapInfo = {
      geometryList: newObjectList,
      pointsContent: pointsContent,
    };
    setMapInfo(newMapInfo);

    const newData: any = {
      type: 1, // 1 : one, 2: all
      amdCode: selectedAmdCode,
      geometryId: tmpObject.geometryId,
    };
    deleteAmdGeometryMutation(newData);
  };

  const deleteAllGeometryInfo = () => {
    const newData: any = {
      type: 2, // 1 : one, 2: all
      amdCode: selectedAmdCode,
      geometryId: '0',
    };
    resetGeometryData();
    deleteAmdGeometryMutation(newData);
  };

  const changeGeometryInfo = (newSpot: any) => {
    const newData = {
      amdCode: selectedAmdCode,
      geometryId: geometryObjectList[editIdx].geometryId,
      name: newSpot.name,
      category: newSpot.category,
    };
    changeAmdGeometryInfoMutation(newData);
  };

  useMemo(() => {
    if (
      map.current !== null &&
      map.current !== undefined &&
      map.current.updateBy !== undefined
    ) {
      map.current.updateBy(
        new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        15
      );
    }
  }, [mapCenter]);

  useEffect(() => {
    if (contentMarkerShow && markerShow) {
      marker?.map((m: any) => m?.setMap(map.current));
      markerContent?.map((m: any) => m?.setMap(null));
    } else if (contentMarkerShow && !markerShow) {
      marker?.map((m: any) => m?.setMap(null));
      markerContent?.map((m: any) => m?.setMap(null));
    } else if (!contentMarkerShow) {
      marker?.map((m: any) => m?.setMap(null));
      markerContent?.map((m: any) => m?.setMap(map.current));
    }
  }, [contentMarkerShow]);

  useEffect(() => {
    console.log('maps>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    initMap();
    initGeometryObjectList();
    drawingMap();
    return () => {
      map.current = null;
      drawingPointsRef.current = null;
      tempCircleOpacityRef.current = 0.6;
      tempPolylineRef.current = null;
      tempCourseRef.current = null;
      tempMarkerRef.current = null;
      centerRef.current = null;
      radiusRef.current = null;
      reloadRef.current = null;
      addPointRef.current = null;
      changePointRef.current = null;
      tempPointsRef.current = [];
      stampCircleRef.current = 150;
    };
  }, [maps, disabled]);

  useEffect(() => {
    console.log('drawingOpacity>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    course?.setOptions({
      strokeOpacity: drawingOpacity ? 0 : 0.3,
    });
    course?.setMap(map.current);
    tempCircleOpacityRef.current = drawingOpacity ? 0 : 0.6;
  }, [drawingOpacity]);

  useEffect(() => {
    if (points.length > 1) {
      console.log('stampCircle>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(
          points[points.length - 1].lat,
          points[points.length - 1].lng
        ),
        radius: stampCircle,
        strokeOpacity: 0,
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setCourseWeight(newWeight);
      course?.setOptions({
        strokeWeight: newWeight,
      });
      polyline?.setOptions({
        strokeWeight: courseWeight / 15,
      });
      course?.setMap(map.current);
      polyline?.setMap(map.current);

      const newPoints = points.map((point: any) => {
        const newPoint = {
          lat: point.lat,
          lng: point.lng,
          radius: stampCircle,
        };
        return newPoint;
      });
      const newMapInfo = {
        courseStep: mapInfo.courseStep,
        courseTime: mapInfo.courseTime,
        courseDistance: mapInfo.courseDistance,
        points: newPoints,
        pointsContent: pointsContent,
        circle: mapInfo.circle,
      };
      setMapInfo(newMapInfo);
    }
  }, [stampCircle]);

  // 워크 코스 포인트 설정
  useEffect(() => {
    if (centerRef.current) {
      const newPoint = { lat: centerRef.current.y, lng: centerRef.current.x };
      const newPointList =
        geometryObjectList[geometryIndex] !== undefined &&
        geometryObjectList[geometryIndex].points !== undefined
          ? geometryObjectList[geometryIndex].points
          : [];
      const tmpPoints = newPointList.filter(
        (p: any, idx: number) =>
          idx === 0 || (idx !== 0 && p !== newPointList[0])
      );
      var newPoints = [...tmpPoints, newPoint];
      // if (newPointList.length > 0) {
      //   newPoints = [...newPoints, newPointList[0]];
      // }

      centerRef.current = null;
      let newCircleData: any = null;
      let newArea = 0;
      if (drawTypeRef?.current?.value === 2) {
        if (newPointList.length < 1) {
          tempPolyline = new naver.maps.Polygon({
            paths: newPoints.map(
              (point: any) => new naver.maps.LatLng(point.lat, point.lng)
            ),
            fillColor: FILL_COLOR,
            fillOpacity: 0.1,
            strokeWeight: 5,
            strokeColor: LINE_COLOR,
            strokeOpacity: 1,
            strokeStyle: 'solid',
            strokeLineCap: 'round',
            strokeLineJoin: 'round',
          });
          setCourse(tempPolyline);
          tempPolyline.setMap(null);

          if (geometryObjectList[geometryIndex] !== undefined) {
            const newObjectList = geometryObjectList;
            newObjectList[geometryIndex].points = newPoints;
            newObjectList[geometryIndex].courseMap = tempPolyline;
            newObjectList[geometryIndex].type = POLYGON_TYPE;
            newObjectList[geometryIndex].area = newArea;
            setGeometryObjectList(newObjectList);
          }
        } else {
          const prePoint = newPoints.length - 1;
          const newCourse = course;
          newCourse?.setOptions({
            paths: newPoints.map(
              (point: any) => new naver.maps.LatLng(point.lat, point.lng)
            ),
          });
          tempPolyline = new naver.maps.Polygon({
            map: map.current,
            paths: [
              new naver.maps.LatLng(
                newPoints[prePoint]?.lat,
                newPoints[prePoint]?.lng
              ),
              new naver.maps.LatLng(newPoint.lat, newPoint.lng),
            ],
            strokeOpacity: 0,
          });
          setCourse(newCourse);
          newArea = newCourse ? newCourse.getAreaSize() : 0;
          tempPolyline.setMap(null);

          if (geometryObjectList[geometryIndex] !== undefined) {
            const newObjectList = geometryObjectList;
            newObjectList[geometryIndex].points = newPoints;
            newObjectList[geometryIndex].courseMap = newCourse;
            newObjectList[geometryIndex].type = POLYGON_TYPE;
            newObjectList[geometryIndex].area = newArea;
            setGeometryObjectList(newObjectList);

            const newMapInfo = {
              geometryList: newObjectList,
              pointsContent: pointsContent,
            };
            setMapInfo(newMapInfo);

            if (markerStart === false && rightClickRef.current === true) {
              // naver.maps.Event.addListener(newCourse, 'click', () => {
              //   // 다각형의 채우기 옵션을 변경합니다
              //   console.log('--> polygon clicked !!!');
              // });
              saveCurrentGeometry();
            }
          }
        }

        const centerLoc: any = getGeometryObjectCenterLocation(newPoints);
        changeMarker(centerLoc);
      } else if (drawTypeRef?.current?.value === 3) {
        const newStamps = [...stamps, tempCircleInfoRef.current];
        const newCircle = new naver.maps.Circle({
          map: map.current,
          center: new naver.maps.LatLng(
            tempCircleInfoRef.current?.point[0] || 0,
            tempCircleInfoRef.current?.point[1] || 0
          ),
          radius: tempCircleInfoRef.current?.radius || 0,
          fillColor: FILL_COLOR,
          fillOpacity: 0.1,
          strokeWeight: 5,
          strokeColor: LINE_COLOR,
          strokeStyle: 'solid',
          strokeOpacity: 1,
        });
        newCircleData = {
          center: new naver.maps.LatLng(
            tempCircleInfoRef.current?.point[0] || 0,
            tempCircleInfoRef.current?.point[1] || 0
          ),
          radius: tempCircleInfoRef.current?.radius || 0,
        };
        newArea = newCircle.getAreaSize();
        setCircle(newCircle);
        setStamps(newStamps);
        setStampsCircle([...stampsCircle, newCircle]);
        tempCircleInfoRef.current = null;

        const tempMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(
            tempCircleInfoRef.current?.point[0] || 0,
            tempCircleInfoRef.current?.point[1] || 0
          ),
          map: map.current,
          icon: {
            content: `<div class='walkcourse_marker'>${geometryIndex}</div>`,
            anchor: new naver.maps.Point(18, 38),
          },
          clickable: false,
          draggable: false,
        });
        tempMarker.setMap(map.current);

        if (geometryObjectList[geometryIndex] !== undefined) {
          const newObjectList = geometryObjectList;
          newObjectList[geometryIndex].circle = newCircleData;
          newObjectList[geometryIndex].circleMap = newCircle;
          newObjectList[geometryIndex].type = CIRCLE_TYPE;
          newObjectList[geometryIndex].area = newArea;
          newObjectList[geometryIndex].marker = tempMarker;
          setGeometryObjectList(newObjectList);

          const newMapInfo = {
            geometryList: newObjectList,
            pointsContent: pointsContent,
          };
          setMapInfo(newMapInfo);

          if (markerStart === false && rightClickRef.current === true) {
            saveCurrentGeometry();
          }
        }
      }

      // if (geometryObjectList[geometryIndex] !== undefined) {
      //   const newObjectList = geometryObjectList;
      //   if (drawTypeRef?.current?.value === 2) {
      //     newObjectList[geometryIndex].points = newPoints;
      //     newObjectList[geometryIndex].courseMap = course;
      //     newObjectList[geometryIndex].type = POLYGON_TYPE;
      //   } else if (drawTypeRef?.current?.value === 3) {
      //     newObjectList[geometryIndex].circle = newCircleData;
      //     newObjectList[geometryIndex].circleMap = circle;
      //     newObjectList[geometryIndex].type = CIRCLE_TYPE;
      //   }
      //   newObjectList[geometryIndex].area = newArea;
      //   setObjectList(newObjectList);

      // console.log('--> markerStart:', newObjectList);
    }
  }, [markerStart]);

  useEffect(() => {
    if (centerRef.current) {
      console.log('markerCharacterStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', course);
      const newPoint = {
        lat: centerRef.current.y,
        lng: centerRef.current.x,
        radius: stampCircleRef.current,
      };
      const newPoints = [...pointsCharacter, newPoint];
      currentMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(newPoint?.lat, newPoint?.lng),
        map: map.current,
        icon: {
          content: `<div class='walkcourse_marker'>${newPoints.length}</div>`,
          anchor: new naver.maps.Point(13, 33),
        },
        clickable: true,
        draggable: false,
      });
      // naver.maps.Event.addListener(currentMarker, 'click', (e: any) => {
      //   console.log(e);
      // });
      setMarkerCharacter([...markerCharacter, currentMarker]);
      tempMarkerCharacterRef.current = [...markerCharacter, currentMarker];
      currentMarker?.setMap(null);
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(newPoint.lat, newPoint.lng),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
        strokeStyle: 'solid',
        fillColor: '#00a0e9',
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setPointsCharacter(newPoints);
      setCourseWeight(newWeight);
      centerRef.current = null;
    }
  }, [markerCharacterStart]);

  useEffect(() => {
    if (centerRef.current) {
      console.log('markerContentStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', course);
      const newPoint = {
        lat: centerRef.current.y,
        lng: centerRef.current.x,
        radius: stampCircleRef.current,
      };
      const newPoints = [...pointsContent, newPoint];
      currentMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(newPoint?.lat, newPoint?.lng),
        map: map.current,
        icon: {
          content: `<div class='walkcourse_marker'>${newPoints.length}</div>`,
          anchor: new naver.maps.Point(13, 33),
        },
        clickable: false,
        draggable: false,
      });
      // naver.maps.Event.addListener(currentMarker, 'click', (e: any) => {
      //   console.log(e);
      // });
      setMarkerContent([...markerContent, currentMarker]);
      currentMarker?.setMap(null);
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(newPoint.lat, newPoint.lng),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
        strokeStyle: 'solid',
        fillColor: '#00a0e9',
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setPointsContent(newPoints);
      setCourseWeight(newWeight);
      centerRef.current = null;
    }
  }, [markerContentStart]);

  // 전체 반경 설정
  useEffect(() => {
    console.log('circleStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    console.log('circleStart> radiusRef.current :', radiusRef.current);
    circle?.setMap(null);
    if (radiusRef.current) {
      const radius = new naver.maps.Polyline({
        map: map.current,
        path: radiusRef.current,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0.1,
        strokeStyle: 'solid',
      });
      const newCircle = new naver.maps.Circle({
        map: map.current,
        center: radiusRef.current[0],
        radius: radius.getDistance(),
        strokeWeight: 1,
        fillColor: '#FF8A65',
        fillOpacity: 0.15,
        strokeColor: '#FF8A65',
        strokeOpacity: 0.3,
      });
      setCircle(newCircle);
      radius.setMap(null);
      newCircle.setMap(null);
      radiusRef.current = null;
    }
  }, [circleStart]);

  useEffect(() => {
    course?.setMap(map.current);
    // const newObjectList = geometryObjectList;
    // if (newObjectList[geometryIndex] !== undefined) {
    //   newObjectList[geometryIndex].courseMap = course;
    //   newObjectList[geometryIndex].type = POLYGON_TYPE;
    //   setObjectList(newObjectList);
    // }
  }, [course]);

  useEffect(() => {
    circle?.setMap(map.current);
    // const newObjectList = geometryObjectList;
    // if (newObjectList[geometryIndex] !== undefined) {
    //   newObjectList[geometryIndex].circleMap = circle;
    //   newObjectList[geometryIndex].type = CIRCLE_TYPE;
    //   setObjectList(newObjectList);
    // }
  }, [circle]);

  useEffect(() => {
    if (course) {
      console.log('courseWeight>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      // const newCourse = course;
      // const newPolyline = polyline;
      // newCourse?.setOptions({
      //   strokeWeight: courseWeight,
      // });
      // newPolyline?.setOptions({
      //   strokeWeight: courseWeight / 15,
      // });
      // setCourse(newCourse);
      // setPolyline(newPolyline);
      // newCourse?.setMap(map.current);
      // newPolyline?.setMap(map.current);
    }
  }, [courseWeight]);

  useEffect(() => {
    console.log('points>>>>>>>>>>>>>>>>>>>>>>>>>');
    marker?.map((m: any, idx: number) =>
      m.setIcon({
        content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
        anchor: new naver.maps.Point(13, 33),
      })
    );
    marker?.map((m: any) => m?.setMap(map.current));
    const newMapInfo = {
      courseStep: 0,
      courseTime: courseTime,
      courseDistance: courseDistance,
      points: points,
      pointsContent: pointsContent,
      circle: mapInfo?.circle,
    };
    setMapInfo(newMapInfo);
  }, [points]);

  useEffect(() => {
    console.log('pointsContent>>>>>>>>>>>>>>>>>>>>>>>>>');
    markerContent?.map((m: any, idx: number) =>
      m.setIcon({
        content: `<div class='field_content_marker'>${idx + 1}</div>`,
        anchor: new naver.maps.Point(13, 33),
      })
    );
    markerContent?.map((m: any) => m?.setMap(map.current));
    const newMapInfo = {
      courseStep: 0,
      courseTime: courseTime,
      courseDistance: courseDistance,
      points: points,
      pointsContent: pointsContent,
      circle: mapInfo?.circle,
    };
    setMapInfo(newMapInfo);
  }, [pointsContent]);

  useEffect(() => {
    if (isEdit !== null) {
      console.log('isEdit>>>>>>>>>>>>>>>>>>>>>>>>>null');
      course?.setMap(null);
      polyline?.setMap(null);
      marker?.map((m: any) => m?.setMap(null));
      tempPolylineRef.current = polyline;
      tempCourseRef.current = course;
      tempMarkerRef.current = marker;
      tempMarkerRef.current?.map((m: any) => m?.setMap(map.current));
      const markers = document.querySelectorAll('div.walkcourse_marker');
      markers
        .item(changePointRef.current)
        .classList.add('walkcourse_marker_selected');
      tempCourseRef.current.setMap(map.current);
      tempPolylineRef.current.setMap(map.current);
    } else if (changePointRef.current != null) {
      console.log('isEdit>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>notnull');
      tempMarkerRef.current?.map((m: any) => m?.setMap(null));
      changePointRef.current = null;
      const newCourse = new naver.maps.Polyline({
        path: tempPointsRef.current?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        strokeWeight: courseWeight,
        strokeColor: '#2681FF6F',
        strokeOpacity: 0.5,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const newPolyline = new naver.maps.Polyline({
        path: tempPointsRef.current?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        strokeWeight: courseWeight / 15,
        strokeColor: '#2681FF',
        strokeOpacity: 1,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const newMarker: any = [];
      tempPointsRef.current?.map((q: any, idx: number) => {
        newMarker.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
      });
      setPoints(tempPointsRef.current);
      setMarker(newMarker);
      setCourse(newCourse);
      setPolyline(newPolyline);
      reloadRef.current = tempPointsRef.current
        ? tempPointsRef.current[tempPointsRef.current.length - 1]
        : null;
      newCourse?.setMap(map.current);
      newPolyline?.setMap(map.current);
      newMarker?.map((m: any) => m?.setMap(map.current));
    }
  }, [isEdit]);

  useMemo(() => {
    if (tempCourseRef.current && pointChange) {
      console.log('pointchange>>>>>>>>>>>>>>>>>>>>>>');
      const tempTime: any = [];
      const tempDistance: any = [];
      tempPointsRef.current?.map((q: any, idx: number) => {
        tempPolyline = new naver.maps.Polyline({
          path: [
            new naver.maps.LatLng(
              tempPointsRef.current[idx - 1]?.lat,
              tempPointsRef.current[idx - 1]?.lng
            ),
            new naver.maps.LatLng(q.lat, q.lng),
          ],
          map: map.current,
          strokeOpacity: 0,
        });
        tempPolyline?.getDistance() &&
          tempTime.push(tempPolyline.getDistance() / 67);
        tempDistance.push(tempPolyline?.getDistance());
      });
      setCurrentDistance(tempDistance);
      setCurrentTime(tempTime);
      setCourseDistance(tempCourseRef.current.getDistance());
      setCourseTime(tempCourseRef.current.getDistance() / 67);
      console.log(
        'erer>>>>>>>>>>>>>',
        new naver.maps.LatLng(
          tempPointsRef.current[-1]?.lat,
          tempPointsRef.current[-1]?.lng
        )
      );
      tempPolyline?.setMap(null);
      setPointChange(false);
    }
  }, [pointChange]);

  useEffect(() => {
    if (tempPoint.length > 0) {
      console.log('tempPoint>>>>>>>>>>>>>>>>>>>>>>');
      course?.setMap(null);
      polyline?.setMap(null);
      marker.map((m: any) => m?.setMap(null));
      markerContent?.map((m: any) => m?.setMap(null));
      const tempstime: any = [];
      const tempsdistance: any = [];
      tempPoint?.map((q: any, idx: number) => {
        tempPolyline = new naver.maps.Polyline({
          path: [
            new naver.maps.LatLng(
              tempPoint[idx - 1]?.lat,
              tempPoint[idx - 1]?.lng
            ),
            new naver.maps.LatLng(q.lat, q.lng),
          ],
          map: map.current,
          strokeOpacity: 0,
        });
        tempPolyline?.getDistance() &&
          tempstime.push(tempPolyline.getDistance() / 67);
        tempsdistance.push(tempPolyline?.getDistance());
      });
      tempPolyline?.setMap(null);
      setCurrentDistance(tempsdistance);
      setCurrentTime(tempstime);
      ncourse = new naver.maps.Polyline({
        path: tempPoint?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        map: map.current,
        strokeWeight: courseWeight,
        strokeColor: '#2681FF6F',
        strokeOpacity: drawingOpacity ? 0 : 0.3,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      tempPolyline = new naver.maps.Polyline({
        path: tempPoint.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        map: map.current,
        strokeWeight: courseWeight / 15,
        strokeColor: '#2681FF',
        strokeOpacity: 1,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const temps: any = [];
      tempPoint?.map((q: any, idx: number) => {
        temps.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
        // naver.maps.Event.addListener(temps[idx], 'click', (e: any) => {
        //   console.log(e);
        // });
      });
      setMarker(temps);
      setPolyline(tempPolyline);
      setCourse(ncourse);
      setCourseDistance(ncourse.getDistance());
      setCourseTime(ncourse.getDistance() / 67);
      setPoints(tempPoint);
      setTempPoints([]);
    }
  }, [tempPoint]);

  useEffect(() => {
    if (tempPointsContent.length > 0) {
      console.log('tempPointsContent>>>>>>>>>>>>>>>>>>>>>>');
      markerContent?.map((m: any) => m?.setMap(null));

      const temps: any = [];
      tempPointsContent?.map((q: any, idx: number) => {
        temps.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='field_content_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
        // naver.maps.Event.addListener(temps[idx], 'click', (e: any) => {
        //   console.log(e);
        // });
      });
      setMarkerContent(temps);
      setPointsContent(tempPointsContent);
      setTempPoints([]);
    } else {
      setMarkerContent([]);
      setPointsContent([]);
      setTempPoints([]);
    }
  }, [tempPointsContent]);

  useEffect(() => {
    if (gpxData.length > 0) {
      const result: any = [];

      gpxData.forEach((element: any, idx: number) => {
        result.push({
          lat: element[0],
          lng: element[1],
          radius: stampCircleRef.current,
        });
      });

      setTempPoints(result);
      setUploadFile('');
      setMapCenter([result[0]?.lat, result[0]?.lng]);
    }
  }, [gpxData]);

  useEffect(() => {
    if (selectedAmdCode !== '') {
      drawingPointsRef.current = null;
      setSelectInsertCharacter(false);
      setSelectInsertContent(false);
      resetGeometryData();
      resetContentData();

      amdGeometryRefetch();
    }
  }, [selectedAmdCode]);

  useEffect(() => {
    console.log('fieldData Data>>>>>>>>>>>>>>>>>>>>>>>>>:', fieldData);
    setSelectInsertCharacter(false);
    setSelectInsertContent(false);
    if (fieldData && fieldData.gpsData && fieldData.gpsData.length > 0) {
      const newTempPoints = fieldData.gpsData;
      setTempPoints(newTempPoints);

      var newRadius = 9999999;
      fieldData.gpsData.map((gps: any) => {
        newRadius = gps.radius < newRadius ? gps.radius : newRadius;
      });
      setStampCircle(newRadius);
      stampCircleRef.current = newRadius;
    }
    if (fieldData) {
      setSelectedGeometryId(fieldData.geometryId);
      setSelectedPath(fieldData.path);
      setSelectedAmdCode(fieldData.amdCode);
      if (fieldData.amdCode === selectedAmdCode) {
        changeFitBoundByPath(fieldData.path);
        scrollingToItem(geometryObjectList);
      }
    }

    if (fieldData) {
      totalAreaRef.current = squareMetersToSquareKilometers(
        fieldData.totalArea
      );
    }
  }, [fieldData]);

  return (
    <div style={{ margin: '2rem 0' }}>
      <Box className='flex_center'>
        <div
          id={elementId}
          ref={map}
          style={{
            width: '70%',
            height: '70vh',
            borderRadius: '10px',
            minWidth: '900px',
          }}
          onContextMenu={e => {
            e.preventDefault();
          }}
        >
          {selectInsertCharacter === false && selectInsertContent === false && (
            <OverlayView sx={{ justifyContent: 'end' }}>
              <Box>
                <TextField
                  select
                  sx={{
                    width: '10rem',
                    ml: '1rem',
                    backgroundColor: '#ffffff',
                  }}
                  inputRef={drawTypeRef}
                  value={drawType}
                  onChange={evt => setDrawType(Number(evt.target.value))}
                >
                  <MenuItem value={2}>다각형 그리기</MenuItem>
                  <MenuItem value={3}>원 그리기</MenuItem>
                </TextField>
                <Button
                  sx={{ ml: '1rem' }}
                  color='info'
                  variant='contained'
                  onClick={() => {
                    setDialogTitle('생성한 필드를 전부 다 삭제하시겠습니까?');
                    setOpenConfirm(4); // geometry all delete
                  }}
                >
                  초기화
                </Button>
              </Box>
            </OverlayView>
          )}
        </div>
        {selectInsertCharacter === false && selectInsertContent === false && (
          <ContentsPaper>
            <Box className='walkcourse_content_header'>공간 정보</Box>
            <Box className='flex_between'>
              <Button
                disabled={disabled}
                size='small'
                color='error'
                onClick={() => {
                  setDialogTitle('생성한 필드를 전부 다 삭제하시겠습니까?');
                  setOpenConfirm(4); // geometry all delete
                }}
              >
                전체 삭제
              </Button>
            </Box>
            <Divider sx={{ m: 0, p: 0 }} />
            <Box sx={{ p: '1rem', overflowY: 'scroll', height: '60vh' }}>
              {geometryObjectList
                .filter(
                  (geometryObject: any, idx: number) =>
                    geometryObject.type !== ''
                )
                .map((geometryObject: any, idx: number) => (
                  <Box key={`geometryObject_${idx}`}>
                    <Box
                      sx={{
                        backgroundColor:
                          geometryObject.geometryId === selectedGeometryId
                            ? '#a4f1f9'
                            : '#ffffff',
                      }}
                      key={`geometryObject_item_${idx}`}
                      ref={el => {
                        itemRefs.current[idx] = el;
                      }} // 각 항목에 ref 연결
                      style={{ padding: '0 1rem 1rem' }}
                      onClick={evt => {
                        evt.stopPropagation();
                        setSelectedGeometryId(geometryObject.geometryId);
                        changeFitBoundByObject(geometryObject);
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          mb: '1rem',
                        }}
                        key={idx.toString()}
                      >
                        <Box className='flex_center'>
                          <Box
                            id={idx.toString()}
                            aria-hidden
                            className='walkcourse_marker'
                            sx={{ mr: '1.5rem' }}
                          >
                            {idx + 1}
                          </Box>
                          <Box>{geometryObject.name}</Box>
                          <Button
                            id={idx.toString()}
                            size='small'
                            color='info'
                            onClick={evt => {
                              setEditIdx(idx);
                              setOpenSpot(true);
                            }}
                          >
                            <EditOutlinedIcon />
                          </Button>
                        </Box>
                        <div>
                          {editIdx !== idx && (
                            <IconButton
                              id={idx.toString()}
                              size='small'
                              sx={{ ml: '1rem', pt: '.2rem' }}
                              onClick={evt => {
                                evt.stopPropagation();
                                setSelectedIndex(idx);
                                setDialogTitle(
                                  `${idx + 1}번 필드를 삭제하시겠습니까?`
                                );
                                setOpenConfirm(1); // geometry delete
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      </Box>
                      <Typography sx={{ fontSize: '1.06rem' }}>
                        {geometryObject.geometryId}
                      </Typography>
                    </Box>
                    <Divider sx={{ p: 0, m: 0 }} />
                  </Box>
                ))}
            </Box>
          </ContentsPaper>
        )}
      </Box>
      <SwLoadGPX
        type={1}
        open={openGpx}
        onclose={() => setOpenGpx(false)}
        gpxData={gpxData}
        setGpxData={setGpxData}
        radius={stampCircleRef.current}
      />
      <GeofencingInfoView
        open={openSpot}
        spot={geometryObjectList[editIdx]}
        onClose={() => setOpenSpot(false)}
        onSave={spot => {
          changeGeometryInfo(spot);
          setOpenSpot(false);
        }}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents={dialogTitle}
        open={openConfirm !== 0}
        onClose={() => setOpenConfirm(0)}
        onConfirm={() => {
          openConfirm === 1
            ? deleteGeometryInfo(selectedIndex)
            : openConfirm === 4
            ? deleteAllGeometryInfo()
            : '';
          setOpenConfirm(0);
        }}
        confirm={1}
      />
    </div>
  );
};

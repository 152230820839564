import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import {
  DefaultExercise,
  IExercise,
  IExerciseForm,
} from '../../../models/challenge';
import { SwChallengeHomeTrainingItem } from '../../commonComponent/SwChallengeHomeTrainingItem';
import { ButtonBox } from './ChallengeStyles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_UPLOAD_FILE_SIZE,
} from '../../commonComponent/program/SwProgramCommon';
import { getFileSize } from '../../../common/helperProgram';
import { uploadFile } from '../../../common/api/ApiUpload';
import { RESULT_OK } from '../../../common/resultCode';
import {
  getChallengeHomeTraining,
  registerChallengeHomeTraining,
} from '../../../common/api/ApiChallenge';
import { hometrainingType } from '../../../common/helper';
import { KEY_CHALLENGE_HOME_TRAINING_CONTENT_LIST } from '../../../common/key';
import { exerciseDataState } from '../../../common/atom';

interface Props {
  challengeInfo: any;
}

export const ChallengeHomeTraining: React.FC<Props> = ({ challengeInfo }) => {
  const [exerciseData, setExerciseData] = useRecoilState(exerciseDataState);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const setUpHomeTraining = (newHometraining: IExercise[]) => {
    const newExerciseData: any[] = [];

    exerciseData.map((item, idx) => {
      const newItem = newHometraining.find(
        exercise => exercise.exerciseType - 1 === idx
      );
      if (newItem) {
        newExerciseData.push({ ...newItem });
        return;
      }
      newExerciseData.push({ ...DefaultExercise });
    });

    setExerciseData([...newExerciseData]);

    const newCheckedButtons = newHometraining.map(
      (item: any) => hometrainingType[item.exerciseType - 1].id
    );
    setCheckedButtons([...newCheckedButtons]);
  };
  const {
    data,
    isLoading,
    refetch: challengeHomeTrainingContentRefetch,
  } = useQuery(
    KEY_CHALLENGE_HOME_TRAINING_CONTENT_LIST,
    () => {
      if (challengeInfo && challengeInfo.challengeId.length > 0) {
        const newData: any = {
          challengeId: challengeInfo.challengeId,
        };
        return getChallengeHomeTraining(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (
          res !== null &&
          res.homeTraining !== undefined &&
          res.homeTraining !== null
        ) {
          setUpHomeTraining(res.homeTraining);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeHomeTrainingMutation,
    isError: isMutateError,
  } = useMutation(registerChallengeHomeTraining, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('운동 콘텐츠가 등록되었습니다.');
      } else {
        setOpenSnackbar(true);
        setAlertMessage(
          `운동 콘텐츠를 등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '운동 콘텐츠 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      if (checkedButtons.length === 3) {
        setErrorMessage('최대 3개까지 선택가능합니다.');
        setTimeout((_: any) => {
          setErrorMessage('');
        }, 2000);
        return;
      }
      const newData = [...checkedButtons, id];
      setCheckedButtons(newData);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));

      const uncheckedIdx = hometrainingType.findIndex(item => item.id === id);
      const newExerciseData = [...exerciseData];
      newExerciseData[uncheckedIdx] = { ...DefaultExercise };
      setExerciseData(newExerciseData);
    }
    setErrorMessage('');
  };

  const addChallengeHomeTraining = (newExerciseData: IExercise[]) => {
    const tmpExerciseData = newExerciseData.filter(
      (item: IExercise, idx: number) => item.goalType1 !== 0
    );
    const newData: any = {
      challengeId: challengeInfo.challengeId,
      homeTrainings: tmpExerciseData,
    };
    registerChallengeHomeTrainingMutation(newData);
  };

  async function uploadMultipleFiles() {
    const newExerciseData = await Promise.all(
      exerciseData.map(async (item: IExercise, idx: number) => {
        const updatedMethodList = await Promise.all(
          item.methodList.map(async (formItem: IExerciseForm, i: number) => {
            const newFormItem = { ...formItem };
            var newUploadFile: any = null;
            if (
              formItem.imageFile !== undefined &&
              formItem.imageFile !== null
            ) {
              newUploadFile = await uploadFile('challenge', formItem.imageFile);
            }
            newFormItem.uploadUrl =
              formItem.imageFile !== null && newUploadFile !== null
                ? newUploadFile.data.uploadUrl.file.path
                : formItem.image;
            return newFormItem;
          })
        );
        return { ...item, methodList: updatedMethodList };
      })
    );
    setExerciseData(newExerciseData);
    addChallengeHomeTraining(newExerciseData);
  }

  const checkValid = (newExerciseData: IExercise) => {
    var val = 0;

    if (val === 0 && newExerciseData.goalType1 === 0) val = 1;
    if (val === 0 && newExerciseData.goalType2 === 0) val = 2;
    if (val === 0 && newExerciseData.breakTime === 0) val = 3;
    if (val === 0 && newExerciseData.exerciseArea.trim() === '') val = 4;
    if (val === 0 && newExerciseData.videoCode.trim() === '') val = 5;
    if (
      val === 0 &&
      newExerciseData.sequence > 0 &&
      newExerciseData.afterBreakTime === 0
    )
      val = 6;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
        case 2:
          message = '목표 세트를 선택해주세요.';
          break;
        case 3:
        case 6:
          message = '휴식 시간을 선택해주세요';
          break;
        case 4:
          message = '운동 부위를 입력해주세요';
          break;
        case 5:
          message = '참고 영상을 입력해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    return val;
  };

  const checkExerciseData = () => {
    const result: number[] = [];
    if (checkedButtons.length > 0) {
      checkedButtons.map((item: any, idx: number) => {
        const newIdx = hometrainingType.findIndex(
          newItem => newItem.id === item
        );
        const newExerciseData = exerciseData[newIdx];
        if (checkValid(newExerciseData) !== 0) {
          result.push(idx);
        }
      });
    }

    if (result.length > 0) return false;

    const fileSize: number[] = [];
    exerciseData.map((item: IExercise, idx: number) => {
      item.methodList.map((formItem: IExerciseForm, i: number) => {
        if (
          formItem.imageFile !== undefined &&
          formItem.imageFile !== null &&
          getFileSize(formItem.imageFile) >= MAX_UPLOAD_FILE_SIZE
        )
          fileSize.push(i);
      });
    });
    if (fileSize.length > 0) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return false;
    }
    console.log('----> exerciseData:', exerciseData);
    uploadMultipleFiles();
    return true;
  };

  useEffect(() => {
    if (challengeInfo === undefined || challengeInfo === null) {
      setExerciseData([
        { ...DefaultExercise },
        { ...DefaultExercise },
        { ...DefaultExercise },
        { ...DefaultExercise },
      ]);
      challengeHomeTrainingContentRefetch();
    }
  }, [challengeInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Box>
        <Typography className='title'>운동 선택 (최대 3개)</Typography>
        <FormGroup sx={{ flexDirection: 'row', ml: '1rem' }}>
          <FormControlLabel
            control={
              <Checkbox
                value={hometrainingType[0]}
                checked={checkedButtons.includes(hometrainingType[0].id)}
                onChange={evt =>
                  changeHandler(
                    evt.currentTarget.checked,
                    hometrainingType[0].id
                  )
                }
              />
            }
            label={hometrainingType[0].name}
          />
          <FormControlLabel
            control={
              <Checkbox
                value={hometrainingType[1]}
                checked={checkedButtons.includes(hometrainingType[1].id)}
                onChange={evt =>
                  changeHandler(
                    evt.currentTarget.checked,
                    hometrainingType[1].id
                  )
                }
              />
            }
            label={hometrainingType[1].name}
          />
          <FormControlLabel
            control={
              <Checkbox
                value={hometrainingType[2]}
                checked={checkedButtons.includes(hometrainingType[2].id)}
                onChange={evt =>
                  changeHandler(
                    evt.currentTarget.checked,
                    hometrainingType[2].id
                  )
                }
              />
            }
            label={hometrainingType[2].name}
          />
          <FormControlLabel
            control={
              <Checkbox
                value={hometrainingType[3]}
                checked={checkedButtons.includes(hometrainingType[3].id)}
                onChange={evt =>
                  changeHandler(
                    evt.currentTarget.checked,
                    hometrainingType[3].id
                  )
                }
              />
            }
            label={hometrainingType[3].name}
          />
        </FormGroup>
        {errorMessage !== '' && (
          <Box>
            <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
          </Box>
        )}
        <Divider />
        {checkedButtons
          .map((id: any, idx: number) => {
            const newItem = hometrainingType.filter(
              (item: any) => item.id === id
            );
            return newItem !== null && newItem.length > 0 ? newItem[0] : null;
          })
          .filter((item: any) => item !== null)
          .map((item: any, idx: number) => (
            <SwChallengeHomeTrainingItem sequence={idx + 1} exercise={item} />
          ))}
      </Box>
      <ButtonBox className='flex_end ' sx={{ mt: '2rem' }}>
        <Box>
          <Button
            disabled={checkedButtons.length === 0}
            variant='contained'
            onClick={() => checkExerciseData()}
          >
            정보 저장
          </Button>
        </Box>
      </ButtonBox>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </Paper>
  );
};

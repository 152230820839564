import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { ChallengeTypeBox } from '../ChallengeStyles';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { SwStampTourView } from '../../../commonComponent/SwStampTourView';
import { GridTitle } from '../../../localstore/localstoreManagement/LocalstoreStyles';
import { newUuidId, SwUuidCreate } from '../../../commonComponent/SwUuidCreate';
import { SwFileSetting } from '../../../commonComponent/SwFileSetting';
import { SwSearchContentManageTarget } from '../../../commonComponent/SwSearchContentManageTarget';
import { SwSurveyCreate } from '../../../commonComponent/program/SwSurveyCreate';
import { SwQuizCreate } from '../../../commonComponent/program/SwQuizCreate';
import {
  requiredAllAnswerSts,
  selectedConnectedMissionItemsState,
} from '../../../../common/atom';
import {
  getProgramMissionDetailInfo,
  getProgramMissionQnaQuestionList,
  putProgramMissionQnaQuestionIsRequired,
} from '../../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../../common/resultCode';
import {
  KEY_PROGRAM_MISSION_QNA_DETAIL_INFO,
  KEY_PROGRAM_MISSION_QNA_QUESTION_LIST,
} from '../../../../common/key';
import { checkNaN } from '../../../../common/helper';
import { DefaultQuestionAnswer } from '../../../../models/challenge';

const Item = styled(Box)({
  textAlign: 'left',
  fontWeight: 500,
});

const GridCell = styled(Grid)({
  borderLeft: 'none',
  padding: '.7em',
});

interface Props {
  questionAnswerInfo: any;
  setQuestionAnswerInfo: Dispatch<React.SetStateAction<any>>;
  qnaType: number;
}

export const QnaMissionView: React.FC<Props> = ({
  questionAnswerInfo,
  setQuestionAnswerInfo,
  qnaType,
}) => {
  const [selectedConnectedMissionItems, setSelectedConnectedMissionItems] =
    useRecoilState(selectedConnectedMissionItemsState);

  const queryClient = useQueryClient();
  const { loginVal } = useSession();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [requiredAllAnswerInfo, setRequiredAllAnswerInfo] =
    useRecoilState(requiredAllAnswerSts);
  const titleRef = useRef<any>();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [achieveCount, setAchieveCount] = useState<number>(1);
  const [retryAnswer, setRetryAnswer] = useState<string>('retry-permit');
  const [attendDay, setAttendDay] = useState<string>('day-all');
  const [isRepeated, setIsRepeated] = useState<number>(0);
  const [minAnswer, setMinAnswer] = useState<number>(1);
  const [requiredAllAnswer, setRequiredAllAnswer] = useState<number>(-1);
  const [requiredAllAnswerSwitch, setRequiredAllAnswerSwitch] =
    useState<number>(-1);
  const [questionAnswerTotalPoint, setQuestionAnswerTotalPoint] =
    useState<number>(0);
  const [content, setContent] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openQnaConfirm, setOpenQnaConfirm] = useState<number>(0);
  const [missionId, setMissionId] = useState<string>('');
  const [weekId, setWeekId] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [showAttenDay, setShowAttendDay] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<number>(0);
  const [deleteAll, setDeleteAll] = useState<boolean>(false);
  const [forcedSave, setForcedSave] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [hasWeekAndTime, setHasWeekAndTime] = useState<string>('no');
  const [attendWeeks, setAttendWeeks] = useState<any[]>([]);
  const [attendTimes, setAttendTimes] = useState<any[]>([]);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [targetTitle, setTargetTitle] = useState<string>('');

  const reset = () => {
    setTabValue(0);
    setTitle('');
    setPoint(10);
    setLink('');
    setQuestionList([]);
    setMinAnswer(1);
    setRequiredAllAnswer(-1);
    setRequiredAllAnswerSwitch(-1);
    setIsRepeated(0);
    setQuestionAnswerTotalPoint(0);
    setRetryAnswer('retry-deny');
  };

  const makeDummyQuestion = (
    newType: number,
    newMissionId: string,
    newWeekId: string | undefined
  ) => {
    const newOption = [{ body: '', isCorrect: 0, isCustomAnswer: 0 }];
    const newQuestion = {
      image: null,
      missionId: newMissionId,
      optionList: JSON.stringify(newOption),
      seq: 0,
      title: '',
      type: 1,
      weekId: newWeekId,
    };
    setQuestionList([newQuestion]);
  };

  const {
    data: detailInfo,
    isLoading,
    refetch: programMissionRefetch,
  } = useQuery(
    KEY_PROGRAM_MISSION_QNA_DETAIL_INFO,
    () => {
      console.log('missionId: ', missionId);
      if (missionId.length > 0) {
        const newData: any = {
          type: 'qna',
          missionId: missionId,
        };
        return getProgramMissionDetailInfo(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('mission detailInfo :', res);
        if (res !== null && res.mission !== null && res.mission.qna !== null) {
          res.mission.qna.title !== undefined &&
            setTitle(res.mission.qna.title);
          res.mission.qna.successScore !== undefined &&
            setPoint(res.mission.qna.successScore);
          setIsRepeated(
            res.mission.qna.isRepeated === null
              ? 0
              : res.mission.qna.isRepeated === true ||
                res.mission.qna.isRepeated === 1
              ? 1
              : 0
          );
          setAttendDay('day-all');
          if (res.mission.qna.type !== undefined) {
            res.mission.qna.type === 1 ? setTabValue(0) : setTabValue(1);
          }
          setQuestionList(
            res.mission.questionList !== null ? res.mission.questionList : []
          );
          setQuestionAnswerTotalPoint(
            res.mission.questionList !== null
              ? res.mission.questionList.length
              : 0
          );
          // if (
          //   res.mission.questionList !== null &&
          //   res.mission.questionList.length === 1
          // ) {
          //   setIsEdit(2);
          // }
          if (
            res.mission.questionList !== null &&
            res.mission.questionList.length === 0
          ) {
            if (
              questionAnswerInfo === undefined ||
              questionAnswerInfo.length === 0
            )
              makeDummyQuestion(qnaType, missionId, questionAnswerInfo.weekId);
            // setIsEdit(2);
          }

          setMinAnswer(res.mission.qna.minAnswer);
        } else if (missionId.length > 0) {
          reset();
          makeDummyQuestion(qnaType, missionId, questionAnswerInfo.weekId);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newMissionId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_MISSION_QNA_DETAIL_INFO, () => {
      const newData: any = {
        type: 'qna',
        missionId: newMissionId,
      };
      return getProgramMissionDetailInfo(newData);
    });
  };

  const {
    mutate: putMissionQnaQuestionIsRequiredMutate,
    isError: isRequriedMutateError,
  } = useMutation(putProgramMissionQnaQuestionIsRequired, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('변경되었습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const changeRequiredAnswer = (value: number) => {
    setRequiredAllAnswer(value);

    const newIds = questionList.map((item: any) => item.questionId);

    setRequiredAllAnswerInfo(prev => ({
      ...prev,
      ids: value === 1 ? [...newIds] : [''],
      requiredAll: value,
    }));

    const newData = {
      questionIds: newIds,
      isRequired: value === 1 ? 1 : 0,
    };
    putMissionQnaQuestionIsRequiredMutate(newData);
  };

  const prefetchQuestionList = (newMissionId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_MISSION_QNA_QUESTION_LIST, () => {
      const newData: any = {
        missionId: newMissionId,
      };
      return getProgramMissionQnaQuestionList(newData);
    });
  };

  const reloadMission = (newMissionId: string) => {
    prefetchQuestionList(newMissionId);
  };

  const changeRetryAnswer = (value: string) => {
    value === 'retry-permit'
      ? setRetryAnswer('retry-permit')
      : setRetryAnswer('retry-deny');
    setSelectedConnectedMissionItems({
      ...selectedConnectedMissionItems,
      questionAnswer: {
        ...questionAnswerInfo,
        retryAnswer: value === 'retry-permit' ? 1 : 0,
      },
    });
  };

  useEffect(() => {
    console.log('missionId :', missionId);
    if (missionId !== undefined && missionId !== null && missionId.length > 0) {
      programMissionRefetch();
      questionAnswerInfo.isEdit === 2
        ? setIsEdit(2)
        : setIsEdit(disabled ? 1 : 2);
    }
  }, [missionId]);

  useEffect(() => {
    if (questionAnswerInfo !== undefined && questionAnswerInfo !== null) {
      console.log('questionAnswerInfo -> :', questionAnswerInfo);
      setMissionId(questionAnswerInfo.qnaId);
      setForcedSave(false);
      setMinAnswer(questionAnswerInfo.goalPoint);
      setQuestionAnswerTotalPoint(questionAnswerInfo.questionCount);
      setRetryAnswer(
        questionAnswerInfo.retryAnswer === 0 ? 'retry-deny' : 'retry-permit'
      );

      setDisabled(questionAnswerInfo.attendUserCount);
      setRequiredAllAnswerSwitch(-1);

      makeDummyQuestion(qnaType, questionAnswerInfo.qnaId, undefined);

      setSelectedConnectedMissionItems({
        ...selectedConnectedMissionItems,
        questionAnswer: { ...questionAnswerInfo },
      });
    } else {
      const newId = newUuidId();
      setMissionId(newId);
      setForcedSave(false);
      setMinAnswer(0);
      setQuestionAnswerTotalPoint(0);

      setDisabled(false);
      setRequiredAllAnswerSwitch(-1);

      makeDummyQuestion(qnaType, newId, undefined);

      setQuestionAnswerInfo({
        qnaId: newId,
        retryAnswer: 0,
        cmd: 'new',
      });

      setSelectedConnectedMissionItems({
        ...selectedConnectedMissionItems,
        questionAnswer: { ...DefaultQuestionAnswer, qnaId: newId },
      });
    }
  }, [questionAnswerInfo]);

  return (
    <>
      <Paper sx={{ backgroundColor: '#EFEFF3', mt: '1rem' }}>
        <Box sx={{ padding: '1rem 1rem' }}>
          <Typography className='title'>재답변 허용 여부</Typography>
          <RadioGroup
            row
            aria-labelledby='qna-retry-row-radio-buttons-group-label'
            name='retry-row-radio-buttons-group'
            value={retryAnswer}
            onChange={evt => {
              changeRetryAnswer(evt.currentTarget.value);
            }}
          >
            <FormControlLabel
              value='retry-permit'
              control={<Radio />}
              label='허용'
            />
            <FormControlLabel
              value='retry-deny'
              control={<Radio />}
              label='미허용'
            />
          </RadioGroup>
        </Box>
        <Box sx={{ padding: '1rem 1rem' }}>
          {qnaType === 1 ? (
            <>
              <SwSurveyCreate
                requiredAllAnswer={requiredAllAnswer}
                missionInfo={questionAnswerInfo}
                questionList={questionList}
                setQuestionList={setQuestionList}
                isEdit={isEdit}
                onReloadMission={reloadMission}
                forcedSave={forcedSave}
                disabled={disabled}
                onDisabledButton={type => setDisabledButton(type)}
              />
            </>
          ) : (
            <>
              <Box>
                <Typography className='title'>달성 인정 정답 개수</Typography>
                <Box className='flex_start'>
                  <TextField
                    sx={{ width: '5rem' }}
                    value={minAnswer}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      const newValue = checkNaN(evt.target.value, minAnswer);
                      setMinAnswer(
                        newValue > 0 && newValue <= questionList.length
                          ? newValue
                          : newValue > questionList.length
                          ? questionList.length
                          : 0
                      );
                    }}
                  />
                  <Typography sx={{ ml: '.5rem' }}>
                    / {questionList !== null && questionList.length}
                  </Typography>
                </Box>
                <Divider />
              </Box>
              <SwQuizCreate
                missionInfo={questionAnswerInfo}
                questionList={questionList}
                setQuestionList={setQuestionList}
                isEdit={isEdit}
                onReloadMission={reloadMission}
                forcedSave={forcedSave}
                disabled={disabled}
                onDisabledButton={type => setDisabledButton(type)}
              />
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};

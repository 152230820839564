import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import {
  KEY_CHALLENGE_AUDIO_CONTENT,
  KEY_CHALLENGE_AUDIO_CONTENT_LIST,
  KEY_CHALLENGE_PHOTO_CERTIFICATE_INFO,
  KEY_CHALLENGE_QUESTON_ANSWER_INFO,
  KEY_STAMPTOUR_REQUEST_DETAIL,
} from '../../../../common/key';
import { getStamptourRequestDetail } from '../../../../common/api/ApiStamptour';
import { ConnectMissionTable } from './ConnectMissionTable';
import { AudioContentConnectedMissionView } from './AudioContentConnectedMissionView';
import { PhotoCertificationMissionView } from './PhotoCertificationMissionView';
import { QnaMissionView } from './QnaMissionView';
import {
  getChallengeConnectedAudioContent,
  getChallengeConnectedPhotoCertificate,
  getChallengeConnectedQuestionAnswer,
  registerChallengeConnectMission,
} from '../../../../common/api/ApiChallengeTour';
import { RESULT_OK } from '../../../../common/resultCode';
import { uploadFile } from '../../../../common/api/ApiUpload';
import { newUuidId } from '../../../commonComponent/SwUuidCreate';
import {
  selectedConnectedMissionItemsState,
  selectedConnectedMissionState,
} from '../../../../common/atom';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';

const connectMissionTypeList = [
  { type: 1, title: '오디오' },
  { type: 2, title: '사진인증' },
  { type: 3, title: '선택인증' },
  { type: 4, title: '설문조사' },
  { type: 5, title: '퀴즈' },
];

interface Props {
  challengeId: string;
  mission: any;
}

export const ConnectMissionView: React.FC<Props> = ({
  challengeId,
  mission,
}) => {
  const { loginVal } = useSession();
  const selectedConnectedMission = useRecoilValue(
    selectedConnectedMissionState
  );
  const selectedConnectedMissionItems = useRecoilValue(
    selectedConnectedMissionItemsState
  );
  const [stamptourId, setStamptourId] = useState<string>('');
  const [stampId, setStampId] = useState<string>('0');
  const [stampList, setStampList] = useState<any[]>([]);
  const [audioContent, setAudioContent] = useState<any>();
  const [photoCertificate, setPhotoCertificate] = useState<any>();
  const [questionAnswerInfo, setQuestionAnswerInfo] = useState<any>();
  const [connectMissionType, setConnectMissionType] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const { refetch: stamptourRequestDetailRefetch } = useQuery(
    KEY_STAMPTOUR_REQUEST_DETAIL,
    () => {
      if (stamptourId && stamptourId.length > 0)
        return getStamptourRequestDetail(stamptourId);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.creationRequestDetail) {
          const tmpData = res.creationRequestDetail.stamp.map((stamp: any) => {
            const temp = {
              id: stamp.stampId,
              name: stamp.stampName,
              lat: stamp.stampPointLat,
              lng: stamp.stampPointLng,
              radius: stamp.stampRadius,
            };
            return temp;
          });
          setStampList(tmpData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data: audioData,
    isLoading,
    refetch: challengeAudioContentRefetch,
  } = useQuery(
    KEY_CHALLENGE_AUDIO_CONTENT,
    () => {
      if (
        selectedConnectedMission &&
        selectedConnectedMission.targetType === 1
      ) {
        const newData: any = {
          challengeId: selectedConnectedMission.missionId,
          missionId: selectedConnectedMission.targetId,
        };
        return getChallengeConnectedAudioContent(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (
          res !== null &&
          res.audioContent !== undefined &&
          res.audioContent !== null
        ) {
          setAudioContent(res.audioContent);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data: photoCertificateData,
    isLoading: isPhotoLoading,
    refetch: challengePhotoCertificateRefetch,
  } = useQuery(
    KEY_CHALLENGE_PHOTO_CERTIFICATE_INFO,
    () => {
      if (
        selectedConnectedMission &&
        (selectedConnectedMission.targetType === 2 ||
          selectedConnectedMission.targetType === 3)
      ) {
        const newData: any = {
          challengeId: challengeId,
          missionId: selectedConnectedMission.targetId,
        };
        return getChallengeConnectedPhotoCertificate(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (
          res !== null &&
          res.photoCertificate !== undefined &&
          res.photoCertificate !== null
        ) {
          const newData = {
            ...res.photoCertificate,
            successCount: selectedConnectedMission.goal,
          };
          setPhotoCertificate(newData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data: questionAnswerData,
    isLoading: isQnaLoading,
    refetch: challengeQuestionAnswerRefetch,
  } = useQuery(
    KEY_CHALLENGE_QUESTON_ANSWER_INFO,
    () => {
      if (
        selectedConnectedMission &&
        (selectedConnectedMission.targetType === 4 ||
          selectedConnectedMission.targetType === 5)
      ) {
        const newData: any = {
          challengeId: challengeId,
          missionId: selectedConnectedMission.targetId,
        };
        return getChallengeConnectedQuestionAnswer(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res !== null && res.qna !== undefined && res.qna !== null) {
          setQuestionAnswerInfo(res.qna);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeConnectMissionMutation,
    isError: isMutateError,
  } = useMutation(registerChallengeConnectMission, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('챌린지 미션 연결이 되었습니다.');
        setReload(true);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `챌린지 미션 연결을 진행하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '챌린지 미션 연결이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const addConnectMission = (data: any) => {
    console.log('--> addConnectMission data:', data);
    const newPhotoCert = selectedConnectedMissionItems.photoCertificate;
    const newAudioContent = selectedConnectedMissionItems.audioContent;
    const newQuestionAnswer = selectedConnectedMissionItems.questionAnswer;
    const selectedStamp = stampList.filter((item: any) => item.id === stampId);

    const newData: any = {
      missionId: mission.missionId,
      connectedId: stampId,
      targetId:
        connectMissionType === 1
          ? newAudioContent.audioContentId
          : connectMissionType === 2 || connectMissionType === 3
          ? newPhotoCert?.photoCertId
          : connectMissionType === 4 || connectMissionType === 5
          ? newQuestionAnswer.qnaId
          : '',
      targetType: connectMissionType,
      goal:
        connectMissionType === 1
          ? 1
          : connectMissionType === 2 || connectMissionType === 3
          ? newPhotoCert?.successCount
          : connectMissionType === 4 || connectMissionType === 5
          ? 1
          : 1,
      audioContent:
        connectMissionType === 1
          ? {
              audioContentId: newAudioContent.audioContentId,
              category: newAudioContent.category,
              title: newAudioContent.contentTitle,
              mainContent: data.audioContentUrl,
              mainPlayTime: data.mainPlayTime,
              producer: newAudioContent.producer,
              image:
                newAudioContent.contentFile !== null
                  ? data.audioImageUrl
                  : newAudioContent.image.length > 0
                  ? newAudioContent.image
                  : '',
            }
          : null,
      photoCertificate:
        connectMissionType === 2 || connectMissionType === 3
          ? {
              certificateType: connectMissionType === 2 ? 1 : 2,
              successCount: newPhotoCert?.successCount,
              photoCertSuccessImage:
                newPhotoCert?.photoCertSuccessFile !== undefined &&
                newPhotoCert?.photoCertSuccessFile !== null
                  ? data.successUploadUrl
                  : newPhotoCert?.photoCertSuccessImage.length > 0
                  ? newPhotoCert?.photoCertSuccessImage
                  : '',
              photoCertFailImage:
                newPhotoCert?.photoCertFailFile !== undefined &&
                newPhotoCert?.photoCertFailFile !== null
                  ? data.failUploadUrl
                  : newPhotoCert?.photoCertFailImage.length > 0
                  ? newPhotoCert?.photoCertFailImage
                  : '',
              photoCertDescription: newPhotoCert?.photoCertDescription,
            }
          : null,
      questionAnswer:
        connectMissionType === 4 || connectMissionType === 5
          ? {
              qnaId: newQuestionAnswer.qnaId,
              title:
                selectedStamp !== null
                  ? selectedStamp[0].name
                  : connectMissionType === 4
                  ? '설문조사'
                  : '퀴즈',
              isRepeated: newQuestionAnswer.retryAnswer,
              type: connectMissionType === 4 ? 1 : 2,
            }
          : null,
    };
    const newMission: any = {
      challengeId: challengeId,
      connectedMissionInfo: newData,
    };
    registerChallengeConnectMissionMutation(newMission);
  };

  async function uploadAudioMultipleFiles() {
    const newData = { ...selectedConnectedMissionItems.audioContent };
    console.log(
      '--> selectedConnectedMissionItems:',
      selectedConnectedMissionItems
    );
    var newUploadUrl = '';
    if (newData.contentFile !== undefined && newData.contentFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.contentFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newAudioContentPathUrl = '';
    var newMainPlayTime = newData.mainPlayTime;
    if (
      newData.audioContentPath !== undefined &&
      newData.audioContentPath.file !== null
    ) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.audioContentPath.file
      );
      newAudioContentPathUrl = newUploadFile.data.uploadUrl.file.path;
      newMainPlayTime = newUploadFile.data.uploadUrl.file.duration;
    } else {
      newAudioContentPathUrl = newData.audioContentPath
        ? newData.audioContentPath.name
        : '';
    }

    addConnectMission({
      audioImageUrl: newUploadUrl,
      audioContentUrl: newAudioContentPathUrl,
      mainPlayTime: newMainPlayTime,
    });
  }

  async function uploadPhotoCertificateMultipleFiles() {
    const newData = { ...selectedConnectedMissionItems.photoCertificate };
    console.log(
      '--> selectedConnectedMissionItems:',
      selectedConnectedMissionItems
    );

    var newSuccessUploadUrl = '';
    if (
      newData.photoCertSuccessFile !== undefined &&
      newData.photoCertSuccessFile !== null
    ) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.photoCertSuccessFile
      );
      newSuccessUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    var newFailUploadUrl = '';
    if (
      newData.photoCertFailFile !== undefined &&
      newData.photoCertFailFile !== null
    ) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.photoCertFailFile
      );
      newFailUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    addConnectMission({
      successUploadUrl: newSuccessUploadUrl,
      failUploadUrl: newFailUploadUrl,
    });
  }

  const checkConnectMission = () => {
    if (connectMissionType === 1) {
      uploadAudioMultipleFiles();
      return;
    }
    if (connectMissionType === 2 || connectMissionType === 3) {
      uploadPhotoCertificateMultipleFiles();
      return;
    }
    addConnectMission({});
  };

  const changeConnectMissionType = (newConnectionMissionType: number) => {
    setConnectMissionType(newConnectionMissionType);
  };

  useEffect(() => {
    console.log('--> selectedConnectedMission:', selectedConnectedMission);
    if (selectedConnectedMission && selectedConnectedMission.connectedId) {
      setStampId(selectedConnectedMission.connectedId);
      changeConnectMissionType(selectedConnectedMission.targetType);

      if (selectedConnectedMission.targetType === 1) {
        challengeAudioContentRefetch();
      } else if (
        selectedConnectedMission.targetType === 2 ||
        selectedConnectedMission.targetType === 3
      ) {
        challengePhotoCertificateRefetch();
      } else if (
        selectedConnectedMission.targetType === 4 ||
        selectedConnectedMission.targetType === 5
      ) {
        challengeQuestionAnswerRefetch();
      }
    }
  }, [selectedConnectedMission]);

  useEffect(() => {
    if (stamptourId && stamptourId !== '') stamptourRequestDetailRefetch();
  }, [stamptourId]);

  useEffect(() => {
    if (mission) {
      setStampId('0');
      setConnectMissionType(0);
      setStamptourId(mission.stampTourId);
    }
  }, [mission]);

  return (
    <>
      <Box>
        <Typography className='title' sx={{ fontSize: '1rem' }}>
          지점에 미션 연결하기{' '}
        </Typography>
      </Box>
      <Box sx={{ mb: '1rem' }}>
        <ConnectMissionTable
          challengeId={challengeId}
          mission={mission}
          reload={reload}
          setReload={setReload}
        />
      </Box>
      <Box>
        <Typography className='title'>연결할 스탬프 지점 선택</Typography>
        <TextField
          select
          value={stampId}
          sx={{ width: '20rem' }}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setStampId(evt.target.value);
          }}
        >
          <MenuItem key='key_0' value='0'>
            지점을 선택해 주세요.
          </MenuItem>
          {stampList &&
            stampList.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      <Box>
        <Typography className='title'>연결할 미션 선택</Typography>
        <TextField
          select
          value={connectMissionType}
          sx={{ width: '20rem' }}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            changeConnectMissionType(Number(evt.target.value));
          }}
        >
          <MenuItem key='ckey_0' value='0'>
            미션 종류를 선택해 주세요.
          </MenuItem>
          {connectMissionTypeList.map((item: any) => (
            <MenuItem key={item.type} value={item.type}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {connectMissionType === 1 ? (
        <Box>
          <AudioContentConnectedMissionView
            setAudioContent={setAudioContent}
            audioContent={audioContent}
            setReset={setReset}
            reset={reset}
          />
        </Box>
      ) : connectMissionType === 2 || connectMissionType === 3 ? (
        <Box>
          <PhotoCertificationMissionView
            setPhotoCertificate={setPhotoCertificate}
            photoCertificate={photoCertificate}
            setReset={setReset}
            reset={reset}
            // save={save}
            // onSave={saveData => {
            //   setPhotoCertificate(saveData);
            //   uploadPhotoCertificateMultipleFiles(saveData);
            // }}
            connected={true}
          />
        </Box>
      ) : connectMissionType === 4 || connectMissionType === 5 ? (
        <Box>
          <QnaMissionView
            questionAnswerInfo={questionAnswerInfo}
            setQuestionAnswerInfo={setQuestionAnswerInfo}
            qnaType={connectMissionType === 4 ? 1 : 2}
          />
        </Box>
      ) : (
        ''
      )}
      {!(stampId === '0' || connectMissionType === 0) && (
        <Box className='flex_start' sx={{ mt: '1rem' }}>
          <Button
            variant='outlined'
            color='primary'
            sx={{ mr: '1rem' }}
            onClick={() => setReset(true)}
          >
            초기화
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => checkConnectMission()}
          >
            미션 연결 저장
          </Button>
        </Box>
      )}
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};

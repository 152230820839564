import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { useSession } from '../../../hooks/session';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { DraggablePaper } from '../../styles/Styles';
import {
  KEY_CHALLENGE_AUDIO_CONTENT,
  KEY_CHALLENGE_AUDIO_CONTENT_LIST,
  KEY_CHALLENGE_PHOTO_CERTIFICATE_INFO,
  KEY_CHALLENGE_TOUR_MISSION_LIST,
  KEY_CHALLENGE_TOUR_MISSION_TYPE_LIST,
} from '../../../common/key';
import {
  deleteChallengeAudioContent,
  getChallengeAudioContentList,
  registerChallengeAudioContent,
  updateChallengeAudioContentCommonAd,
} from '../../../common/api/ApiChallenge';
import { uploadFile } from '../../../common/api/ApiUpload';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import {
  checkUrl,
  getAddUrl,
  timestamp2Localestring,
  validCheckNumberDot,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSearchContentManageTarget } from '../../commonComponent/SwSearchContentManageTarget';
import { ChallengeAudioGpsContent } from './ChallengeAudioGpsContent';
import { SwDatesPickerWithScroll } from '../../commonComponent/dateSetting/SwDatesPickerWithScroll';
import {
  deleteChallengeMission,
  getChallengeConnectedPhotoCertificate,
  getChallengeTourMissionList,
  getChallengeTourMissionTypeList,
  registerChallengeMission,
} from '../../../common/api/ApiChallengeTour';
import { StampMissionView } from './tourMission/StampMissionView';
import { ConnectMissionView } from './tourMission/ConnectMissionView';
import { WalkCourseMissionView } from './tourMission/WalkCourseMissionView';
import { FieldMissionView } from './tourMission/FieldMissionView';
import { AudioContentMissionView } from './tourMission/AudioContentMissionView';
import { ChallengeTypeBox } from './ChallengeStyles';
import { PhotoCertificationMissionView } from './tourMission/PhotoCertificationMissionView';
import { QnaMissionView } from './tourMission/QnaMissionView';
import { selectedConnectedMissionItemsState } from '../../../common/atom';
import { LocationFilterView } from './tourMission/LocationFilterView';

const MAX_GUIDE_PAGE = 6;
const DEFAULT_IMAGE = '/v2/common/audio_content_default.jpg';
const CONTENT_WIDTH = '100%';

const columns = ['미션 번호', '미션 이름', '미션 종류', '삭제'];

const goalMeterGuide = {
  title: '목표 거리(km)',
  placeholder: '거리를 입력해 주세요',
};

const goalStepGuide = {
  title: '목표 걸음수',
  placeholder: '걸음수를 입력해 주세요',
};

const goalStepsMaxGuide = {
  title: '하루 제한 걸음 수',
  placeholder: '걸음수를 입력해 주세요',
};

const GridTitle = styled(Grid)({
  borderLeft: 'none',
  padding: '.7em',
});

const GridCell = styled(Grid)({
  borderLeft: 'none',
  padding: '.7em',
});

const Item = styled(Box)({
  textAlign: 'left',
  fontWeight: 500,
  fontSize: '1rem',
});

export const getSelectedType = (category: number, type: number) => {
  if (category === 1) {
    switch (type) {
      case 1:
        return { category: 1, type: 1 };
      case 2:
        return { category: 1, type: 2 };
      case 3:
        return { category: 1, type: 3 };
      case 4:
        return { category: 1, type: 1 };
      case 5:
        return { category: 1, type: 1 };
      case 6:
        return { category: 1, type: 1 };
      case 7:
        return { category: 1, type: 1 };
      case 8:
        return { category: 1, type: 1 };
      case 9:
        return { category: 1, type: 2 };
      default:
        return { category: 1, type: 0 };
    }
  } else if (category === 2) {
    switch (type) {
      case 1:
        return { category: 2, type: 1 };
      case 2:
        return { category: 2, type: 2 };
      case 3:
        return { category: 2, type: 3 };
      case 4:
        return { category: 2, type: 4 };
      default:
        return { category: 2, type: 0 };
    }
  } else if (category === 3) {
    switch (type) {
      case 1:
        return { category: 3, type: 1 };
      case 2:
        return { category: 3, type: 2 };
      default:
        return { category: 3, type: 0 };
    }
  } else if (category === 4) {
    switch (type) {
      case 1:
        return { category: 4, type: 1 };
      case 2:
        return { category: 4, type: 2 };
      default:
        return { category: 4, type: 0 };
    }
  }
  return { category: 0, type: 0 };
};

interface Props {
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
  reloadChallenge: (id: string) => void;
}

export const ChallengeTourMission: React.FC<Props> = ({
  challengeInfo,
  setChallengeInfo,
  reloadChallenge,
}) => {
  const selectedConnectedMissionItems = useRecoilValue(
    selectedConnectedMissionItemsState
  );

  const queryClient = useQueryClient();

  const { loginVal } = useSession();
  const [challengeId, setChallengeId] = useState<string>('');
  const [selectedTypeIdx, setSelectedTypeIdx] = useState<number>(0);
  const [missionTitle, setMissionTitle] = useState<string>('');
  const [missionList, setMissionList] = useState<any>([]);
  const [missionTypeList, setMissionTypeList] = useState<any>([]);
  const [selectedMissionId, setSelectedMissionId] = useState<string>(
    newUuidId()
  );
  const [mission, setMission] = useState<any>('');
  const [missionGoal, setMissionGoal] = useState<string>('');
  const [missionStepsMax, setMissionStepsMax] = useState<string>('');
  const [selectedType, setSelectedType] = useState<any>({
    category: 1,
    type: 1,
  });
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [photoCertificate, setPhotoCertificate] = useState<any>();
  const [questionAnswerInfo, setQuestionAnswerInfo] = useState<any>();
  const [reset, setReset] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const {
    data: photoCertificateData,
    isLoading: isPhotoLoading,
    refetch: challengePhotoCertificateRefetch,
  } = useQuery(
    KEY_CHALLENGE_PHOTO_CERTIFICATE_INFO,
    () => {
      if (
        selectedMissionId &&
        selectedType &&
        selectedType.category === 3 &&
        (selectedType.type === 1 || selectedType.type === 2)
      ) {
        const newData: any = {
          challengeId: challengeId,
          missionId: selectedMissionId,
        };
        return getChallengeConnectedPhotoCertificate(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (
          res !== null &&
          res.photoCertificate !== undefined &&
          res.photoCertificate !== null
        ) {
          const newData = {
            ...res.photoCertificate,
            successCount: mission.goal,
          };
          setPhotoCertificate(newData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const getMissionType = (category: number, type: number) => {
    const newMissionType = missionTypeList.filter(
      (item: any) => item.category === category && item.type === type
    );
    return newMissionType !== null && newMissionType.length > 0
      ? newMissionType[0].title
      : '-';
  };

  const getMissionTypeIdx = (category: number, type: number) => {
    const newMissionTypeIdx = missionTypeList
      .map((item: any, idx: number) =>
        item.category === category && item.type === type ? idx : -1
      )
      .filter((item: any) => item !== -1);
    return newMissionTypeIdx !== null && newMissionTypeIdx.length > 0
      ? Number(newMissionTypeIdx[0])
      : 0;
  };

  const clickRowItem = (idx: number, newContentList: any[]) => {
    setSelectedRow(idx);
    setSelectedMissionId(newContentList[idx].missionId);
    const newMission = {
      category: newContentList[idx].category,
      type: newContentList[idx].type,
      fieldId: newContentList[idx].fieldId,
      goal: newContentList[idx].goal,
      gpsGetType: newContentList[idx].gpsGetType,
      maxPoint: newContentList[idx].mapPoint,
      missionId: newContentList[idx].missionId,
      seq: newContentList[idx].seq,
      stampTourId: newContentList[idx].stampTourId,
      stepsMax: newContentList[idx].stepsMax
        ? String(newContentList[idx].stepsMax)
        : '',
      stepsMin: newContentList[idx].stepsMin,
      title: newContentList[idx].title,
      walkCourseId: newContentList[idx].walkCourseId,
    };
    setSelectedType(
      getSelectedType(newContentList[idx].category, newContentList[idx].type)
    );
    setSelectedTypeIdx(
      getMissionTypeIdx(newContentList[idx].category, newContentList[idx].type)
    );
    setMissionTitle(newContentList[idx].title);
    setMissionGoal(
      newContentList[idx].category === 2 &&
        (newContentList[idx].type === 2 || newContentList[idx].type === 3)
        ? (newContentList[idx].goal / 1000).toFixed(2)
        : newContentList[idx].goal
    );
    setMission(newMission);
  };

  const clickRow = (event: any, idx: number) => {
    if (event.target.classList.contains('MuiButton-root')) {
      return;
    }
    clickRowItem(idx, missionList);
  };

  const { refetch: challengeTourMissionTypeRefetch } = useQuery(
    KEY_CHALLENGE_TOUR_MISSION_TYPE_LIST,
    () => {
      if (challengeId.length > 0) return getChallengeTourMissionTypeList();
      return null;
    },
    {
      onSuccess: res => {
        if (
          res !== null &&
          res.missionType !== undefined &&
          res.missionType !== null
        ) {
          setMissionTypeList(res.missionType);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data,
    isLoading,
    refetch: challengeTourMissionRefetch,
  } = useQuery(
    KEY_CHALLENGE_TOUR_MISSION_LIST,
    () => {
      const newData: any = {
        challengeId: challengeId,
      };
      if (challengeId.length > 0) return getChallengeTourMissionList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.mission !== undefined && res.mission !== null) {
          setMissionList(res.mission);
          const tmpIdx = res.mission
            .map((item: any, idx: number) =>
              item.missionId === selectedMissionId ? idx : -1
            )
            .filter((item: number) => item !== -1);
          if (tmpIdx !== null && tmpIdx.length > 0) {
            clickRowItem(tmpIdx[0], res.mission);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = () => {
    queryClient.prefetchQuery(KEY_CHALLENGE_TOUR_MISSION_LIST, () => {
      const newData: any = {
        challengeId: challengeId,
      };
      if (challengeId.length > 0) return getChallengeTourMissionList(newData);
      return null;
    });
  };

  const { mutate: registerChallengeMissionMutation, isError: isMutateError } =
    useMutation(registerChallengeMission, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('챌린지 미션을 등록되었습니다.');
          prefetch();
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `챌린지 미션을 등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '챌린지 미션 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: deleteChallengeMissionMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteChallengeMission, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('챌린지 미션이 삭제되었습니다.');
      prefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '챌린지 미션를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addChallengeMission = (newUrlInfo: any) => {
    const newPhotoCert = selectedConnectedMissionItems.photoCertificate;
    const newQuestionAnswer = selectedConnectedMissionItems.questionAnswer;

    const newData: any = {
      missionId: selectedMissionId,
      category: missionTypeList[selectedTypeIdx].category,
      type: missionTypeList[selectedTypeIdx].type,
      title: missionTitle,
      goal: mission.goal,
      seq: mission.seq,
      stepsMin: mission.stepsMin,
      stepsMax: Number(mission.stepsMax),
      walkCourseId: mission.walkCourseId,
      stampTourId: mission.stampTourId,
      fieldId: mission.fieldId,
      qnaId:
        selectedType && selectedType.category === 4
          ? newQuestionAnswer.qnaId
          : '',
      gpsGetType: mission.gpsGetType,
      maxPoint: mission.maxPoint,
      photoCertificate:
        selectedType &&
        selectedType.category === 3 &&
        (selectedType.type === 1 || selectedType.type === 2)
          ? {
              certificateType: selectedType.type,
              successCount: newPhotoCert?.successCount,
              photoCertSuccessImage:
                newPhotoCert?.photoCertSuccessFile !== undefined &&
                newPhotoCert?.photoCertSuccessFile !== null
                  ? newUrlInfo.successUploadUrl
                  : newPhotoCert?.photoCertSuccessImage.length > 0
                  ? newPhotoCert?.photoCertSuccessImage
                  : '',
              photoCertFailImage:
                newPhotoCert?.photoCertFailFile !== undefined &&
                newPhotoCert?.photoCertFailFile !== null
                  ? newUrlInfo.failUploadUrl
                  : newPhotoCert?.photoCertFailImage.length > 0
                  ? newPhotoCert?.photoCertFailImage
                  : '',
              photoCertDescription: newPhotoCert?.photoCertDescription,
            }
          : null,
      questionAnswer:
        selectedType &&
        selectedType.category === 4 &&
        (selectedType.type === 1 || selectedType.type === 2)
          ? {
              qnaId: newQuestionAnswer.qnaId,
              title: missionTitle,
              isRepeated: newQuestionAnswer.retryAnswer,
              type: selectedType.type,
            }
          : null,
      geohashs:
        selectedType &&
        selectedType.category === 2 &&
        (selectedType.type === 2 || selectedType.type === 3)
          ? selectedConnectedMissionItems.geohashs
          : null,
      region:
        selectedType &&
        selectedType.category === 2 &&
        (selectedType.type === 2 || selectedType.type === 3)
          ? selectedConnectedMissionItems.region
          : null,
    };
    const newMission: any = {
      challengeId: challengeId,
      missionInfo: newData,
    };
    registerChallengeMissionMutation(newMission);
  };

  const resetChallengeMission = () => {
    const newMissionId = newUuidId();
    setSelectedRow(-1);
    setSelectedTypeIdx(0);
    setSelectedMissionId(newMissionId);
    setMissionTitle('');
    setSelectedType({ category: 1, type: 1 });

    const newMission = {
      category: 1,
      type: 1,
      fieldId: '',
      goal: 1,
      gpsGetType: 1,
      maxPoint: 0,
      missionId: newMissionId,
      seq: 1,
      stampTourId: '',
      stepsMax: '',
      stepsMin: 0,
      title: '',
      walkCourseId: '',
    };
    setMission(newMission);
  };

  async function uploadPhotoCertificateMultipleFiles() {
    const newData = { ...selectedConnectedMissionItems.photoCertificate };

    var newSuccessUploadUrl = '';
    if (
      newData.photoCertSuccessFile !== undefined &&
      newData.photoCertSuccessFile !== null
    ) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.photoCertSuccessFile
      );
      newSuccessUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    var newFailUploadUrl = '';
    if (
      newData.photoCertFailFile !== undefined &&
      newData.photoCertFailFile !== null
    ) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.photoCertFailFile
      );
      newFailUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    addChallengeMission({
      successUploadUrl: newSuccessUploadUrl,
      failUploadUrl: newFailUploadUrl,
    });
  }

  const removeChallengeMission = () => {
    const newData = {
      challengeId: challengeId,
      missionId: missionList[selectedRow].missionId,
    };
    deleteChallengeMissionMutation(newData);
  };

  const checkChallengeMission = () => {
    if (
      selectedType &&
      selectedType.category === 3 &&
      (selectedType.type === 1 || selectedType.type === 2)
    ) {
      uploadPhotoCertificateMultipleFiles();
    } else {
      addChallengeMission({});
    }
  };

  const searchChalengeMission = () => {};

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchChalengeMission();
    }
  };

  const changeMissionType = (idx: number) => {
    setSelectedTypeIdx(idx);
    setSelectedType(missionTypeList[idx]);
    setMissionGoal('');
    setMissionTitle('');
    setMissionStepsMax('');
    setPhotoCertificate(null);
    setQuestionAnswerInfo(null);
  };

  useEffect(() => {
    if (
      selectedMissionId &&
      selectedType &&
      selectedType.category === 3 &&
      (selectedType.type === 1 || selectedType.type === 2)
    ) {
      challengePhotoCertificateRefetch();
    }
  }, [selectedMissionId]);

  useEffect(() => {
    if (searchResult !== undefined && searchResult !== null) {
      setSearchResult(null);
    }
  }, [searchResult]);

  useEffect(() => {
    challengeTourMissionTypeRefetch();
    challengeTourMissionRefetch();
  }, [challengeId]);

  useEffect(() => {
    if (challengeInfo) {
      setChallengeId(challengeInfo.challengeId);
    }
    resetChallengeMission();
  }, [challengeInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Paper sx={{ width: CONTENT_WIDTH }}>
        <Box>
          <Typography sx={{ m: '1rem 0 ' }}>미션 목록</Typography>
          <TableContainer
            sx={{
              p: '0 1rem',
              height: '33vh',
              overflowY: 'auto',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <TableCell
                      key={`audio_content_head_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {missionList?.map((row: any, idx: number) => (
                  <TableRow
                    key={`audio_content_body_${idx}`}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                    onClick={event => clickRow(event, idx)}
                  >
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>
                      {getMissionType(row.category, row.type)}
                    </TableCell>
                    <TableCell
                      className='removeButton'
                      sx={{ p: '.1rem .6rem' }}
                    >
                      <Button
                        color='info'
                        variant='contained'
                        onClick={() => {
                          removeChallengeMission();
                        }}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      <Divider />
      <Box>
        <Typography className='title'>미션 종류</Typography>
        <TextField
          select
          value={selectedTypeIdx}
          sx={{ width: '20rem' }}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            const idx = Number(evt.target.value);
            changeMissionType(idx);
          }}
        >
          {missionTypeList &&
            missionTypeList.map((item: any, idx: number) => (
              <MenuItem key={`mission_key_${idx + 1}`} value={idx}>
                {item.title}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      <Box>
        <Typography className='title'>미션 이름</Typography>
        <TextField
          sx={{ width: '20rem' }}
          value={missionTitle}
          onChange={(
            evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setMissionTitle(evt.target.value)}
        />
        <Divider />
      </Box>
      {selectedType &&
        selectedType.category === 1 &&
        selectedType.type === 1 && (
          <StampMissionView
            challengeId={challengeId}
            mission={mission}
            setMission={setMission}
          />
        )}
      {selectedType &&
        selectedType.category === 1 &&
        selectedType.type === 2 && (
          <WalkCourseMissionView
            challengeId={challengeId}
            mission={mission}
            setMission={setMission}
          />
        )}
      {selectedType &&
        selectedType.category === 1 &&
        selectedType.type === 3 && (
          <FieldMissionView
            challengeId={challengeId}
            mission={mission}
            setMission={setMission}
          />
        )}
      {selectedType &&
        selectedType.category === 2 &&
        selectedType.type === 1 && (
          <AudioContentMissionView
            challengeId={challengeId}
            mission={mission}
          />
        )}
      {selectedType &&
        selectedType.category === 2 &&
        (selectedType.type === 2 ||
          selectedType.type === 3 ||
          selectedType.type === 4) && (
          <>
            <Box sx={{ mt: '1rem' }}>
              <Typography className='title'>
                {selectedType.type === 2 || selectedType.type === 3
                  ? goalMeterGuide.title
                  : goalStepGuide.title}
              </Typography>
              <TextField
                sx={{ marginRight: '1rem' }}
                placeholder={
                  selectedType.type === 2 || selectedType.type === 3
                    ? goalMeterGuide.placeholder
                    : goalStepGuide.placeholder
                }
                value={missionGoal}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const newGoal = evt.target.value;
                  if (
                    (selectedType.type === 2 || selectedType.type === 3) &&
                    validCheckNumberDot(newGoal, 2)
                  ) {
                    setMissionGoal(newGoal);
                    setMission({
                      ...mission,
                      goal: Math.round(parseFloat(newGoal) * 1000),
                    });
                  } else if (selectedType.type === 4) {
                    setMissionGoal(newGoal);
                    setMission({ ...mission, goal: Number(newGoal) });
                  }
                }}
              />
            </Box>
            {selectedType.type === 4 && (
              <Box>
                <Typography className='title'>
                  {goalStepsMaxGuide.title}
                </Typography>
                <TextField
                  sx={{ marginRight: '1rem' }}
                  placeholder={goalStepsMaxGuide.placeholder}
                  value={missionStepsMax}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    const newStepsMax = evt.target.value;
                    setMissionStepsMax(newStepsMax);
                    setMission({ ...mission, stepsMax: Number(newStepsMax) });
                  }}
                />
              </Box>
            )}
            {(selectedType.type === 2 || selectedType.type === 3) && (
              <>
                <Typography className='title'>위치(GPS) 제한</Typography>
                <LocationFilterView mission={mission} setMission={setMission} />
              </>
            )}
          </>
        )}
      {selectedType &&
        selectedType.category === 3 &&
        (selectedType.type === 1 || selectedType.type === 2) && (
          <PhotoCertificationMissionView
            setPhotoCertificate={setPhotoCertificate}
            photoCertificate={photoCertificate}
            setReset={setReset}
            reset={reset}
            // save={save}
            // onSave={() => {
            //   console.log('-->');
            // }}
            connected={false}
          />
        )}
      {selectedType &&
        selectedType.category === 4 &&
        (selectedType.type === 1 || selectedType.type === 2) && (
          <QnaMissionView
            questionAnswerInfo={questionAnswerInfo}
            setQuestionAnswerInfo={setQuestionAnswerInfo}
            qnaType={selectedType.type}
          />
        )}

      {selectedType &&
        selectedType.category === 1 &&
        selectedType.type === 1 &&
        mission.gpsGetType !== 2 && (
          <>
            <Divider />
            <ConnectMissionView challengeId={challengeId} mission={mission} />
          </>
        )}
      <Divider />
      <Box className='flex_between' sx={{ mt: '0rem' }}>
        <Box>
          <Button variant='outlined' onClick={() => resetChallengeMission()}>
            신규작성
          </Button>
        </Box>
        <Box>
          <Button variant='contained' onClick={() => checkChallengeMission()}>
            정보 저장
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Paper>
  );
};

import {
  Box,
  Button,
  Chip,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { GeofencingMap } from './GeofencingMap';
import {
  IFieldDetail,
  IFieldInfo,
  DefaultFieldInfo,
  DefaultFieldDetail,
} from '../../../models/field';
import {
  getFieldRequestList,
  getFieldRequestDetail,
  changeFieldApplyStatus,
} from '../../../common/api/ApiField';
import {
  KEY_FIELD_REQUEST_LIST,
  KEY_FIELD_REQUEST_DETAIL,
  KEY_REGION_REPORT_LIST,
} from '../../../common/key';
import {
  addHyphenToPhoneno,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwCreationRequestReject } from '../../commonComponent/SwCreationRequestReject';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { selectedFieldIdState } from '../../../common/atom';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { requestMigrationMetaPlace } from '../../../common/api/ApiGeohash';
import { useSession, xapi } from '../../../hooks/session';
import { getRegionReportList } from '../../../common/api/ApiReport';

interface Props {}

const requestColumnlists = [
  '필드 이름',
  '신청일자',
  '신청자',
  '연락처',
  '상태',
  '담당자',
];
const clickColumnlist = ['필드 이름', '신청자', '신청자', '담당자'];

export const GeofencingCreate: React.FC<Props> = () => {
  const { loginVal } = useSession();
  const [selectRegion, setSelectRegion] = useState<any[]>([]);

  const queryClient = useQueryClient();
  const uploadDataRef = useRef<any>();
  const [commandType, setCommandType] = useState<number>(0); // 0: no-action, 1: save, 2:update, 3: delete
  const [gpsData, setGpsData] = useState<any>([]);
  const [mapReload, setMapReload] = useState(0);
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [fieldList, setFieldList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [mapInfo, setMapInfo] = useState<any>();
  const [fieldDetail, setFieldDetail] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [fieldId, setFieldId] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [fieldData, setFieldData] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [registerStatus, setRegisterStatus] = useState<number>(0);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const {
    data,
    isLoading,
    refetch: getRegionReportRefetch,
  } = useQuery(
    KEY_REGION_REPORT_LIST,
    () => {
      const newData = {
        rateYear: 2024,
        amdCode: 51,
      };
      if (checkRequest) {
        return getRegionReportList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        setCheckRequest(false);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: migrationMetaPlaceMutation, isError: isMutateError } =
    useMutation(requestMigrationMetaPlace, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('마이그레이션 처리 되었습니다.');
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '마이그레이션 처리가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const onReload = () => {
    console.log('-->');
  };

  const reloadField = (id: string) => {
    if (id !== '') {
      setFieldId(id);
      console.log('-->');
    }
  };

  const onGenerateReport = () => {
    setCheckRequest(true);
  };

  const onMigration = () => {
    const newData = {
      amdCode: 0,
    };
    migrationMetaPlaceMutation(newData);
  };

  const onUpload = (evt: ChangeEvent<HTMLInputElement>) => {
    const excelFile = evt.target.files && evt.target.files[0];

    var formData = new FormData();
    if (excelFile !== null) formData.append('file', excelFile);

    xapi
      .post(`/admin/v2/apis/upload/geofencing/geometry`, formData)
      .then(res => {
        uploadDataRef.current.value = null;
        if (res.status !== 200) throw new Error('http 에러');
        setOpenSnackbar(true);
        setDialogMessage('엑셀 업로드로 지오메트리를 등록했습니다.');
        return res.data;
      });
  };

  useEffect(() => {
    if (checkRequest) {
      getRegionReportRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  useEffect(() => {
    if (selectRegion && selectRegion.length > 0) {
      setFieldData({
        amdCode: selectRegion[0].amd,
      });
    }
  }, [selectRegion]);

  return (
    <>
      <Box>
        <Paper sx={{ cursor: 'pointer', p: '1rem', mt: '.5rem' }}>
          <Box>
            <Typography className='title'>시군구 선택</Typography>
            <Box>
              <SwSelectLocation
                limitKu={true}
                includeAmd={true}
                selectOne={true}
                showButton={false}
                showDepth1={false}
                location={selectRegion}
                setLocation={setSelectRegion}
                showContainer={false}
                mapId='geofencing_map'
              />
            </Box>
          </Box>
        </Paper>
      </Box>
      <GeofencingMap
        fieldData={fieldData}
        mapInfo={mapInfo}
        setMapInfo={setMapInfo}
        reloadField={reloadField}
        elementId='geofencing_create'
      />
      {loginVal.value?.user?.id === 'shjin' && (
        <Box className='flex_start'>
          <Button variant='outlined' onClick={() => onGenerateReport()}>
            보고서 생성
          </Button>
          <Button
            sx={{ ml: '1rem' }}
            variant='outlined'
            onClick={() => onMigration()}
          >
            마이그레이션
          </Button>
          <Button
            sx={{ ml: '1rem' }}
            variant='outlined'
            onClick={() => {
              const uploadData = document.getElementById('uploadDataId');
              uploadData && uploadData.click();
            }}
          >
            중심/면적 데이터 업로드
          </Button>
          <input
            ref={uploadDataRef}
            type='file'
            id='uploadDataId'
            style={{ display: 'none' }}
            onChange={onUpload}
          />
        </Box>
      )}
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  SwSwitch,
  AddGroupTextField,
  NoneBorderChip,
  DraggablePaper,
} from '../../styles/Styles';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import {
  deleteChallengeSearchThemeRegion,
  getChallengeSearchThemeRegionList,
  getPopularSearchTerm,
  registerPopularSearchTerm,
} from '../../../common/api/ApiChallenge';
import { RESULT_OK } from '../../../common/resultCode';
import {
  KEY_CHALLENGE_SEARCH_THEME_REGION_LIST,
  KEY_POPULAR_SERACH_TERM,
} from '../../../common/key';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { useSession } from '../../../hooks/session';
import { ThemeRegionSetting } from './ThemeRegionSetting';

const columns = ['이름', '이미지', '구분', '지역', '삭제'];

interface Props {}

export const ThemeRegionManage: React.FC<Props> = () => {
  const { loginVal } = useSession();

  const [dataList, setDataList] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [request, setRequest] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [openSettingChange, setOpenSettingChange] = useState(false);

  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: searchThemeRegionRefetch,
  } = useQuery(
    KEY_CHALLENGE_SEARCH_THEME_REGION_LIST,
    () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (request) return getChallengeSearchThemeRegionList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setDataList(res.themeRegion);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: deleteChallengeSearchThemeRegionMutate,
    isError: isMutateError,
  } = useMutation(deleteChallengeSearchThemeRegion, {
    onSuccess: () => {
      setOpenSnacker(true);
      setDialogMessage('테마별/지역별 항목을 삭제했습니다.');
      searchThemeRegionRefetch();
    },
    onError: error => {
      setOpenSnacker(true);
      setDialogMessage(
        '테마별/지역별 항목 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const deleteThemeRegion = (idx: number) => {
    const newData = {
      idx: idx,
    };
    deleteChallengeSearchThemeRegionMutate(newData);
  };

  const clickRow = (idx: number) => {
    setSelectedData(dataList[idx]);
    setOpenSettingChange(true);
  };

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box>
        <Box className='flex_start'>
          <Typography className='title'>테마별/지역별 항목</Typography>
          <Button
            variant='contained'
            sx={{ ml: '2rem' }}
            onClick={() => {
              setSelectedData(null);
              setOpenSettingChange(true);
            }}
          >
            추가
          </Button>
        </Box>
        <Paper sx={{ m: '.5rem 0', p: '2rem 1rem' }}>
          <Box className='flex_between' sx={{ pb: '1rem' }}>
            <TableTitle title='테마별/지역별 항목' count={totalRecords} />
          </Box>
          <Paper>
            <Table size='small'>
              <TableHead sx={{ bgcolor: '#F9FAFC' }}>
                <TableRow>
                  {columns.map((column, idx) => (
                    <TableCell
                      key={idx.toString()}
                      align={
                        idx === 2 || idx === 3
                          ? 'right'
                          : idx === 4
                          ? 'center'
                          : 'inherit'
                      }
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList.length > 0 ? (
                  dataList?.map((item: any, idx: number) => (
                    <TableRow
                      key={idx.toString()}
                      onClick={() => clickRow(idx)}
                    >
                      <TableCell sx={{ width: '20vw' }}>{item.name}</TableCell>
                      <TableCell sx={{ width: '20vw' }}>
                        {item.image !== null && item.image !== '' ? (
                          <img
                            src={`${imageUrlPrefix}${item.image}`}
                            alt=''
                            style={{ width: '4vw', height: '4vw' }}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell align='right'>
                        {item.type === 1 ? '테마별' : '지역별'}
                      </TableCell>
                      <TableCell align='right'>{item.regionName}</TableCell>
                      <TableCell align='center'>
                        <Button
                          variant='outlined'
                          color='info'
                          onClick={evt => {
                            evt.stopPropagation();
                            deleteThemeRegion(item.idx);
                          }}
                        >
                          삭제
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>검색결과 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {dataList.length > 0 && (
              <SwPagination
                page={page}
                handleChangePage={handleChangePage}
                count={Math.ceil(totalRecords / rowsPerPage)}
              />
            )}
          </Paper>
        </Paper>
      </Box>
      <ThemeRegionSetting
        open={openSettingChange}
        title='테마별/지역별 항목'
        onClose={() => setOpenSettingChange(!openSettingChange)}
        onConfirm={() => {
          searchThemeRegionRefetch();
          setOpenSettingChange(!openSettingChange);
        }}
        themeRegionInfo={selectedData}
      />
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};

import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IAmd } from '../../models/amd';
import { IRegionFilter } from '../../models/regionfilter';
import { throwError } from '../helper';

/*
 * GeoHash
 */

export const getAmdList = () =>
  xapi.get(`/admin/v2/apis/region-filter/amd`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getGeoHashGroupList = (searchKey: string, searchWord: string) =>
  xapi
    .get(
      `/admin/v2/apis/region-filter?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRecentLocaitonList = (month: number) =>
  xapi
    .get(`/admin/v2/apis/region-filter/user/recent-location/${month}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerRegionFilter = (data: IRegionFilter) =>
  xapi.post(`/admin/v2/apis/region-filter`, data);

export const deleteRegionFilter = (data: IRegionFilter) =>
  xapi.delete(`/admin/v2/apis/region-filter/${data.geoHashId}`);

// -------------------------------------------------------------------------
// geofencing
// -------------------------------------------------------------------------
export const getGeoFencingGeometryList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/geofencing/geometry?amdCode=${data.amdCode}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// -------------------------------------------------------------------------
// amdCode geomtry
// -------------------------------------------------------------------------
export const getAmdGeometryList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/geofencing/amd/${data.amdCode}/geometry`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const updateAmdGeometryInfo = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/geofencing/amd/${data.amdCode}/geometry/${data.geometryId}/info`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerAmdGeometry = (data: any) =>
  xapi
    .post(`/admin/v2/apis/geofencing/amd/${data.amdCode}/geometry`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteAmdGeometry = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/geofencing/amd/${data.amdCode}/type/${data.type}/geometry/${data.geometryId}`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const requestMigrationMetaPlace = (data: any) =>
  xapi.post(`/admin/v2/apis/geofencing/geometry/migration`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { ChallengeTypeBox } from '../ChallengeStyles';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { SwStampTourView } from '../../../commonComponent/SwStampTourView';
import { GridTitle } from '../../../localstore/localstoreManagement/LocalstoreStyles';
import { newUuidId, SwUuidCreate } from '../../../commonComponent/SwUuidCreate';
import { SwFileSetting } from '../../../commonComponent/SwFileSetting';
import { SwSearchContentManageTarget } from '../../../commonComponent/SwSearchContentManageTarget';
import {
  selectedConnectedMissionItemsState,
  selectedConnectedMissionState,
} from '../../../../common/atom';
import { DefaultPhotoCertificate } from '../../../../models/challenge';

const Item = styled(Box)({
  textAlign: 'left',
  fontWeight: 500,
});

const GridCell = styled(Grid)({
  borderLeft: 'none',
  padding: '.7em',
});

interface Props {
  setPhotoCertificate: Dispatch<React.SetStateAction<any>>;
  photoCertificate: any;
  setReset: Dispatch<React.SetStateAction<boolean>>;
  reset: boolean;
  connected: boolean;
}

export const PhotoCertificationMissionView: React.FC<Props> = ({
  setPhotoCertificate,
  photoCertificate,
  setReset,
  reset,
  connected = true,
}) => {
  const [selectedConnectedMissionItems, setSelectedConnectedMissionItems] =
    useRecoilState(selectedConnectedMissionItemsState);

  const { loginVal } = useSession();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [photoCertId, setPhotoCertId] = useState<string>(newUuidId());
  const [photoCertDescription, setPhotoCertDescription] = useState<string>();
  const [photoCertSuccessImage, setPhotoCertSuccessImage] =
    useState<string>('');
  const [photoCertSuccessFile, setPhotoCertSuccessFile] = useState<any>(null);
  const [photoCertFailImage, setPhotoCertFailImage] = useState<string>('');
  const [photoCertFailFile, setPhotoCertFailFile] = useState<any>(null);
  const [successCount, setSuccessCount] = useState<number>(1);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [targetTitle, setTargetTitle] = useState<string>('');

  const resetPhotoCertificate = () => {
    const newId = newUuidId();
    setPhotoCertId(newId);
    setPhotoCertDescription('');
    setPhotoCertSuccessImage('');
    setPhotoCertFailImage('');
    setPhotoCertSuccessFile(null);
    setPhotoCertFailFile(null);
    setSuccessCount(1);

    setSelectedConnectedMissionItems({
      ...selectedConnectedMissionItems,
      photoCertificate: { ...DefaultPhotoCertificate, photoCertId: newId },
    });
  };

  const changeSuccessCount = (newSuccessCount: number) => {
    const newData = {
      ...selectedConnectedMissionItems.photoCertificate,
      successCount: newSuccessCount,
    };
    setSelectedConnectedMissionItems({
      ...selectedConnectedMissionItems,
      photoCertificate: { ...newData },
    });
  };

  useEffect(() => {
    const newData = {
      ...selectedConnectedMissionItems.photoCertificate,
      photoCertDescription:
        photoCertDescription !== undefined ? photoCertDescription : '',
    };
    setSelectedConnectedMissionItems({
      ...selectedConnectedMissionItems,
      photoCertificate: { ...newData },
    });
  }, [photoCertDescription]);

  useEffect(() => {
    const newData = {
      ...selectedConnectedMissionItems.photoCertificate,
      photoCertSuccessImage: '',
      photoCertSuccessFile: photoCertSuccessFile,
    };
    setSelectedConnectedMissionItems({
      ...selectedConnectedMissionItems,
      photoCertificate: { ...newData },
    });
  }, [photoCertSuccessFile]);

  useEffect(() => {
    const newData = {
      ...selectedConnectedMissionItems.photoCertificate,
      photoCertFailImage: '',
      photoCertFailFile: photoCertFailFile,
    };
    setSelectedConnectedMissionItems({
      ...selectedConnectedMissionItems,
      photoCertificate: { ...newData },
    });
  }, [photoCertFailFile]);

  useEffect(() => {
    if (photoCertificate) {
      setPhotoCertId(
        photoCertificate.photoCertId !== undefined &&
          photoCertificate.photoCertId !== null
          ? photoCertificate.photoCertId
          : newUuidId()
      );
      setPhotoCertDescription(
        photoCertificate.photoCertDescription
          ? photoCertificate.photoCertDescription
          : ''
      );
      setPhotoCertSuccessImage(
        photoCertificate.photoCertSuccessImage
          ? photoCertificate.photoCertSuccessImage
          : ''
      );
      setPhotoCertFailImage(
        photoCertificate.photoCertFailImage
          ? photoCertificate.photoCertFailImage
          : ''
      );
      setPhotoCertSuccessFile(null);
      setPhotoCertFailFile(null);
      setSuccessCount(photoCertificate.successCount);

      setSelectedConnectedMissionItems({
        ...selectedConnectedMissionItems,
        photoCertificate: { ...photoCertificate },
      });
    } else {
      resetPhotoCertificate();
    }
  }, [photoCertificate]);

  useEffect(() => {
    if (reset) {
      resetPhotoCertificate();
      setReset(false);
    }
  }, [reset]);

  return (
    <>
      <Paper sx={{ backgroundColor: '#EFEFF3', mt: '1rem' }}>
        <Box sx={{ padding: '1rem 1rem' }}>
          {!connected && (
            <Box sx={{ mt: '1rem' }}>
              <Typography className='title'>목표 인증 횟수</Typography>
              <TextField
                sx={{ width: '8rem' }}
                select
                value={successCount}
                onChange={evt => {
                  setSuccessCount(Number(evt.target.value));
                  changeSuccessCount(Number(evt.target.value));
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 5 * 35,
                        overflow: 'auto',
                      },
                    },
                  },
                }}
              >
                {Array.from({ length: 30 }, (_, i) => ``).map(
                  (_v: any, idx: number) => (
                    <MenuItem key={`auth_idx_${idx}`} value={idx + 1}>
                      {idx + 1}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Box>
          )}
          <Box>
            <Typography className='title'>사진 인증 방법</Typography>
            <Stack
              direction='row'
              divider={
                <Divider orientation='vertical' flexItem sx={{ m: 0 }} />
              }
              spacing={2}
            >
              <Box>
                <Typography>정답 예시</Typography>
                <Box className='flex_between'>
                  <Box sx={{ mr: '1rem' }}>
                    <SwFileSetting
                      setImage={setPhotoCertSuccessImage}
                      image={
                        photoCertSuccessFile !== null
                          ? photoCertSuccessImage
                          : photoCertSuccessImage !== ''
                          ? `${imageUrlPrefix}${photoCertSuccessImage}`
                          : ''
                      }
                      setFile={setPhotoCertSuccessFile}
                      file={photoCertSuccessFile}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography>오답 예시</Typography>
                <Box className='flex_between'>
                  <Box sx={{ mr: '1rem' }}>
                    <SwFileSetting
                      setImage={setPhotoCertFailImage}
                      image={
                        photoCertFailFile !== null
                          ? photoCertFailImage
                          : photoCertFailImage !== ''
                          ? `${imageUrlPrefix}${photoCertFailImage}`
                          : ''
                      }
                      setFile={setPhotoCertFailFile}
                      file={photoCertFailFile}
                    />
                  </Box>
                </Box>
              </Box>
            </Stack>
            <Box sx={{ mr: '1rem' }}>
              <Typography className='title'>인증 방법 내용</Typography>
              <TextField
                fullWidth
                multiline
                rows={5}
                onChange={evt => {
                  const newDesc = evt.target.value.substring(0, 500);
                  setPhotoCertDescription(newDesc);
                }}
                value={photoCertDescription}
                placeholder='내용을 입력해 주세요.'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>{photoCertDescription?.length} / 500</span>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                    padding: '.5rem',
                  },
                }}
              />
            </Box>
            {connected && (
              <Box>
                <Typography className='title'>사진 인증 달성 횟수</Typography>
                <TextField
                  placeholder='달성 횟수를 입력해 주세요.'
                  value={successCount}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setSuccessCount(Number(evt.target.value));
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { KEY_STORE_BRAND_LIST } from '../../../common/key';
import { getStoreBrandList } from '../../../common/api/ApiRewardMall';

export const productStatusList = ['숨김', '판매중', '품절'];

interface Props {
  status: number;
  setTarget: Dispatch<React.SetStateAction<any>>;
}

export const SelectProductStatus: React.FC<Props> = ({ status, setTarget }) => {
  const [productStatus, setProductStatus] = useState<number>(0);

  const changeTarget = (idx: number) => {
    setTarget({
      value: idx,
      name: productStatusList[idx],
    });
  };

  useEffect(() => {
    switch (status) {
      case 0:
        setProductStatus(0);
        changeTarget(0);
        break;
      case 1:
        setProductStatus(1);
        changeTarget(1);
        break;
      default:
        setProductStatus(2);
        changeTarget(2);
    }
  }, [status]);

  return (
    <Box>
      <Typography className='title'>상태</Typography>
      <TextField
        select
        sx={{ width: '50%' }}
        defaultValue={0}
        value={productStatus}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          const newStatus = Number(event.target.value);
          setProductStatus(newStatus);
          changeTarget(newStatus);
        }}
      >
        {productStatusList.map((item: any, idx: number) => (
          <MenuItem key={`product_status_${idx}`} value={idx}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

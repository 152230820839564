import { WeekDay } from './others';

export interface IGuideTool {
  title: string;
  contents: string;
  image?: any;
  imageFile?: any;
  uploadUrl?: any;
}

export interface IChallengeReqeust {
  searchType?: number;
  drawType?: string;
  challengeId?: string;
  rewardId?: string;
  searchKey?: string;
  searchWord?: string;
  userIds?: string[];
  portalId?: string;
  status?: number;
  startDate?: number;
  endDate?: number;
  page?: number;
  rowsPerPage?: number;
  point?: number;
  message?: string;
}

export interface IChallengePost {
  challengeId?: string;
  advertisementId?: string;
  searchKey?: string;
  searchWord?: string;

  idx?: number;
  id?: string;
  cid?: string;
  name?: string;
  profile?: string;
  content?: string;
  img?: string;
  createTime?: number;
  useActionButton?: string;
  actionButtonText?: string;
  actionButtonUrl?: string;
  repeatType?: number;
  startDate?: number;
  endDate?: number;
  endDates?: number[];
  period?: number;
  useBrandPoint?: number;
  brandPointId?: string;
  introductionImage?: string;
  pointName?: string;
  creator?: string[];
  filters?: any[];
  expirationPeriod?: number;
}

export interface IChallengeCreate {
  ticket: string;
  challengeId: string;
  challengeType: number;
  challengeGoal: number;
  challengeGpsGoal?: number;
  challengeStepsMax: number;
  challengeTitle: string;
  challengeImgpathMain: string;
  challengeInfoDuration: string;
  challengeInfoReward: string;
  challengeInfoGoal: string;
  challengeTestStime: number;
  challengeViewStime: number;
  challengeViewEtime: number;
  challengeAttendStime: number;
  challengeAttendEtime: number;
  challengeStepsPerPoint: number;
  challengeStepsMin: number;
  challengeWalkCourseId: string;
  challengeWalkZoneId: string;
  challengeStampTourId: string;
  challengeDescription: string;
  challengeCreator?: string;
  challengeStatus: number;
  challengeViewType: number;
  useChallengePost: number;
  challengeRewardType: number;
  testType: number;
  challengeApplyStatus: number;
  customPopupText: string;
  customPopupDeleted: number;
  challengeCreatorList?: string[];
  coverInfo: string;
  coverReward: string;
  coverAd: string;
  classType: number;
  recommendType: number;
  attendLimit: number;
  challengeAddress: string;
  challengeLocationLat: number;
  challengeLocationLng: number;
  isWithStep?: number;
  isLocationCert?: number;
  photoCertDescription?: string;
  photoCertificateWeeks?: number[];
  photoCertificateTimes?: string[];
  photoCertSuccessImage?: string;
  photoCertFailImage?: string;
  brandPointId?: string;
  hidden: number;
  bannerImage?: string;
  link?: string;
  challengeFieldId?: string;
  maxPoint?: number;
  useRank?: number;
  isPrivate?: number;
  useGallery?: number;
  allowAppReview?: number;
  invitationLink?: string;
  challengeSubType?: number;
  statType?: number;
  filters?: IGroupFilter[];
  geohashs?: [];
  region?: [];
  location?: [];
  authRegions?: [];
  authAddress?: [];
  ages?: [];
  gender?: 0;
  gpsGetType?: number;
  useLocationFilter?: number;
}

export interface IGroupFilter {
  filterType: 'group';
  targetId: string;
  challengeId?: string;
  communityName?: string;
}

export interface IFilter {
  filterType: number;
  targetId?: string;
  targetAmd?: string;
  targetAddress?: string;
}

export interface IAuthRegion {
  targetType: number;
  targetId?: string;
  targetAmd?: string;
  targetAddress?: string;
}

export interface IAuthAddress {
  targetType: number;
  targetId?: string;
  targetAmd?: string;
  targetAddress?: string;
}

export interface IChallengeFilter {
  challengeId: string;
  filters: IFilter[];
  geohashs: string[];
  region: IFilter[];
  location: IFilter[];
  authRegions: IAuthRegion[];
  authAddress: IAuthAddress[];
  ages: number[];
  gender: number;
}

export interface IChallengeRewardBatch {
  challengeId: string;
  rewards: IChallengeReward[];
}

export interface IChallengeReward {
  rewardId: string;
  challengeId: string;
  rewardTitle: string;
  rewardSponsored: string;
  rewardMaxCount: number;
  rewardUseStime: number;
  rewardUseEtime: number;
  rewardUseDay: number;
  rewardStatus: number;
  rewardUseType: number;
  rewardImgpathMain: string;
  rewardImgpathSub: string;
  rewardDescription: string;
  rewardPointPerGet: number;
  rewardStampPerGet: number;
  rewardStepPerGet: number;
  rewardAddress: string;
  rewardContact: string;
  rewardCouponLink: string;
  rewardIsDuplicate: number;
  rewardGiveType: number;
  rewardSummary: string;
  rewardLocationLat: number;
  rewardLocationLng: number;
  rewardCreator: string;
  rewardEditor: string;
  rewardUsetypeSub: number;
  rewardApplyform: string;
  link: string;
  phoneNo: string;
  brandPointId?: string;
  storeId?: string;
  applyformVersion: number;
  rewardValue: number;
  certTitle: string;
  certContent: string;
  rewardAutoDrawDate?: number;
  invitationLink?: string;
  checkedMission?: string[];
}

export interface IChallengeConfirm {
  challengeId: string;
  testType: number;
}

export interface IChallengeApplyStatus {
  challengeId: string;
  status: number;
  message: string;
}

export interface IChallengeClassType {
  type: number;
  title: string;
}

// ç���� ���� Ÿ�� (�׷���� ������, ����, ������)
export interface IChallengeSubType {
  challengeType: number;
  subTypeList: {
    challengeSubType: number;
    challengeSubTypeCreateTime: number;
    challengeSubTypeDescription: string;
    challengeSubTypeName: string;
  }[];
}

// ç���� �� Ÿ�� (�׷���� ���, ����)
export interface IChallengeStatType {
  challengeType: number;
  challengeSubType: number;
  statTypeList: {
    challengeStatType: number;
    challengeStatTypeCreateTime: number;
    challengeStatTypeDescription: string;
    challengeStatTypeName: string;
  }[];
}

// �׷���� Ŀ�´�Ƽ ����
export interface IGroupBattleCommunity {
  challengeId?: string;
  communityName: string;
  filterType: string;
  targetId: string;
}

export const DefaultChallengeCreate: IChallengeCreate = {
  ticket: '',
  challengeId: '',
  challengeType: 0,
  challengeGoal: 0,
  challengeStepsMax: 0,
  challengeTitle: '',
  challengeImgpathMain: '',
  challengeInfoDuration: '',
  challengeInfoReward: '',
  challengeInfoGoal: '',
  challengeTestStime: 0,
  challengeViewStime: 0,
  challengeViewEtime: 0,
  challengeAttendStime: 0,
  challengeAttendEtime: 0,
  challengeStepsPerPoint: 0,
  challengeStepsMin: 0,
  challengeWalkCourseId: '',
  challengeWalkZoneId: '',
  challengeStampTourId: '',
  challengeDescription: '',
  challengeCreator: '',
  challengeStatus: 0,
  challengeViewType: 0,
  useChallengePost: 0,
  challengeRewardType: 0,
  testType: 0,
  challengeApplyStatus: 0,
  customPopupText: '',
  customPopupDeleted: 1,
  coverInfo: '',
  coverReward: '',
  coverAd: '',
  classType: 0,
  recommendType: 0,
  attendLimit: 0,
  challengeAddress: '',
  challengeLocationLat: 0,
  challengeLocationLng: 0,
  hidden: 0,
};

export const enum ChallengeType {
  STEPS = 1, // 걸음 수 챌린지
  WALKCOURSE = 4, // 길 따라 걷기 챌린지
  DONATE = 5, // 기부 챌린지
  MILEAGE = 6, // 마일리지 챌린지
  STAMP = 7, // 스탬프 투어 챌린지
  WALKCOURSE_DAILY = 9, // 매일 따라 걷기 챌린지
  // POINT_OUTBOUND = 10, //( 마일리지) 타사와의 포인트 연계 타입, 워크온내에서 포인트 사용 기능 없음
  // LOCitems-centerHALLENGE = 11, //우리 동네 챌린지
  PHOTO_CERTIFICATE = 12, // 사진 인증형 챌린지
  WALKCOURSE_DAILY_STEPS = 13, // 매일 따라 걷기 + 걸음 수 챌린지
  STAMP_STEPS = 14, // 스탬프 투어 + 걸음 수 챌린지
  WALKCOURSE_STEPS = 15, // 길 따라 걷기 + 걸음 수 챌린지
  AUDIO_LISTENING = 16, // 오디오 듣기 챌린지
  CHARACTER_CATCH = 17, // 캐릭터 잡기 챌린지
  GROUP_BATTLE = 18,
}

export const enum ChallengeSubType {
  WALK = 1, // 걸음 수
  PHOTO = 2, // 사진인증
  STAMP = 3, // 스탬프
}

export const enum ChallengeStatType {
  AVERAGE = 1, // 평균
  ACCUMULATE = 2, // 누적
}

export interface IChallenge {
  challengeAttendUserCount: number;
  challengeEndDate: number;
  challengeId: string;
  challengeImage: string;
  challengeStartDate: number;
  challengeTitle: string;
  challengeViewEndDate: number;
  challengeViewStartDate: number;
  challengeTestStartDate: number; // 테스트 시작 날짜 (없을 수도 있음)
  isFilteredByLocation: boolean;
  isFilteredByRegion?: boolean;
  challengeType: ChallengeType;
  challengeSubType?: ChallengeSubType; // 그룹대항 1-걸음수 2-사진인증 3-스탬프
  statType?: ChallengeStatType; // 그룹대항1-평균 2-누적
  portalId: string;
  portalName: string;
  invitationLink: string | null; // 초대링크
}

export const DefaultChallenge: IChallenge = {
  challengeAttendUserCount: 0,
  challengeEndDate: 0,
  challengeId: '',
  challengeImage: '',
  challengeStartDate: 0,
  challengeTitle: '',
  challengeViewEndDate: 0,
  challengeViewStartDate: 0,
  challengeTestStartDate: 0,
  isFilteredByRegion: false,
  isFilteredByLocation: false,
  challengeType: 1,
  portalId: '',
  portalName: '',
  invitationLink: '',
};

export interface IAudioInfo {
  idx: number;
  title: string;
  description: string;
  image: string;
}

export interface IAudio {
  audioContentId?: string;
  title: string;
  category: string;
  producer: string;
  image: string;
  mainContent?: string;
  mainContentFileName?: string;
  openDate?: number;
  playTime?: number;
  description: IAudioInfo[];
  walkonContentId: string; // 워크온 콘텐츠면 contentId보내기
}

export interface IChallengePhoto {
  day: WeekDay[]; // 사진 인증 가능 요일 목록(1-월 2-화 ... 7-일)
  certificateTime: {
    startTime: number; // 인증 시작 시간
    endTime: number; // 인증 종료 시간
  }[];
  isLocationCertificate: boolean; // 위치 정보 자동 등록 여부
  isWithStep: boolean; // 걸음수 포함 여부
  successImage: string; // 인증 성공 이미지
  failImage: string; // 인증 실패 이미지
  description: string; // 인증 방법 설명
}

export const DefaultExercise: IExercise = {
  homeTrainingId: '',
  sequence: 0,
  exerciseType: 0,
  goalType1: 0,
  goalType2: 0,
  breakTime: 0,
  afterBreakTime: 0,
  exerciseArea: '',
  videoCode: '',
  methodList: [],
};

export interface IExerciseForm {
  sequence: number;
  title: string;
  content: string;
  image: string;
  imageFile: any;
  uploadUrl: string;
}

export interface IExercise {
  homeTrainingId: string;
  sequence: number;
  exerciseType: number;
  goalType1: number;
  goalType2: number;
  breakTime: number;
  afterBreakTime: number;
  exerciseArea: string;
  videoCode: string;
  methodList: IExerciseForm[];
}

export const DefaultPhotoCertificate: IPhotoCertificate = {
  photoCertId: '',
  photoCertSuccessImage: '',
  photoCertFailImage: '',
  photoCertSuccessFile: '',
  photoCertFailFile: '',
  photoCertDescription: '',
  successCount: 1,
};

export const DefaultAudioContent: IAudioContent = {
  audioContentId: '',
  category: '',
  contentTitle: '',
  producer: '',
  image: '',
  contentFile: null,
  mainContent: '',
  audioContentPath: null,
  mainPlayTime: 0,
};

export const DefaultQuestionAnswer: IQuestionAnswer = {
  qnaId: '',
  goalPoint: 0,
  questionCount: 1,
  attendUserCount: 0,
  retryAnswer: 0,
};

export interface IPhotoCertificate {
  photoCertId: string;
  photoCertSuccessImage: string;
  photoCertFailImage: string;
  photoCertSuccessFile: string;
  photoCertFailFile: string;
  photoCertDescription: string;
  successCount: number;
}

export interface IAudioContent {
  audioContentId: string;
  category: string;
  contentTitle: string;
  producer: string;
  image: string;
  contentFile: any;
  mainContent: string;
  audioContentPath: any;
  mainPlayTime: number;
}

export interface IQuestionAnswer {
  qnaId: string;
  goalPoint: number;
  questionCount: number;
  attendUserCount: number;
  retryAnswer: number;
}

export interface IConnctedMission {
  challengeId: string;
  missionId: string;
  connectedId: string;
  connectedName: string;
  targetId: string;
  targetType: number;
  goal: number;
}

export interface IConnctedMissionItems {
  photoCertificate: IPhotoCertificate;
  audioContent: IAudioContent;
  questionAnswer: IQuestionAnswer;
  geohashs: any[];
  region: any[];
}

import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import useLocalStorage from '../../hooks/storage';
import { getAmdList } from '../../common/api/ApiGeohash';
import { KEY_AMD_LIST } from '../../common/key';

import {
  AddGroupTextField,
  CreatePaper,
  NoneBorderChip,
  TermPaper,
} from '../styles/Styles';

interface Props {
  location: any[];
  setLocation: Dispatch<any[]>;
  showDepth1?: boolean;
  limitKu?: boolean;
  selectOne?: boolean;
  includeAmd?: boolean;
  mapId?: string;
}

export const SwSelectLocationSimple: React.FC<Props> = ({
  location,
  setLocation,
  showDepth1 = true,
  limitKu = false,
  selectOne = false,
  includeAmd = false,
  mapId = 'map',
}) => {
  const locationSearch = useRef<any>();
  const [locationLimit, setLocationLimit] = useState<any>([]);
  const [amdList, setAmdList] = useState<any>();
  const [siList, setSiList] = useState<any>([]);
  const [dongList, setDongList] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [doIndex, setDoIndex] = useState<any>('');
  const [siIndex, setSiIndex] = useState<any>('');
  const [dongIndex, setDongIndex] = useState<any>('');
  const [showDongSelector, setShowDongSelector] = useState<any>(true);
  const [request, setRequest] = useState<boolean>(false);

  const settingMenu = (newAmdList: any) => {
    if (location === undefined || location === null) return;

    const address = location[0].location.split(' ');

    if (address.length > 1) {
      const tmpDoIndex = newAmdList.doNames
        .map((item: any, idx: number) => (item === address[0] ? idx : -1))
        .filter((item: any) => item !== -1);
      const newDoIndex = tmpDoIndex !== null ? tmpDoIndex[0] : 0;
      setDoIndex(newDoIndex);

      if (newDoIndex > 0) setSiList(newAmdList.doList[newDoIndex].siNames);

      const tmpSiIndex =
        newDoIndex === 0
          ? null
          : newAmdList.doList[newDoIndex].siNames
              .map((item: any, idx: number) => (item === address[1] ? idx : -1))
              .filter((item: any) => item !== -1);
      const newSiIndex = tmpSiIndex !== null ? tmpSiIndex[0] : 0;
      setSiIndex(newSiIndex);

      if (newSiIndex > 0)
        setDongList(newAmdList.doList[newDoIndex].siList[newSiIndex].dongNames);

      if (address.length > 2) {
        const tmpDongIndex =
          newSiIndex === 0
            ? null
            : newAmdList.doList[newDoIndex].siList[newSiIndex].dongNames
                .map((item: any, idx: number) =>
                  item === address[1] ? idx : -1
                )
                .filter((item: any) => item !== -1);
        const newDongIndex = tmpDongIndex !== null ? tmpDongIndex[0] + 1 : 0;
        setDongIndex(newDongIndex);
      }
    }
  };

  const {
    data,
    isLoading,
    refetch: refetchAmd,
  } = useQuery(
    KEY_AMD_LIST,
    () => {
      if (request) return getAmdList();
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          const newAmd = res.amd;
          setAmdList(newAmd);
          if (selectOne && location.length > 0) {
            settingMenu(newAmd);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const selectSiInfo = (selected: string) => {
    if (Number(selected) >= 0) {
      const idx = Number(selected);
      setSiList(amdList.doList[idx].siNames);
    }
    setSiIndex(0);
    setDongIndex(0);
    setShowDongSelector(true);
  };

  const selectDongInfo = (selected: string) => {
    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      const dongName = amdList.doList[doIndex].siList[idx].dongNames;
      setDongList(dongName);
    }
    setDongIndex(0);
  };

  const deleteSomething = (index: number, from: string) => {
    const newSomething = locationLimit.filter(
      (_val: any, i: number) => i !== index
    );
    setLocationLimit([...newSomething]);
  };

  const addLocationLimit = () => {
    if (amdList === undefined || amdList === null) return;
    const doName = doIndex >= 0 ? amdList.doNames[doIndex] : '';
    const siName = siIndex >= 0 ? amdList.doList[doIndex].siNames[siIndex] : '';
    const siAmdId =
      siIndex >= 0 ? amdList.doList[doIndex].siList[siIndex].amdId : '';
    const dongName =
      siIndex > 0 && dongIndex > 0
        ? amdList.doList[doIndex].siList[siIndex].dongNames[dongIndex]
        : '';

    let newLocaiton = `${doName} ${siIndex === 0 ? '' : siName} ${
      dongIndex === 0 ? '' : dongName
    }`.trimEnd();

    if (limitKu) {
      const newLimit = [newLocaiton];
      const lastChar = String(newLimit[0]).slice(-1);
      setShowDongSelector(false);
      // if (lastChar === '구') {
      //   setShowDongSelector(false);
      // } else {
      //   setShowDongSelector(true);
      // }
    }

    newLocaiton = newLocaiton.includes('전체')
      ? newLocaiton.split(' ')[0]
      : showDepth1
      ? newLocaiton
      : newLocaiton.split(' ')[1];

    if (selectOne && includeAmd) {
      setLocationLimit([newLocaiton]);
      setLocation([{ location: newLocaiton, amd: siAmdId }]);
    } else if (selectOne) {
      setLocationLimit([newLocaiton]);
      setLocation([newLocaiton]);
    } else {
      const newData = locationLimit.filter((d: string) => d === newLocaiton);
      if (newData.length === 0) {
        const newLimit = [...locationLimit, newLocaiton];
        setLocationLimit(newLimit);
        setLocation(newLimit);
      }
    }
  };

  useEffect(() => {
    if (doIndex >= 0 && siIndex >= 0) addLocationLimit();
  }, [doIndex, siIndex, dongIndex]);

  useEffect(() => {
    setLocationLimit(location);
  }, [location]);

  useEffect(() => {
    refetchAmd();
  }, [request]);

  useEffect(() => {
    setRequest(true);
  }, []);

  return (
    <>
      <div
        id={mapId}
        style={{ width: '100vw', height: '50vh', display: 'none' }}
      />
      <Box>
        <Box className='flex_center'>
          {amdList && (
            <TextField
              sx={{ width: '11rem', mr: '1rem', mb: 0 }}
              select
              value={doIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setDoIndex(event.target.value);
                selectSiInfo(event.target.value);
              }}
            >
              {amdList.doNames.map((option: string, idx: number) => (
                <MenuItem key={idx} value={idx}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          {siList && (
            <TextField
              sx={{ width: '11rem', mr: '1rem', mb: 0 }}
              select
              defaultValue={0}
              value={siIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setSiIndex(event.target.value);
                selectDongInfo(event.target.value);
              }}
            >
              {siList.map((option: string, idx: number) => (
                <MenuItem key={idx} value={idx}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
      </Box>
    </>
  );
};

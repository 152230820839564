import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getWalkcourseList } from '../../common/api/ApiWalkcourse';
import {
  KEY_STAMPTOUR_REQUEST_DETAIL_BY_CHALLENGE_ID,
  KEY_WALKCOURSE_LIST,
} from '../../common/key';
import {
  getStamptourRequestDetail,
  getStamptourRequestDetailByChallengeId,
} from '../../common/api/ApiStamptour';
import {
  DefaultStamptourDetail,
  IStamptourDetail,
} from '../../models/stamptour';
import { SwStamptourMap } from './map/SwStamptourMap';
import { SwConfirmDialog } from './views/SwConfirmDialog';
import { registerChallengeRequiredStamp } from '../../common/api/ApiChallenge';
import { SwSnackbar } from './views/SwSnackbar';

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  stamptourId: string;
  challengeId: string;
  readOnly?: boolean;
}

export const SwStampTourView: React.FC<Props> = ({
  title,
  open,
  onClose,
  stamptourId,
  challengeId,
  readOnly = false,
}) => {
  const queryClient = useQueryClient();
  const [commandType, setCommandType] = useState(0);
  const [mapReload, setMapReload] = useState(0);
  const [mapInfo, setMapInfo] = useState<any>();
  const [gpsData, setGpsData] = useState<any>([]);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchWord, setSearchWord] = useState<string>('');
  const [stamptourList, setStamptourList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [stamptourData, setStamptourData] = useState<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [stamptourDetail, setStamptourDetail] = useState<IStamptourDetail>(
    DefaultStamptourDetail
  );
  const [requiredList, setRequiredList] = useState<string[]>([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { refetch: stamptourRequestDetailRefetch } = useQuery(
    KEY_STAMPTOUR_REQUEST_DETAIL_BY_CHALLENGE_ID,
    () => {
      if (stamptourId.length > 0)
        return getStamptourRequestDetailByChallengeId(stamptourId, challengeId);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.creationRequestDetail) {
          const requiredItems: any[] = [];
          const tmpRoute = res.creationRequestDetail.stamp.map((stamp: any) => {
            const newDesc: any[] = [];
            if (stamp.description === null) {
              newDesc.push({
                description:
                  stamp.stampDescription !== null ? stamp.stampDescription : '',
                image:
                  stamp.stampImgpathMain !== null ? stamp.stampImgpathMain : '',
                idx: 1,
              });
            }
            const temp = {
              id: stamp.stampId,
              name: stamp.stampName,
              lat: stamp.stampPointLat,
              lng: stamp.stampPointLng,
              radius: stamp.stampRadius,
              description:
                stamp.description !== null
                  ? stamp.description
                  : JSON.stringify(newDesc),
              image: stamp.stampImgpathMain,
              className: stamp.className,
              link: stamp.link,
              phoneNo: stamp.phoneNo,
              address: stamp.address,
              stampAddress: stamp.stampAddress,
              checked: stamp.isRequired,
            };
            if (stamp.isRequired) requiredItems.push(stamp.stampId);
            return temp;
          });
          setRequiredList(requiredItems);
          setGpsData(tmpRoute);

          const newCircleData = {
            lat: res.creationRequestDetail.stamptour.courseCenterPointY,
            lng: res.creationRequestDetail.stamptour.courseCenterPointX,
            radius: res.creationRequestDetail.stamptour.courseCenterRadius,
          };

          const newCourseData = {
            gpsData: tmpRoute,
            circleData: newCircleData,
          };
          setStamptourData(newCourseData);

          setStamptourDetail(res.creationRequestDetail);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newStamptourId: string) => {
    queryClient.prefetchQuery(
      KEY_STAMPTOUR_REQUEST_DETAIL_BY_CHALLENGE_ID,
      () => getStamptourRequestDetailByChallengeId(newStamptourId, challengeId)
    );
  };

  const {
    mutate: updateRequiredStampMutation,
    isError: isStamptourMutateError,
  } = useMutation(registerChallengeRequiredStamp, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('필수 스탬프로 변경했습니다.');
      setTimeout((_: any) => {
        onClose();
      }, 1000);
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '필수 스탬프로 변경하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const checkChanged = () => {
    const newRequired = mapInfo.stamps
      .filter((stamp: any) => stamp.checked)
      .map((stamp: any) => stamp.id);

    const sortedNewRequired = [...newRequired].sort();
    const sortedRequiredList = [...requiredList].sort();

    const areArraysEqual =
      sortedNewRequired.length === sortedRequiredList.length && // 길이 비교
      sortedNewRequired.every(
        (value, index) => value === sortedRequiredList[index]
      );
    return !areArraysEqual;
  };

  const checkClose = () => {
    if (checkChanged()) setOpenConfirm(true);
    else onClose();
  };

  const onSave = () => {
    if (mapInfo !== null && mapInfo.stamps !== null) {
      const ids = mapInfo.stamps
        .filter((stamp: any) => stamp.checked)
        .map((stamp: any) => stamp.id);
      const data: any = {
        challengeId: challengeId,
        ids: ids,
      };
      if (ids === null || ids.length === 0) {
        setOpenSnackbar(true);
        setDialogMessage('먼저 필수 스탬프를 선택 후 저장을 누르세요.');
        return;
      }
      updateRequiredStampMutation(data);
    } else {
      setOpenSnackbar(true);
      setDialogMessage('먼저 필수 스탬프를 선택 후 저장을 누르세요.');
    }
  };

  useEffect(() => {
    if (open) {
      stamptourRequestDetailRefetch();
    } else {
      setOpenConfirm(false);
      setRequiredList([]);
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '76vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>{title}</span>
          <IconButton onClick={() => checkClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <SwStamptourMap
          stamptourData={stamptourData}
          mapInfo={mapInfo}
          setMapInfo={setMapInfo}
          readOnly={readOnly}
        />
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <DialogActions sx={{ p: '1rem' }}>
        {readOnly ? (
          <Button
            variant='contained'
            color='primary'
            sx={{ fontSize: '.87rem' }}
            onClick={checkClose}
          >
            확인
          </Button>
        ) : (
          <Box>
            <Button
              variant='contained'
              color='secondary'
              sx={{ fontSize: '.87rem', mr: '1rem' }}
              onClick={() => checkClose()}
            >
              취소
            </Button>
            <Button
              variant='contained'
              color='primary'
              sx={{ fontSize: '.87rem' }}
              onClick={onSave}
            >
              저장
            </Button>
          </Box>
        )}
      </DialogActions>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='코스를 선택하지 않고 팝업을 닫으시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          onClose();
        }}
        confirm={1}
      />
    </Dialog>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { ChallengeTypeBox } from '../ChallengeStyles';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { SwStampTourView } from '../../../commonComponent/SwStampTourView';
import { SwWalkCourseView } from '../../../commonComponent/SwWalkCourseView';
import {
  KEY_CHALLENGE_AUDIO_CONTENT,
  KEY_CHALLENGE_TOUR_CONNECTED_MISSION_LIST,
  KEY_WALKCOURSE_REQUEST_DETAIL,
} from '../../../../common/key';
import { getWalkcourseRequestDetail } from '../../../../common/api/ApiWalkcourse';
import { AudioContentConnectedMissionView } from './AudioContentConnectedMissionView';
import { uploadFile } from '../../../../common/api/ApiUpload';
import {
  getChallengeConnectedAudioContent,
  getChallengeConnectedMissionList,
  registerChallengeConnectMission,
} from '../../../../common/api/ApiChallengeTour';
import { RESULT_OK } from '../../../../common/resultCode';
import { selectedConnectedMissionItemsState } from '../../../../common/atom';

const connectMissionTypeList = [{ type: 1, title: '오디오' }];

interface Props {
  challengeId: string;
  mission: any;
  setMission: Dispatch<React.SetStateAction<any>>;
}

export const WalkCourseMissionView: React.FC<Props> = ({
  challengeId,
  mission,
  setMission,
}) => {
  const selectedConnectedMissionItems = useRecoilValue(
    selectedConnectedMissionItemsState
  );

  const { loginVal } = useSession();
  const findWalkcourseRef = useRef<any>();
  const [challengeWalkCourseId, setChallengeWalkCourseId] =
    useState<string>('');
  const [challengeWalkCourseName, setChallengeWalkCourseName] =
    useState<string>('');
  const [walkCourseDistance, setWalkCourseDistance] = useState<number>(0);
  const [missionGoal, setMissionGoal] = useState<number>(0);
  const [gpsGetType, setGpsGetType] = useState<number>(1);
  const [spotId, setSpotId] = useState<string>('0');
  const [spotList, setSpotList] = useState<any[]>([]);
  const [audioContent, setAudioContent] = useState<any>();
  const [contentList, setContentList] = useState<any>([]);
  const [connectMissionType, setConnectMissionType] = useState<number>(0);
  const [connectedMission, setConnectedMission] = useState<any>();
  const [reset, setReset] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');

  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [openWalkcourseView, setOpenWalkcourseView] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const searchWalkcourse = () => {
    setOpenType(1);
    setTargetTitle('길 코스');
    setSearchKey('walkcourse');
    setSearchWord(findWalkcourseRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchWalkcourse();
    }
  };

  const onChangeGpsGetType = (evt: any) => {
    const value = evt.currentTarget.value === 'bymanual' ? 1 : 2;
    setConnectMissionType(0);
    setGpsGetType(value);
    setMission({ ...mission, gpsGetType: value });
  };

  const {
    data: audioData,
    isLoading,
    refetch: challengeAudioContentRefetch,
  } = useQuery(
    KEY_CHALLENGE_AUDIO_CONTENT,
    () => {
      if (connectedMission && connectedMission.targetType === 1) {
        const newData: any = {
          challengeId: mission.missionId,
          missionId: connectedMission.targetId,
        };
        return getChallengeConnectedAudioContent(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (
          res !== null &&
          res.audioContent !== undefined &&
          res.audioContent !== null
        ) {
          setAudioContent(res.audioContent);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: challengeConnectedMissionRefetch } = useQuery(
    KEY_CHALLENGE_TOUR_CONNECTED_MISSION_LIST,
    () => {
      if (mission && challengeId) {
        const newData: any = {
          challengeId: challengeId,
          missionId: mission.missionId,
        };
        return getChallengeConnectedMissionList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.mission) {
          const tmpData = res.mission.map((item: any) => {
            const temp = {
              id: item.connectedId,
              name: item.connectedName,
              targetId: item.targetId,
              targetType: item.targetType,
              goal: item.goal,
            };
            return temp;
          });
          setContentList(tmpData);
          setConnectedMission(tmpData[0]);

          const connectedId =
            tmpData !== null && tmpData.length > 0 ? tmpData[0].id : '';
          const foundItem = spotList.filter(
            (item: any) => item.id === connectedId
          );
          if (foundItem !== null && foundItem.length > 0) {
            setSpotId(foundItem[0].id);
            setConnectMissionType(tmpData[0].targetType);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: walkcourseRequestDetailRefetch } = useQuery(
    KEY_WALKCOURSE_REQUEST_DETAIL,
    () => {
      if (challengeWalkCourseId && challengeWalkCourseId.length > 0)
        return getWalkcourseRequestDetail(challengeWalkCourseId);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.creationRequestDetail) {
          const newSpot = res.creationRequestDetail.spot.map((spot: any) => {
            const temp = {
              id: spot.spotId,
              name: spot.spotName,
              image: spot.spotImage,
              lat: spot.spotPointLat,
              lng: spot.spotPointLng,
              radius: spot.spotRadius,
              description: spot.description,
              className: spot.className,
              link: spot.link,
              phoneNo: spot.phoneNo,
              address: spot.address,
            };
            return temp;
          });

          setSpotList(newSpot);

          challengeConnectedMissionRefetch();
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeConnectMissionMutation,
    isError: isMutateError,
  } = useMutation(registerChallengeConnectMission, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('챌린지 미션 연결이 되었습니다.');
        // setReload(true);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `챌린지 미션 연결을 진행하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '챌린지 미션 연결이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const addConnectMission = (data: any) => {
    console.log('--> addConnectMission data:', data);
    const newPhotoCert = selectedConnectedMissionItems.photoCertificate;
    const newAudioContent = selectedConnectedMissionItems.audioContent;
    const newQuestionAnswer = selectedConnectedMissionItems.questionAnswer;
    const selectedStamp = spotList.filter((item: any) => item.id === spotId);

    const newData: any = {
      missionId: mission.missionId,
      connectedId: spotId,
      targetId:
        connectMissionType === 1
          ? newAudioContent.audioContentId
          : connectMissionType === 2 || connectMissionType === 3
          ? newPhotoCert?.photoCertId
          : connectMissionType === 4 || connectMissionType === 5
          ? newQuestionAnswer.qnaId
          : '',
      targetType: connectMissionType,
      goal:
        connectMissionType === 1
          ? 1
          : connectMissionType === 2 || connectMissionType === 3
          ? newPhotoCert?.successCount
          : connectMissionType === 4 || connectMissionType === 5
          ? 1
          : 1,
      audioContent:
        connectMissionType === 1
          ? {
              audioContentId: newAudioContent.audioContentId,
              category: newAudioContent.category,
              title: newAudioContent.contentTitle,
              mainContent: data.audioContentUrl,
              mainPlayTime: data.mainPlayTime,
              producer: newAudioContent.producer,
              image:
                newAudioContent.contentFile !== null
                  ? data.audioImageUrl
                  : newAudioContent.image.length > 0
                  ? newAudioContent.image
                  : '',
            }
          : null,
      photoCertificate:
        connectMissionType === 2 || connectMissionType === 3
          ? {
              certificateType: connectMissionType === 2 ? 1 : 2,
              successCount: newPhotoCert?.successCount,
              photoCertSuccessImage:
                newPhotoCert?.photoCertSuccessFile !== undefined &&
                newPhotoCert?.photoCertSuccessFile !== null
                  ? data.successUploadUrl
                  : newPhotoCert?.photoCertSuccessImage.length > 0
                  ? newPhotoCert?.photoCertSuccessImage
                  : '',
              photoCertFailImage:
                newPhotoCert?.photoCertFailFile !== undefined &&
                newPhotoCert?.photoCertFailFile !== null
                  ? data.failUploadUrl
                  : newPhotoCert?.photoCertFailImage.length > 0
                  ? newPhotoCert?.photoCertFailImage
                  : '',
              photoCertDescription: newPhotoCert?.photoCertDescription,
            }
          : null,
      questionAnswer:
        connectMissionType === 4 || connectMissionType === 5
          ? {
              qnaId: newQuestionAnswer.qnaId,
              title:
                selectedStamp !== null
                  ? selectedStamp[0].name
                  : connectMissionType === 4
                  ? '설문조사'
                  : '퀴즈',
              isRepeated: newQuestionAnswer.retryAnswer,
              type: connectMissionType === 4 ? 1 : 2,
            }
          : null,
    };
    const newMission: any = {
      challengeId: challengeId,
      connectedMissionInfo: newData,
    };
    console.log('--> audioContent: ', newAudioContent);
    console.log('--> photoCertificate: ', newPhotoCert);
    console.log('--> questonAnswer: ', newQuestionAnswer);
    console.log('--> registerChallengeConnectMissionMutation :', newMission);
    registerChallengeConnectMissionMutation(newMission);
  };

  async function uploadAudioMultipleFiles() {
    const newData = { ...selectedConnectedMissionItems.audioContent };
    console.log(
      '--> selectedConnectedMissionItems:',
      selectedConnectedMissionItems
    );
    var newUploadUrl = '';
    if (newData.contentFile !== undefined && newData.contentFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.contentFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newAudioContentPathUrl = '';
    var newMainPlayTime = newData.mainPlayTime;
    if (
      newData.audioContentPath !== undefined &&
      newData.audioContentPath.file !== null
    ) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        newData.audioContentPath.file
      );
      newAudioContentPathUrl = newUploadFile.data.uploadUrl.file.path;
      newMainPlayTime = newUploadFile.data.uploadUrl.file.duration;
    } else {
      newAudioContentPathUrl = newData.audioContentPath
        ? newData.audioContentPath.name
        : '';
    }

    addConnectMission({
      audioImageUrl: newUploadUrl,
      audioContentUrl: newAudioContentPathUrl,
      mainPlayTime: newMainPlayTime,
    });
  }

  const checkConnectMission = () => {
    if (connectMissionType === 1) {
      uploadAudioMultipleFiles();
      return;
    }
    addConnectMission({});
  };

  useEffect(() => {
    if (connectedMission !== undefined && connectedMission !== null) {
      challengeAudioContentRefetch();
    }
  }, [connectedMission]);

  useEffect(() => {
    if (challengeWalkCourseId && challengeWalkCourseId.length > 0) {
      walkcourseRequestDetailRefetch();
    }
  }, [challengeWalkCourseId]);

  useEffect(() => {
    const newSearchResult = [...searchResult];
    if (openType === 1) {
      setChallengeWalkCourseId(newSearchResult[0]?.id);
      setChallengeWalkCourseName(newSearchResult[0]?.name);
      setWalkCourseDistance(newSearchResult[0]?.distance);
      setMission({ ...mission, walkCourseId: newSearchResult[0]?.id });
    }
  }, [searchResult]);

  useEffect(() => {
    if (mission) {
      console.log('--> walkcousr :', mission);
      setChallengeWalkCourseId(mission.walkCourseId);
      setGpsGetType(mission.gpsGetType ? mission.gpsGetType : 1);
      setMissionGoal(mission.goal);
    }
  }, [mission]);

  return (
    <>
      <Box>
        <ChallengeTypeBox>
          <Box className='flex_start'>
            <Typography className='title'>길 코스</Typography>
            <TextField
              placeholder='길 코스 이름, 아이디 검색'
              sx={{ marginRight: '1rem', width: '21rem' }}
              inputRef={findWalkcourseRef}
              onKeyDown={evt => {
                handleKeyDown(evt, 'walkcourse');
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        searchWalkcourse();
                      }}
                      sx={{ pr: 0 }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className='flex_start'>
            <Typography className='title'>
              {challengeWalkCourseId && challengeWalkCourseId !== ''
                ? `${challengeWalkCourseId}`
                : ''}
            </Typography>
            {challengeWalkCourseId && challengeWalkCourseId !== '' && (
              <Button
                variant='outlined'
                color='primary'
                sx={{ ml: '1rem' }}
                onClick={() => setOpenWalkcourseView(true)}
              >
                코스 확인
              </Button>
            )}
          </Box>
        </ChallengeTypeBox>
        <ChallengeTypeBox sx={{ mt: '1rem' }}>
          <Typography className='title'>목표 퍼센트</Typography>
          <TextField
            sx={{ marginRight: '1rem' }}
            value={missionGoal}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const newGoal = Number(evt.target.value);
              setMissionGoal(newGoal);
              setMission({ ...mission, goal: newGoal });
            }}
          />
        </ChallengeTypeBox>
        <ChallengeTypeBox sx={{ mt: '1rem' }}>
          <Typography className='title' sx={{ mr: '1rem' }}>
            코스 획득 방식
          </Typography>
          <RadioGroup
            row
            aria-labelledby='is-auto-row-radio-buttons-group-label'
            name='is-auto-row-radio-buttons-group'
            value={gpsGetType === 1 ? 'bymanual' : 'auto'}
            onChange={evt => {
              onChangeGpsGetType(evt);
            }}
          >
            <FormControlLabel
              value='bymanual'
              control={<Radio />}
              label='수동'
            />
            <FormControlLabel value='auto' control={<Radio />} label='자동' />
          </RadioGroup>
        </ChallengeTypeBox>
        {gpsGetType === 1 && (
          <ChallengeTypeBox>
            <Box>
              <Box>
                <Typography className='title'>
                  장소 콘텐츠 연결(1개만 연결 가능)
                </Typography>
                <TextField
                  select
                  value={spotId}
                  sx={{ width: '20rem' }}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setSpotId(evt.target.value);
                  }}
                >
                  <MenuItem key='key_0' value='0'>
                    장소 콘텐츠 목록이 나옵니다.
                  </MenuItem>
                  {spotList &&
                    spotList.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
              <Box>
                <Typography className='title'>연결할 미션 선택</Typography>
                <TextField
                  select
                  value={connectMissionType}
                  sx={{ width: '20rem' }}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setConnectMissionType(Number(evt.target.value));
                  }}
                >
                  <MenuItem key='ckey_0' value='0'>
                    미션 종류를 선택해 주세요.
                  </MenuItem>
                  {connectMissionTypeList.map((item: any) => (
                    <MenuItem key={item.type} value={item.type}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
          </ChallengeTypeBox>
        )}
        {spotId !== '0' && connectMissionType === 1 && (
          <ChallengeTypeBox>
            <AudioContentConnectedMissionView
              audioContent={audioContent}
              setAudioContent={setAudioContent}
              setReset={setReset}
              reset={reset}
            />
          </ChallengeTypeBox>
        )}
      </Box>
      {!(spotId === '0' || connectMissionType === 0) && (
        <Box className='flex_start' sx={{ mt: '1rem' }}>
          <Button
            variant='outlined'
            color='primary'
            sx={{ mr: '1rem' }}
            onClick={() => setReset(true)}
          >
            초기화
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => checkConnectMission()}
          >
            미션 연결 저장
          </Button>
        </Box>
      )}
      <SwSearchTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwWalkCourseView
        title='설정된 코스 정보'
        open={openWalkcourseView}
        onClose={() => setOpenWalkcourseView(false)}
        courseId={challengeWalkCourseId}
        challengeId={challengeId}
      />
    </>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  SwSwitch,
  AddGroupTextField,
  NoneBorderChip,
  DraggablePaper,
} from '../../styles/Styles';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import {
  getPopularSearchTerm,
  registerPopularSearchTerm,
} from '../../../common/api/ApiChallenge';
import { RESULT_OK } from '../../../common/resultCode';
import { KEY_POPULAR_SERACH_TERM } from '../../../common/key';
import { PopularSearchTerm } from './PopularSearchTerm';
import { ThemeRegionManage } from './ThemeRegionManage';

interface Props {}

export const SearchWIndowKeyword: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ m: '2rem 0' }}>
      <ThemeRegionManage />
      <PopularSearchTerm />
    </Box>
  );
};

import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { NormalTable } from '../../commonComponent/table/NormalTable';
import { SwGridTable } from '../../commonComponent/table/SwGridTable';
import { SwTable } from '../../commonComponent/table/SwTable';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { getPushList, cancelPush } from '../../../common/api/ApiPush';
import { KEY_PUSH_LIST } from '../../../common/key';
import { IPush, IPushCancel } from '../../../models/push';
import { PushUpdate } from './PushUpdate';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { PushForm } from './PushForm';

interface Props {}

export const AdminPush: React.FC<Props> = () => {
  const site = 'webAdmin';

  return <PushForm site={site} />;
};

import {
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';
import { ChallengeOpenRegion } from './ChallengeOpenRegion';
import { ChallengeForm } from './ChallengeForm';
import { ChallengeReward } from './ChallengeReward';
import { DrawingSetting } from '../rewardDrawing/DrawingSetting';
import { Drawing } from '../rewardDrawing/Drawing';
import { GivingReward } from '../rewardDrawing/GivingReward';
import { RewardPutDel } from './RewardPutDel';
import { ChallengeResult } from './ChallengeResult';
import { ChallengePost } from './ChallengePost';
import { DrawResult } from './DrawResult';
import { SwChallengeFilter } from '../../commonComponent/SwChallengeFilter';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { ButtonBox, MarginButton } from './ChallengeStyles';
import {
  IChallengeReqeust,
  IChallengeCreate,
  DefaultChallengeCreate,
  IChallengeConfirm,
  IChallengeSubType,
  IChallengeStatType,
} from '../../../models/challenge';
import {
  cloneChallengeMaster,
  confirmChallengeRegister,
  downloadChallengeList,
  getChallengeList,
  updateChallengeAttendContent,
} from '../../../common/api/ApiChallenge';
import { KEY_CHALLENGE_LIST } from '../../../common/key';
import { WhiteTextField, SwSwitch } from '../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import {
  brandPointAdvertiserState,
  challengeState,
} from '../../../common/atom';
import { ChallengeAudioContent } from './ChallengeAudioContent';
import { ChallengeLocalStore } from './ChallengeLocalStore';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { ChallengeQRCode } from './ChallengeQRCode';
import { ChallengeHomeTraining } from './ChallengeHomeTraining';
import { ChallengeNFC } from './ChallengeNFC';
import { ChallengeTourMission } from './ChallengeTourMission';

interface Props {}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
});

const requestColumnlists = [
  '고객명',
  '챌린지 이름',
  '챌린지 종류',
  '참여 기간',
  '참여 인원 수',
  '리워드 개수',
];

const rewardColumns = [
  '순서',
  '리워드 이름',
  '수량',
  '지급 방법',
  '사용 방법',
  '상태',
];

export const ChallengeList: React.FC<Props> = () => {
  const [selectedChallengeInfo, setSelectedChallengeInfo] =
    useRecoilState(challengeState);
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const location = useLocation();
  const queryClient = useQueryClient();
  const [challengeInfo, setChallengeInfo] = useState<any>();
  const [challengeId, setChallengeId] = useState<string>('');
  const [requestList, setRequestList] = useState<any>([]);
  const [challengeList, setChallengeList] = useState<any>([]);
  const [challengeRequestData, setChallengeRequestData] = useState<any>();
  const [challengeMasterType, setChallengeMasterType] = useState<any>([]);
  const [challengeMasterSubType, setChallengeMasterSubType] = useState<
    IChallengeSubType[]
  >([]); // 챌린지 서브 타입
  const [challengeMasterStatType, setChallengeMasterStatType] = useState<
    IChallengeStatType[]
  >([]); // 챌린지 지표 타입 (1평균, 2누적)
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [challengeTitle, setChallengeTitle] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [rewardDrawUserList, setRewardDrawUserList] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [isReload, setIsReload] = useState<boolean>(true);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [searchKey, setSearchKey] = useState<string>('challenge');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSwitch, setOpenSwitch] = useState<boolean>(true);
  const [closeSwitch, setCloseSwitch] = useState<boolean>(true);
  const [challengeFilterInfo, setChallengeFilterInfo] = useState<any>();
  const [filter, setFilter] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedId, setSelectedId] = useState<any>('');
  const [selectedRow, setSelectedRow] = useState(-1);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== tabValue) setTabValue(newValue);
  };

  const prefetchWithSearchWord = (
    newSearchWord: string,
    newAttendStartTime: number,
    newAttendEndTime: number
  ) => {
    queryClient.prefetchQuery(KEY_CHALLENGE_LIST, () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newData = {
        status: status,
        startDate: newAttendStartTime,
        endDate: newAttendEndTime,
        challengeFilter: filter !== null ? filter.challenge : '',
        communityFilter: filter !== null ? filter.community : 0, // 1 : 있음, 2 : 없음
        locationFilter: filter !== null ? filter.location : 0, // 1 : 있음, 2 : 없음
        regionFilter: filter !== null ? filter.region : 0, // 1 : 있음, 2 : 없음
        useTypeFilter: filter !== null ? filter.useType : '',
        page: 1,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, newSearchWord),
      };
      return getChallengeList(newData);
    });
  };

  const reloadFromNewWindow = () => {
    // ?challengeId=901BFDA6EEFD42DC9637561438FEFF2C
    const newSearch = location.search;
    if (isReload && newSearch !== null && newSearch.length > 0) {
      const newUrl = newSearch.split('?');
      const newParams = newUrl[1].split('&');
      const newChallengeId = newParams[0].split('=')[1];
      const newAttendStartTime = newParams[1].split('=')[1];
      const newAttendEndTime = newParams[2].split('=')[1];
      setSearchWord(newChallengeId);
      setChallengeId(newChallengeId);
      setSelectedId(newChallengeId);
      prefetchWithSearchWord(
        newChallengeId,
        Number(newAttendStartTime),
        Number(newAttendEndTime)
      );
      setIsReload(false);
    }
  };

  const {
    data,
    isLoading,
    refetch: challengeRefetch,
  } = useQuery(
    KEY_CHALLENGE_LIST,
    () => {
      setLoading(true);
      const newDate = new Date().getTime() / 1000;
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newRequest = {
        status: status,
        startDate: rangeDate[0] !== null ? rangeDate[0] / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1] / 1000 : 0,
        challengeFilter: filter !== null ? filter.challenge : '',
        communityFilter: filter !== null ? filter.community : 0, // 1 : 있음, 2 : 없음
        locationFilter: filter !== null ? filter.location : 0, // 1 : 있음, 2 : 없음
        regionFilter: filter !== null ? filter.region : 0, // 1 : 있음, 2 : 없음
        useTypeFilter: filter !== null ? filter.useType : '',
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (checkRequest) return getChallengeList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        const newChallenge =
          res &&
          res.challenge &&
          res.challenge.challenge.map((item: any, idx: number) => {
            const newType = res.challenge.type
              .filter(
                (typeItem: any) => typeItem.challengeType === item.challengeType
              )
              .map((typeItem: any) => typeItem.challengeTypeDescription);
            const newAttendStime = timestamp2Localestring(
              item.challengeAttendStime,
              1000
            ).split(' ')[0];
            const newAttendEtime = timestamp2Localestring(
              item.challengeAttendEtime,
              1000
            ).split(' ')[0];
            const newData = [
              item.challengeCreatorName,
              item.challengeTitle,
              newType,
              `${newAttendStime}~${newAttendEtime}`,
              item.attendUserCount,
              item.rewardCount,
            ];
            if (challengeId === item.challengeId) {
              setSelectedRow(idx);
              setChallengeInfo(item);
            }
            return newData;
          });
        setRequestList(newChallenge);
        setChallengeList(res ? res.challenge.challenge : []);
        setChallengeRequestData(res ? res.challenge : null);
        setChallengeMasterType(res ? res.challenge.type : null);
        setChallengeMasterSubType(res ? res.challenge.subType : []);
        setChallengeMasterStatType(res ? res.challenge.statType : []);
        setRewardGivetype(res ? res.challenge.rewardGivetype : null);
        setRewardUsetype(res ? res.challenge.rewardUsetype : null);
        setBrandPointAdvertiserList(res ? res.challenge.advertiser : null);
        setTotalRecords(res.pagination.totalRecordCount);

        const newFilterInfo = {
          type: res ? res.challenge.type : null,
          giveType: res ? res.challenge.rewardGivetype : null,
          useType: res ? res.challenge.rewardUsetype : null,
        };
        setChallengeFilterInfo(newFilterInfo);

        if (selectedId !== '') {
          const newSelect = res.challenge.challenge.filter(
            (item: any) => item.challengeId === selectedId
          );
          setSelectedChallengeInfo((prevState: any) => ({
            ...newSelect[0],
            authAddress: newSelect.length > 0 ? newSelect[0].authAddress : '[]',
          }));
        } else {
          setSelectedChallengeInfo((prevState: any) => ({
            ...prevState,
            challengeType: 1,
            challengeId: '',
            useChallengePostAds: false,
            authAddress: '[]',
            gpsGetType: 0,
          }));
        }
        setSelectedId('');

        reloadFromNewWindow();
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number, newFilter: any) => {
    queryClient.prefetchQuery(KEY_CHALLENGE_LIST, () => {
      setLoading(true);
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newData = {
        status: status,
        startDate: rangeDate[0] !== null ? rangeDate[0] / 1000 : 0,
        endDate: rangeDate[1] !== null ? rangeDate[1] / 1000 : 0,
        challengeFilter: newFilter !== null ? newFilter.challenge : '',
        communityFilter: newFilter !== null ? newFilter.community : 0,
        locationFilter: newFilter !== null ? newFilter.location : 0,
        regionFilter: newFilter !== null ? newFilter.region : 0,
        useTypeFilter: newFilter !== null ? newFilter.useType : '',
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getChallengeList(newData);
    });
  };

  const { mutate: confirmChallengeRegisterMutation, isError: isMutateError } =
    useMutation(confirmChallengeRegister, {
      onSuccess: res => {
        console.log('confirmChallengeRegister res:', res);
        if (res.resultCode === RESULT_OK || res.challengeType === 5) {
          setOpenSnackbar(true);
          setAlertMessage('저장되었습니다.');
          challengeRefetch();
        } else {
          setAlertOpen(true);
          setAlertMessage('리워드를 등록해 주세요.');
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '챌린지를 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const { mutate: cloneChallengeMasterMutation, isError: isMasterMutateError } =
    useMutation(cloneChallengeMaster, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('복제 완료, 작성중 내역에서 확인하세요.');
          challengeRefetch();
        } else {
          setOpenSnackbar(true);
          setAlertMessage(
            `챌린지를 복제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          `챌린지를 복제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const {
    mutate: updateChallengeAttendContentMutation,
    isError: isAttendMutateError,
  } = useMutation(updateChallengeAttendContent, {
    onSuccess: res => {
      setOpenSnackbar(true);
      setAlertMessage('챌린지 컨텐츠가 변경되었습니다.');
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '챌린지 컨텐츠를 변경동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const searchChallenge = () => {
    setFilter(null);
    setPage(1);
    prefetch(1, null);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);
    prefetch(newPage, filter);
  };

  const confirmChallege = (testType: number) => {
    const newData: IChallengeConfirm = {
      challengeId: challengeId,
      testType: testType,
    };
    // if (
    //   (challengeInfo.rewardCount === 0 || challengeInfo.rewardCount === null) &&
    //   rewardCount === 0 &&
    //   challengeInfo.challengeType !== 5
    // ) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('리워드 정보를 입력해주세요');
    //   return;
    // }
    confirmChallengeRegisterMutation(newData);
  };

  const checkFilter = (testType: number) => {
    const newFilters = challengeInfo.challengeFilters;
    console.log('newFilters :', newFilters);
    const newFilterList =
      newFilters &&
      newFilters.map((item: any) => {
        const newData = {
          filterType: item.filterType,
          challengeId: challengeInfo.challengeId,
          targetId: item.targetId,
          communityName: item.name,
        };
        return newData;
      });

    if (newFilterList === undefined || newFilterList.length === 0) {
      setOpenFilter(true);
    } else {
      confirmChallege(testType);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchChallenge();
    }
  };

  const onDownloadChallengeList = (bcType: number) => {
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newRequest = {
      bcType: bcType, // 브지챌 다운로드 여부 (0: 일반, 1:브지챌)
      status: status,
      startDate: filter !== null ? filter.rangeDate[0] : 0,
      endDate: filter !== null ? filter.rangeDate[1] : 0,
      challengeFilter: filter !== null ? filter.challenge : '',
      communityFilter: filter !== null ? filter.community : 0, // 1 : 있음, 2 : 없음
      locationFilter: filter !== null ? filter.location : 0, // 1 : 있음, 2 : 없음
      regionFilter: filter !== null ? filter.region : 0, // 1 : 있음, 2 : 없음
      useTypeFilter: filter !== null ? filter.useType : '',
      page: page,
      rowsPerPage: rowsPerPage,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
    };
    downloadChallengeList(newRequest);
  };

  const onUpdateChallengeAttendContent = () => {
    const newData: any = {
      challengeId: challengeId,
    };
    updateChallengeAttendContentMutation(newData);
  };

  const cloneChallenge = () => {
    if (challengeId === '' || challengeInfo === null) {
      setOpenSnackbar(true);
      setAlertMessage(`복제할 챌린지를 선택해주세요.`);
      return;
    }
    const newCreatorList = JSON.parse(challengeInfo.creatorList);

    // photoCertificateTimes
    const parsedTimes: any[] =
      challengeInfo.photoCertificateTimes !== null &&
      challengeInfo.photoCertificateTimes.length > 0
        ? JSON.parse(challengeInfo.photoCertificateTimes)
        : [];
    const newTimes = parsedTimes.map(
      (time: any) => `${time.start}:00~${time.end}:00`
    );

    const parsedWeeks: any[] =
      challengeInfo.photoCertificateWeeks !== null &&
      challengeInfo.photoCertificateWeeks.length > 0
        ? JSON.parse(challengeInfo.photoCertificateWeeks)
        : [];

    const parsedBanner =
      challengeInfo.bannerImage !== null && challengeInfo.bannerImage.length > 0
        ? JSON.parse(challengeInfo.bannerImage)
        : [];

    const newChallengeBanner = parsedBanner.filter(
      (item: any, idx: number) => item.image !== ''
    );

    const newData: IChallengeCreate = {
      ticket: '',
      challengeId: challengeInfo.challengeId,
      challengeType: challengeInfo.challengeType,
      challengeSubType: challengeInfo.challengeSubType,
      statType: challengeInfo.statType,
      challengeGoal: challengeInfo.challengeGoal,
      challengeStepsMax: challengeInfo.challengeStepsMax,
      challengeStepsPerPoint: challengeInfo.challengeStepsPerPoint,
      challengeStepsMin: challengeInfo.challengeStepsMin,
      challengeWalkCourseId: challengeInfo.challengeWalkCourseId,
      challengeWalkZoneId: challengeInfo.challengeWalkZoneId,
      challengeStampTourId: challengeInfo.challengeStampTourId,
      challengeTitle: challengeInfo.challengeTitle,
      challengeImgpathMain: challengeInfo.challengeImgpathMain,
      challengeInfoDuration: challengeInfo.challengeInfoDuration,
      challengeInfoReward: challengeInfo.challengeInfoReward,
      challengeInfoGoal: challengeInfo.challengeInfoGoal,
      challengeTestStime: challengeInfo.challengeTestStime,
      challengeViewStime: challengeInfo.challengeViewStime,
      challengeViewEtime: challengeInfo.challengeViewEtime,
      challengeAttendStime: challengeInfo.challengeAttendStime,
      challengeAttendEtime: challengeInfo.challengeAttendEtime,
      challengeDescription: challengeInfo.challengeDescription,
      challengeCreator:
        newCreatorList !== undefined && newCreatorList.length > 0
          ? newCreatorList[0]
          : 'ADMIN',
      challengeCreatorList: newCreatorList,
      challengeStatus: challengeInfo.challengeStatus,
      challengeViewType: challengeInfo.challengeViewType,
      useChallengePost: challengeInfo.useChallengePost,
      challengeRewardType: 0,
      challengeApplyStatus: challengeInfo.challengeApplyStatus,
      testType: 0,
      customPopupText:
        challengeInfo.customPopupText === null
          ? ''
          : challengeInfo.customPopupText,
      customPopupDeleted:
        challengeInfo.customPopupDeleted === null
          ? 1
          : challengeInfo.customPopupDeleted === 0
          ? 0
          : 1,
      coverInfo: challengeInfo.coverInfo,
      coverReward: challengeInfo.coverReward,
      coverAd: challengeInfo.coverAd,
      classType: challengeInfo.classType,
      recommendType: challengeInfo.recommendType,
      attendLimit: challengeInfo.attendLimit,
      challengeAddress: challengeInfo.challengeAddress,
      challengeLocationLat: challengeInfo.challengeLocationLat,
      challengeLocationLng: challengeInfo.challengeLocationLng,
      isWithStep: challengeInfo.isWithStep,
      isLocationCert: challengeInfo.isLocationCert,
      photoCertDescription: challengeInfo.photoCertDescription,
      photoCertificateWeeks: parsedWeeks,
      photoCertificateTimes: newTimes !== null ? newTimes : [],
      photoCertSuccessImage: challengeInfo.photoCertSuccessImage,
      photoCertFailImage: challengeInfo.photoCertFailImage,
      brandPointId: challengeInfo.brandPointId,
      hidden: challengeInfo.hidden,
      bannerImage: JSON.stringify(newChallengeBanner),
      link: challengeInfo.link,
      challengeFieldId: challengeInfo.challengeFieldId,
      maxPoint: challengeInfo.maxPoint,
      useRank: challengeInfo.useRank,
      isPrivate: challengeInfo.isPrivate,
      useGallery: challengeInfo.useGallery,
      gpsGetType: challengeInfo.gpsGetType,
      useLocationFilter: challengeInfo.useLocationFilter,
    };

    cloneChallengeMasterMutation(newData);
  };

  useEffect(() => {
    prefetch(1, filter);
  }, [openSwitch, closeSwitch]);

  const chooseRequest = (i: number) => {
    const Chosen = requestList[i];
    setSelectedRow(i);
    const newChosen = [Chosen[2], Chosen[1], Chosen[3]];
    setChosenRequest([newChosen]);
    setChallengeId(challengeList[i].challengeId);
    setChallengeInfo(challengeList[i]);
    setTabValue(0);

    setSelectedChallengeInfo((prevState: any) => ({
      ...prevState,
      challengeType: challengeList[i].challengeType,
      challengeId: challengeList[i].challengeId,
      useChallengePostAds: challengeList[i].useChallengePostAds,
      authAddress: challengeList[i].authAddress,
      gpsGetType: challengeList[i].gpsGetType,
    }));
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== challengeInfo.rewardCount) {
      challengeRefetch();
    }
  };

  const reloadChallenge = (id: string) => {
    if (id !== '') {
      setChallengeId(id);
      setSelectedId(id);
      challengeRefetch();
    }
  };

  useEffect(() => {
    if (checkRequest) {
      challengeRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (checkRequest) {
      setPage(1);
      prefetch(1, filter);
    }
  }, [rangeDate]);

  useEffect(() => {
    if (filter !== null && filter !== undefined) {
      setPage(1);
      prefetch(1, filter);
    }
  }, [filter]);

  // useEffect(() => {
  //   if (reloadChallenge !== '') {
  //     challengeRefetch();
  //   }
  // }, [reloadChallenge]);

  return (
    <>
      <Box className='flex_between' sx={{ m: '1.5rem 0' }}>
        <Box className='flex_start'>
          <TextField
            placeholder='챌린지 이름, 아이디, 기관명, 포탈 아이디로 검색해 주세요'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em', mt: '1rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={searchChallenge}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box className='flex_start' sx={{ mr: '1rem' }}>
            <SwDateRangePicker
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
            />
          </Box>
          <Button color='info' variant='outlined' onClick={() => setOpen(true)}>
            <FilterAltOutlinedIcon />
            필터
            <ArrowBackIosNewOutlinedIcon
              sx={{ fontSize: '1rem', ml: '.5rem' }}
            />
          </Button>
        </Box>
        <Box sx={{ mr: '2rem' }}>
          <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onUpdateChallengeAttendContent()}
          >
            챌린지 컨텐츠 보정
          </Button>
          <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onDownloadChallengeList(1)}
          >
            브지챌 데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
          <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onDownloadChallengeList(0)}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
        </Box>
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>
              챌린지 <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
          <Box className='flex_between'>
            <Typography sx={{ mr: '.4rem' }}>Open</Typography>
            <SwSwitch
              defaultChecked={true}
              value={openSwitch}
              onChange={() => setOpenSwitch(!openSwitch)}
            />
            <Typography sx={{ ml: '1rem', mr: '.4rem' }}>Close</Typography>
            <SwSwitch
              defaultChecked={true}
              value={closeSwitch}
              onChange={() => setCloseSwitch(!closeSwitch)}
            />
          </Box>
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: '33vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {requestColumnlists.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) => (
                    <TableCell
                      key={rowIdx.toString()}
                      className={
                        selectedRow === idx &&
                        challengeList[idx].challengeStatus === 0
                          ? clsx('selected_row not_important_row')
                          : selectedRow === idx &&
                            challengeList[idx].isTest === 1
                          ? clsx(' selected_row tested_row')
                          : selectedRow === idx
                          ? clsx(' selected_row')
                          : challengeList[idx].isTest === 1
                          ? clsx('tested_row')
                          : challengeList[idx].challengeStatus === 0
                          ? clsx('not_important_row')
                          : ''
                      }
                    >
                      {rowIdx !== 1
                        ? row[rowIdx]
                        : challengeList[idx].challengeStatus === 0
                        ? `${row[rowIdx]} (close)`
                        : row[rowIdx]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Paper sx={{ p: '0rem 0' }}>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <Tab key={0} value={0} label='챌린지 정보' />
              {(selectedChallengeInfo.challengeType === 16 ||
                selectedChallengeInfo.challengeType === 19 ||
                selectedChallengeInfo.challengeType === 20 ||
                selectedChallengeInfo.challengeType === 21) && (
                <Tab key={1} value={1} label='오디오 콘텐츠' />
              )}
              {selectedChallengeInfo.challengeType === 24 && (
                <Tab key={2} value={2} label='투어미션' />
              )}
              {(selectedChallengeInfo.challengeType === 7 ||
                selectedChallengeInfo.challengeType === 14 ||
                selectedChallengeInfo.challengeType === 20) &&
                selectedChallengeInfo.gpsGetType === 3 && (
                  <Tab key={3} value={3} label='QR코드 관리' />
                )}
              {(selectedChallengeInfo.challengeType === 7 ||
                selectedChallengeInfo.challengeType === 14 ||
                selectedChallengeInfo.challengeType === 20) &&
                selectedChallengeInfo.gpsGetType === 4 && (
                  <Tab key={4} value={4} label='NFC 태그 관리' />
                )}
              {selectedChallengeInfo.challengeType === 23 && (
                <Tab key={5} value={5} label='운동 콘텐츠' />
              )}
              <Tab
                key={6}
                value={6}
                label={
                  <Badge
                    variant='dot'
                    color='warning'
                    invisible={
                      selectedChallengeInfo.authAddress !== undefined &&
                      selectedChallengeInfo.authAddress !== null
                        ? JSON.parse(selectedChallengeInfo.authAddress)
                            .length === 0
                        : false
                    }
                  >
                    공개 범위(필터)
                  </Badge>
                }
              />
              {selectedChallengeInfo.challengeType === 11 && (
                <Tab key={7} value={7} label='가게 정보' />
              )}
              <Tab key={8} value={8} label='리워드 정보' />
              <Tab
                key={9}
                value={9}
                label={
                  <Badge
                    variant='dot'
                    color='warning'
                    invisible={selectedChallengeInfo.useChallengePostAds !== 1}
                  >
                    게시판 광고
                  </Badge>
                }
              />
              <Tab key={10} value={10} label='리워드 지급/취소' />
              <Tab key={11} value={11} label='챌린지 결과' />
              <Tab key={12} value={12} label='추첨 결과' />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0} key={0}>
            <ChallengeForm
              challengeMasterType={challengeMasterType}
              challengeMasterSubType={challengeMasterSubType}
              challengeMasterStatType={challengeMasterStatType}
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} key={1}>
            <ChallengeAudioContent
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2} key={2}>
            <ChallengeTourMission
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3} key={3}>
            <ChallengeQRCode challengeInfo={challengeInfo} />
          </TabPanel>
          <TabPanel value={tabValue} index={4} key={4}>
            <ChallengeNFC challengeInfo={challengeInfo} />
          </TabPanel>
          <TabPanel value={tabValue} index={5} key={5}>
            <ChallengeHomeTraining challengeInfo={challengeInfo} />
          </TabPanel>
          <TabPanel value={tabValue} index={6} key={6}>
            <ChallengeOpenRegion
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={7} key={7}>
            <ChallengeLocalStore challengeInfo={challengeInfo} />
          </TabPanel>
          <TabPanel value={tabValue} index={8} key={8}>
            <ChallengeReward
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              saveRewardCount={saveRewardCount}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={9} key={9}>
            <ChallengePost
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={10} key={10}>
            <RewardPutDel
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={11} key={11}>
            <ChallengeResult
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={12} key={12}>
            <DrawResult
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
            />
          </TabPanel>
        </Box>
        <Box>
          <SwChallengeFilter
            open={open}
            onClose={() => setOpen(!open)}
            challengeFilterInfo={challengeFilterInfo}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box>
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => {
                window.location.reload();
              }}
            >
              신규작성
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => cloneChallenge()}
            >
              챌린지 복제
            </MarginButton>
          </Box>
          <Box>
            <Button
              variant='contained'
              color='info'
              onClick={() => checkFilter(0)}
            >
              승인
            </Button>
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents='공개 대상 커뮤니티 혹은 지역 설정이 되어있지 않습니다. 이대로 개설하시겠습니까?'
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onConfirm={() => {
          confirmChallege(0);
          setOpenFilter(false);
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </>
  );
};

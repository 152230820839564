import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { ChallengeTypeBox } from '../ChallengeStyles';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { SwStampTourView } from '../../../commonComponent/SwStampTourView';
import { SwFieldView } from '../../../commonComponent/SwFieldView';
import { SwSelectGeohashGroup } from '../../../commonComponent/SwSelectGeohashGroup';
import { RegionFilterMap } from '../../regionalFilter/RegionFilterMap';
import { NoneBorderChip } from '../../../styles/Styles';
import { KEY_CHALLENGE_FILTER_LIST } from '../../../../common/key';
import { getChallengeFilterList } from '../../../../common/api/ApiChallenge';
import { selectedConnectedMissionItemsState } from '../../../../common/atom';

interface Props {
  mission: any;
  setMission: Dispatch<React.SetStateAction<any>>;
}

export const LocationFilterView: React.FC<Props> = ({
  mission,
  setMission,
}) => {
  const [selectedConnectedMissionItems, setSelectedConnectedMissionItems] =
    useRecoilState(selectedConnectedMissionItemsState);

  const { loginVal } = useSession();
  const [regionLimit, setRegionLimit] = useState<string[]>([]);
  const [geohashGroupLimit, setGeohashGroupLimit] = useState<any>([]);
  const [geohashList, setGeohashList] = useState<any>([]);
  const [geohashData, setGeohashData] = useState<any>([]);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');

  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [openFieldView, setOpenFieldView] = useState<boolean>(false);

  const setupFilter = (newFilter: any) => {
    const newGeohashData = newFilter.geohash;

    if (newGeohashData && newGeohashData.length > 0) {
      setGeohashData([newGeohashData]);
    } else {
      setGeohashData([]);
      setRegionLimit([]);
    }

    const newRegionFilterList = newFilter.regionFilters;
    const newRegion =
      newRegionFilterList &&
      newRegionFilterList.map((item: any) => item.targetAddress);
    setRegionLimit(newRegion);

    var isSaved = false;
    const newGeohashGroupLimit =
      newRegionFilterList &&
      newRegionFilterList.map((item: any) => {
        const geo = {
          load: true,
          name: item.targetAddress,
          id: item.targetId,
          group: isSaved === false ? JSON.stringify(newFilter.geohash) : [],
          creator: '',
          createDate: '',
        };
        isSaved = true;
        return geo;
      });
    setGeohashGroupLimit(newGeohashGroupLimit);
  };

  const { refetch: challengeFilterInfoRefetch } = useQuery(
    KEY_CHALLENGE_FILTER_LIST,
    () => {
      if (mission && mission.missionId) {
        const newData: any = {
          challengeId: mission.missionId,
        };
        return getChallengeFilterList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setupFilter(res.filter);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const deleteAllRegion = () => {
    setRegionLimit([]);
    setGeohashGroupLimit([]);

    setSelectedConnectedMissionItems({
      ...selectedConnectedMissionItems,
      region: [],
      geohashs: [],
    });
  };

  useEffect(() => {
    if (geohashGroupLimit.length > 0) {
      const isLoad = geohashGroupLimit.filter(
        (_val: any, i: number) => _val.load === undefined
      );

      const newGeohash = geohashGroupLimit.map(
        (_val: any, i: number) => _val.name
      );
      const newGeohashData = geohashGroupLimit
        .filter((_val: any, i: number) => _val.group.length !== 0)
        .map((_val: any, i: number) =>
          _val.group.length === 0 ? [] : JSON.parse(_val.group)
        );
      setGeohashData(newGeohashData);
      setRegionLimit([...newGeohash]);

      const newRegions = geohashGroupLimit.map((item: any) => {
        const newData = {
          filterType: 2,
          filterTarget: item.id,
          targetAddress: item.name,
        };
        return newData;
      });
      setSelectedConnectedMissionItems({
        ...selectedConnectedMissionItems,
        region: newRegions,
      });
    }
  }, [geohashGroupLimit]);

  useEffect(() => {
    if (geohashList) {
      const newGeohashs = geohashList.map((item: any) => {
        const newData = {
          filterType: 1,
          targetHash: item,
        };
        return newData;
      });
      setSelectedConnectedMissionItems({
        ...selectedConnectedMissionItems,
        geohashs: newGeohashs,
      });
    }
  }, [geohashList]);

  useEffect(() => {
    if (mission) {
      challengeFilterInfoRefetch();
    }
    return () => {};
  }, [mission]);

  return (
    <>
      <Box>
        <Box className='flex_center'>
          <SwSelectGeohashGroup
            geohashGroup={geohashGroupLimit}
            setGeohashGroup={setGeohashGroupLimit}
            showContainer={false}
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>지역</Typography>
          {regionLimit.length > 0 &&
            regionLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={location}
                variant='outlined'
                // onDelete={() => deleteRegion(idx, 'regionLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              onClick={() => deleteAllRegion()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
      </Box>

      <Box>
        <RegionFilterMap
          geohashData={geohashData}
          setGeohashData={setGeohashData}
          setTargetGeohashList={setGeohashList}
          elementId='map-region'
        />
      </Box>
    </>
  );
};

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import {
  DefaultAudioContent,
  DefaultChallenge,
  DefaultChallengeCreate,
  DefaultExercise,
  DefaultPhotoCertificate,
  DefaultQuestionAnswer,
  IChallenge,
  IChallengeClassType,
  IConnctedMission,
  IConnctedMissionItems,
  IExercise,
} from '../models/challenge';
import { DefaultCommunity, ICommunity } from '../models/community';
import { IJobState } from '../models/common';

const { persistAtom } = recoilPersist();

export const brandPointAdvertiserState = atom({
  key: 'brandPointAdvertiser',
  default: [
    {
      cid: '',
      createTime: 0,
      id: '',
      introductionImage: '',
      name: '',
      pointName: '',
      profile: '',
      brandPointId: '',
    },
  ],
});

export const challengeState = atom({
  key: 'challengeInfo',
  default: {
    challengeTitle: '',
    challengeId: '',
    challengeType: 0,
    challengeTypeContents: {
      goal: '',
    },
    challengeMainImage: '',
    challengeHeaderInfo: '',
    exposureRangeDate: '',
    participationRangeDate: '',
    guideList: [],
    score: '',
    challengeheungtae: '',
    dataportalConnect: '',
    status: '',
    useChallengePostAds: 0,
    authAddress: '[]',
    gpsGetType: 0,
  },
});

export const challengeRewardState = atom({
  key: 'challengeReward',
  default: [
    {
      order: '',
      id: '',
      type: '',
      typedetail: {
        content1: '',
        content2: '',
        content3: '',
        content4: '',
      },
      duplicate: false,
      rangeDate: [],
      count: '',
      name: '',
      image: '',
      guideList: [],
      areaInfo: '',
      status: '',
    },
  ],
});

export const challengeBasicState = atom({
  key: 'challengeBasic',
  default: DefaultChallengeCreate,
});

export const challengeClassTypeState = atom<IChallengeClassType[]>({
  key: 'challengeClassType',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const chosenCommunityState = atom<ICommunity>({
  key: 'chosenCommunity',
  default: DefaultCommunity,
});

export const communitySearchState = atom({
  key: 'communitySearch',
  default: {
    select: '',
    searchWord: '',
    chosenId: '',
    page: 1,
  },
});

export const currentYearReportState = atom<number>({
  key: 'currentYearReport',
  default: 0,
});

export const dashBoardState = atom({
  key: 'dashboard',
  default: {
    count: 0,
  },
});

export const rangeSettingState = atom({
  key: 'rangeSetting',
  default: {
    community: '',
    group: '',
    area: [],
    region: [],
    gender: '',
    age: '',
  },
});

export const requiredAllAnswerSts = atom({
  key: 'requiredAllAnswer',
  default: {
    ids: [''], // qna Id
    requiredAll: 0, // 0 : 전체 필수응답 미선택, 1: 전체 필수응답 선택
  },
});

export const searchWalkcourseFlagState = atom<boolean>({
  key: 'searchWalkcourseFlag',
  default: false,
});

export const selectedFieldIdState = atom<string>({
  key: 'selectedFieldId',
  default: '',
});

export const selectedUserInfoState = atom({
  key: 'selectedUserInfo',
  default: {
    value: '',
    type: 'phoneNumber',
  },
});

// 대용량 데이터 다운 아톰
export const jobStatusState = atom<IJobState[]>({
  key: 'jobStatus',
  default: [],
  // effects_UNSTABLE: [persistAtom],
});

export const exerciseDataState = atom<IExercise[]>({
  key: 'exerciseData',
  default: [
    { ...DefaultExercise },
    { ...DefaultExercise },
    { ...DefaultExercise },
    { ...DefaultExercise },
  ],
  // effects_UNSTABLE: [persistAtom],
});

// Challenge Connected Mission
export const selectedConnectedMissionState = atom<IConnctedMission>({
  key: 'selectedConnectedMission',
  default: {
    challengeId: '',
    missionId: '',
    connectedId: '',
    connectedName: '',
    targetId: '',
    targetType: 1,
    goal: 1,
  },
});

export const selectedConnectedMissionItemsState = atom<IConnctedMissionItems>({
  key: 'selectedConnectedMissionItems',
  default: {
    photoCertificate: { ...DefaultPhotoCertificate },
    audioContent: { ...DefaultAudioContent },
    questionAnswer: { ...DefaultQuestionAnswer },
    geohashs: [],
    region: [],
  },
});

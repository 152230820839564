import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import React, {
  ChangeEvent,
  Dispatch,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { DraggablePaper } from '../../styles/Styles';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { useSession, xapi } from '../../../hooks/session';
import { IUploadFile } from '../../../models/common';
import {
  checkUrl,
  getAddUrl,
  INIT_LOCATION,
  timestamp2string,
} from '../../../common/helper';
import {
  getChallengeListByRegion,
  postChallengeBanner,
  postChallengeSearchThemeRegion,
  putChallengeBanner,
  putChallengeBannerPriority,
  putChallengeSearchThemeRegion,
} from '../../../common/api/ApiChallenge';
import { SwPushSelectLocation } from '../../commonComponent/SwPushSelectLocation';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { SwSelectLocationSimple } from '../../commonComponent/SwSelectLocationSimple';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { KEY_CHALLENGE_LIST_BY_REGION } from '../../../common/key';

const ChallengeChip = styled(Chip)({
  border: 'none',
  '& .MuiChip-deleteIcon': {
    fontSize: '.9rem',
    marginTop: '.1rem',
  },
});

interface Props {
  title: string;
  open: boolean;
  themeRegionInfo: any;
  onClose: () => void;
  onConfirm: () => void;
}

export const ThemeRegionSetting: React.FC<Props> = ({
  title,
  open,
  themeRegionInfo,
  onClose,
  onConfirm,
}) => {
  const { loginVal } = useSession();
  const findReceiverRef = useRef<any>();
  const [itemIdx, setItemIdx] = useState<number>();
  const [itemName, setItemName] = useState<string>('');
  const [itemImage, setItemImage] = useState<string>('');
  const [itemType, setItemType] = useState<number>(1);
  const [itemImageFile, setItemImageFile] = useState<any>(null);
  const [regionCode, setRegionCode] = useState<string>('0');
  const [locationLimit, setLocationLimit] = useState<any[]>([INIT_LOCATION]);
  const [challenges, setChallenges] = useState<any[]>([]);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: challengeListByRegionRefetch,
  } = useQuery(
    KEY_CHALLENGE_LIST_BY_REGION,
    () => {
      const newData = {
        page: 0,
        rowsPerPage: 0,
        emdCode: locationLimit[0].amd,
      };
      if (open && locationLimit.length > 0 && itemType === 2)
        return getChallengeListByRegion(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setRegionCode(locationLimit[0].amd);
          const newChallenges = res.challenge.map((item: any) => {
            const newData: any = {
              id: item.challengeId,
              name: item.challengeTitle,
              isAuto: 1,
            };
            return newData;
          });
          setChallenges(newChallenges);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: postChallengeSearchThemeRegionMutate,
    isError: isMutateSearchThemeRegionError,
  } = useMutation(postChallengeSearchThemeRegion, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnacker(true);
        setDialogMessage('테마별/지역별 항목을 추가했습니다.');
        onConfirm();
      } else {
        setOpenSnacker(true);
        setDialogMessage(
          `테마별/지역별 항목 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnacker(true);
      setDialogMessage(
        `테마별/지역별 항목 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
      );
    },
  });

  const {
    mutate: putChallengeSearchThemeRegionMutate,
    isError: isMutateError,
  } = useMutation(putChallengeSearchThemeRegion, {
    onSuccess: () => {
      onConfirm();
    },
    onError: error => {
      setOpenSnacker(true);
      setDialogMessage(
        '테마별/지역별 항목 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const addItem = (newUploadUrl: string) => {
    const newImage =
      itemImageFile !== null
        ? newUploadUrl
        : itemImage.length > 0
        ? itemImage
        : '';
    const newData = {
      idx: itemIdx,
      name: itemName,
      image: newImage,
      type: itemType,
      region: locationLimit[0].amd,
      regionName: locationLimit[0].location,
      challenges: challenges,
    };
    const newThemeRegion: any = {
      themeRegion: newData,
    };
    if (itemIdx === 0) {
      postChallengeSearchThemeRegionMutate(newThemeRegion);
    } else {
      putChallengeSearchThemeRegionMutate(newThemeRegion);
    }
  };

  const uploadImage = () => {
    const uploadfile: IUploadFile = {
      type: 'challenge',
      file: itemImageFile,
    };
    var formData = new FormData();
    formData.append('file', itemImageFile);

    xapi
      .post(`/admin/v2/apis/upload/${uploadfile.type}`, formData)
      .then(res => {
        if (res.status !== 200) throw new Error('http 에러');

        addItem(res.data.uploadUrl.file.path);

        return res.data;
      });
  };

  const checkValid = () => {
    var val = 0;
    if (itemName === null || itemName.trim().length === 0) val = 1;

    if (val === 0) {
      if (itemImageFile !== null) {
        uploadImage();
      } else {
        addItem(itemImage);
      }
    } else {
      const msg = val === 1 ? '이름을 입력해주세요' : '';
      setOpenDialog(true);
      setDialogMessage(msg);
    }
  };

  const doSearchResult = () => {
    setSearchKey('challenging');
    setSearchWord(findReceiverRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      doSearchResult();
    }
  };

  useEffect(() => {
    if (locationLimit !== null && locationLimit.length > 0) {
      if (regionCode === '0' || regionCode !== locationLimit[0].amd)
        challengeListByRegionRefetch();
    }
  }, [locationLimit]);

  useEffect(() => {
    if (searchResult !== undefined && searchResult !== null) {
      const findItem = challenges.filter(
        (item: any) => item.id === searchResult[0].id
      );
      if (findItem !== null && findItem.length > 0) {
        setOpenSnacker(true);
        setDialogMessage('중복된 챌린지입니다.');
        return;
      }
      const newSearchResult = [...challenges, ...searchResult];
      setChallenges(newSearchResult);
    }
  }, [searchResult]);

  useEffect(() => {
    if (!open || (open && themeRegionInfo === null)) {
      setItemIdx(0);
      setItemName('');
      setItemImage('');
      setLocationLimit([INIT_LOCATION]);
      setItemType(1);
      setChallenges([]);
      setRegionCode('0');
    } else if (open && themeRegionInfo !== null) {
      setItemIdx(themeRegionInfo.idx);
      setItemName(themeRegionInfo.name);
      setItemImage(themeRegionInfo.image);
      setItemImageFile(null);
      setItemType(themeRegionInfo.type);
      setLocationLimit([
        themeRegionInfo.type === 1
          ? INIT_LOCATION
          : {
              location: themeRegionInfo.regionName,
              amd: themeRegionInfo.region,
            },
      ]);
      setRegionCode(themeRegionInfo.type === 1 ? '0' : themeRegionInfo.region);
      setChallenges([...themeRegionInfo.challenges]);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '45vw',
            minHeight: '55vh',
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle className='flex_between'>
          <Box>{title}</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          <Box>
            <Typography className='title'>이름</Typography>
            <TextField
              sx={{ width: '48rem' }}
              placeholder='이름 입력'
              value={itemName}
              onChange={(
                evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setItemName(evt.target.value)}
            />
          </Box>
          <Box>
            <Typography className='title'>이미지</Typography>
            <SwFileSetting
              image={
                itemImageFile !== null
                  ? itemImage
                  : itemImage !== ''
                  ? `${imageUrlPrefix}${itemImage}`
                  : ''
              }
              setImage={setItemImage}
              file={itemImageFile}
              setFile={setItemImageFile}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>지역별/테마별</Typography>
            <TextField
              select
              sx={{ width: '10rem' }}
              value={itemType}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setItemType(Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={1}>
                테마별
              </MenuItem>
              <MenuItem key={1} value={2}>
                지역별
              </MenuItem>
            </TextField>
            {itemType === 2 && (
              <SwSelectLocationSimple
                limitKu={true}
                includeAmd={true}
                selectOne={true}
                showDepth1={true}
                location={locationLimit}
                setLocation={setLocationLimit}
              />
            )}
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>챌린지 추가</Typography>
            <TextField
              placeholder='챌린지 이름 아이디 검색'
              inputRef={findReceiverRef}
              onKeyDown={handleKeyDown}
              sx={{ width: '44rem' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => doSearchResult()} sx={{ pr: 0 }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              <Grid container sx={{ mt: '.5rem' }}>
                {challenges.length > 0 &&
                  challenges.map((item: any, idx: number) => (
                    <Grid item xs={12} key={idx.toString()}>
                      <ChallengeChip
                        variant='outlined'
                        label={
                          <>
                            <span>{item.name}&nbsp;&nbsp;</span>
                            <span className='bluecontent'>{item.id}</span>
                          </>
                        }
                        onDelete={() => {
                          const newItems = challenges.filter(
                            (r: string, i: number) => i !== idx
                          );
                          setChallenges(newItems);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <Divider sx={{ p: 0, mt: '1rem' }} />
        <DialogActions>
          <Box sx={{ textAlign: 'right' }}>
            <Button variant='outlined' color='info' onClick={onClose}>
              취소
            </Button>
            <Button
              variant='contained'
              color='info'
              sx={{ ml: '1rem' }}
              onClick={checkValid}
            >
              저장
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwSearchTarget
        title={title !== null ? title[0] : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
    </>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Drawer,
  styled,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Dispatch, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useRecoilState, useSetRecoilState } from 'recoil';
import logo from '../../images/logo.svg';
import whitelogo from '../../images/whitelogo.svg';
import useClasses from '../../hooks/useClasses';
import { Footer } from '../commonComponent/views/Footer';
import tabItems from '../commonComponent/tabList.json';
import { useSession } from '../../hooks/session';
import { useResize } from '../../hooks/resize';
import { dashBoardState } from '../../common/atom';

interface Props {
  openNav: boolean;
  setOpenNav: Dispatch<React.SetStateAction<boolean>>;
  param: string;
}

interface ItabList {
  title: string;
  contents: {
    id: string;
    title: string;
  }[];
}

const devCheck = process.env.REACT_APP_SERVER_URL === 'http://52.78.235.179:89';

const LeftTab = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '18em',
    boxSizing: 'border-box',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const TabAccordion = styled(Accordion)({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 'inherit',
    borderLeft: '3px solid #2581FF',
    backgroundColor: devCheck ? '#383D4A' : 'rgba(37, 129, 255, 0.04)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '15px 0',
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: devCheck && '#2C313D',
    color: devCheck && '#FFFFFF',
  },
  '& .MuiAccordionDetails-root': {
    color: devCheck && '#FFFFFF',
    backgroundColor: devCheck && '#424242',
  },
  borderRadius: 1,
});

const TabTitle = styled(Typography)({
  fontSize: '.8rem',
  fontWeight: 500,
  color: '#999999',
  margin: '1rem',
  marginBottom: '.5rem',
});

const TabButton = styled(Button)({
  color: 'inherit',
  fontSize: '.85rem',
  textAlign: 'left',
  padding: '.5rem 1.5rem',
  justifyContent: 'flex-start',
});

const styles = {
  isDevTab: {
    '& .MuiDrawer-paper': {
      backgroundColor: '#2C313D !important',
      color: '#FFFFFF',
    },
  },
  defaultDashboard: {
    padding: '2rem 1rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
  },
  dashboard: {
    backgroundColor: '#F6F7F8',
  },
};

const tabList: ItabList[] = tabItems;

export const SideTab: React.FC<Props> = ({ openNav, setOpenNav, param }) => {
  const { logout } = useSession();
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const classes = useClasses(styles);
  const [dashbaordInfo, setDashboardInfo] = useRecoilState(dashBoardState);
  const [expanded, setExpanded] = useState<string | false>(false);
  const tabChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const currentTab = tabList.findIndex(
      q => q.contents.findIndex(w => w.title === param) !== -1
    );
    if (currentTab.toString() === '-1') return;
    setExpanded(currentTab.toString());
  }, [param]);

  return (
    <LeftTab
      open={openNav}
      variant='persistent'
      // variant='temporary'
      className={clsx({ [classes.isDevTab]: devCheck })}
    >
      <Box className='flex_between navigation' onClick={() => navigate('/')}>
        {devCheck ? (
          <>
            <img src={whitelogo} alt='logo' className='homeLogo' />
            <Typography variant='h5'>개발</Typography>
          </>
        ) : (
          <>
            <img src={logo} alt='logo' className='homeLogo' />
            <Typography variant='h5' color='primary'>
              운영
            </Typography>
          </>
        )}
      </Box>
      <Box
        className={clsx(
          { [classes.dashboard]: !devCheck },
          classes.defaultDashboard
        )}
        onClick={() => {
          navigate('/');
        }}
      >
        <Box className='flex_start'>
          <Typography sx={{ mr: '1rem' }}>대시보드</Typography>
          <Badge
            badgeContent={
              Number.isNaN(dashbaordInfo.count) ? 0 : dashbaordInfo.count
            }
            color='primary'
          >
            <span> </span>
          </Badge>
        </Box>
      </Box>
      {tabList.map((list, idx) => (
        <div key={idx.toString()}>
          {idx === 0 && <TabTitle>서비스 관리</TabTitle>}
          {idx === 6 && <TabTitle>운영 관리</TabTitle>}
          <TabAccordion
            className={clsx({ isDev: devCheck })}
            expanded={expanded === idx.toString()}
            onChange={tabChange(idx.toString())}
            square
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <NavigateNextIcon className={clsx({ whitecolor: devCheck })} />
              }
            >
              <Typography variant='h6' component='div'>
                {list.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className='flex_column' sx={{ p: 0 }}>
              {list.contents.map((content, i) => (
                <TabButton
                  key={i.toString()}
                  onClick={() => navigate(`/${content.id}`)}
                  className={clsx(
                    { selected_tab: param === content.title && !devCheck },
                    { selected_devtab: param === content.title && devCheck }
                  )}
                >
                  {content.title}
                </TabButton>
              ))}
            </AccordionDetails>
          </TabAccordion>
        </div>
      ))}
      {isMobile() && <Button onClick={logout}>로그아웃</Button>}
      <Footer />
    </LeftTab>
  );
};

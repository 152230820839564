import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SelectTextField, TableTopButton, SwSwitch } from '../../styles/Styles';
import { timestamp2Localestring } from '../../../common/helper';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { ChallengeBannerSetting } from './ChallengeBannerSetting';
import { useSession } from '../../../hooks/session';
import { ChallengeBannerView } from './ChallengeBannerView';

interface Props {}

export const ChallengeBanner: React.FC<Props> = () => {
  const { loginVal } = useSession();

  return (
    <Box sx={{ m: '2rem 0' }}>
      <ChallengeBannerView type='main' viewTitle='챌린지 메인 광고 배너' />
      <ChallengeBannerView type='search' viewTitle='챌린지 검색창 광고 배너' />
    </Box>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRange, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSession } from '../../../../hooks/session';
import { ChallengeTypeBox } from '../ChallengeStyles';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import { SwStampTourView } from '../../../commonComponent/SwStampTourView';
import { GridTitle } from '../../../localstore/localstoreManagement/LocalstoreStyles';
import { newUuidId, SwUuidCreate } from '../../../commonComponent/SwUuidCreate';
import { SwFileSetting } from '../../../commonComponent/SwFileSetting';
import { SwSearchContentManageTarget } from '../../../commonComponent/SwSearchContentManageTarget';
import { selectedConnectedMissionItemsState } from '../../../../common/atom';

const Item = styled(Box)({
  textAlign: 'left',
  fontWeight: 500,
});

const GridCell = styled(Grid)({
  borderLeft: 'none',
  padding: '.7em',
});

interface Props {
  setAudioContent: Dispatch<React.SetStateAction<any>>;
  audioContent: any;
  setReset: Dispatch<React.SetStateAction<boolean>>;
  reset: boolean;
}

export const AudioContentConnectedMissionView: React.FC<Props> = ({
  setAudioContent,
  audioContent,
  setReset,
  reset,
}) => {
  const [selectedConnectedMissionItems, setSelectedConnectedMissionItems] =
    useRecoilState(selectedConnectedMissionItemsState);

  const { loginVal } = useSession();
  const searchAudioContentRef = useRef<any>();
  const [audioContentId, setAudioContentId] = useState<string>(newUuidId());
  const [connectedTitle, setConnectedTitle] = useState<string>();
  const [connectedInfo, setConnectedInfo] = useState<any>();
  const [audioContentPath, setAudioContentPath] = useState<any>();
  const [audioContentTitle, setAudioContentTitle] = useState<string>('');
  const [audioContentCategory, setAudioContentCategory] = useState<string>('');
  const [audioContentProducer, setAudioContentProducer] = useState<string>('');
  const [contentImage, setContentImage] = useState<string>('');
  const [contentFile, setContentFile] = useState<any>(null);
  const [mainPlayTime, setMainPlayTime] = useState<number>(0);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [targetTitle, setTargetTitle] = useState<string>('');

  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [openConnectedDialog, setOpenConnectedDialog] =
    useState<boolean>(false);

  const resetAudioContent = () => {
    setAudioContentId(newUuidId());
    setAudioContentTitle('');
    setAudioContentCategory('');
    setAudioContentPath({ name: '' });
    setAudioContentProducer('');
    setMainPlayTime(0);
    setContentFile(null);
    setContentImage('');
    setConnectedTitle('');
  };

  const searchAudioContent = () => {
    setTargetTitle('콘텐츠');
    setSearchKey('audio');
    setSearchWord(searchAudioContentRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchAudioContent();
    }
  };

  useEffect(() => {
    if (connectedInfo !== undefined && connectedInfo !== null)
      setConnectedTitle(connectedInfo.name);
    else setConnectedTitle('');
  }, [connectedInfo]);

  useEffect(() => {
    if (searchResult !== undefined && searchResult !== null) {
      const newContentId = newUuidId();
      setAudioContentId(newContentId);
      setAudioContentTitle(searchResult.title);
      setAudioContentCategory(searchResult.categoryTitle);
      setAudioContentProducer(searchResult.source);

      const newContentImage =
        searchResult.thumbImage !== null && searchResult.thumbImage !== ''
          ? searchResult.thumbImage
          : '';
      setContentImage(newContentImage);
      setContentFile(null);

      const newAudioContentPath =
        searchResult.audioContentPath !== null &&
        searchResult.audioContentPath !== ''
          ? { name: searchResult.audioContentPath, file: null }
          : { name: '', file: null };
      setAudioContentPath(newAudioContentPath);
      setMainPlayTime(searchResult.playTime);
      setSearchResult(null);

      setSelectedConnectedMissionItems({
        ...selectedConnectedMissionItems,
        audioContent: {
          audioContentId: newContentId,
          category: searchResult.categoryTitle,
          contentTitle: searchResult.title,
          producer: searchResult.source,
          image: searchResult.thumbImage,
          contentFile: null,
          mainContent: searchResult.audioContentPath,
          audioContentPath: newAudioContentPath,
          mainPlayTime: searchResult.playTime,
        },
      });
    }
  }, [searchResult]);

  useEffect(() => {
    if (audioContent) {
      setAudioContentId(audioContent.audioContentId);
      setAudioContentTitle(audioContent.title);
      setAudioContentCategory(audioContent.category);
      setAudioContentProducer(audioContent.producer);

      const newContentImage =
        audioContent.image !== null && audioContent.image !== ''
          ? audioContent.image
          : '';
      setContentImage(newContentImage);
      setContentFile(null);

      const newAudioContentPath =
        audioContent.mainContent !== null && audioContent.mainContent !== ''
          ? { name: audioContent.mainContent, file: null }
          : { name: '', file: null };
      setAudioContentPath(newAudioContentPath);
      setMainPlayTime(audioContent.mainPlayTime);
      setSearchResult(null);

      setSelectedConnectedMissionItems({
        ...selectedConnectedMissionItems,
        audioContent: {
          audioContentId: audioContent.audioContentId,
          category: audioContent.category,
          contentTitle: audioContent.title,
          producer: audioContent.producer,
          image: audioContent.image,
          contentFile: null,
          mainContent: audioContent.mainContent,
          audioContentPath: newAudioContentPath,
          mainPlayTime: audioContent.mainPlayTime,
        },
      });
    }
  }, [audioContent]);

  useEffect(() => {
    if (reset) {
      resetAudioContent();
      setReset(false);
    }
  }, [reset]);

  return (
    <>
      <Paper sx={{ backgroundColor: '#EFEFF3', mt: '1rem' }}>
        <Box sx={{ ml: '1rem' }}>
          <Box className='flex_start' sx={{ padding: '.7em' }}>
            <Typography className='title' sx={{ mt: '1rem' }}>
              오디오 콘텐츠 불러오기
            </Typography>
            <TextField
              placeholder='콘텐츠 이름, 아이디 검색'
              sx={{ ml: '.5rem', mt: '1rem', width: '21rem' }}
              inputRef={searchAudioContentRef}
              onKeyDown={evt => {
                handleKeyDown(evt, 'audio');
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        searchAudioContent();
                      }}
                      sx={{ pr: 0 }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              <GridTitle item xs={12}>
                <Item>콘텐츠 등록</Item>
              </GridTitle>
              <GridCell item xs={6}>
                <Box className='flex_between'>
                  <TextField
                    fullWidth
                    placeholder='`등록`버튼 선택'
                    value={audioContentPath?.name}
                  />
                  <Button
                    sx={{ ml: '1rem', mr: '1rem' }}
                    color='info'
                    variant='contained'
                    onClick={() => {
                      const newFile =
                        document.getElementById('audio_content_file');
                      newFile?.click();
                    }}
                  >
                    등록
                  </Button>
                  <input
                    className='nonedisplay'
                    id='audio_content_file'
                    accept='audio/*'
                    type='file'
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      if (evt.target.files) {
                        const newData: any = {
                          name: evt.target.files[0].name,
                          file: evt.target.files[0],
                        };
                        setAudioContentPath(newData);
                      }
                    }}
                  />
                </Box>
                <Typography className=''>
                  {' '}
                  play time :{' '}
                  {mainPlayTime > 0 ? `${mainPlayTime} sec` : '미등록'}
                </Typography>
              </GridCell>
            </Grid>
          </Box>
          <Box sx={{ ml: '.5rem' }}>
            <SwUuidCreate
              disabled={true}
              title='오디오 콘텐츠 아이디'
              id={audioContentId}
              setId={setAudioContentId}
            />
          </Box>
          <Box>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              <GridTitle item xs={4}>
                <Item>타이틀</Item>
              </GridTitle>
              <GridTitle item xs={4}>
                <Item>분류</Item>
              </GridTitle>
              <GridTitle item xs={4}>
                <Item>제작</Item>
              </GridTitle>
              <GridCell item xs={4}>
                <TextField
                  fullWidth
                  placeholder='콘텐츠 타이틀 입력'
                  value={audioContentTitle}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setAudioContentTitle(event.target.value);
                    setAudioContent({
                      ...audioContent,
                      title: event.target.value,
                    });
                  }}
                />
              </GridCell>
              <GridCell item xs={4}>
                <TextField
                  fullWidth
                  placeholder='분류 입력'
                  value={audioContentCategory}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setAudioContent({
                      ...audioContent,
                      category: event.target.value,
                    });
                    setAudioContentCategory(event.target.value);
                  }}
                />
              </GridCell>
              <GridCell item xs={4}>
                <TextField
                  fullWidth
                  placeholder='제작처 입력'
                  value={audioContentProducer}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setAudioContentProducer(event.target.value);
                    setAudioContent({
                      ...audioContent,
                      producer: event.target.value,
                    });
                  }}
                />
              </GridCell>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              <GridTitle item xs={12}>
                <Item>콘텐츠 이미지</Item>
              </GridTitle>
              <GridCell item xs={12}>
                <Box>
                  <SwFileSetting
                    setImage={setContentImage}
                    image={
                      contentFile !== null
                        ? contentImage
                        : contentImage !== ''
                        ? `${imageUrlPrefix}${contentImage}`
                        : ''
                    }
                    setFile={setContentFile}
                    file={contentFile}
                  />
                </Box>
              </GridCell>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <SwSearchContentManageTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
    </>
  );
};

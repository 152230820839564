import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
  TOOLTIP_FONT_SIZE,
  TOOLTIP_PROGRAM_EXPOSURE_RAGE,
  TOOLTIP_PROGRAM_MEMBER_LIMIT,
  TOOLTIP_PROGRAM_PARTICIPATION_RAGE,
  TOOLTIP_PROGRAM_TYPE,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
} from './ProgramStyles';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { registerProgramMaster } from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';

import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { SwSearchProgram } from '../../commonComponent/SwSearchProgram';
import { getFileSize, makeTemplateCode } from '../../../common/helperProgram';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_UPLOAD_FILE_SIZE,
} from '../../commonComponent/program/SwProgramCommon';
import { SwDateRangePickerWithoutDay } from '../../commonComponent/dateSetting/SwDateRangePickerWithoutDay';
import dynamicLink from '../../../images/dynamic-link.svg';

interface Props {
  programInfo: any;
  setProgramInfo: Dispatch<React.SetStateAction<any>>;
  reloadProgram: (id: string) => void;
}

const guidetool = {
  title: '소제목',
  contents: '안내내용',
  image: '',
  imageFile: null,
  uploadUrl: '',
};

const MAX_GUIDE_PAGE = 6;

export const ProgramForm: React.FC<Props> = ({
  programInfo,
  setProgramInfo,
  reloadProgram,
}) => {
  const location = useLocation();
  const usingTicket = useRef<any>();
  const programTitleRef = useRef<any>();
  const masterTypeRef = useRef<any>();
  const searchMasterTypeRef = useRef<any>();
  const { loginVal } = useSession();
  const [findCommunity, setFindCommunity] = useState<any>();
  // const [connectCommunity, setConnectCommunity] = useState<any>();
  const [selectedProgram, setSelectedProgram] = useState<boolean>(false);
  const [testType, setTestType] = useState<number>();
  const [masterType, setMasterType] = useState<any>([]);
  const [curriculumId, setCurriculumId] = useState<string>('');
  const [defaultCurriculumId, setDefaultCurriculumId] = useState<string>('');
  const [programTitle, setProgramTitle] = useState<string>('');
  const [programGoalPoint, setProgramGoalPoint] = useState<number>(0);
  const [programTotalPoint, setProgramTotalPoint] = useState<number>(0);
  const [programUserLimit, setProgramUserLimit] = useState<number>(0);
  const [programDescription, setProgramDescription] = useState<string>('');
  const [programStepsMax, setProgramStepsMax] = useState<number>(0);
  const [programStepsMin, setProgramStepsMin] = useState<number>(0);
  const [programStepsPerPoint, setProgramStepsPerPoint] = useState<number>(0);
  const [programStatus, setProgramStatus] = useState<number>(1);
  const [programInfoDuration, setProgramInfoDuration] = useState<string>('');
  const [programStampTourId, setProgramStampTourId] = useState<string>('');
  const [programWalkCourseId, setProgramWalkCourseId] = useState<string>('');
  const [programWalkZoneId, setProgramWalkZoneId] = useState<string>('');
  const [programInfoReward, setProgramInfoReward] = useState<string>('');
  const [programInfoGoal, setProgramInfoGoal] = useState<string>('');
  const [programViewType, setProgramViewType] = useState<any>('9');
  const [programTestStime, setProgramTestStime] = useState<number>(0);
  const [guideTitle, setGuideTitle] = useState<any>('');
  const [guideContent, setGuideContent] = useState<any>('');
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>(null);
  const [programMainImage, setProgramMainImage] = useState<string>('');
  const [programMainImageFile, setProgramMainImageFile] = useState<any>(null);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<any[]>([]);
  const [templateCode, setTemplateCode] = useState(1);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [programId, setProgramId] = useState<string>('');
  const [findDataPortal, setFindDataPortal] = useState<string>('');
  const [connectDataPortal, setConnectDataPortal] = useState<any>([]);
  const [dataPortal, setDataPortal] = useState<any>([]);
  const [customPopupText, setCustomPopupText] = useState<any>('');
  const [customPopupUseYn, setCustomPopupUseYn] = useState<any>(0);
  const [programApplyStatus, setProgramApplyStatus] = useState<any>(1);
  const [exposureRangeDate, setExposureRangeDate] = useState<any[]>([
    null,
    null,
  ]);
  const [participationRangeDate, setParticipationRangeDate] = useState<any[]>([
    null,
    null,
  ]);
  const [invitationLink, setInvitationLink] = useState<string>('');
  const [useRank, setUseRank] = useState<number>(1);
  const [isPrivate, setIsPrivate] = useState<number>(1);
  const [allowAppReview, setAllowAppReview] = useState<number>(0);
  const [hasCommonMission, setHasCommonMission] = useState<number>(0);
  const [useProgramPost, setUseProgramPost] = useState<number>(0);
  const [companyName, setCompanyName] = useState<string>('');
  const [addTerm1, setAddTerm1] = useState<string>('');
  const [addTerm2, setAddTerm2] = useState<string>('');
  const [extraTerms, setExtraTerms] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');
  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [disableType, setDisableType] = useState<boolean>(false);
  const [disabledSaved, setDisabledSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const guideItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  var guideIndex = 0;

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const {
    mutate: registerProgramMasterMutation,
    isError: isMasterMutateError,
  } = useMutation(registerProgramMaster, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        if (selectedProgram) {
          setOpenSnackbar(true);
          setAlertMessage('프로그램 마스터 정보가 저장되었습니다.');
          reloadProgram(programId);
        } else {
          setAlertOpen(true);
          setAlertMessage('프로그램 생성 탭에서 작성중 내역을 확인해주세요.');
        }
      } else {
        showError(
          `프로그램 마스터 정보를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
      setLoading(false);
      setDisabledSaved(false);
    },
    onError: error => {
      setLoading(false);
      setDisabledSaved(false);
      showError(
        `프로그램 마스터 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const saveFileImage = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.target.files &&
      setProgramMainImage(URL.createObjectURL(evt.target.files[0]));
  };

  const deleteFileImage = () => {
    URL.revokeObjectURL(programMainImage);
    setProgramMainImage('');
  };

  const addProgramMaster = (newGuideList: any, newUploadUrl: string) => {
    const newViewStime =
      exposureRangeDate[0] === null
        ? 0
        : new Date(exposureRangeDate[0]).getTime() / 1000;
    const newViewEtime =
      exposureRangeDate[1] === null
        ? 0
        : new Date(exposureRangeDate[1]).getTime() / 1000;
    const newAttendStime =
      participationRangeDate[0] === null
        ? 0
        : new Date(participationRangeDate[0]).getTime() / 1000;
    const newAttendEtime =
      participationRangeDate[1] === null
        ? 0
        : new Date(participationRangeDate[1]).getTime() / 1000;
    const newMainImage =
      programMainImageFile !== null
        ? newUploadUrl
        : programMainImage.length > 0
        ? programMainImage
        : '';
    const newDescription = newGuideList.map((item: any, idx: number) => {
      const newData = {
        idx: `${idx + 1}`,
        title: item.title,
        description: item.contents,
        image:
          item.imageFile !== null
            ? item.uploadUrl
            : item.image !== null && item.image !== undefined
            ? item.image
            : '',
      };
      return newData;
    });

    const newTags = JSON.stringify(tags);

    const tmpTermsOptions = {
      customerName: companyName,
      purpose: addTerm1,
      item: addTerm2,
    };
    const newTermsOptions = JSON.stringify(tmpTermsOptions);

    const newCreator =
      dataPortal.length > 0
        ? dataPortal.map((item: any) => item.portalId)
        : connectDataPortal !== undefined && connectDataPortal.length > 0
        ? connectDataPortal.map((item: any) => item.portalId)
        : ['ADMIN'];

    const newProgramApplyStatus =
      programInfo !== undefined &&
      programInfo !== null &&
      programInfo.programId === programId
        ? programApplyStatus
        : 1;

    if (newMainImage === undefined || newMainImage.length === 0) {
      setAlertMessage('프로그램 메인 이미지를 선택해주세요');
      setOpenSnackbar(true);
      setDisabledSaved(false);
      return;
    }

    const newData: any = {
      programId: programId,
      curriculumId: curriculumId,
      defaultCurriculumId: defaultCurriculumId,
      type: templateCode,
      title: programTitle,
      description: JSON.stringify(newDescription),
      image: newMainImage,
      prepareItem: newTags,
      userLimit: programUserLimit,
      termsOptions: newTermsOptions,
      additionalTerms: extraTerms,
      totalPoint: programTotalPoint,
      goalPoint: programGoalPoint,
      viewStartDate: newViewStime,
      viewEndDate: newViewEtime,
      attendStartDate: newAttendStime,
      attendEndDate: newAttendEtime,
      registerStatus: programApplyStatus,
      status: programStatus,
      portalId: newCreator[0],
      portalIds: newCreator,
      useRank: useRank,
      isPrivate: isPrivate,
      allowAppReview: allowAppReview,
      invitationLink: invitationLink,
    };

    console.log('registerProgramMaster :', newData);
    setLoading(true);
    registerProgramMasterMutation(newData);
  };

  const checkValid = () => {
    const newCustomerName = companyName;

    const newViewStime =
      exposureRangeDate[0] === null
        ? 0
        : new Date(exposureRangeDate[0]).getTime() / 1000;
    const newViewEtime =
      exposureRangeDate[1] === null
        ? 0
        : new Date(exposureRangeDate[1]).getTime() / 1000;
    const newAttendStime =
      participationRangeDate[0] === null
        ? 0
        : new Date(participationRangeDate[0]).getTime() / 1000;
    const newAttendEtime =
      participationRangeDate[1] === null
        ? 0
        : new Date(participationRangeDate[1]).getTime() / 1000;

    var val = 0;

    if (val === 0 && programId.length === 0) val = 1;
    if (val === 0 && hasCommonMission > 0 && newCustomerName.length === 0)
      val = 2;
    if (
      val === 0 &&
      (curriculumId === null || curriculumId.length === 0) &&
      (defaultCurriculumId === null || defaultCurriculumId.length === 0)
    )
      val = 3;
    if (val === 0 && programTitle.length === 0) val = 10;
    if (val === 0 && exposureRangeDate[0] === null) val = 13;
    if (val === 0 && participationRangeDate[0] === null) val = 14;
    if (
      val === 0 &&
      (newViewStime > newAttendStime || newViewEtime < newAttendEtime)
    )
      val = 15;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '프로그램 아이디를 입력해주세요.';
          break;
        case 2:
          message = '프로그램 약관에서 기관명을 입력해주세요';
          break;
        case 3:
          message = '프로그램 종류를 선택해주세요';
          break;
        case 10:
          message = '프로그램 이름을 입력해주세요';
          break;
        case 11:
          message = '프로그램 메인 이미지를 선택해주세요';
          break;
        case 12:
          message = '연결한 커뮤니티를 선택해주세요';
          break;
        case 13:
          message = '프로그램 노출기간을 지정해주세요';
          break;
        case 14:
          message = '프로그램 참여기간을 지정해주세요';
          break;
        case 15:
          message = '노출기간을 확인해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setOpenSnackbar(true);
    }

    return val;
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (programMainImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'program',
        programMainImageFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    const newGuideList = await Promise.all(
      guideLists.map(async (item: any, idx: number) => {
        var imageUrl = null;
        var newUploadFile: any = null;
        if (item.imageFile !== null) {
          newUploadFile = await uploadFile('program', item.imageFile);
        }

        return {
          title: item.title,
          contents: item.contents,
          image:
            item.image === null || item.image === undefined ? '' : item.image,
          imageFile: item.imageFile,
          uploadUrl:
            item.imageFile !== null
              ? newUploadFile.data.uploadUrl.file.path
              : null,
        };
      })
    );

    addProgramMaster(newGuideList, newUploadUrl);
  }

  function addGuideInfo() {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = guideLists;
      const newGuide = guideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        contents: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      return newGuideLists;
    }
    return [];
  }

  const addProgramGuideView = () => {
    if (guideLists.length === MAX_GUIDE_PAGE) {
      setAlertMessage('안내 내용은 최대 6까지 추가할 수 있습니다.');
      setOpenSnackbar(true);
      return;
    }

    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      const newGuide = [...newGuideLists, guidetool];
      setGuideLists(newGuide);
      setGuideTitle(guidetool.title);
      setGuideContent(guidetool.contents);
      setGuideImage('');
      setGuideImageFile(null);
      setGuidePage(guidePage + 1);
    }
  };

  const updateProgramGuideView = () => {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = [...guideLists];
      const newGuide = newGuideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        contents: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      setGuideLists(newGuideLists);
    }
  };

  const ChangePageContents = () => {
    setGuideTitle(
      guideLists[guidePage - 1] ? guideLists[guidePage - 1].title : '소제목'
    );
    setGuideContent(
      guideLists[guidePage - 1]
        ? guideLists[guidePage - 1].contents
        : '안내내용'
    );

    const newImage = guideLists[guidePage - 1]
      ? guideLists[guidePage - 1].image
      : '';
    setGuideImage(newImage);
  };

  const removeProgramGuideView = () => {
    console.log(guidePage);
    ChangePageContents();
    const newGuide = guideLists.filter((_guide, idx) => idx !== guidePage - 1);
    // const newGuidePage = guidePage - 1 < 1 ? guidePage + 1 : guidePage - 1;
    console.log('newguide>>>>>>>>>>>', newGuide);
    setGuideLists(newGuide);
    newGuide.length < guidePage && setGuidePage(guidePage - 1);
    // setGuidePage(guidePage);
    if (newGuide.length === 0) {
      setGuideTitle('');
      setGuideContent('');
      setGuidePage(1);
    }
  };

  const checkProgram = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      setDisabledSaved(false);
      return;
    }

    if (
      programMainImageFile !== undefined &&
      programMainImageFile !== null &&
      getFileSize(programMainImageFile) >= MAX_UPLOAD_FILE_SIZE
    ) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      setDisabledSaved(false);
      return;
    }

    const newGuideImages = guideLists
      .filter(
        (item: any, idx: number) =>
          item.imageFile !== undefined &&
          item.imageFile !== null &&
          getFileSize(item.imageFile) >= MAX_UPLOAD_FILE_SIZE
      )
      .filter(item => item === true);
    if (newGuideImages.length > 0) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      setDisabledSaved(false);
      return;
    }
    // Guide 마지막 페이지 수정사항 저장
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }

    uploadMultipleFiles();
  };

  const saveProgramMaster = () => {
    console.log('saveProgram !!!');
    setDisabledSaved(true);
    checkProgram();
  };

  const clearProgramMasterType = () => {
    masterTypeRef.current.value = '';
  };

  const searchProgramMasterType = () => {
    setTargetTitle('프로그램 템플릿 CODE');
    setSearchKey('masterType');
    setSearchWord(searchMasterTypeRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchProgramMasterType();
    }
  };

  const copyDynamicLink = async (text: string) => {
    if (text === undefined || text === null || text.length === 0) {
      setOpenSnackbar(true);
      setAlertMessage('초대링크에 오류가 있습니다.');
    }
    try {
      await navigator.clipboard.writeText(text === null ? '' : text);
    } catch (error) {
      console.log('er>', error);
    }
  };

  // Guide Drag & Drop
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: guideLists,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setGuideLists(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setGuideLists(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    guideItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    guideIndex = Number(evt.currentTarget.dataset.position);
    setGuidePage(guideIndex + 1);
    setListEvent({
      ...listEvent,
      clicked: newContain ? guideIndex : null,
    });
    setGuideTitle(guideLists[guideIndex].title);
    setGuideContent(guideLists[guideIndex].contents);
    setGuideImage(guideLists[guideIndex].image);
    setGuideImageFile(guideLists[guideIndex].imageFile);
  };

  useEffect(() => {
    const newSearchResult = [...searchResult];
    console.log('searchResult :', searchResult);
    if (searchResult.length > 0 && searchResult[0].defaultCurriculum !== null) {
      masterTypeRef.current.value = makeTemplateCode(newSearchResult[0]);
      setDefaultCurriculumId(searchResult[0].defaultCurriculum);
      setTemplateCode(searchResult[0].templateCode);
    }
    return () => {};
  }, [searchResult]);

  useEffect(() => {
    const newDate =
      participationRangeDate[0] !== null && participationRangeDate[1] !== null
        ? `${moment(new Date(participationRangeDate[0])).format(
            'YYYY/MM/DD'
          )} ~ ${moment(new Date(participationRangeDate[1])).format(
            'YYYY/MM/DD'
          )}`
        : '';
    setProgramInfoDuration(newDate);
  }, [participationRangeDate]);

  useEffect(() => {
    if (programInfo) {
      console.log('programInfo -> :', programInfo);
      setSelectedProgram(true);
      setProgramId(programInfo.programId);
      setCurriculumId(programInfo.curriculumId);
      setProgramTitle(programInfo.title);
      setProgramUserLimit(programInfo.userLimit);
      setProgramDescription(programInfo.description);
      setTemplateCode(programInfo.type);
      setProgramGoalPoint(programInfo.goalPoint);
      setProgramTotalPoint(programInfo.totalPoint);
      // setConnectCommunity(programInfo.communityId);
      setProgramStatus(programInfo.status);
      setProgramApplyStatus(programInfo.registerStatus);

      setProgramMainImage(programInfo.image !== null ? programInfo.image : '');
      setProgramMainImageFile(null);

      const newExposureDate = [
        timestamp2Localestring(programInfo.viewStartDate, 1000),
        timestamp2Localestring(programInfo.viewEndDate, 1000),
      ];
      const newParticipationDate = [
        timestamp2Localestring(programInfo.attendStartDate, 1000),
        timestamp2Localestring(programInfo.attendEndDate, 1000),
      ];
      setExposureRangeDate(newExposureDate);
      setParticipationRangeDate(newParticipationDate);

      setUseRank(programInfo.useRank);
      setIsPrivate(programInfo.isPrivate);
      setAllowAppReview(programInfo.allowAppReview || 0);
      setInvitationLink(programInfo.invitationLink || '');

      const newTags = JSON.parse(programInfo.prepareItem);
      setTags(newTags);

      const newTerms = JSON.parse(programInfo.termsOptions);
      setCompanyName(newTerms.customerName);
      setAddTerm1(
        newTerms.purpose !== undefined && newTerms.purpose !== 'undefined'
          ? newTerms.purpose
          : ''
      );

      const newItems = JSON.parse(programInfo.recordItem);
      setAddTerm2(
        newItems !== null && newItems.length > 0 ? newItems.join(',') : ''
      );
      setHasCommonMission(
        newItems !== null && newItems.length > 0 ? newItems.length : 0
      );
      setExtraTerms(
        programInfo.additionalTerms !== undefined &&
          programInfo.additionalTerms !== null
          ? programInfo.additionalTerms
          : ''
      );

      masterTypeRef.current.value = programInfo.typeFullName;

      setGuideImage('');
      setGuideImageFile(null);
      setGuidePage(1);
      setGuideTitle('');
      setGuideContent('');

      const newCreatorList =
        programInfo.creatorList !== undefined &&
        programInfo.creatorList !== null
          ? JSON.parse(programInfo.creatorList)
          : [];
      const newPortal = newCreatorList.map((item: any) => {
        const newData = {
          portalId: item,
          name: '',
        };
        return newData;
      });
      setConnectDataPortal(newPortal !== null ? newPortal : []);

      try {
        const newDescription = JSON.parse(programInfo.description);
        console.log('newDescription :', newDescription);
        const newGuideList =
          newDescription &&
          newDescription.map((item: any) => {
            const newGuide = {
              title: item.title,
              contents: item.description,
              image: item.image,
              imageFile: null,
            };
            return newGuide;
          });
        setGuideLists(newGuideList);
        if (newGuideList.length > 0) {
          setGuideImage(newGuideList[0].image);
          setGuideTitle(newGuideList[0].title);
          setGuideContent(newGuideList[0].contents);
        }

        const now = new Date(Date.now()).getTime() / 1000;
        programInfo.attendStartDate < now && programInfo.registerStatus === 4
          ? setDisableType(true)
          : setDisableType(false);
      } catch (error) {
        setGuideLists([]);
      }
    } else {
      location.pathname.includes('create')
        ? setSelectedProgram(true)
        : setSelectedProgram(false);
    }
    return () => {};
  }, [programInfo]);
  return (
    <>
      <CreatePaper sx={{ m: '0 0' }}>
        <Box>
          <Typography className='title'>사용한 이용권</Typography>
          <TextField sx={{ width: '18rem' }} disabled defaultValue='' />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>프로그램 종류</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_PROGRAM_TYPE}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <TextField
            disabled={disableType}
            placeholder='프로그램 이름, 코드 검색'
            sx={{ marginRight: '1rem', width: '21rem' }}
            inputRef={searchMasterTypeRef}
            onKeyDown={evt => {
              handleKeyDown(evt, 'masterType');
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    disabled={disableType}
                    onClick={() => {
                      searchProgramMasterType();
                    }}
                    sx={{ pr: 0 }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            disabled={disableType}
            sx={{ width: '25rem' }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    disabled={disableType}
                    sx={{ pr: 0 }}
                    onClick={clearProgramMasterType}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputRef={masterTypeRef}
          />
          <Divider />
        </Box>
        <Box>
          <Box
            className='flex_start'
            sx={{ alignItems: 'flex-end', display: 'flex' }}
          >
            <SwUuidCreate
              title='프로그램'
              id={programId}
              setId={setProgramId}
              disabled={true}
            />
            <Box>
              <Button
                variant='outlined'
                sx={{ ml: '1rem', mb: '.5rem' }}
                onClick={() => copyDynamicLink(programInfo.invitationLink)}
              >
                <img src={dynamicLink} alt='link' />
                초대링크
              </Button>
            </Box>
          </Box>
          <Divider />
        </Box>
        {/* <Box>
          <CommunityConnect
            findCommunity={findCommunity}
            setFindCommunity={setFindCommunity}
            connectCommunity={connectCommunity}
            setConnectCommunity={setConnectCommunity}
          />
          <Divider />
        </Box> */}
        <Box>
          <Box className='flex_start'>
            <Typography className='title_doubleline'>
              프로그램 인원 제한
            </Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_PROGRAM_MEMBER_LIMIT}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <Typography
            color='#999999'
            fontSize='.81rem'
            sx={{ pl: '.15rem', pb: '.5rem' }}
          >
            프로그램 참가 제한 인원을 입력해주세요.
          </Typography>
          <TextField
            sx={{ width: '12rem' }}
            value={programUserLimit}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) =>
              setProgramUserLimit(checkNaN(evt.target.value, programUserLimit))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>명</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>프로그램 진행기간</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_PROGRAM_PARTICIPATION_RAGE}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <SwDateRangePickerWithoutDay
            rangeDate={participationRangeDate}
            setRangeDate={setParticipationRangeDate}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>프로그램 노출기간</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_PROGRAM_EXPOSURE_RAGE}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <SwDateRangePicker
            rangeDate={exposureRangeDate}
            setRangeDate={setExposureRangeDate}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>프로그램 이름</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={programTitle}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setProgramTitle(evt.target.value.substring(0, 100))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{programTitle?.length} / 100</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>소개 이미지</Typography>
          <SwFileSetting
            image={
              programMainImageFile != null
                ? programMainImage
                : programMainImage !== ''
                ? `${imageUrlPrefix}${programMainImage}`
                : ''
            }
            setImage={setProgramMainImage}
            setFile={setProgramMainImageFile}
            file={programMainImageFile}
          />
          <Divider />
        </Box>
        {/* <Box>
          <Typography className='title'>프로그램 소개</Typography>
          <MultilineTextField
            fullWidth
            multiline
            rows={6}
            value={programDescription}
            onChange={evt =>
              setProgramDescription(evt.target.value.substring(0, 1000))
            }
            placeholder='프로그램 소개글을 1000자 이내로 작성해주세요.'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{programDescription?.length} / 1000</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box> */}
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>프로그램 소개 (선택)</Typography>
            <div>
              <Button onClick={updateProgramGuideView}>저장</Button>
              <Button onClick={addProgramGuideView}>추가</Button>
              {guideLists.length > 0 && (
                <Button onClick={removeProgramGuideView}>삭제</Button>
              )}
            </div>
          </Box>
          <TextField
            sx={{ width: '16rem' }}
            value={guideTitle}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setGuideTitle(evt.target.value)}
          />
          <TextField
            value={guideContent}
            fullWidth
            multiline
            rows={5}
            sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setGuideContent(evt.target.value)}
          />
          <SwFileSetting
            image={
              guideImageFile !== null
                ? guideImage
                : guideImage !== ''
                ? `${imageUrlPrefix}${guideImage}`
                : ''
            }
            setImage={setGuideImage}
            setFile={setGuideImageFile}
            file={guideImageFile}
          />
          {/* <Button
            variant='contained'
            onClick={() => {
              if (
                guideTitleRef.current.value &&
                guideContentsRef.current.value
              ) {
                const newTitle = guideTitleRef.current.value;
                const newContents = guideContentsRef.current.value;
                const newGuideLists = guideLists;
                const newGuideList = guideLists[guidePage - 1];
                newGuideLists[guidePage - 1] = {
                  ...newGuideList,
                  title: newTitle,
                  contents: newContents,
                };
                setGuideLists(newGuideLists);
              }
            }}
          >
            저장
          </Button> */}
          {/* <SwPagination
            page={guidePage}
            handleChangePage={onChangeChallengeGuideView}
            count={guideLists.length}
          /> */}
          <Typography className='title'>프로그램 소개 목록</Typography>
          {guideLists?.map((item: any, idx: number) => (
            <DraggablePaper
              key={idx.toString()}
              className='draggable'
              id={idx.toString()}
              variant='outlined'
              onDragStart={dragStart}
              onDragEnd={dragEnd}
              onDragOver={dragOver}
              onDrop={drop}
              onDragLeave={dragLeave}
              onDragEnter={dragEnter}
              draggable
              data-position={idx}
              onClick={selectList}
              sx={{ width: '50%' }}
            >
              <div>
                <span>{idx}. </span>
                <span>{item.title}</span>
              </div>
              <DragHandleIcon />
            </DraggablePaper>
          ))}
          <Divider />
        </Box>
        <Box>
          <SwSelectTags
            title='프로그램 준비물'
            description='준비물은 최대 5개까지 추가할 수 있습니다.'
            placeholder='준비물을 입력해주세요.'
            keyword='준비물'
            tags={tags}
            setTags={setTags}
          />
          <Divider />
        </Box>
        {/* <Box>
          <Typography className='title'>이수증 발급 목표 설정</Typography>
          <TextField
            sx={{ marginRight: '1rem' }}
            value={programGoalPoint}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setProgramGoalPoint(checkNaN(evt.target.value, programGoalPoint));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>점</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box> */}
        <Box>
          <DataportalMultiConnect
            findDataportal={findDataPortal}
            setFindDataportal={setFindDataPortal}
            connectDataportal={connectDataPortal}
            setConnectDataportal={setConnectDataPortal}
            setTargetInfo={setDataPortal}
            isDetail={true}
          />
          <Divider />
        </Box>
        {hasCommonMission > 0 && (
          <Box>
            <Typography className='title'>필수 약관</Typography>
            <TermPaper>
              <Typography component='div' sx={{ fontWeight: 700, mb: '.5rem' }}>
                프로그램 민감(건강)정보 제공 동의
              </Typography>
              <Typography component='div'>
                스왈라비는 사용자분들의 건강 정보를 바탕으로 맞춤형 건강 증진
                서비스를 제공하기 위해 아래와 같이 사용자의 민감정보를 제
                3자에게 제공하고 있습니다.
              </Typography>
              <Typography component='div'>
                동의를 거부할 권리가 있으며, 동의를 거부하실 경우 프로그램 참가
                및 이용이 제한됩니다.
              </Typography>
              <Box className='flex_start'>
                <Typography component='div'> 2. 제공 받는 자 :</Typography>
                <TextField
                  sx={{ ml: '.5rem', width: '20rem' }}
                  value={companyName}
                  placeholder='(필수)기관명을 입력해 주세요'
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setCompanyName(evt.target.value)}
                />
              </Box>
              <Box className='flex_start'>
                <Typography component='div'>
                  ① 제공 받는 자의 목적 : 회원의 건강 정보를 바탕으로 맞춤형
                  건강 증진 서비스 및 건강 정보 제공,
                </Typography>
                <TextField
                  sx={{ ml: '.5rem', width: '20rem' }}
                  value={addTerm1}
                  placeholder='(선택)추가사항이 있다면 입력해 주세요'
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setAddTerm1(evt.target.value)}
                />
                <Typography component='div'> 등</Typography>
              </Box>
              <Box>
                <Typography component='div'>② 제공 항목 :</Typography>
                <AddGroupTextField
                  sx={{ mt: '.5rem', mb: '.5rem' }}
                  fullWidth
                  multiline
                  rows={4}
                  value={addTerm2}
                  placeholder='(선택) 추가사항이 있다면 입력해주세요.'
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setAddTerm2(evt.target.value)}
                />
              </Box>
              <Typography component='div'>
                ③ 보유 및 이용 기간 : 커뮤니티 탈퇴시 혹은 계약 종료시까지
              </Typography>
            </TermPaper>

            <Typography sx={{ mt: '1rem' }}>약관추가(선택)</Typography>
            <Typography
              color='#999999'
              fontSize='.81rem'
              sx={{ pl: '.15rem', pb: '.5rem' }}
            >
              프로그램별로 운영 약관이 상이할 경우, 약관을 추가하거나 수정할 수
              있습니다.
            </Typography>
            <AddGroupTextField
              fullWidth
              multiline
              rows={4}
              value={extraTerms}
              onChange={evt => setExtraTerms(evt.target.value)}
              placeholder='추가할 내용이 있으시면 이곳에 작성부탁드립니다.'
            />
            <Divider />
          </Box>
        )}
        <Box>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
          >
            <Box>
              <Typography className='title'>상태</Typography>
              <TextField
                sx={{ width: '12rem' }}
                select
                value={programStatus === undefined ? 1 : programStatus}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setProgramStatus(Number(evt.target.value))}
              >
                <MenuItem key={0} value={0}>
                  Close
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Open
                </MenuItem>
              </TextField>
            </Box>
            <Box className='flex_start'>
              <Box>
                <Typography className='title'>프로그램 랭킹 사용</Typography>
                <TextField
                  select
                  sx={{ width: '10rem' }}
                  value={useRank === undefined ? 1 : useRank}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setUseRank(Number(event.target.value));
                    Number(event.target.value) === 0 ? setIsPrivate(0) : '';
                  }}
                >
                  <MenuItem key={0} value={0}>
                    사용안함
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    사용
                  </MenuItem>
                </TextField>
              </Box>
              {useRank === 1 && (
                <Box sx={{ ml: '1.5rem', mt: '1.5rem' }}>
                  <RadioGroup
                    row
                    aria-labelledby='movie-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={
                      isPrivate === 0
                        ? 'user'
                        : isPrivate === 1
                        ? 'hidden'
                        : 'open'
                    }
                    onChange={evt => {
                      evt.currentTarget.value === 'user'
                        ? setIsPrivate(0)
                        : evt.currentTarget.value === 'hidden'
                        ? setIsPrivate(1)
                        : setIsPrivate(2);
                      // evt.currentTarget.value === 'user'
                      //   ? setUseRank(0)
                      //   : setUseRank(1);
                    }}
                  >
                    <FormControlLabel
                      value='user'
                      control={<Radio />}
                      label='사용자 지정'
                    />
                    <FormControlLabel
                      value='hidden'
                      control={<Radio />}
                      label='비공개'
                    />
                    <FormControlLabel
                      value='open'
                      control={<Radio />}
                      label='강제 공개'
                    />
                  </RadioGroup>
                </Box>
              )}
            </Box>
            <Box>
              <Box className='flex_start'>
                <Typography className='title'>앱 리뷰 요청</Typography>
              </Box>
              <Box>
                <RadioGroup
                  row
                  aria-labelledby='movie-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={allowAppReview === 0 ? 'notallow' : 'allow'}
                  onChange={evt => {
                    evt.currentTarget.value === 'allow'
                      ? setAllowAppReview(1)
                      : setAllowAppReview(0);
                  }}
                >
                  <FormControlLabel
                    value='allow'
                    control={<Radio />}
                    label='허용'
                  />
                  <FormControlLabel
                    value='notallow'
                    control={<Radio />}
                    label='허용 안함'
                  />
                </RadioGroup>
              </Box>
            </Box>
          </Stack>
          <Divider />
          <Box sx={{ mt: '1.5rem' }}>
            <ButtonBox className='flex_end'>
              <Box>
                <Button
                  variant='contained'
                  disabled={disabledSaved}
                  onClick={() => saveProgramMaster()}
                >
                  정보 저장
                </Button>
              </Box>
            </ButtonBox>
          </Box>
        </Box>
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwSearchProgram
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      {loading && <SwLoading />}
    </>
  );
};

import {
  Box,
  Button,
  Collapse,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Cloud from '@mui/icons-material/Cloud';
import {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import { IGuideTool } from '../../../models/challenge';
import {
  CurriculumPaper,
  RowsCell,
  MultilineTextField,
} from '../../styles/Styles';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import {
  countArr,
  curriculumType,
  curriculumTypeList,
  pointPerAchieve,
} from './SwProgramCommon';
import { SwMissionMovie } from './SwMissionMovie';
import { SwMissionMagazine } from './SwMissionMagazine';
import { SwMissionLifeStyle } from './SwMissionLifeStyle';
import { SwMissionQna } from './SwMissionQna';
import {
  putProgramCurriculumMissionCopy,
  putProgramCurriculumMissionDelete,
  putProgramCurriculumMissionExport,
  putProgramCurriculumMissionInfo,
  putProgramCurriculumMissionList,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { getMissionTypeString } from '../../../common/helperProgram';
import { SwSelectTemplateCategory } from './SwSelectTemplateCategory';

const DataChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const DetailPaper = styled(Paper)({
  width: '100%',
  padding: '2rem 1.5rem .5rem',
  background: '#F9FAFC',
  border: '1px solid #DFE3E9',
});

const GroupDetailDivider = styled(Divider)({
  padding: 0,
  margin: '.5rem 0 1rem',
});

const formtool = {
  title: '소제목',
  content: '안내내용',
};

const infoType = [
  { id: 99, title: '선택' },
  { id: 0, title: '연락처' },
  { id: 1, title: '카드번호' },
  { id: 2, title: '생년월일' },
  { id: 3, title: '주소' },
  { id: 4, title: '객관식' },
  { id: 5, title: '주관식' },
];

const applyFormTemplate = [
  {
    type: 0,
    title: '연락처',
    content: '연락처를 입력해주세요',
  },
  {
    type: 1,
    title: '카드번호',
    content: '카드번호 16자리를 입력해주세요',
  },
  {
    type: 2,
    title: '생년월일',
    content: '생년월일 6자리를 입력해주세요',
  },
  {
    type: 3,
    title: '주소',
    content: '주소를 입력해주세요',
  },
  {
    type: 4,
    title: '질문을 입력해주세요',
    content: '댭변을 입력해주세요',
  },
  {
    type: 5,
    title: '질문을 입력해주세요',
    content: '안내내용을 입력해주세요',
  },
];

const MAX_FORM_PAGE = 10;

interface Props {
  missionType: number;
  formIndex: number;
  formList: any[];
  setFormList: Dispatch<React.SetStateAction<any[]>>;
  onReloadCurriculum: () => void;
  disabled?: boolean;
}

export const SwCurriculumMissionItem: React.FC<Props> = ({
  missionType,
  formIndex,
  formList,
  setFormList,
  onReloadCurriculum,
  disabled = false,
}) => {
  const location = useLocation();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [missionTitle, setMissionTitle] = useState<string>('');
  const [subIndex, setSubIndex] = useState<number>(0);
  const [type, setType] = useState<number>(99);
  const [formTitle, setFormTitle] = useState<any>('');
  const [formContent, setFormContent] = useState<any>('');
  const [formPage, setFormPage] = useState(1);
  const [successCount, setSuccessCount] = useState<number[]>([1, 1, 1, 1, 1]);
  const [successScore, setSuccessScore] = useState<number[]>([
    10, 10, 10, 10, 10,
  ]);
  const [categoryType, setCategoryType] = useState<number>(0);
  const [selectTarget, setSelectTarget] = useState<any>();
  const [isTemplateCode, setIsTemplateCode] = useState<boolean>(true);
  const [options, setOptions] = useState<any[]>([]);
  const [optionList, setOptionList] = useState<any[]>([]);
  const [optionsCount, setOptionsCount] = useState<number>(0);
  const [localFormIndex, setLocalFormIndex] = useState<number>(0);
  const [detailInfo, setDetailInfo] = useState<any>();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openMissionMovie, setOpenMissionMovie] = useState<boolean>(false);
  const [openMissionMagazine, setOpenMissionMagazine] =
    useState<boolean>(false);
  const [openMissionQna, setOpenMissionQna] = useState<boolean>(false);
  const [openMissionLifeStyle, setOpenMissionLifeStyle] =
    useState<boolean>(false);
  const [openTemplateCategory, setOpenTemplateCategory] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    console.log('select menu idx :', idx);
    setAnchorEl(event.currentTarget);
    setMissionTitle(formList[formIndex][idx].missionTitle);
    setSubIndex(idx);
  };

  const handleClose = () => {
    setMissionTitle('');
    setAnchorEl(null);
    setSelectTarget('');
  };

  const {
    mutate: putProgramCurriculumMissionListMutate,
    isError: isMutateListError,
  } = useMutation(putProgramCurriculumMissionList, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('순서가 변경되었습니다.');
        onReloadCurriculum();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `순서를 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('순선변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: putProgramCurriculumMissionInfoMutate,
    isError: isMutateInfoError,
  } = useMutation(putProgramCurriculumMissionInfo, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('미션정보가 변경되었습니다.');
        onReloadCurriculum();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `미션정보를 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '미션정보변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteProgramCurriculumMissionMutate,
    isError: isMutateDeleteError,
  } = useMutation(putProgramCurriculumMissionDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제되었습니다.');
        onReloadCurriculum();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: copyProgramCurriculumMissionMutate,
    isError: isMutateCloneError,
  } = useMutation(putProgramCurriculumMissionCopy, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('복사되었습니다.');
        onReloadCurriculum();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `복사하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('복사가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: exportProgramCurriculumMissionMutate,
    isError: isMutateExportError,
  } = useMutation(putProgramCurriculumMissionExport, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('복사되었습니다.');
        onReloadCurriculum();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `복사하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('복사가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const deleteMission = () => {
    const newMissionData = {
      weekId: formList[formIndex][subIndex].weekId,
      missionId: formList[formIndex][subIndex].missionId,
      type: getMissionTypeString(formList[formIndex][subIndex].missionType),
    };
    deleteProgramCurriculumMissionMutate(newMissionData);

    handleClose();
  };

  const copyMission = () => {
    const newMissionData = {
      weekId: formList[formIndex][subIndex].weekId,
      missionId: formList[formIndex][subIndex].missionId,
      type: getMissionTypeString(formList[formIndex][subIndex].missionType),
    };
    copyProgramCurriculumMissionMutate(newMissionData);

    handleClose();
  };

  const exportMission = (target: any) => {
    const newMissionData = {
      categoryId: target,
      missionId: formList[formIndex][subIndex].missionId,
      type: getMissionTypeString(formList[formIndex][subIndex].missionType),
    };
    exportProgramCurriculumMissionMutate(newMissionData);

    handleClose();
  };

  const updateMissionSequenceList = (newItemList: any[]) => {
    const newMissionList: any[] = [];

    newItemList.map((item: any, i: number) =>
      newMissionList.push({
        missionId: item.missionId,
        seq: i,
      })
    );

    const newData = {
      weekList: [],
      missionList: newMissionList,
    };
    putProgramCurriculumMissionListMutate(newData);
  };

  const updateMissionInfo = (newItem: any) => {
    putProgramCurriculumMissionInfoMutate(newItem);
  };

  function addFormInfo() {
    if (formTitle.length > 0 && formContent.length > 0) {
      const newTitle = formTitle;
      const newContent = formContent;
      const newFormLists = formList;
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        title: newTitle,
        content: newContent,
      };
      return newFormLists;
    }
    return [];
  }
  const saveOptions = (opts: any[]) => {
    const newOptions = opts.map(opt => opt);
    const newFormList = formList.map((frm: any, idx: number) => {
      const newData =
        idx === formIndex
          ? {
              type: Number(frm.type),
              title: frm.title,
              content: frm.content,
              options: newOptions,
            }
          : frm;
      return newData;
    });
    setFormList(newFormList);
  };

  const addFormOptions = () => {
    const newOptions = [...options, { idx: 0, content: '' }];
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const changeOption = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const newOptions = options.map((opt: any, i: number) => {
      const newData =
        i === idx
          ? {
              idx: i,
              content: evt.target.value,
            }
          : opt;
      return newData;
    });
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const removeFormOption = (idx: number) => {
    const newOptions = options.filter((_form, i) => i !== idx);
    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const changePageContents = () => {
    // console.log('ChangePageContents>>>>>>> :', guideLists);
    if (formIndex === undefined || formIndex === null) return;

    setFormTitle(formList[formIndex] ? formList[formIndex].title : '-');
    setFormContent(formList[formIndex] ? formList[formIndex].content : '-');
    const newSuccessCount: any[] = [];
    const newSuccessScore: any[] = [];
    formList[formIndex] !== undefined &&
      formList[formIndex].map((item: any, idx: number) => {
        newSuccessCount.push(item.successCount);
        newSuccessScore.push(item.successScore);
      });
    setSuccessCount(newSuccessCount);
    setSuccessScore(newSuccessScore);
  };

  const addFormView = () => {
    if (formList.length === MAX_FORM_PAGE) {
      setDialogMessage(
        `리워드 폼은 최대 ${MAX_FORM_PAGE}까지 추가할 수 있습니다.`
      );
      setOpenSnackbar(true);
      return;
    }

    if (type !== 99) {
      const newFormData = applyFormTemplate[type];
      const isExist = formList.filter(
        (item: any) => Number(item.type) === type
      );
      console.log('formList:', formList);
      console.log('type:', type, ', isExist:', isExist);
      if (
        (type === 0 || type === 1 || type === 2 || type === 3) &&
        isExist !== undefined &&
        isExist.length > 0
      ) {
        setDialogMessage(`중복 추가 불가합니다.`);
        setOpenSnackbar(true);
        return;
      }
      const newForm = [...formList, newFormData];
      setFormList(newForm);
    }
  };

  const updateFormView = () => {
    if (formTitle.length > 0 && formContent.length > 0) {
      const newTitle = formTitle;
      const newContent = formContent;
      const newFormLists = formList;
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        title: newTitle,
        content: newContent,
      };
      setFormList(newFormLists);
      console.log('updateFormView :', newFormLists);
    }
  };

  const removeFormView = (idx: number) => {
    console.log(formPage);
    changePageContents();
    const newForm = formList.filter((_form: any, i: number) => i !== idx);
    // const newGuidePage = guidePage - 1 < 1 ? guidePage + 1 : guidePage - 1;
    setFormList(newForm);
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    // groupItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newFormIndex = Number(evt.currentTarget.dataset.position);
    // setListEvent({
    //   ...listEvent,
    //   clicked: newContain ? newFormIndex : null,
    // });
    setLocalFormIndex(newFormIndex);
    const newFormData = formList[newFormIndex];
    setFormTitle(newFormData.title);
    setFormContent(newFormData.content);
  };

  const topMove = (idx: number) => {
    console.log('top idex :', idx);
    const newList = [...formList[formIndex]];
    const tmp = newList[idx];
    newList[idx] = newList[idx - 1];
    newList[idx - 1] = tmp;

    const newFormList = formList.map((item: any, i: number) => {
      if (i === formIndex) return newList;
      return item;
    });
    setFormList(newFormList);
    updateMissionSequenceList(newList);
  };

  const bottomMove = (idx: number) => {
    console.log('bottom idex :', idx);
    const newList = [...formList[formIndex]];
    const tmp = newList[idx];
    newList[idx] = newList[idx + 1];
    newList[idx + 1] = tmp;

    const newFormList = formList.map((item: any, i: number) => {
      if (i === formIndex) return newList;
      return item;
    });
    setFormList(newFormList);
    updateMissionSequenceList(newList);
  };

  const openDetailInfo = (idx: number, isEdit: number) => {
    console.log('missionType:', missionType);
    const newData = formList[formIndex][idx];
    newData.isEdit = isEdit;
    setDetailInfo(newData);
    missionType === curriculumType.movie
      ? setOpenMissionMovie(true)
      : missionType === curriculumType.magazine
      ? setOpenMissionMagazine(true)
      : missionType === curriculumType.qna
      ? setOpenMissionQna(true)
      : missionType === curriculumType.lifestyle
      ? setOpenMissionLifeStyle(true)
      : '';
  };

  const updateMission = () => {
    openDetailInfo(subIndex, 2);
    handleClose();
  };

  const changeSuccessCount = (idx: number, count: number) => {
    const newSuccessCount = [...successCount];
    newSuccessCount[idx] = count;
    setSuccessCount(newSuccessCount);

    const newList: any = [...formList[formIndex]];
    newList[idx].successCount = count;

    const newFormList = formList.map((item: any, i: number) => {
      if (i === formIndex) return newList;
      return item;
    });
    setFormList(newFormList);
    updateMissionInfo(newList[idx]);
  };

  const changeSuccessScore = (idx: number, score: number) => {
    const newSuccessScore = [...successScore];
    newSuccessScore[idx] = score;
    setSuccessScore(newSuccessScore);

    const newList: any = [...formList[formIndex]];
    newList[idx].successScore = score;

    const newFormList = formList.map((item: any, i: number) => {
      if (i === formIndex) return newList;
      return item;
    });
    setFormList(newFormList);

    updateMissionInfo(newList[idx]);
  };

  const selectCategory = () => {
    const newCategoryType =
      missionType === curriculumType.movie
        ? 1
        : missionType === curriculumType.magazine
        ? 2
        : missionType === curriculumType.qna &&
          formList[formIndex][subIndex].qnaType === 1
        ? 3
        : missionType === curriculumType.lifestyle
        ? 4
        : missionType === curriculumType.qna &&
          formList[formIndex][subIndex].qnaType === 2
        ? 5
        : 0;
    if (newCategoryType === 0) {
      setOpenSnackbar(true);
      setDialogMessage('잘못된 미션 타입입니다.');
      return;
    }
    setCategoryType(newCategoryType);
    setOpenTemplateCategory(true);
  };

  useEffect(() => {
    if (selectTarget !== undefined && selectTarget !== '') {
      setOpenTemplateCategory(false);
      exportMission(selectTarget);
    }
  }, [selectTarget]);

  useEffect(() => {
    if (formList != null && formList.length > 0) {
      changePageContents();
    }

    location.pathname.includes('templatecode')
      ? setIsTemplateCode(true)
      : setIsTemplateCode(false);
  }, [formList]);

  return (
    <>
      <Box>
        {formList[formIndex]?.map((item: any, idx: number) => (
          <Box className='flex_between' key={`form_${missionType}_${idx}`}>
            <CurriculumPaper
              id={idx.toString()}
              variant='outlined'
              data-position={idx}
              sx={{ width: '100%', margin: '.2rem .7rem' }}
            >
              <Box className='flex_center' sx={{ width: '10%' }}>
                <Typography
                  className='title'
                  sx={{
                    background: '#E3EEFF',
                    borderRadius: '6px',
                  }}
                >
                  {missionType === curriculumType.movie
                    ? curriculumTypeList[curriculumType.movie].title
                    : missionType === curriculumType.magazine
                    ? curriculumTypeList[curriculumType.magazine].title
                    : missionType === curriculumType.qna
                    ? curriculumTypeList[curriculumType.qna].title
                    : missionType === curriculumType.lifestyle
                    ? curriculumTypeList[curriculumType.lifestyle].title
                    : ''}
                </Typography>
              </Box>
              <Box sx={{ width: '40%' }}>
                <Typography className='title'>
                  {item.missionTitle !== undefined
                    ? `${idx + 1}.  ${item.missionTitle}`
                    : ''}
                </Typography>
              </Box>
              <Box className='flex_between' sx={{ width: '50%' }}>
                <TextField
                  disabled={disabled}
                  select
                  value={
                    item.successCount !== undefined ? item.successCount : 1
                  }
                  sx={{ width: '7rem' }}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    changeSuccessCount(idx, Number(evt.target.value));
                  }}
                >
                  {countArr
                    .filter((option: any, cIdx: number) =>
                      missionType === curriculumType.movie ||
                      missionType === curriculumType.magazine ||
                      missionType === curriculumType.qna
                        ? cIdx < 1
                        : true
                    )
                    .map((option: any, cIdx: number) => (
                      <MenuItem key={`count_${cIdx}`} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  disabled={disabled}
                  select
                  value={
                    item.successScore !== undefined ? item.successScore : 10
                  }
                  sx={{ width: '7rem' }}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    changeSuccessScore(idx, Number(evt.target.value));
                  }}
                >
                  {pointPerAchieve.map((option: any, cIdx: number) => (
                    <MenuItem key={`health_${cIdx}`} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  variant='outlined'
                  color='info'
                  size='small'
                  sx={{ width: '7rem' }}
                  onClick={() => openDetailInfo(idx, 1)}
                >
                  상세보기
                </Button>
                <Button
                  variant='outlined'
                  color='info'
                  disabled={
                    idx === 0 || formList[formIndex].length === 1 || disabled
                  }
                  onClick={() => topMove(idx)}
                >
                  <ExpandLessOutlinedIcon />
                </Button>
                <Button
                  variant='outlined'
                  color='info'
                  disabled={
                    idx === formList[formIndex].length - 1 ||
                    formList[formIndex].length === 1 ||
                    disabled
                  }
                  onClick={() => bottomMove(idx)}
                >
                  <ExpandMoreOutlinedIcon />
                </Button>
                <Button
                  disabled={isTemplateCode && disabled}
                  color='info'
                  onClick={e => {
                    handleClick(e, idx);
                  }}
                >
                  <MoreVertIcon />
                </Button>
                <Menu
                  id={`basic-menu-${idx}`}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    disabled={disabled}
                    onClick={() => {
                      copyMission();
                    }}
                  >
                    <ListItemIcon>
                      <ContentCopy fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>복사</ListItemText>
                  </MenuItem>
                  <MenuItem
                    disabled={disabled}
                    onClick={() => {
                      updateMission();
                    }}
                  >
                    <ListItemIcon>
                      <EditOutlinedIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>수정</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenConfirm(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutlineIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>삭제</ListItemText>
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      selectCategory();
                    }}
                  >
                    <ListItemIcon>
                      <TrendingFlatIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>내보내기</ListItemText>
                  </MenuItem> */}
                </Menu>
              </Box>
            </CurriculumPaper>
          </Box>
        ))}
      </Box>
      <SwMissionMovie
        disabled={disabled}
        data={detailInfo}
        setData={setDetailInfo}
        open={openMissionMovie}
        onClose={() => {
          setOpenMissionMovie(false);
        }}
        onSave={() => {
          // saveMissionMovie();
          setOpenMissionMovie(false);
          onReloadCurriculum();
        }}
        onDelete={() => {
          // saveMissionMovie();
          setOpenMissionMovie(false);
          onReloadCurriculum();
        }}
      />
      <SwMissionMagazine
        disabled={disabled}
        data={detailInfo}
        setData={setDetailInfo}
        open={openMissionMagazine}
        onClose={() => {
          setOpenMissionMagazine(false);
          onReloadCurriculum();
        }}
        onSave={() => {
          setOpenMissionMagazine(false);
          onReloadCurriculum();
        }}
      />
      <SwMissionQna
        disabled={disabled}
        data={detailInfo}
        setData={setDetailInfo}
        open={openMissionQna}
        onClose={() => {
          setOpenMissionQna(false);
          onReloadCurriculum();
        }}
        onSave={() => {
          setOpenMissionQna(false);
          onReloadCurriculum();
        }}
      />
      <SwMissionLifeStyle
        disabled={disabled}
        data={detailInfo}
        setData={setDetailInfo}
        open={openMissionLifeStyle}
        onClose={() => {
          setOpenMissionLifeStyle(false);
          onReloadCurriculum();
        }}
        onSave={() => {
          setOpenMissionLifeStyle(false);
          onReloadCurriculum();
        }}
      />
      <SwConfirmDialog
        contents={`미션 ‘${missionTitle}’을/를 삭제하시겠습니까?`}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          handleClose();
          deleteMission();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
      <SwSelectTemplateCategory
        open={openTemplateCategory}
        onClose={() => setOpenTemplateCategory(false)}
        categoryType={categoryType}
        setTarget={setSelectTarget}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
